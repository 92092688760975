import { getAxiosInstance } from "../Config/axios";

export const getPolicyService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'policy/get/all'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const getPolicyByCompanyService = async (companyId) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: `policy/by-company/${companyId}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const editPolicyService = async ({ data, id }) => {
    try {
        const method = 'PUT';
        const url = `policy/${id}`;
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const destroyPolicyService = async (id) => {
    try {
        const method = 'DELETE';
        const url = `policy/${id}`;
        const axios = getAxiosInstance();
        const options = {
            method,
            url
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};


export const createPolicyService = async ({ data }) => {
    try {
        const method = 'POST';
        const url = 'policy';
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const editPolicyCostsService = async ({ data, id }) => {
    try {
        const method = 'PUT';
        const url = `policy/all/amount/${id}`;
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};
