import React, { useState, useEffect, useRef, Fragment } from 'react'
import LayoutHeaderPage from '../../../Layouts/header_page'
import GenerateForm from '../../../Components/Forms/generate_form'
import { PrintFormsPartTwo } from '../../../Config/Forms/Print'
import Reload from '../../../Assets/Img/Icons/reload.svg'
import Print from '../../../Assets/Img/Icons/print-menu.svg'
import { getMember, reducerName as userReducerName } from '../../../Store/Reducers/member'
import { useDispatch, useSelector } from 'react-redux'
import { getPrintEnvelopesByMember, getPrintLabelByMember, reducerName as printReducerName  } from '../../../Store/Reducers/print'

const PrintLabelsAndEnvelopesByUsers = () => {
  const dispatch = useDispatch();
  const getMemberRequest = useSelector((state)=> state[userReducerName].getMemberRequest)
  const printEnvelopesByMemberRequest = useSelector((state)=> state[printReducerName].printEnvelopesByMemberRequest)
  const printLabelByMemberRequest = useSelector((state)=> state[printReducerName].printLabelByMemberRequest)

  const [memberIds, setMemberIds] = useState([]);
	const searchSelectControl = useRef();

  useEffect(() => {
    dispatch(getMember());
  }, [dispatch]);

  const PrintLabels = (e) => {
    e.preventDefault();
    dispatch(getPrintLabelByMember({
      parameters: {
        member_ids: memberIds
      }
    }));
  }

  const PrintEnvelopes = (e) => {
    e.preventDefault();
    dispatch(getPrintEnvelopesByMember({
      parameters: {
        member_ids: memberIds
      }
    }));
  }

	const handleReset = () => {
		searchSelectControl?.current?.clearValue();
		setMemberIds([]);
	}

  return (<>
    <LayoutHeaderPage title='Print Labels and Envelopes By Users'/>
    <div className='tab-content m3-4' id='pills-tabContent'>
      <div className='row'>
        {
          PrintFormsPartTwo.map((section, i) =>
            <Fragment key={i}>
              <label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
              {
                section.forms.map((form, i) =>
                  <GenerateForm
                    key={i}
                    {...form}
                    isLoading={getMemberRequest}
                    defaultValue={memberIds}
                    refVar={searchSelectControl}
                    onChange={(e)=> setMemberIds(e.target.value)}/> )
              }
            </Fragment>)
        }
        <div className='col-12 mb-2'>
          <div className='row'>
            <div className='col-12'>
              <button type='button' disabled={!memberIds.length} onClick={handleReset} className='btn-blue btn-default btn-reload-report mt-3 mb-2'>
                <img className='icon-buttom-refresh-layout-header' src={Reload} alt='Reload'/>
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className='col-sm-4 col-md-3 mt-2 col-8 mobile-col-offset-2 mb-1'>
          <button style={{textAlign: 'left',paddingLeft: '12px'}} type='submit' className='btn-orange btn btn-default btn-primary' disabled={!memberIds.length || printEnvelopesByMemberRequest || printLabelByMemberRequest} onClick={PrintLabels}><img className='icon-info-user pa-2' alt='login icon' src={Print} /> Print labels</button>
        </div>
        <div className='col-sm-4 col-md-3 mt-2 col-8 mobile-col-offset-2 mb-5'>
          <button style={{textAlign: 'left',paddingLeft: '12px'}} type='submit' className='btn-blue btn btn-default btn-primary' disabled={!memberIds.length || printEnvelopesByMemberRequest || printLabelByMemberRequest} onClick={PrintEnvelopes}><img className='icon-info-user pa-2' alt='login icon' src={Print} /> Print Envelopes</button>
        </div>
      </div>
    </div>
  </>)
}

export default PrintLabelsAndEnvelopesByUsers;
