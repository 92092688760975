import { getAxiosInstance } from "../Config/axios";

export const getPositionService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'position/get/all'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const editPositionService = async ({ data, id }) => {
    try {
        const method = 'PUT';
        const url = `position/${id}`;
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const destroyPositionService = async ({ id }) => {
    try {
        const method = 'DELETE';
        const url = `position/${id}`;
        const axios = getAxiosInstance();
        const options = {
            method,
            url
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const createPositionService = async ({ data }) => {
    try {
        const method = 'POST';
        const url = 'position';
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};
