import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setDataFiltered,
	clearDataFiltered,
	reducerName as officerReducerName
} from './../../../../Store/Reducers/officer';
import InputSearch from './../../../../Components/Forms/input_search';

const TableHeader = () => {
	const dispatch = useDispatch();
	const officerData = useSelector((state)=> state[officerReducerName].data || []);
	const officerDataFiltered = useSelector((state)=> state[officerReducerName].dataFiltered);

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();

		return data.name?.toLowerCase().includes(searchTerm) || data.cost?.toString()?.toLowerCase().includes(searchTerm);
	}


	return <>
		<div className="col-12 col-sm-7 col-md-6 col-lg-7">
		</div>
		<div className="col-3 col-md-2 text-center" style={{padding:0,margin:0}}>
			<label htmlFor='searchTable' className='form-label mt-2'>Search: </label>
		</div>
		<div className="col-8 col-md-3" style={{padding:0,margin:0}}>
			<InputSearch
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						officerDataFiltered !== null && dispatch(clearDataFiltered(
							null
						));
						return;
					}

					dispatch(setDataFiltered(
						officerData.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</>
};

export default TableHeader;
