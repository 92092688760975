import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { reducerName as memberReducerName } from './../../../Store/Reducers/member';
import {useNavigate} from 'react-router-dom';
import { setActiveTabSlug } from '././../../../Store/Reducers/memberForm';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

const Benefits = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const dashboardData = useSelector((state)=> state[memberReducerName]?.getDashboard || {});
	const graphicData = dashboardData.graphic || {boxs: [], data: []};

	const handleClickToMemberForm = (slug) =>{
		dispatch(setActiveTabSlug({slug}));
		navigate('/dashboard/members_member_management')
		
	}
	
	return (<>
	<div className='col-12 col-md-4'>
		<div className='row mb-5'>
			<div className="col-6">
				<legend>Benefits</legend>
			</div>
			<div className="col-6" style={{textAlign:'right'}}>
				<button onClick={() => handleClickToMemberForm('deductions')} className='gray-button'>
					<FontAwesomeIcon style={{marginRight: '3px', fontSize: '1.4em'}} icon={faEdit} /> Details
				</button>
			</div>
			<div hidden={graphicData.total === 0} className='row' style={{ maxHeight: '650px', overflow: 'auto' }}>
				{ graphicData.boxs?.map((boxData, index)=> <div key={index} className='row mb-3'>
						<div className="col-3">
							<div className='policyColor' style={{background: graphicData.background[index]}}></div>
						</div>
						<div className="col-9">
							<legend>{boxData.company}</legend>
							<span>{boxData.policy}</span>
						</div>
				</div>) }
			</div>
			<div hidden={graphicData.total !== 0} className='row'><center><FontAwesomeIcon style={{marginRight: '3px', fontSize: '1.4em'}} icon={faFolderOpen} /></center></div>
		</div>
	</div>
	</>)
}

export default Benefits