import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Datepicker = ({
  onChange = () => {},
  onKeyUp = () => {},
  defaultValue = ''
}) => {
	return <>
		<FontAwesomeIcon className='search-dropdown' color='#cccccc' icon={faSearch} />
    <input
      onChange={onChange}
      onKeyUp={onKeyUp}
      disabled={false}
      type='text'
      defaultValue={defaultValue}
      placeholder={'Search'}
      className='form-control search-dropdown-input'
      name={'searchTable'}
      id={`searchTable`}
      aria-describedby="Search in table"
    />
   
	</>
};

export default Datepicker;
