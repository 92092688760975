import React, { useState } from 'react';
import NoteTable from './table';
import NewNoteForm from './newNoteForm';

const MemberNotes = () => {
  
  const [showAddNoteForm, setShowAddNoteForm] = useState(false);
  const [showNoteTable, setShowNoteTable] = useState(true);

  const handleShowAddNote = () => {
    setShowAddNoteForm(true);
    setShowNoteTable(false);
  }

  const handleShowNoteTable = () => {
    setShowAddNoteForm(false);
    setShowNoteTable(true);
  }

  return (
    <div className='row'>
      {
        showAddNoteForm ?
        <NewNoteForm showNoteTable={handleShowNoteTable} /> :
        null
      }
      {
        showNoteTable ?
        <NoteTable showAddNote={handleShowAddNote} /> :
        null
      }
    </div>
  )
}

export default MemberNotes;
