import React, { useState, useContext } from 'react'
import LayoutHeaderPage from '../../../Layouts/header_page'
import UploadTemplate from '../../../Components/upload_template'
import Notification from '../../../Components/notification'
import { downloadExcelBase64File } from '../../../Helpers'
import { memberAdd, reducerName as bulkMemberReducerName } from '../../../Store/Reducers/bulkMember'
import moment from 'moment'
import { ReactReduxContext, useSelector } from 'react-redux';
const initData = {
	title: 'Upload Member_IAFF_Report.xlsx',
	textOne: 'Download the template file. Populate with new members you wish to add to the system making sure to include all the same required fields used to manually add members to the system. Save and upload the file using the dialog below. Click the "Process" button to execute the data update.',
	textTwo: 'If a record in the data file does not exist, a new member will be created in the application mapped to the associated IAFF #.' ,
	labels: []
};

const BulkMemberAdd = () => {
	const { store } = useContext(ReactReduxContext);
	const bulkMemberData = useSelector((state)=> state[bulkMemberReducerName].bulkMemberData);
	const [file, setFile] = useState();

	const sendData = () => {
		const formData = new FormData();
		formData.append('file', file);

		store.dispatch(memberAdd(formData));
	};

	const handleDownloadErrors = () => {
		const date = moment().format('L')
		downloadExcelBase64File(bulkMemberData.file.replace('data:application/vnd.ms-excel;base64,', ''), `MembersErrors-${date}.xlsx`);
	};

	return (
		<>
			<LayoutHeaderPage title="Bulk Member Add"/>
			<div className="tab-content mt-3" id="pills-tabContent">
				<UploadTemplate fileData={setFile} initialData={initData} showInfo={true}/>
				<Notification />
				<div className="row pb-3 pt-3">
					<div className="col-12 col-md-3 mb-3 hide-on-mobile">
					</div>
					<div className="col-12 col-md-3 mb-3 hide-on-mobile">
					</div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className="col-8 col-md-3 mb-3">
						<button type="button" disabled={!bulkMemberData?.file} onClick={handleDownloadErrors} className="btn-orange btn btn-default btn-primary">Download Errors</button>
					</div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className="col-8 col-md-3 mb-3">
						<button type="submit" onClick={sendData} className="btn-orange btn btn-default btn-primary">Process</button>
					</div>
				</div>				
			</div>
		</>
	)
}

export default BulkMemberAdd