import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addressUpdateService,
  memberAddService,
  downloadMemberTemplateService,
  downloadMemberBOCCService,
  downloadMemberAssignmentService,
  memberBOCCService,
  memberAssignmentService
} from './../../Services/bulkMemberService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'bulkMember';

const initialState = {

  bulkAddressRequest: false,
  bulkAddressError: null,
  bulkAddressData: null,

  bulkMemberRequest: false,
  bulkMemberError: null,
  bulkMemberData: null,

  downloadMemberTemplateRequest: true,
  downloadMemberTemplateError: null,
  downloadMemberTemplateData: null,

  downloadMemberBOCCRequest: true,
  downloadMemberBOCCError: null,
  downloadMemberBOCCData: null,

  downloadMemberAssignmentRequest: true,
  downloadMemberAssignmentError: null,
  downloadMemberAssignmentData: null,

  bulkMemberBOCCRequest: false,
  bulkMemberBOCCError: null,
  bulkMemberBOCCData: null,

  bulkMemberAssignmentRequest: false,
  bulkMemberAssignmentError: null,
  bulkMemberAssignmentData: null

};

export const addressUpdate = createAsyncThunk(
  'bulkMember/addressUpdate',
  async (bulkMemberData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await addressUpdateService(bulkMemberData);

      !response.file && !response.members_error && dispatch(showNotification({
        message: `The data was successfully updated. Members updated: ${response.members_success}`,
        type: 'SUCCESS'
      }));

      response.file && response.members_error && dispatch(showNotification({
        message: `Members who have not been updated: ${response.members_error}`,
        type: 'ERROR'
      }));

      return {
        data: response
      };
    } catch (error) {

      const messageError = typeof error?.message === 'object' ? error.message[Object.keys(error?.message)?.[0]]?.[0] : error.message;

      dispatch(showNotification({
        message: messageError,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const memberAdd = createAsyncThunk(
  'bulkMember/memberAdd',
  async (bulkMemberData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await memberAddService(bulkMemberData);

      !response.file && !response.members_error && dispatch(showNotification({
        message: `The data was successfully updated. Members updated: ${response.members_success}`,
        type: 'SUCCESS'
      }));

      response.file && response.members_error && dispatch(showNotification({
        message: `Members who have not been updated: ${response.members_error}`,
        type: 'ERROR'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const downloadMemberTemplate = createAsyncThunk(
  'bulkMember/downloadMemberTemplate',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await downloadMemberTemplateService();

      return {
        data: response
      };
    } catch (error) {

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const downloadMemberBOCC = createAsyncThunk(
  'bulkMember/downloadMemberBOCC',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await downloadMemberBOCCService();

      return {
        data: response
      };
    } catch (error) {

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const downloadMemberAssignment = createAsyncThunk(
  'bulkMember/downloadMemberAssignment',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await downloadMemberAssignmentService();

      return {
        data: response
      };
    } catch (error) {

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const memberBOCC = createAsyncThunk(
  'bulkMember/memberBOCC',
  async (bulkMemberData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await memberBOCCService(bulkMemberData);

      !response.file && !response.members_error && dispatch(showNotification({
        message: `The data was successfully updated. Members updated: ${response.members_success}`,
        type: 'SUCCESS'
      }));

      response.file && response.members_error && dispatch(showNotification({
        message: `Members who have not been updated: ${response.members_error}`,
        type: 'ERROR'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const memberAssignment = createAsyncThunk(
  'bulkMember/memberAssignment',
  async (bulkMemberData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await memberAssignmentService(bulkMemberData);

      !response.file && !response.members_error && dispatch(showNotification({
        message: `The data was successfully updated. Members updated: ${response.members_success}`,
        type: 'SUCCESS'
      }));

      response.file && response.members_error && dispatch(showNotification({
        message: `Members who have not been updated: ${response.members_error}`,
        type: 'ERROR'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const bulkMemberReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {

    builder.addCase(addressUpdate.fulfilled, (state, action) => {
      state.bulkAddressData = action.payload?.data;
      state.bulkAddressRequest = false;
    })

    builder.addCase(addressUpdate.rejected, (state, action) => {
      state.bulkAddressError = action.payload.error;
      state.bulkAddressRequest = false;
      state.bulkAddressData = null;
    })

    builder.addCase(addressUpdate.pending, (state) => {
      state.bulkAddressRequest = true;
      state.bulkAddressError = null;
      state.bulkAddressData = null;
    })

    builder.addCase(memberAdd.fulfilled, (state, action) => {
      state.bulkMemberData = action.payload?.data;
      state.bulkMemberRequest = false;
    })

    builder.addCase(memberAdd.rejected, (state, action) => {
      state.bulkMemberError = action.payload.error;
      state.bulkMemberRequest = false;
      state.bulkMemberData = null;
    })

    builder.addCase(memberAdd.pending, (state) => {
      state.bulkMemberRequest = true;
      state.bulkMemberError = null;
      state.bulkMemberData = null;
    })

    builder.addCase(downloadMemberTemplate.fulfilled, (state, action) => {
      state.downloadMemberTemplateData = action.payload?.data;
      state.downloadMemberTemplateRequest = false;
    })

    builder.addCase(downloadMemberTemplate.rejected, (state, action) => {
      state.downloadMemberTemplateError = action.payload.error;
      state.downloadMemberTemplateRequest = false;
      state.downloadMemberTemplateData = null;
    })

    builder.addCase(downloadMemberTemplate.pending, (state) => {
      state.downloadMemberTemplateRequest = true;
      state.downloadMemberTemplateError = null;
      state.downloadMemberTemplateData = null;
    })

    builder.addCase(downloadMemberBOCC.fulfilled, (state, action) => {
      state.downloadMemberBOCCData = action.payload?.data;
      state.downloadMemberBOCCRequest = false;
    })

    builder.addCase(downloadMemberBOCC.rejected, (state, action) => {
      state.downloadMemberBOCCError = action.payload.error;
      state.downloadMemberBOCCRequest = false;
      state.downloadMemberBOCCData = null;
    })

    builder.addCase(downloadMemberBOCC.pending, (state) => {
      state.downloadMemberBOCCRequest = true;
      state.downloadMemberBOCCError = null;
      state.downloadMemberBOCCData = null;
    })

    builder.addCase(downloadMemberAssignment.fulfilled, (state, action) => {
      state.downloadMemberAssignmentData = action.payload?.data;
      state.downloadMemberAssignmentRequest = false;
    })

    builder.addCase(downloadMemberAssignment.rejected, (state, action) => {
      state.downloadMemberAssignmentError = action.payload.error;
      state.downloadMemberAssignmentRequest = false;
      state.downloadMemberAssignmentData = null;
    })

    builder.addCase(downloadMemberAssignment.pending, (state) => {
      state.downloadMemberAssignmentRequest = true;
      state.downloadMemberAssignmentError = null;
      state.downloadMemberAssignmentData = null;
    })

    builder.addCase(memberBOCC.fulfilled, (state, action) => {
      state.bulkMemberBOCCData = action.payload?.data;
      state.bulkMemberBOCCRequest = false;
    })

    builder.addCase(memberBOCC.rejected, (state, action) => {
      state.bulkMemberBOCCError = action.payload.error;
      state.bulkMemberBOCCRequest = false;
      state.bulkMemberBOCCData = null;
    })

    builder.addCase(memberBOCC.pending, (state) => {
      state.bulkMemberBOCCRequest = true;
      state.bulkMemberBOCCError = null;
      state.bulkMemberBOCCData = null;
    })

    builder.addCase(memberAssignment.fulfilled, (state, action) => {
      state.bulkMemberAssignmentData = action.payload?.data;
      state.bulkMemberAssignmentRequest = false;
    })

    builder.addCase(memberAssignment.rejected, (state, action) => {
      state.bulkMemberAssignmentError = action.payload.error;
      state.bulkMemberAssignmentRequest = false;
      state.bulkMemberAssignmentData = null;
    })

    builder.addCase(memberAssignment.pending, (state) => {
      state.bulkMemberAssignmentRequest = true;
      state.bulkMemberAssignmentError = null;
      state.bulkMemberAssignmentData = null;
    })
  }
});

export default bulkMemberReducer.reducer;
