import React, { useState, useRef, useContext, Fragment } from 'react'
import GenerateForm from '../Components/Forms/generate_form'
import { CountyAssessmentFormPartOne } from '../Config/Forms/Report/county_assessment'
import { downloadMemberTemplate, downloadMemberBOCC, downloadMemberAssignment } from './../Store/Reducers/bulkMember'
import { ReactReduxContext } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const UploadTemplate = (props) => {
	const { store } = useContext(ReactReduxContext);
	const fileInput = useRef(null);
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);

	const downloadTemplate = (type) => {
		switch (type) {
			case 'Upload Member_BOCC_Report.xlsx':
					store.dispatch(downloadMemberBOCC())
				break;

			case 'Upload Member_Assignment_Report.xlsx':
					store.dispatch(downloadMemberAssignment())
				break;

			case 'Upload Member_IAFF_Report.xlsx':
					store.dispatch(downloadMemberTemplate())
				break;
			default:
				break;
		}
	}

	const onFileChange = event => {
		setIsFilePicked(false)
		setSelectedFile(null);
		props.fileData(null)

		if(event.target.files.length > 0){
			setIsFilePicked(true)
			setSelectedFile(event.target.files[0]);
			props.fileData(event.target.files[0])
		}
	};

	const openSelectFiles = (event) => {
		fileInput.current.click()
	}

	return (
		<>
			<div className="row mt-2">
			{
				props.showInfo ?
				<>
					<label className="title-component-upload-template">{props.initialData.title}</label>
					<p className="text-component-upload-template mt-3">{props.initialData.textOne}</p>
					<p className="text-component-upload-template mt-1">{props.initialData.textTwo}</p>
					{
						props.initialData.labels.map((label, i) =>
							<div key={i} className="row">
								{
									label.section.map((item, i) => 
										<div key={i} className="col-6 col-md-3 col-lg-2 col-sm-4 mb-3">
											<label className="label-component-upload-template">{item.name}</label>
										</div>								
									)
								}
							</div>				
						)
					}
					{
						!props.downloadTemplate ?
							<>
								<div className="col-2 show-on-mobile"></div>
								<div className="col-8 col-md-3 mb-4 mt-3">
									<button onClick={() => downloadTemplate(props.initialData.title)} className="btn-blue btn btn-default btn-primary">
										<FontAwesomeIcon style={{marginRight:'5px'}} icon={faDownload} />
										Download Template
									</button>
								</div>				
								<div className="col-2 show-on-mobile"></div>
								<div className="col-md-9 hide-on-mobile"></div>
							</>
						:
							<div>			
							</div>					
					}
				</>
				:
				<>
					<label className="form-label">Upload File</label>
				</>
			}
				<>
					<div className="col-2 show-on-mobile"></div>
					<div className="col-8 col-md-3">
						<button type="submit" data-bs-target="#inputGroupFile01" className="btn-component-select-file btn btn-default btn-primary mb-3" onClick={openSelectFiles}>
							Select Files
						</button>
						<input type="file" accept=".xlsx" hidden className="form-control" onChange={onFileChange} ref={fileInput} id="inputGroupFile01"/>
					</div>	
					<div className="col-9 hide-on-mobile"></div>
					<div className="col-12 col-md-3 text-center mb-3">
						{ isFilePicked ?
							<span className="text-component-select-file">{ selectedFile.name }</span>
							:
							<span className="text-component-select-file">No file was chosen </span>
						}
					</div>
					<div className="col-9 hide-on-mobile"></div>
					<div className="col-12 col-md-3 text-center">
						<span className="text-component-upload-template">* Allowable format: .xlsx</span><br />
						<span className="text-component-upload-template">* Max file size is: 2MB</span>
					</div>
				</>
				{
					props.downloadTemplate ?
					<>
					</>
					:
						<div>
						{
							props.dataform ?
							<>
							<div className="row">
								{
									CountyAssessmentFormPartOne.map((section, i) =>
										<Fragment key={i}>
										<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
										{
											section.forms.map((form, i) => <GenerateForm key={i} placeholder={form.placeholder} validate={form.validate} options={form.options} required={form.required} subtitle={form.subtitle} type={form.type} col={form.col} name={form.name} label={form.label} onChange={props.handleInputChange}/> )
										}
										</Fragment>					
									)
								}
							</div>						
							<div className="row mt-5">
								<div className="col">						
								</div>
								<div className="col">
								</div>
								<div className="col">
								</div>
								<div className="col">
									<button type="submit" className="btn-light-grey btn btn-default btn-primary">Process</button>
								</div>
							</div>
							</>								
							:
							<></>
						}
						</div>				
				}				
							
			</div>
		</>
	)
}

export default UploadTemplate