import { NUMBER, STRING, ALPHANUMERIC, EMAIL } from './../../../Components/Forms/constant_form';

export const AddMembersFormsPartOne = [
	{
		label: 'Personal Info',
		forms: [
			{
				label: 'Title',
				placeholder: 'Select a Title',
				type: 'select',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: NUMBER
				},
				name: 'title_id',
				reduxInfo: {
					reducer: 'title',
					key: 'data'
				},
				options: []
			},
			{
				label: 'Last Name',
				placeholder: 'Last Name',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: true,
				validate: {
					type: STRING
				},
				name: 'last_name',
				options: []
			},
			{
				label: 'First Name',
				placeholder: 'First Name',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: true,
				validate: {
					type: STRING
				},
				name: 'first_name',
				options: []
			},
			{
				label: 'Middle Name',
				placeholder: 'Middle Name',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'middle_name',
				options: []
			},
			{
				label: 'Suffix',
				subtitle: '(Type in custom Suffix)',
				placeholder: 'Suffix',
				type: 'text',
				col: 'col-sm-12 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'suffix',
				options: []
			},
			{
				label: 'Address',
				subtitle: '(1)',
				placeholder: 'Address (1)',
				type: 'text',
				col: 'col-sm-12 col-12',
				required: false,
				validate: {
					type: ALPHANUMERIC
				},
				name: 'address_line_1',
				options: []
			},
			{
				label: 'Address',
				subtitle: '(2)',
				placeholder: 'Address (2)',
				type: 'text',
				col: 'col-sm-12 col-12',
				required: false,
				validate: {
					type: ALPHANUMERIC
				},
				name: 'address_line_2',
				options: []
			},
			{
				label: 'Zip Code',
				placeholder: 'Zip Code',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'zip_code',
				options: []
			},
			{
				label: 'State',
				placeholder: 'State',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'state',
				options: []
			},
			{
				label: 'City',
				placeholder: 'City',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'city',
				options: []
			},
			{
				label: 'Social Security #',
				placeholder: 'Social Security #',
				type: 'mask',
				col: 'col-sm-6 col-12',
				required: true,
				name: 'social_security_number',
				mask: '999-99-9999'
			},
			{
				label: 'Employee Number',
				subtitle: '',
				placeholder: 'Employee Number',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: NUMBER
				},
				name: 'employee_number',
				options: []
			},
			{
				label: 'IAFF#',
				subtitle: '',
				placeholder: 'IAFF#',
				type: 'text',
				col: 'col-sm-6 col-12 mb-4',
				required: true,
				validate: {
					type: NUMBER
				},
				name: 'iaff_member_number',
				options: []
			}						

		]

	}

]

export const AddMembersFormsPartTwo = [
	{
		label: 'Member Status',
		forms: [
			{
				label: 'Initiated',
				placeholder: 'Initiated',
				type: 'radio',
				col: 'col-6 col-md-6',
				required: true,
				validate: {
					type: NUMBER
				},
				value: 12,
				name: 'union_membership_status_id',
				options: []
			},
			{
				label: 'Transferred In',
				placeholder: 'Transferred In',
				type: 'radio',
				col: 'col-6 col-md-6 mb-3',
				required: true,
				validate: {
					type: NUMBER
				},
				value: 13,
				name: 'union_membership_status_id',
				options: []
			}
		]
	},
	{
		label: 'Demographics',
		forms: [
			{
				label: 'Date of Birth',
				type: 'date',
				placeholder: 'Date of Birth',
				col: 'col-sm-6 col-12',
				required: false,
				name: 'birth_date'
			},
			{
				label: 'Join Date',
				placeholder: 'Join Date',
				type: 'date',
				col: 'col-sm-6 col-12',
				required: false,
				name: 'join_date'
			},			
			{
				label: 'Gender',
				type: 'select',
				placeholder: 'Select a Gender',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: NUMBER
				},
				name: 'gender_id',
				options: [],
				reduxInfo: {
					reducer: 'gender',
					key: 'data'
				}
			},
			{
				label: 'Race',
				type: 'select',
				placeholder: 'Select a Race',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: NUMBER
				},
				name: 'ethnicity_id',
				options: [],
				reduxInfo: {
					reducer: 'ethnicity',
					key: 'data'
				}
			}
		]
	},
	{
		label: 'Job Info',
		forms: [
			{
				label: 'Original Hire',
				subtitle: '',
				placeholder: 'Original Hire',
				type: 'date',
				col: 'col-sm-6 col-12',
				required: false,
				name: 'employ_date'
			},
			{
				label: 'Badge #',
				placeholder: 'Badge #',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'badge_number',
				options: []
			},			
			{
				label: 'Position',
				type: 'select',
				placeholder: 'Select a Position',
				col: 'col-sm-12 col-12',
				required: false,
				validate: {
					type: NUMBER
				},
				name: 'position_id',
				options: [],
				reduxInfo: {
					reducer: 'position',
					key: 'data'
				}
			},
			{
				label: 'Sick Plan',
				placeholder: 'Select a Sick Plan',
				type: 'select',
				col: 'col-sm-12 col-12',
				required: false,
				validate: {
					type: NUMBER
				},
				name: 'sick_plan_id',
				options: [],
				reduxInfo: {
					reducer: 'sickPlan',
					key: 'data'
				}
			}
		]
	},
	{
		label: 'Contact Details',
		forms: [
			{
				label: 'Home Phone',
				type: 'mask',
				placeholder: 'Home Phone',
				col: 'col-sm-6 col-12',
				required: false,
				name: 'home_phone'
			},
			{
				label: 'Cell Phone',
				type: 'mask',
				placeholder: 'Cell Phone',
				col: 'col-sm-6 col-12',
				required: false,
				name: 'cell_phone'
			},
			{
				label: 'Email Address',
				type: 'text',
				placeholder: 'Email Address',
				col: 'col-sm-12 col-12',
				required: true,
				validate: {
					type: EMAIL
				},
				name: 'email',
				options: []
			}
		]
	}
]
