import React from 'react';
import LayoutHeaderPage from './../../../../Layouts/header_page';
import { useSelector, useDispatch } from 'react-redux';
import {
	reducerName as addressReducerName,
	getAddressExcelReport,
	getAddressPDFReport,
	destroyAddress
} from './../../../../Store/Reducers/address';
import TableComponent from './../../../../Components/table_v2';
import Notification from './../../../../Components/notification';
import TableHeader from './table_header';
import moment from 'moment';
import DeleteIcon from './../../../../Assets/Img/Icons/delete.jpg';
import ModalComponent from './../../../../Components/modal';

const AddressReport = () => {

	const dispatch = useDispatch();
	const data = useSelector((state)=> state[addressReducerName].data || []);
	const byFilterRequest = useSelector((state)=> state[addressReducerName].byFilterRequest);
	const dataFiltered = useSelector((state)=> state[addressReducerName].dataFiltered);
	const dateFilter = useSelector((state)=> state[addressReducerName].dateFilter);

	const tableColumns = [
		{
			title: 'IAFF',
			sort: true,
			value: 'member',
			parseValue: (value) => {
				if(!value?.iaff_member_number) { return ''; }
				return value.iaff_member_number
			}
		},
		{
			title: 'First Name',
			value: 'member',
			parseValue: (value) => {
				if(!value?.first_name) { return ''; }
				return value.first_name
			}
		},
		{
			title: 'Middle Name',
			value: 'member',
			parseValue: (value) => {
				if(!value?.middle_name) { return ''; }
				return value.middle_name
			}
		},
		{
			title: 'Last Name',
			value: 'member',
			parseValue: (value) => {
				if(!value?.last_name) { return ''; }
				return value.last_name
			}
		},
		{
			title: 'Suffix',
			value: 'member',
			parseValue: (value) => {
				if(!value?.suffix) { return ''; }
				return value.suffix
			}
		},
		{
			title: 'Address line 1',
			value: 'address_line_1'
		},
		{
			title: 'Address line 2',
			value: 'address_line_2'
		},
		{
			title: 'City',
			value: 'city'
		},
		{
			title: 'State',
			value: 'state'
		},
		{
			title: 'Zip Code',
			value: 'zip_code'
		},
		{
			title: 'Changed',
			value: 'updated_at',
			parseValue: (value) => {
				if(!value) { return; }
				var date = moment(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		},
		{
			title: 'Actions',
      value: 'actions',
			getLabelFromStore: (storeData, index) => {
				return <>
					<button 
						className='btn-icon'
						onClick={()=> {
							openModal();
							setIndex(index);
						}
					} disabled={false}>
						<img src={DeleteIcon} alt='Delete'/>Delete
					</button>
				</>
			}
		}
	];

	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [index, setIndex] = React.useState(null);

	const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

	const handlePDFReport = () => {
		const currentDate = new Date(dateFilter);
		const year = currentDate.getFullYear();
		const month = (`0${currentDate.getMonth() + 1}`).slice(-2);
		dispatch(getAddressPDFReport({
			parameters: {
			month: month,
			year: year
		}}))
	}

	const handleExcelReport = () => {
		const currentDate = new Date(dateFilter);
		const year = currentDate.getFullYear();
		const month = (`0${currentDate.getMonth() + 1}`).slice(-2);
	  dispatch(getAddressExcelReport({
			month: month,
			year: year
		}))
	}

	return (
		<>
			<LayoutHeaderPage title="Address Changes Report"/>
			<div className="tab-content mt-3 pt-4" id="pills-tabContent">
				<TableComponent
					paginationSize={10}
					columns={tableColumns}
					data={dataFiltered || data}
					header={TableHeader}
				/>
				<Notification />
				<div className='row mt-5 mb-3'>
					<div className='col-12 col-md-6'></div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className='col-8 col-md-3 mb-3'>
						<button type='button' onClick={handlePDFReport} disabled={byFilterRequest} className='btn-orange btn-default btn-primary'>View all PDF</button>
					</div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className='col-8 col-md-3'>
						<button type='button' onClick={handleExcelReport} disabled={byFilterRequest} className='btn-blue btn-default btn-primary'>Export to Excel</button>
					</div>
				</div>
			</div>
			<ModalComponent
				modalIsOpen={modalIsOpen}
				description={`Are you sure that you want to permanently delete the selected Address Change ?`}
				closeModal={closeModal}
				confirmModal={()=>{
					dispatch(destroyAddress({
						addressId: (dataFiltered || data)?.[index]?.id,
						dateFilter: dateFilter
					}))
					closeModal();
				}}
			/>
		</>
	)
}

export default AddressReport;
