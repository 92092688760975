import React, { useState, useContext } from 'react'
import LayoutHeaderPage from '../../../Layouts/header_page'
import UploadTemplate from '../../../Components/upload_template'
import Notification from '../../../Components/notification'
import { downloadExcelBase64File } from '../../../Helpers'
import { addressUpdate, reducerName as bulkMemberReducerName } from '../../../Store/Reducers/bulkMember'
import moment from 'moment'
import { ReactReduxContext, useSelector } from 'react-redux';

const initData = {
	title: 'Upload Member_IAFF_Report.xlsx',
	textOne: 'Download the template file. Populate with the member changes you specify and upload the modified file. Click the "Process" button to execute the data update.',
	textTwo: 'This process updates the following member fields. If a record in the data file does not exists, a new member will be created in the application:' ,
	labels: [
		{
			section: [
				{
					name: 'Title'
				},
				{
					name: 'Last Name'
				},
				{
					name: 'Middle Name'
				},
				{
					name: 'First Name'
				},
				{
					name: 'Suffix'
				},
				{
					name: 'Address'
				}

			]

		},
		{
			section: [
				{
					name: 'City'
				},
				{
					name: 'State'
				},
				{
					name: 'Zip Code'
				},
				{
					name: 'Home Phone'
				},
				{
					name: 'Mobile Phone'
				},
				{
					name: 'Email Address'
				}

			]

		}
	]
};

const BulkAddressUpdate = () => {
	const { store } = useContext(ReactReduxContext);
	const bulkAddressData = useSelector((state)=> state[bulkMemberReducerName].bulkAddressData);
	const bulkAddressRequest = useSelector((state)=> state[bulkMemberReducerName].bulkAddressRequest);

	const [file, setFile] = useState();

	const sendData = () => {
		
		const formData = new FormData();
		formData.append('file', file);

		store.dispatch(addressUpdate(formData));
	}

	const handleDownloadErrors = () => {
		const date = moment().format('L')
		downloadExcelBase64File(bulkAddressData?.file?.replace('data:application/vnd.ms-excel;base64,', ''), `MembersErrors-${date}.xlsx`);
	}

	return (
		<>
			<LayoutHeaderPage title="Bulk Address Update"/>
			<div className="tab-content mt-3" id="pills-tabContent">
				<UploadTemplate fileData={setFile} initialData={initData} showInfo={true}/>
				<Notification />
				<div className="row pb-3 pt-5">
					<div className="col-12 col-md-6 mb-3 hide-on-mobile"></div>
					<div className="col-2 show-on-mobile"></div>
					<div className="col-8 mb-3 col-md-3">
						<button type="button" disabled={!bulkAddressData?.file || bulkAddressRequest} onClick={handleDownloadErrors} className="btn-orange btn btn-default btn-primary">Download Errors</button>
					</div>
					<div className="col-2 show-on-mobile"></div>
					<div className="col-2 show-on-mobile"></div>
					<div className="col-8 mb-3 col-md-3">
						<button type="submit" disabled={bulkAddressRequest} onClick={sendData} className="btn-orange btn btn-default btn-primary">Process</button>
					</div>
				</div>				
			</div>
		</>
	)
};

export default BulkAddressUpdate;
