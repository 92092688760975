import { getAxiosInstance } from "../Config/axios";
import { downloadPDFBase64File } from "../Helpers";

export const getPrintLabelService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: data.parameters,
            url: 'labels'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Labels ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return true;
        
    } catch (error) {
        data.reportPage.close();
        throw error
    }
};

export const getPrintEnvelopesService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: data.parameters,
            url: 'envelopes'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Envelopes ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return true;
        
    } catch (error) {
        data.reportPage.close();
        throw error
    }
};

export const getPrintLabelByMemberService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: data.parameters,
            url: 'labels/by-member'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Labels ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return result.file;
        
    } catch (error) {
        data.reportPage.close();
        throw error
    }
};

export const getPrintEnvelopesByMemberService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: data.parameters,
            url: 'envelopes/by-member'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Envelopes ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return result.file;
        
    } catch (error) {
        data.reportPage.close();
        throw error
    }
};
