import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getGeneralSettingService,
  editGeneralSettingService,
  destroyGeneralSettingService,
  createGeneralSettingService
} from './../../Services/generalSettingService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'generalSetting';

const initialState = {
  request: false,
  error: null,
  data: null
};

export const getGeneralSetting = createAsyncThunk(
  'generalSetting/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getGeneralSettingService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const editGeneralSetting = createAsyncThunk(
  'generalSetting/editGeneralSetting',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await editGeneralSettingService(parameters);

      dispatch(getGeneralSetting());
      dispatch(showNotification({
        message: 'General Setting updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };

    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const destroyGeneralSetting = createAsyncThunk(
  'generalSetting/destroyGeneralSetting',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await destroyGeneralSettingService(id);

      dispatch(getGeneralSetting());
      dispatch(showNotification({
        message: 'General Setting deleted successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const createGeneralSetting = createAsyncThunk(
  'generalSetting/createGeneralSetting',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await createGeneralSettingService(parameters);

      dispatch(getGeneralSetting());
      dispatch(showNotification({
        message: 'General Setting created successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setDataFilteredReducer = (state, action) => {
  return {
    ...state,
    dataFiltered: action.payload.data
  }
};

const setDataFilteredAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearDataFilteredReducer = (state) => {
  return {
    ...state,
    dataFiltered: null
  }
};

const setEditDataHandler = (state, data) => {
  return {
    ...state,
    editData: data.payload
  }
};

export const generalSettingReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setDataFiltered: {
      reducer: setDataFilteredReducer,
      prepare: setDataFilteredAction
    },
    clearDataFiltered: clearDataFilteredReducer,
    setEditData: setEditDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getGeneralSetting.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getGeneralSetting.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getGeneralSetting.pending, (state) => {
      state.request = true;
      state.error = null;
    })
  }
});

export const { setDataFiltered, clearDataFiltered, setEditData } = generalSettingReducer.actions

export default generalSettingReducer.reducer;
