import { getAxiosInstance } from "../Config/axios";
import { downloadExcelBase64File, downloadPDFBase64File } from "../Helpers";

export const addMemberInsuranceService = async (noteData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            url: `member/member-policy/${noteData.memberId}`,
            data: noteData.data
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const deleteMemberInsuranceService = async (memberPolicyId) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'DELETE',
            url: `member-policy/insurance/${memberPolicyId}`
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result.data?.status;
        
    } catch (error) {
        throw error
    }
};

export const destroyPolicyInsuranceByIdService = async (memberPolicyId) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'DELETE',
            url: `member-policy/${memberPolicyId}`
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result.data?.status;
        
    } catch (error) {
        throw error
    }
};

export const cancellationMemberInsuranceService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            url: `member-policy/cancellation/${data.memberPolicyId}`,
            data: data.data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result.data?.status;
        
    } catch (error) {
        throw error
    }
};

export const getMemberInsuranceService = async (memberId) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: `member-policy/insurance/${memberId}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const getCancellationInsuranceService = async (memberId) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: `member-policy/cancellation/${memberId}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const getMemberPDFPolicyService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: `download/member-policies-single-pdf/${data.memberId}`
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Member policies ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return result.file;
        
    } catch (error) {
        data.reportPage.close();
        throw error
    }
};

export const getMemberExcelPolicyService = async (memberId) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: `download/member-policies-single-excel/${memberId}`
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        downloadExcelBase64File(result.data.file.replace('data:application/vnd.ms-excel;base64,', ''), `Member_policy_${memberId}.xlsx`);

        return result.file;
        
    } catch (error) {
        throw error
    }
};

export const getMemberPoliciesByCancellationDateService = async (parameters) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: parameters,
            url: 'member-policy/by-cancellation_date'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const getAllMemberPDFPolicyService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: data.parameters,
            url: 'download/member-policies-pdf'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file'); 
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Member policies ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return result.file;
        
    } catch (error) {
        data.reportPage.close();
        throw error
    }
};

export const getAllMemberExcelPolicyService = async (parameters) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: parameters,
            url: `download/member-policies-excel`
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        downloadExcelBase64File(result.data.file.replace('data:application/vnd.ms-excel;base64,', ''), `All_Member_policy.xlsx`);

        return result.file;
        
    } catch (error) {
        throw error
    }
};