import React from 'react';
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './Store'
import App from './Pages';
import SessionEnd from './Components/SessionEnd';
import './Styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render( <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <SessionEnd />
    </Provider>
  </BrowserRouter>
</React.StrictMode>);
