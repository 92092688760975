import React from 'react';
import { useSelector } from 'react-redux';
import { reducerName as memberReducerName } from './../../../Store/Reducers/member';
import PieChart from './../../../Components/pie_chart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';

const Deductions = () => {
	const dashboardData = useSelector((state)=> state[memberReducerName]?.getDashboard || {});
	const graphicData = dashboardData.graphic || {boxs: [], data: []};

	return (<>
		<div className="col-12 col-md-8">
			<legend className='mb-3'>Deductions</legend>
			<div hidden={graphicData.total === 0} className='row' style={{top: '-50px',position: 'relative'}}>
				<div className="col-12 col-md-1">	</div>
				<div className="col-12 col-md-9 pie_chart">	
					<PieChart
						data={graphicData.boxs?.map((boxData, index)=>({
							title: index,
							value: graphicData.data[index],
							color: graphicData.background[index]
						}))}
					/>
				</div>
			</div>
			<div hidden={graphicData.total !== 0} className='row'><center><FontAwesomeIcon style={{marginRight: '3px', fontSize: '1.4em'}} icon={faChartPie} /></center></div>
		</div>
	</>)
};

export default Deductions;
