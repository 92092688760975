import { ALPHANUMERIC } from './../../../../Components/Forms/constant_form';

export const formFields = [
	{
		forms: [
      {
				label: 'Name',
				placeholder: 'Type station name',
				type: 'text',
				col: 'col-12',
				required: true,
				validate: {
					type: ALPHANUMERIC
				},
				name: 'name'
			}
		]

	}

];
