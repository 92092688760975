import React from 'react'
import GenerateForm from './generate_form'
import { NUMBER, STRING } from './constant_form';
import { reducerName as beneficiaryReducerName } from './../../Store/Reducers/beneficiary';
import { useSelector } from 'react-redux';

const PRIMARY = 'primary';
const SECONDARY = 'secundary';
const TERTIARY = 'tertiary';

export const beneficiaryDistributionTypes = {
	[PRIMARY] : {
		color: '#ee3d3b',
		label: 'Primary'
	},
	[SECONDARY] : {
		color: '#ffb66e',
		label: 'Secondary'
	},
	[TERTIARY] : {
		color: '#81b23f',
		label: 'Tertiary'
	},
}

const AddMembersFormsPartOne = [
	{
		label: beneficiaryDistributionTypes[PRIMARY].label,
		color: beneficiaryDistributionTypes[PRIMARY].color,
		forms: [
			{
				label: 'Last Name',
				subtitle: '',
				placeholder: 'Last Name',
				type: 'text',
				col: 'col-sm-4 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'last_name',
				options: []
			},
			{
				label: 'First Name',
				subtitle: '',
				placeholder: 'First Name',
				type: 'text',
				col: 'col-sm-4 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'first_name',
				options: []
			},
			{
				label: 'Percentage',
				type: 'text',
				placeholder: 'Percentage',
				col: 'col-sm-4 col-12 percentage',
				required: false,
				validate: {
					type: NUMBER
				},
				name: 'percent',
				options: []
			}
		]
	},{
		label: beneficiaryDistributionTypes[SECONDARY].label,
		color: beneficiaryDistributionTypes[SECONDARY].color,
		forms: [

			{
				label: 'Last Name',
				subtitle: '',
				placeholder: 'Last Name',
				type: 'text',
				col: 'col-sm-4 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'last_name',
				options: []
			},
			{
				label: 'First Name',
				subtitle: '',
				placeholder: 'First Name',
				type: 'text',
				col: 'col-sm-4 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'first_name',
				options: []
			},
			{
				label: 'Percentage',
				type: 'text',
				placeholder: 'Percentage',
				col: 'col-sm-4 col-12 percentage',
				required: false,
				validate: {
					type: NUMBER
				},
				name: 'percent',
				options: []
			}
		]
	},{
		label: beneficiaryDistributionTypes[TERTIARY].label,
		color: beneficiaryDistributionTypes[TERTIARY].color,
		forms: [
			{
				label: 'Last Name',
				subtitle: '',
				placeholder: 'Last Name',
				type: 'text',
				col: 'col-sm-4 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'last_name',
				options: []
			},
			{
				label: 'First Name',
				subtitle: '',
				placeholder: 'First Name',
				type: 'text',
				col: 'col-sm-4 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'first_name',
				options: []
			},
			{
				label: 'Percentage',
				type: 'text',
				placeholder: 'Percentage',
				col: 'col-sm-4 col-12 percentage',
				required: false,
				validate: {
					type: NUMBER
				},
				name: 'percent',
				options: []
			}
		]
	}

];

const BeneficiaryForm = (props) => {

	const beneficiaryData = useSelector((state)=> state[beneficiaryReducerName].getByMemberId);

	return (
		<div className="row">
			<div className="col-sm-12 col-12">
					<div className='row'>
					<label className='ut-subtitle mt-3 mb-3'>Beneficiary Details</label>
					{
								AddMembersFormsPartOne.map((section, i) =>
									<div className='row' key={i}>
										<div>
											<div style={{backgroundColor: section.color }} className='colorBox' />
											<label className='ut-subtitle ut-box-subtitle mt-3'>{section.label}</label>
										</div>
										{
											section.forms.map((fieldData)=>({
												...fieldData,
												defaultValue: beneficiaryData?.[i]?.[fieldData.name]
											})).map((form, i) => 
												<GenerateForm 
													key={i} 
													{...form}
													onChange={props.onChange}/> 
											)
										}
										</div>
								)
					}
					</div>
			</div>
		</div>
	)
}

export default BeneficiaryForm