import React from 'react'
import Print from '../../Assets/Img/Icons/print-menu.svg'
import { getEnvelope } from './../../Store/Reducers/envelope';
import { useDispatch, useSelector } from 'react-redux';
import { reducerName as memberReducerName, searchMemberById, updateMemberPersonalData, updateMemberJobData } from './../../Store/Reducers/member';
import { reducerName as memberFormReducerName, setActiveTabSlug } from './../../Store/Reducers/memberForm';
import { addBeneficiaryByMemberId } from './../../Store/Reducers/beneficiary';
import { reducerName as userReducerName } from './../../Store/Reducers/user';
import { ROLES } from './../../Router/Roles';

const ButtonsActions = (props) => {
	const dispatch = useDispatch();
	const managementData = useSelector((state)=> state[memberReducerName].managementData);
	const formsData = useSelector((state)=> state[memberFormReducerName].form);
	const userRoles = useSelector((state)=>state[userReducerName].data?.user?.roles || []).map((roleData)=> roleData.name);

	const handlePrintEnvelope = () => {
		dispatch(getEnvelope({
			memberId: managementData.member.id,
		}));
	};

	const handleClose = () => {
		dispatch(searchMemberById());
	};

	const handleSaveAll = () => {

		const formWithError = Object.keys(formsData).find((formKey) => formsData[formKey].error);

		if(formWithError) {
			dispatch(setActiveTabSlug({ slug: formWithError }));
			return;
		}

		Object.keys(formsData).forEach((key)=>{
			const formName = key;
			const formValues = formsData[key];

			if(formName === 'member-info') {
				dispatch(updateMemberPersonalData({
					id: managementData.member.id, data: formValues
				}));
			}

			if(formName === 'beneficiaries') {
				dispatch(addBeneficiaryByMemberId({
					id: managementData.member.id,
					data: formValues.params
				}));
			}

			if(formName === 'job-info') {
				dispatch(updateMemberJobData({
					id: managementData.member.id, data: formValues
				}));
			}
		})

	}

	return (
		<>
			<div className="row pb-4 pt-5 actions" hidden={props.hidden}>
				<div className="col-6 mb-3 col-md-3">
					<button type="button" hidden={!userRoles.includes(ROLES.administrator) && !userRoles.includes(ROLES.developer)} disabled={props.disabled} onClick={handlePrintEnvelope} className="btn-blue btn btn-default btn-primary"><img className="hide-on-mobile icon-info-user pa-2 me-2" alt="login icon" src={Print} />Print Envelopes</button>
				</div>
				<div className="col-6 mb-3 col-md-3">
					<button type="button" hidden={!userRoles.includes(ROLES.administrator) && !userRoles.includes(ROLES.developer)} onClick={handleSaveAll} disabled={Object.keys(formsData).length === 0} className="btn-dark-grey btn btn-default btn-primary">Save All</button>
				</div>
				<div className="col-6 mb-3 col-md-3" hidden={!userRoles.includes(ROLES.administrator) && !userRoles.includes(ROLES.developer)}>
					<button type="submit" disabled={props.disabled} className="btn-orange btn btn-default btn-primary">Save</button>
				</div>
				<div className="col-6 mb-3 col-md-3">
					<button type="button" hidden={!userRoles.includes(ROLES.administrator) && !userRoles.includes(ROLES.developer)} onClick={handleClose} disabled={props.disabled} className="btn-light-grey btn btn-default btn-primary">Close</button>
				</div>
				<div className="col-12" hidden={userRoles.includes(ROLES.administrator) || userRoles.includes(ROLES.developer)} />
				<div className="col-2 col-md-9" hidden={userRoles.includes(ROLES.administrator) || userRoles.includes(ROLES.developer)} />
				<div className="col-8 col-md-3" hidden={userRoles.includes(ROLES.administrator) || userRoles.includes(ROLES.developer)}>
					<button type="submit" disabled={props.disabled} className="btn-orange btn btn-default btn-primary">Save</button>
				</div>
			</div>
		</>
	)
}

export default ButtonsActions