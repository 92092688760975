import React, { useState } from 'react';
import TableComponent from '../../../../Components/table_v2'
import DeleteIcon from './../../../../Assets/Img/Icons/delete.jpg';
import EditIcon from './../../../../Assets/Img/Icons/edit.jpg';
import {
	reducerName as memberNoteReducerName,
	deleteMemberNote,
	getMemberNoteToEdit
} from '../../../../Store/Reducers/memberNote';
import { reducerName as memberReducerName } from '../../../../Store/Reducers/member';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from './../../../../Components/modal';
import { reducerName as noteTypeReducerName } from '../../../../Store/Reducers/noteType';
import ButtonsActions from './../../../../Components/Forms/buttons_actions'
import moment from 'moment';

const NoteTable = ({ showAddNote }) => {
	const dispatch = useDispatch();
	const noteTypeData = useSelector((state)=> state[noteTypeReducerName].data);
	const memberNoteGetData = useSelector((state)=> state[memberNoteReducerName].getData);
  const managementData = useSelector((state)=> state[memberReducerName].managementData);

	const [index, setIndex] = useState(null);

	const tableColumns = [
		{
			title: 'Type',
			sort: true,
      value: 'note_type_id',
			getLabelFromStore: (storeData, index) => {
				return storeData.noteType?.data?.find((dat) => dat.id === memberNoteGetData?.[index]?.note_type_id)?.name
			}
		},
		{
			title: 'Note',
      value: 'note'
		},
		{
			title: 'Date',
      value: 'date',
			getLabelFromStore: (storeData, index) => {
				const value = storeData.memberNote.getData?.[index]?.updated_at;
				var date = moment.utc(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		},
		{
			title: 'Actions',
      value: 'actions',
			className: 'td-space-table-two-buttons',
			getLabelFromStore: (storeData, index) => {
				
				
				return <>
					<button className='btn-icon' onClick={()=> {
						setIndex(index);
						dispatch(getMemberNoteToEdit(memberNoteGetData?.[index]?.id));
						showAddNote()
					}
					} disabled={false}>
						<img src={EditIcon} alt='Edit'/>Edit
					</button>
					<button className='btn-icon' onClick={()=> {
						setIndex(index);
						setModalDescription(`Are you sure that you want to permanently delete note: ${noteTypeData.find((noteType) => noteType.id === memberNoteGetData?.[index]?.note_type_id)?.name}`);
						openModal();
					}
					} disabled={false}>
						<img src={DeleteIcon} alt='Edit'/>Delete
					</button>
				</>
			}
		}
	]

	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [modalDescription, setModalDescription] = React.useState('');

	const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

	return (<>
		<ModalComponent
			modalIsOpen={modalIsOpen}
			description={modalDescription}
			closeModal={closeModal}
			confirmModal={()=>{
				dispatch(deleteMemberNote({
					noteId: memberNoteGetData?.[index]?.id, 
					memberId: managementData.member.id
				}));
				closeModal();
			}}
		/>
		<TableComponent
			columns={tableColumns}
			data={memberNoteGetData || []}
    />
		<div className='row'>
			<div className='col col-6 col-lg-3'>
				<button type='button' disabled={false} onClick={showAddNote} className='btn-blue btn btn-default btn-primary'>Add</button>
			</div>
		</div>
		<ButtonsActions disabled={!managementData || !Object.keys(managementData).length} UpdateAll={()=>{}} UpdateSection={()=>{}}/>
	</>)
}

export default NoteTable