import React, { useState, useEffect, Fragment } from 'react'
import moment from 'moment';
import GenerateForm from '../../../Components/Forms/generate_form'
import { MembersFormsPartOne, MembersFormsPartTwo, disabledFieldsByMemberRole } from '../../../Config/Forms/MembersManagements'
import { reducerName as memberReducerName, updateMemberPersonalData } from '../../../Store/Reducers/member';
import { useSelector, useDispatch } from 'react-redux';
import ButtonsActions from '../../../Components/Forms/buttons_actions'
import { searchPlaceByPostalCode, reducerName as zipPopotamReducerName } from '../../../Store/Reducers/zipPopotam';
import { editForm } from '../../../Store/Reducers/memberForm';
import Notification from '../../../Components/notification';
import { reducerName as userReducerName } from './../../../Store/Reducers/user';
import { ROLES } from './../../../Router/Roles';

const Members = ({ slug: formName }) => {
	const dispatch = useDispatch();
	const managementData = useSelector((state) => state[memberReducerName].managementData);
	const placeByPostalCodeData = useSelector((state) => state[zipPopotamReducerName].placeByPostalCode);
	const updateRequest = useSelector((state) => state[memberReducerName].updateRequest);
	const roles = useSelector((state) => state[userReducerName].data?.user?.roles || []).map((roleData) => roleData.name);
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [memberData, setMemberData] = useState({});
	const updatePersonalDataRequest = useSelector((state) => state[memberReducerName].updatePersonalDataRequest);


	useEffect(() => {
		const actualPostalCode = document.querySelector(`#${formName} input[name=zip_code]`)?.value;
		const placeData = placeByPostalCodeData[actualPostalCode];

		if (!actualPostalCode || !placeData) {
			return;
		}

		document.querySelector(`#${formName} input[name=state]`).value = placeData.state;
		document.querySelector(`#${formName} input[name=city]`).value = placeData['place name'];

		const newState = placeData.state;
		const newCity = placeData['place name'];

		setState(placeData.state);
		setCity(newCity);
		dispatchLocationFields({
			state: newState,
			city: newCity
		});

	}, [placeByPostalCodeData, formName]);

	const fillRequiredFieldValues = () => {
		dispatch(editForm({
			formName: formName,
			fieldValue: document.querySelector(`#${formName} input[name=first_name]`).value,
			fieldName: 'first_name'
		}));

		dispatch(editForm({
			formName: formName,
			fieldValue: document.querySelector(`#${formName} input[name=iaff_member_number]`).value,
			fieldName: 'iaff_member_number'
		}));

		dispatch(editForm({
			formName: formName,
			fieldValue: document.querySelector(`#${formName} input[name=last_name]`).value,
			fieldName: 'last_name'
		}));

		dispatch(editForm({
			formName: formName,
			fieldValue: document.querySelector(`#${formName} input[name=email]`).value,
			fieldName: 'email'
		}));

		dispatch(editForm({
			formName: formName,
			fieldValue: document.querySelector(`#${formName} input[name=social_security_number]`).value,
			fieldName: 'social_security_number'
		}));

		dispatch(editForm({
			formName: formName,
			fieldName: 'error',
			fieldValue: !!document.querySelectorAll(`#${formName} input.is-invalid`).length
		}));
	}

	const dispatchLocationFields = ({
		city: cityInput,
		state: stateInput,
	} = {}) => {
		dispatch(editForm({
			formName: formName,
			fieldValue: cityInput || city,
			fieldName: 'city'
		}));
		dispatch(editForm({
			formName: formName,
			fieldValue: stateInput || state,
			fieldName: 'state'
		}));
		dispatch(editForm({
			formName: formName,
			fieldValue: document.querySelector(`#${formName} input[name=zip_code]`).value,
			fieldName: 'zip_code'
		}));
		dispatch(editForm({
			formName: formName,
			fieldValue: document.querySelector(`#${formName} input[name=address_line_1]`).value,
			fieldName: 'address_line_1'
		}));
		dispatch(editForm({
			formName: formName,
			fieldValue: document.querySelector(`#${formName} input[name=address_line_2]`).value,
			fieldName: 'address_line_2'
		}));
	}

	const handleInputChangeMembers = (event) => {

		if (event.target.name === 'zip_code') {
			const newCity = '';
			const newState = '';
			document.querySelector(`#${formName} input[name=state]`).value = newState;
			document.querySelector(`#${formName} input[name=city]`).value = newCity;
			setCity(newCity);
			setState(newState);
			dispatchLocationFields({
				city: newCity,
				state: newState
			});

			if (!!event.target.value) {
				dispatch(searchPlaceByPostalCode(event.target.value));
			}

		}

		if (event.target.name === 'state') {
			setState(event.target.value);
			dispatchLocationFields({
				state: event.target.value
			});
		}

		if (event.target.name === 'city') {
			setCity(event.target.value);
			dispatchLocationFields({
				city: event.target.value
			});
		}

		if (['address_line_1', 'address_line_2'].includes(event.target.name)) {
			dispatchLocationFields();
		}

		fillRequiredFieldValues();

		dispatch(editForm({
			formName: formName,
			fieldValue: event.target.value,
			fieldName: event.target.name
		}));

	};

	const handleInputDateChanges = (name, value) => {
		const date = moment(value).format('L')
		const dateFormat = date.split('/')
		const dateFormatEnd = `${dateFormat[2]}-${dateFormat[0]}-${dateFormat[1]}`

		fillRequiredFieldValues();

		dispatch(editForm({
			formName: formName,
			fieldValue: dateFormatEnd,
			fieldName: name
		}));
	}

	const handleFormMemberManagementSubmit = (e) => {

		e.preventDefault();

		if (document.querySelectorAll(`#${formName} input.is-invalid`).length) {
			document.querySelector(`#${formName} input.is-invalid`).focus();
			return;
		}

		const elements = e.target.elements;
		const memberData = {};
		const fields = [];

		MembersFormsPartOne.forEach((formMember) => formMember.forms?.forEach((field) => fields.push(field)))
		MembersFormsPartTwo.forEach((formMember) => formMember.forms?.forEach((field) => fields.push(field)))

		for (let i = 0; i < elements.length; i++) {

			const { name, value } = elements.item(i);

			if (!name.length) {
				continue;
			}

			const fieldConfiguration = fields.find((field) => field?.name === name);

			if (fieldConfiguration?.type === "date") {
				const dateValue = value.split("/");
				if (dateValue.length >= 2) {
					memberData[name] = `${dateValue[2]}-${dateValue[0]}-${dateValue[1]}`;
				} else {
					memberData[name] = '';
				}
			} else {

				if (fieldConfiguration?.required && !value) {
					elements.item(i).parentElement?.childNodes?.[2]?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]?.focus();
					return;
				}

				memberData[name] = value;
			}
		}

		memberData.city = city;
		memberData.state = state;

		dispatch(updateMemberPersonalData({
			id: managementData.member.id, data: memberData
		}));

	}

	useEffect(() => {
		const addresses = managementData?.address?.filter((add) => add.status);
		const address = addresses?.[addresses.length - 1];

		if (!address) {
			return;
		}

		setCity(address.city);
		setState(address.state);
		setMemberData(managementData);
	}, [managementData]);


	return (
		<>
			<form id={formName} onSubmit={handleFormMemberManagementSubmit}>
				<div className="row">
					<div className="col-sm-6 col-12">
						<div className="row">
							{
								MembersFormsPartOne.map((section, i) =>
									<Fragment key={i}>
										<label key={i} className={`title-label-section-forms ${i > 0 ? 'mt-3' : ''}`}>{section.label}</label>
										{
											section.forms.map((field) => ({
												...field,
												disabled: (!memberData || !Object.keys(memberData).length) || field.disabled || (!roles.includes(ROLES.developer) && !roles.includes(ROLES.administrator) && roles.includes(ROLES.member) && disabledFieldsByMemberRole.includes(field.name)),
												defaultValue: typeof field?.getDefaultValue === 'function' ?
													field.getDefaultValue(memberData) :
													typeof field.component === 'string' ?
														Array.isArray(memberData?.[field.component]) ?
															memberData[field.component][memberData[field.component].length - 1]?.[field.componentKey || field.name] :
															memberData?.[field.component]?.[field.componentKey || field.name] :
														memberData?.[field.name]
											})).map((form, i) => <GenerateForm
												key={i}
												{...form}
												onChange={handleInputChangeMembers}
											/>)
										}
									</Fragment>
								)
							}
						</div>
					</div>
					<div className="col-sm-6 col-12">
						<div className="row">
							{
								MembersFormsPartTwo.map((form, i) =>
									<Fragment key={i}>
										<label key={i} className={`title-label-section-forms ${i > 0 ? 'mt-3' : ''}`}>{form.label}</label>
										{
											form.forms.map((field) => ({
												...field,
												disabled: (!memberData || !Object.keys(memberData).length) || field.disabled || (!roles.includes(ROLES.developer) && !roles.includes(ROLES.administrator) && roles.includes(ROLES.member) && disabledFieldsByMemberRole.includes(field.name)),
												defaultValue: typeof field?.getDefaultValue === 'function' ?
													field.getDefaultValue(memberData) :
													typeof field.component === 'string' ?
														Array.isArray(memberData?.[field.component]) ?
															memberData[field.component][memberData[field.component].length - 1]?.[field.componentKey || field.name] :
															memberData?.[field.component]?.[field.componentKey || field.name] :
														memberData?.[field.name]
											})).map((form, i) => <GenerateForm
												key={i}
												dataInputDates={handleInputDateChanges}
												onChange={handleInputChangeMembers}
												{...form}
											/>)
										}
									</Fragment>
								)
							}
						</div>
					</div>
				</div>
				<Notification />
				<ButtonsActions disabled={updatePersonalDataRequest || updateRequest || !memberData || !Object.keys(memberData).length} UpdateAll={() => { }} UpdateSection={() => { }} />
			</form>
		</>
	)
}

export default Members