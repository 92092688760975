import axios from "axios";
import qs from 'qs';
import { getAxiosInstance } from "../Config/axios";

const UNAUTHORIZED_MESSAGE = 'Unauthorized';
const unionTrackingApiHost = process.env.REACT_APP_UNION_TRACKING_API_HOST;

export const loginService = async (userData) => {
    try {
        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(userData),
            url: `${unionTrackingApiHost}login`,
        };
    
        const result = await axios(options);

        return result.data?.data;
        
    } catch (error) {
        const jsonError = error?.toJSON();
        throw jsonError.status === 401 ? new Error(UNAUTHORIZED_MESSAGE) : error
    }
}

export const changePasswordService = async (memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: memberData,
            url: 'user/change-password'
        };
    
        const result = await axios(options);
        
        if(result?.data?.code === 400 || result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};
