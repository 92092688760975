import React, { useEffect, useRef } from 'react';
import Reload from '../Assets/Img/Icons/reload.svg'
import { reducerName, searchMemberById } from '../Store/Reducers/member';
import MemberSearch from '../Components/memberSearch';
import { useSelector, useDispatch } from 'react-redux';

const HeaderPage = (props) => {
	const dispatch = useDispatch();
	const searchSelectControl = useRef();
	const managementData = useSelector((state)=> state[reducerName].managementData);
	const deleteMember = useSelector((state)=> state[reducerName].deleteMember);

	useEffect(() => {
		if(!deleteMember) {
			return;
		}

		searchSelectControl.current?.clearValue();
	}, [deleteMember, searchSelectControl]);

	const handleRefreshMember = () => {		
		dispatch(searchMemberById(null));
		searchSelectControl.current?.clearValue();
	}


	return (
		props.search ?
		<div className={`col-12 col-md-7 mt-3 ${props.className}`}>
			<button disabled={!managementData} onClick={handleRefreshMember} className="buttom-refresh-layout-header">
				<img className="icon-buttom-refresh-layout-header" src={Reload} alt='Reload'/>
			</button>
			<span className="text-layout-header-search">Search</span>
			<MemberSearch customWidth={props.customWidth} background='#cecece' refVar={searchSelectControl} />
		</div>
	:
		<div></div>
	)
};

export default HeaderPage;
