import React from 'react'
import GenerateForm from './../../../../Components/Forms/generate_form'
import { useSelector, useDispatch } from 'react-redux';
import {
  reducerName as unionInformationReducerName,
  editUnionInformation
} from './../../../../Store/Reducers/unionInformation';
import { formFields } from './constants'

const formName = 'unionInformationForm';

const UnionInformationForm = ({onSubmit, onCancel, index}) => {
  
  const dispatch = useDispatch();
  const unionInformationData = useSelector((state)=> state[unionInformationReducerName].editData);
  const request = useSelector((state)=> state[unionInformationReducerName].request);
  const editData = useSelector((state)=> state[unionInformationReducerName].editData);
  const editRequest = useSelector((state)=> state[unionInformationReducerName].editRequest);

  const handleInputChange = (event) => {
	};

  const handleFormSubmit = (e) => {
			
		e.preventDefault();

		if(!editData.id || document.querySelectorAll(`#${formName} input.is-invalid`).length) {
			document.querySelector(`#${formName} input.is-invalid`).focus();
			return;
		}
		
		const elements = e.target.elements;
		const formData ={};
		const fields = [];

		formFields.forEach((formMember)=>formMember.forms?.forEach((field)=>fields.push(field)))
		
		for (let i = 0; i < elements.length; i++) {

				const { name, value } = elements.item(i);

        if(!name.length) {
          continue;
        }

				const fieldConfiguration = fields.find((field)=> field?.name === name);

        if(fieldConfiguration?.required && !value) {
          elements.item(i).parentElement?.childNodes?.[2]?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]?.focus();
          return;
        }
        
        formData[name] = value;
				
		}

    dispatch(editUnionInformation({
      id: editData.id,
      data: formData
    }));

		onSubmit();
	}


	return (<>
    <form id={formName} onSubmit={handleFormSubmit}>
      <div className="row">
        <div className="col col-12">
          <div className="row">
            {
              formFields.map((section, i) =>
                <>
                <label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
                {
                  section.forms.map((field)=>({
                    ...field,
                    disabled: request,
                    defaultValue: unionInformationData?.[field.name]
                  })).map((form, i) => <GenerateForm
                    key={i}
                    {...form}
                    onChange={handleInputChange}
                    dataInputDates={()=>{}}
                  /> )
                }
                </>					
              )
            }
          </div>
        </div>
      </div>
      <div className='row mt-5 mb-5'>
        <div className='col-2' />
        <div className='col-8 mb-3'>
          <button type='button' disabled={!!editRequest} onClick={onCancel} className='btn-light-grey btn btn-default btn-primary'>Back</button>
        </div>
        <div className='col-2' />
        <div className='col-2' />
        <div className='col-8 mb-5'>
          <button type='submit' disabled={!!editRequest} className='btn btn-default btn-primary'>Accept</button>
        </div>
      </div>
    </form>
  </>)
}

export default UnionInformationForm