import { getAxiosInstance } from "../Config/axios";
import { downloadExcelBase64File } from "../Helpers";
import moment from 'moment';

export const addressUpdateService = async (memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: memberData,
            url: 'member/bulk-address-update'
        };
    
        const result = await axios(options);

        if(!result?.data?.file && result?.data?.message) {
            throw result.data;
        }

        return result?.data;
        
    } catch (error) {
        throw error
    }
};

export const memberAddService = async (memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: memberData,
            url: 'member/bulk-member-create'
        };
    
        const result = await axios(options);

        if(!result?.data?.file && result?.data?.message) {
            throw result.data;
        }

        return result?.data;
        
    } catch (error) {
        throw error
    }
};

export const downloadMemberTemplateService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'download/member-excel'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw result.data;
        }

        const date = moment().format('L')
		downloadExcelBase64File(result?.data?.file.replace('data:application/vnd.ms-excel;base64,', ''), `Member_IAFF_Report_Template-${date}.xlsx`);

        return result?.data;
        
    } catch (error) {
        throw error
    }
};

export const downloadMemberBOCCService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'download/member-bocc-excel'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw result.data;
        }

        const date = moment().format('L')
		downloadExcelBase64File(result?.data?.file.replace('data:application/vnd.ms-excel;base64,', ''), `Member_BOCC_Report_Template-${date}.xlsx`);

        return result?.data;
        
    } catch (error) {
        throw error
    }
};

export const downloadMemberAssignmentService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'download/member-assignment-excel'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw result.data;
        }

        const date = moment().format('L')
		downloadExcelBase64File(result?.data?.file.replace('data:application/vnd.ms-excel;base64,', ''), `Member_Assignment_Report_Template-${date}.xlsx`);

        return result?.data;
        
    } catch (error) {
        throw error
    }
};

export const memberBOCCService = async (memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: memberData,
            url: 'member/bulk-bocc-update'
        };
    
        const result = await axios(options);

        if(!result?.data?.file && result?.data?.message) {
            throw result.data;
        }

        return result?.data;
        
    } catch (error) {
        throw error
    }
};

export const memberAssignmentService = async (memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: memberData,
            url: 'member/bulk-assignment'
        };
    
        const result = await axios(options);

        if(!result?.data?.file && result?.data?.message) {
            throw result.data;
        }

        return result?.data;
        
    } catch (error) {
        throw error
    }
};
