import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setAllDataFiltered,
	clearAllDataFiltered,
	reducerName as insuranceCompanyReducerName
} from './../../../../Store/Reducers/insuranceCompany';
import InputSearch from './../../../../Components/Forms/input_search';

const TableHeader = () => {
	const dispatch = useDispatch();
	const insuranceCompanyData = useSelector((state)=> state[insuranceCompanyReducerName].allData || []);
	const insuranceCompanyDataFiltered = useSelector((state)=> state[insuranceCompanyReducerName].allDataFiltered);

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();

		return data.name?.toLowerCase().includes(searchTerm);
	}


	return <>
		<div className="col-12 col-md-8">
		</div>
		<div className="col-3 col-md-1 text-center">
			<label htmlFor='searchTable' className='form-label mt-2'>Search: </label>
		</div>
		<div className="col-8 col-md-3">
			<InputSearch
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						insuranceCompanyDataFiltered !== null && dispatch(clearAllDataFiltered(
							null
						));
						return;
					}

					dispatch(setAllDataFiltered(
						insuranceCompanyData.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</>
}

export default TableHeader;
