import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCertificateService } from './../../Services/certificateService'

export const reducerName = 'certificate';

const initialState = {
  request: false,
  error: null,
  data: null
};

export const getCertificate = createAsyncThunk(
  'certificate/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getCertificateService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);


export const certificateReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {

    builder.addCase(getCertificate.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getCertificate.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getCertificate.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    })
  }
});

export default certificateReducer.reducer;
