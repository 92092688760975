import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getNewMemberReportService, getNewMemberReportPDFReportService, getNewMemberReportExcelReportService } from './../../Services/newMemberReportService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'newMemberReport';

const initialState = {
  request: false,
  error: null,
  data: [],

  PDFReportError: null,
  PDFReportRequest: false,
  PDFReportData: null,
  
  ExcelReportError: null,
  ExcelReportRequest: false,
  ExcelReportData: null
};

export const getNewMemberReport = createAsyncThunk(
  'newMemberReport/getNewMemberReport',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getNewMemberReportService(parameters);

      return {
        data: response.members
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getNewMemberReportPDFReport = createAsyncThunk(
  'newMemberReport/getNewMemberReportPDFReport',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getNewMemberReportPDFReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getNewMemberReportExcelReport = createAsyncThunk(
  'newMemberReport/getNewMemberReportExcelReport',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getNewMemberReportExcelReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setDataFilterReducer = (state, action) => {
  return {
    ...state,
    dataFiltered: action.payload.data
  }
};

const setDataFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearFilteredDataHandler = (state) => {
  return {
    ...state,
    dataFiltered: null
  }
};

const setDateFilterReducer = (state, action) => {
  return {
    ...state,
    dateFilter: action.payload.data
  }
};

const setDateFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};


export const newMemberReportReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setDateFilter: {
      reducer: setDateFilterReducer,
      prepare: setDateFilterAction
    },
    setDataFilter: {
      reducer: setDataFilterReducer,
      prepare: setDataFilterAction
    },
    clearFilteredData: clearFilteredDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getNewMemberReport.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    });

    builder.addCase(getNewMemberReport.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    });

    builder.addCase(getNewMemberReport.pending, (state) => {
      state.request = true;
      state.error = null;
    });

    builder.addCase(getNewMemberReportPDFReport.fulfilled, (state, action) => {
      state.PDFReportData = action.payload?.data;
      state.PDFReportRequest = false;
    })

    builder.addCase(getNewMemberReportPDFReport.rejected, (state, action) => {
      state.PDFReportError = action.payload.error;
      state.PDFReportRequest = false;
      state.PDFReportData = null;
    })

    builder.addCase(getNewMemberReportPDFReport.pending, (state) => {
      state.PDFReportRequest = true;
      state.PDFReportError = null;
      state.PDFReportData = null;
    })

    builder.addCase(getNewMemberReportExcelReport.fulfilled, (state, action) => {
      state.ExcelReportData = action.payload?.data;
      state.ExcelReportRequest = false;
    })

    builder.addCase(getNewMemberReportExcelReport.rejected, (state, action) => {
      state.ExcelReportError = action.payload.error;
      state.ExcelReportRequest = false;
      state.ExcelReportData = null;
    })

    builder.addCase(getNewMemberReportExcelReport.pending, (state) => {
      state.ExcelReportRequest = true;
      state.ExcelReportError = null;
      state.ExcelReportData = null;
    })
  }
});
export const { setDataFilter, clearFilteredData, setDateFilter, setEndDateFilter } = newMemberReportReducer.actions

export default newMemberReportReducer.reducer;
