import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getStatusService, getAllStatusService } from './../../Services/statusService'

export const reducerName = 'status';

const initialState = {
  request: false,
  error: null,
  data: null,
  
  allRequest: false,
  allError: null,
  allData: []
};

export const getStatus = createAsyncThunk(
  'status/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getStatusService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getAllStatus = createAsyncThunk(
  'status/getAllStatus',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getAllStatusService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);


export const statusReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {

    builder.addCase(getStatus.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getStatus.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getStatus.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    })

    builder.addCase(getAllStatus.fulfilled, (state, action) => {
      state.allData = action.payload?.data;
      state.allRequest = false;
    })

    builder.addCase(getAllStatus.rejected, (state, action) => {
      state.AllError = action.payload.error;
      state.AllRequest = false;
      state.AllData = null;
    })

    builder.addCase(getAllStatus.pending, (state) => {
      state.AllRequest = true;
      state.AllError = null;
      state.AllData = null;
    })
  }
});

export default statusReducer.reducer;
