import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMemberDisabilityService, getMemberDisabilityPDFReportService, getMemberDisabilityExcelReportService } from './../../Services/memberDisabilityService'

export const reducerName = 'memberDisability';

const date = new Date();
const currentFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
const currentLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

const initialState = {
  request: false,
  error: null,
  data: [],

  endDateFilter: currentLastDay.toISOString(),
  startDateFilter: currentFirstDay.toISOString(),

  getMemberDisabilityPDFReportError: null,
  getMemberDisabilityPDFReportRequest: false,
  getMemberDisabilityPDFReportData: null,
  
  getMemberDisabilityExcelReportError: null,
  getMemberDisabilityExcelReportRequest: false,
  getMemberDisabilityExcelReportData: null
};

export const getMemberDisability = createAsyncThunk(
  'memberDisability/getMemberDisability',
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getMemberDisabilityService(parameters);

      return {
        data: response.member_disabilities
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getMemberDisabilityPDFReport = createAsyncThunk(
  'memberDisability/getMemberDisabilityPDFReport',
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getMemberDisabilityPDFReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getMemberDisabilityExcelReport = createAsyncThunk(
  'memberDisability/getMemberDisabilityExcelReport',
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getMemberDisabilityExcelReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setDataFilterReducer = (state, action) => {
  return {
    ...state,
    filteredData: action.payload.data
  }
};

const setDataFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearFilteredDataHandler = (state) => {
  return {
    ...state,
    filteredData: null
  }
};

const setStartDateFilterReducer = (state, action) => {
  return {
    ...state,
    startDateFilter: action.payload.data
  }
};

const setStartDateFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const setEndDateFilterReducer = (state, action) => {
  return {
    ...state,
    endDateFilter: action.payload.data
  }
};

const setEndDateFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};


export const memberDisabilityReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setStartDateFilter: {
      reducer: setStartDateFilterReducer,
      prepare: setStartDateFilterAction
    },
    setEndDateFilter: {
      reducer: setEndDateFilterReducer,
      prepare: setEndDateFilterAction
    },
    setDataFilter: {
      reducer: setDataFilterReducer,
      prepare: setDataFilterAction
    },
    clearFilteredData: clearFilteredDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getMemberDisability.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    });

    builder.addCase(getMemberDisability.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    });

    builder.addCase(getMemberDisability.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    });

    builder.addCase(getMemberDisabilityPDFReport.fulfilled, (state, action) => {
      state.getMemberDisabilityPDFReportData = action.payload?.data;
      state.getMemberDisabilityPDFReportRequest = false;
    })

    builder.addCase(getMemberDisabilityPDFReport.rejected, (state, action) => {
      state.getMemberDisabilityPDFReportError = action.payload.error;
      state.getMemberDisabilityPDFReportRequest = false;
      state.getMemberDisabilityPDFReportData = null;
    })

    builder.addCase(getMemberDisabilityPDFReport.pending, (state) => {
      state.getMemberDisabilityPDFReportRequest = true;
      state.getMemberDisabilityPDFReportError = null;
      state.getMemberDisabilityPDFReportData = null;
    })

    builder.addCase(getMemberDisabilityExcelReport.fulfilled, (state, action) => {
      state.getMemberDisabilityExcelReportData = action.payload?.data;
      state.getMemberDisabilityExcelReportRequest = false;
    })

    builder.addCase(getMemberDisabilityExcelReport.rejected, (state, action) => {
      state.getMemberDisabilityExcelReportError = action.payload.error;
      state.getMemberDisabilityExcelReportRequest = false;
      state.getMemberDisabilityExcelReportData = null;
    })

    builder.addCase(getMemberDisabilityExcelReport.pending, (state) => {
      state.getMemberDisabilityExcelReportRequest = true;
      state.getMemberDisabilityExcelReportError = null;
      state.getMemberDisabilityExcelReportData = null;
    })
  }
});
export const { setDataFilter, clearFilteredData, setStartDateFilter, setEndDateFilter } = memberDisabilityReducer.actions

export default memberDisabilityReducer.reducer;
