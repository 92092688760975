import { NUMBER, STRING, SPECIALALPHANUMERIC } from './../../../../Components/Forms/constant_form';

export const formFields = [
	{
		forms: [
			{
				label: 'IAFF Local#',
				placeholder: 'Type IAFF local',
				type: 'text',
				col: 'col-12 col-lg-6',
				required: true,
				validate: {
					type: NUMBER
				},
				name: 'iaff_local'
			},
			{
				label: 'IAFF Affiliate Name',
				placeholder: 'Type IAFF affiliate name',
				type: 'text',
				col: 'col-12 col-lg-6',
				required: true,
				validate: {
					type: STRING
				},
				name: 'iaff_affiliate_name'
			},
			{
				label: 'Address Line 1',
				placeholder: 'Type address line 1',
				type: 'text',
				col: 'col-12',
				required: true,
				validate: {
					type: SPECIALALPHANUMERIC
				},
				name: 'address_line_1'
			},
			{
				label: 'City',
				placeholder: 'Type city',
				type: 'text',
				col: 'col-12 col-lg-6',
				required: true,
				validate: {
					type: STRING
				},
				name: 'city'
			},
			{
				label: 'State',
				placeholder: 'Type state',
				type: 'text',
				col: 'col-12 col-lg-6',
				required: true,
				validate: {
					type: STRING
				},
				name: 'state'
			},
			{
				label: 'Zip',
				placeholder: 'Type zip',
				type: 'text',
				col: 'col-12',
				required: true,
				validate: {
					type: NUMBER
				},
				name: 'zip_code'
			},
			{
				label: 'Phone Number',
				placeholder: 'Phone number',
				type: 'mask',
				col: 'col-12',
				required: true,
				name: 'phone_number'
			}
		]

	}

];
