import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getJobUnionStatusChangesByDate,
  getJobUnionStatusPDFReportService,
  getJobUnionStatusExcelReportService
} from './../../Services/jobUnionStatusService'

export const reducerName = 'jobUnionStatus';

const initialState = {

  statusChangesData: [],
  statusChangesError: null,
  statusChangesRequest: false
};

export const getJobUnionStatusChanges = createAsyncThunk(
  `${reducerName}/getJobUnionStatusChanges`,
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getJobUnionStatusChangesByDate(parameters);

      return {
        data: response.job_union_statuses
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setStartDateFilterReducer = (state, action) => {
  return {
    ...state,
    startDateFilter: action.payload.data
  }
};

const setStartDateFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const setEndDateFilterReducer = (state, action) => {
  return {
    ...state,
    endDateFilter: action.payload.data
  }
};

const setEndDateFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const setDataFilterReducer = (state, action) => {
  return {
    ...state,
    statusChangesFilteredData: action.payload.data
  }
};

const setDataFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearFilteredDataHandler = (state) => {
  return {
    ...state,
    statusChangesFilteredData: null
  }
};

export const getJobUnionStatusPDFReport = createAsyncThunk(
  `${reducerName}/getJobUnionStatusPDFReport`,
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getJobUnionStatusPDFReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getJobUnionStatusExcelReport = createAsyncThunk(
  `${reducerName}/getJobUnionStatusExcelReport`,
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getJobUnionStatusExcelReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const statusReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setStartDateFilter: {
      reducer: setStartDateFilterReducer,
      prepare: setStartDateFilterAction
    },
    setEndDateFilter: {
      reducer: setEndDateFilterReducer,
      prepare: setEndDateFilterAction
    },
    setDataFilter: {
      reducer: setDataFilterReducer,
      prepare: setDataFilterAction
    },
    clearFilteredData: clearFilteredDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getJobUnionStatusChanges.fulfilled, (state, action) => {
      state.statusChangesData = action.payload?.data;
      state.statusChangesRequest = false;
    })

    builder.addCase(getJobUnionStatusChanges.rejected, (state, action) => {
      state.statusChangesError = action.payload.error;
      state.statusChangesRequest = false;
    })

    builder.addCase(getJobUnionStatusChanges.pending, (state) => {
      state.statusChangesRequest = true;
      state.statusChangesError = null;
    })



    builder.addCase(getJobUnionStatusPDFReport.fulfilled, (state, action) => {
      state.pdfReportData = action.payload?.data;
      state.pdfReportRequest = false;
    })

    builder.addCase(getJobUnionStatusPDFReport.rejected, (state, action) => {
      state.pdfReportError = action.payload.error;
      state.pdfReportRequest = false;
    })

    builder.addCase(getJobUnionStatusPDFReport.pending, (state) => {
      state.pdfReportRequest = true;
      state.pdfReportError = null;
    })

    builder.addCase(getJobUnionStatusExcelReport.fulfilled, (state, action) => {
      state.excelReportData = action.payload?.data;
      state.excelReportRequest = false;
    })

    builder.addCase(getJobUnionStatusExcelReport.rejected, (state, action) => {
      state.excelReportError = action.payload.error;
      state.excelReportRequest = false;
    })

    builder.addCase(getJobUnionStatusExcelReport.pending, (state) => {
      state.excelReportRequest = true;
      state.excelReportError = null;
    })
  }
});

export const { setDataFilter, clearFilteredData, setStartDateFilter, setEndDateFilter } = statusReducer.actions
export default statusReducer.reducer;
