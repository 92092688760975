import { createSlice } from '@reduxjs/toolkit';

export const reducerName = 'notification';

const initialState = {
  message: null,
  type: null
};


export const notificationReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    showNotification: (state, action) => {
      state.message = action.payload?.message;
      state.type = action.payload?.type;
    },
    hideNotification: (state) => {
      state.message = null;
      state.type = null;
    }
  }
});

export const { showNotification, hideNotification } = notificationReducer.actions


export default notificationReducer.reducer;
