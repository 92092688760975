import React, { useState, useEffect, Fragment, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeaderPage from '../../../Layouts/header_page'
import GenerateForm from '../../../Components/Forms/generate_form'
import { ReportFormPartOne, ReportFormPartTwo } from '../../../Config/Forms/Report'
import Reload from '../../../Assets/Img/Icons/reload.svg'
import Report from '../../../Assets/Img/Icons/report-menu.svg'
import { downloadReportAll, downloadReportByMemberId, reducerName as payrollDeductionReducerName } from '../../../Store/Reducers/payrollDeduction'
import { getMember } from '../../../Store/Reducers/member'

const PayrollDeduction = () => {
	const dispatch = useDispatch();
	const selectedMembersRef = useRef();
	const downloadReportByIdRequest = useSelector((state)=> state[payrollDeductionReducerName].downloadReportByIdRequest);
	const downloadReportAllRequest = useSelector((state)=> state[payrollDeductionReducerName].downloadReportAllRequest);
	
	const [formData, setFormData] = useState({
		'monthly_payroll': 'monthly_payroll'
	})

	useEffect(() => {
		dispatch(getMember());
	}, [dispatch])

	const handleOnChangeForm = (event) => {
		setFormData({
				...formData,
				[event.target.name] : event.target.value
		})
	};

	const handleViewAllPDF = () => {
		dispatch(downloadReportAll({
			parameters: {
				report_type: formData.monthly_payroll === 'monthly_payroll'
			}
		}));
	}

	const handleViewByMemberPDF = () => {
		dispatch(downloadReportByMemberId({
			parameters: {
				members_ids: formData.selected_members,
				report_type: formData.monthly_payroll === 'monthly_payroll'
			}
		}));
	};

	const handleReset = () => {
		setFormData({
			...formData,
			'selected_members': []
		});
		selectedMembersRef.current?.clearValue();
	};



	return (
		<>
			<LayoutHeaderPage title="Payroll Deductions"/>
			<div className="tab-content container mt-3 pt-3" id="pills-tabContent">
				<form>
					<div className="row">
								{
									ReportFormPartOne.map((section, i) =>
										<Fragment key={i}>
										<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
										{
											section.forms.map((fieldData)=>({
												...fieldData,
												isLoading: downloadReportAllRequest || downloadReportByIdRequest,
												defaultValue: formData[fieldData.name],
				                ...(fieldData.name === 'selected_members' && { refVar: selectedMembersRef }),
											})).map((form, i) => 
												<GenerateForm
													key={i}
													{...form}
													onChange={handleOnChangeForm}
												/> )
										}
										</Fragment>					
									)
								}
						<div className="col-sm-12 col-12">
							<button onClick={handleReset} type='button' className="btn-blue btn-default btn-reload-report mt-3">
								<img className="icon-buttom-refresh-layout-header" src={Reload} alt='Reload'/>
								Reset
							</button>				
						</div>
						<div className="col-sm-12 col-12">
							<div className="row mt-3">
								{
									ReportFormPartTwo.map((section, i) =>
										<Fragment key={i}>
										<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
										{
											section.forms.map((field)=> {
												return {
													...field,
													defaultValue: formData[field.name]
												}
											}).map((form, i) =>
											<GenerateForm
												key={i}
												{...form}
												onChange={handleOnChangeForm}
											/> )
										}
										</Fragment>					
									)
								}
							</div>				
						</div>				
					</div>
				</form>
				<div className="row pb-3 pt-3">
						<div className="col-2"></div>
						<div className="col-8 col-md-3 mb-3">
								<button disabled={downloadReportAllRequest || downloadReportByIdRequest} type="button" className="btn-orange btn btn-default btn-primary" onClick={handleViewAllPDF}><img className="icon-info-user pa-2 me-2" alt="login icon" src={Report} />View All PDF</button>
						</div>
						<div className="col-2 show-on-mobile"></div>
						<div className="col-2 show-on-mobile"></div>
						<div className="col-8 col-md-3 mb-3">
							<button
								type="button"
								className="btn-orange btn btn-default btn-primary"
								onClick={handleViewByMemberPDF}
								disabled={!formData.selected_members?.length || downloadReportAllRequest || downloadReportByIdRequest}
							>
								<img className="icon-info-user pa-2 me-2" alt="login icon" src={Report} />View Selected PDF
							</button>
						</div>
				</div>
			</div>
		</>
	)
};

export default PayrollDeduction;
