import React, { useState, useRef, Fragment } from 'react'
import LayoutHeaderPage from '../../../Layouts/header_page'
import { CountyAssessmentFormPartOne } from '../../../Config/Forms/Report/county_assessment'
import GenerateForm from '../../../Components/Forms/generate_form'
import Report from '../../../Assets/Img/Icons/report-menu.svg'
import { getComparisonReport, reducerName } from '../../../Store/Reducers/comparisonReport'
import { useDispatch, useSelector } from 'react-redux';

const CountryAssessment = () => {
	const dispatch = useDispatch();
	const reportRequest = useSelector((state)=> state[reducerName].reportRequest);
	const [file, setFile] = useState();
	const [type, setType] = useState();
	const fileInput = useRef(null);

	const handleInputChange = (event) => setType(event.target.value);

	const handleGetComparisonReport = (action) => {
		const formData = new FormData();
		
		formData.append('file', file);
		formData.append('type', type);
		formData.append('action', action);
		
		dispatch(getComparisonReport({
			parameters: formData
		}))
	}

	return (
		<>
			<LayoutHeaderPage title="County Assessment / Comparison Report"/>
			<div className="tab-content mt-3 pt-4" id="pills-tabContent">
				<div className='row mb-3'>
					<div className='col-12'>
						<label className="title-component-upload-template">Upload File</label>
						<div className="row mt-4">
							<div className="col-2 show-on-mobile"></div>
							<div className="col-8 col-sm-3">
								<button type="submit" data-bs-target="#inputGroupFile01" className="btn-component-select-file btn btn-default btn-primary mb-3" onClick={() => fileInput.current.click()}>
									Select Files
								</button>
								<span className="text-component-upload-template">* Allowable format: .xlsx</span><br />
								<span className="text-component-upload-template">* Max file size is: 2MB</span>
								<input type="file" hidden className="form-control" onChange={(event) => setFile(event.target.files[0])} ref={fileInput} id="inputGroupFile01"/>
							</div>	
							<div className="col-2 show-on-mobile"></div>
							<div className="col-2 show-on-mobile"></div>
							<div className="col-8 col-sm-3">
								{ file ?
									<span className="text-component-select-file">{ file.name }</span>
									:
									<span className="text-component-select-file">No file was chosen </span>
								}
							</div>
						</div>
					</div>
					<div className="col col-6 col-lg-3">
					</div>
				</div>
				<div className="row">
            {
              CountyAssessmentFormPartOne.map((section, i) =>
                <Fragment key={i}>
                <label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
                {
                  section.forms.map((form, i) => <GenerateForm
                    key={i}
                    {...form}
                    onChange={handleInputChange}
                  /> )
                }
                </Fragment>					
              )
            }
          </div>
					<div className='row mb-4 mt-3'>
							<div className="col-12 col-md-6 col-lg-6"></div>
							<div className="col-2 show-on-mobile"></div>
							<div className="col-8 col-sm-3 col-md-3 col-lg-3 mt-2">
								<button type="button" onClick={()=> handleGetComparisonReport('PDF')} disabled={!file || !type || reportRequest} className="btn-orange btn btn-default btn-primary"><img className="icon-info-user pa-2 me-2" alt="login icon" src={Report} />View All PDF</button>
							</div>
							<div className="col-2 show-on-mobile"></div>
							<div className="col-2 show-on-mobile"></div>
							<div className="col-8 col-sm-3 col-md-3 col-lg-3 mt-2">
								<button type="button" onClick={()=> handleGetComparisonReport('Excel')} disabled={!file || !type || reportRequest} className="btn-blue btn btn-default btn-primary"><img className="icon-info-user pa-2 me-2" alt="login icon" src={Report} />Export to Excel</button>
							</div>
					</div>
			</div>
		</>
	)
}

export default CountryAssessment;
