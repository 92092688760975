import React, { useState, useEffect } from 'react';
import LayoutHeaderPage from './../../../../Layouts/header_page';
import { useSelector, useDispatch } from 'react-redux';
import {
	getUnionInformation,
	reducerName as unionInformationReducerName,
	setEditData
} from './../../../../Store/Reducers/unionInformation';
import TableComponent from './../../../../Components/table_v2';
import ModalComponent from './../../../../Components/modal';
import Notification from './../../../../Components/notification';
import Form from './form';
import { formFields } from './constants'

const UnionInformations = () => {

	const dispatch = useDispatch();
	const editUnionInformationData = useSelector((state)=> state[unionInformationReducerName]?.data?.[0]);
	const request = useSelector((state)=> state[unionInformationReducerName]?.request);
	const unionInformationData = useSelector((state)=> {
		
		if(Array.isArray(state[unionInformationReducerName].data)) {
			return formFields[0].forms.map((fieldData) => {
				return {
					field: fieldData.label,
					value: state[unionInformationReducerName].data[0]?.[fieldData.name]
				}
			})
		}

		return [];
	});
	
	const tableColumns = [
		{
			title: 'Field',
			sort: true,
      value: 'field',
		},
		{
			title: 'Value',
      value: 'value',
		}
	];

	useEffect(() => {
		dispatch(getUnionInformation())
	}, [dispatch]);


	const [modalIsOpen, setIsOpen] = useState(false);
	const modalDescription = '';
	const modalTitle = 'Edit Union Information';
	const modalBody = <div className='row modal-footer' style={{width: '500px', maxWidth: '100%'}}>
		<div className='col col-12'><Form onCancel={()=> closeModal()} onSubmit={()=>{
				closeModal();
			}} /></div>
	</div>;

	const openModal = () => {
		dispatch(setEditData(editUnionInformationData));
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };


	return (
		<>
			<LayoutHeaderPage title="Union Information"/>
			<div className="tab-content pt-3" id="pills-tabContent">
				<TableComponent
					columns={tableColumns}
					data={unionInformationData}
					pagination={false}
				/>
				<Notification />
				<div className='row mt-5 mb-3'>
					<div className='col-12 col-lg-9'></div>
					<div className='col-2 show-on-mobile'></div>
					<div className='col-8 col-lg-3'>
						<button type='button' onClick={openModal} disabled={request} className='btn-orange btn-default btn-primary'>Edit</button>
					</div>
				</div>
			</div>
			<ModalComponent
				modalIsOpen={modalIsOpen}
				description={modalDescription}
				modalBody={modalBody}
				title={modalTitle}
				closeModal={closeModal}
				confirmModal={()=>{
					closeModal();
				}}
			/>
		</>
	)
}

export default UnionInformations;
