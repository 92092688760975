import { getAxiosInstance } from "../Config/axios";

export const getUnionMembershipStatusService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'union-membership-status/get/all'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
}