import { createSlice } from '@reduxjs/toolkit';

export const reducerName = 'session';

const initialState = {
  end: true
};

const handlerSessionEnd = (state) => ({
  ...state,
  end: true
});

const handlerClearSessionEnd = (state) => ({
  ...state,
  end: false
});


export const sessionReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    sessionEnd: handlerSessionEnd,
    clearSessionEnd: handlerClearSessionEnd
  }
});

export const { sessionEnd, clearSessionEnd } = sessionReducer.actions

export default sessionReducer.reducer;
