import React, { useState, useEffect, Fragment, useRef } from 'react'
import moment from 'moment'
import LayoutHeaderPage from '../../../Layouts/header_page'
import GenerateForm from '../../../Components/Forms/generate_form'
import { CustomReportFormsPartOne, CustomReportFormsPartTwo, CustomReportFormsPartThree } from '../../../Config/Forms/Report/custom_report'
import Reload from '../../../Assets/Img/Icons/reload.svg'
import Report from '../../../Assets/Img/Icons/report-menu.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getInsuranceCompany, reducerName as insuranceCompanyReducerName } from '../../../Store/Reducers/insuranceCompany';
import { getPolicyByCompany } from '../../../Store/Reducers/policy';
import { getPosition, reducerName as positionReducerName } from '../../../Store/Reducers/position';
import { getUnionMembershipStatus, reducerName as unionMembershipStatusReducerName } from '../../../Store/Reducers/unionMembershipStatus';
import { getCustomReport, reducerName as customReportReducerName } from './../../../Store/Reducers/customReport';
import { getGender, reducerName as genderReducerName } from '../../../Store/Reducers/gender';
import { getEthnicity, reducerName as ethnicityReducerName } from '../../../Store/Reducers/ethnicity';
import { getSickPlan, reducerName as sickPlanReducerName } from '../../../Store/Reducers/sickPlan';

const MembersManagements = () => {
	const dispatch = useDispatch();
	const insuranceCompanySelectControl = useRef();
	const insurancePolicySelectControl = useRef();
	const genderSelectControl = useRef();
	const statusSelectControl = useRef();
	const ethnicitySelectControl = useRef();
	const positionSelectControl = useRef();
	const sickPlanSelectControl = useRef();
	const positionData = useSelector((state)=> state[positionReducerName].data);
	const genderData = useSelector((state)=> state[genderReducerName].data);
	const ethnicityData = useSelector((state)=> state[ethnicityReducerName].data);
	const sickPlanData = useSelector((state)=> state[sickPlanReducerName].data);
	const unionMembershipStatusData = useSelector((state)=> state[unionMembershipStatusReducerName].data);
	const insuranceCompanyData = useSelector((state)=> state[insuranceCompanyReducerName].data);
	const customReportRequest = useSelector((state)=> state[customReportReducerName].ExcelReportRequest);

	useEffect(() => {
		!positionData?.length && dispatch(getPosition());
	}, [positionData, dispatch]);

	useEffect(() => {
		!sickPlanData?.length && dispatch(getSickPlan());
	}, [sickPlanData, dispatch]);

	useEffect(() => {
		dispatch(getGender());
	}, [dispatch]);

	useEffect(() => {
		!ethnicityData?.length && dispatch(getEthnicity());
	}, [ethnicityData, dispatch]);

	useEffect(() => {
		!unionMembershipStatusData?.length && dispatch(getUnionMembershipStatus());
	}, [unionMembershipStatusData, dispatch]);

	useEffect(() => {
		!insuranceCompanyData?.length && dispatch(getInsuranceCompany());
	}, [insuranceCompanyData, dispatch]);
	
	const [dataForm, setDataForm] = useState({fields:[]})
	const [nameInputDate, setNameInputDate] = useState({});
	const [defaultReportTemplate, setDefaultReportTemplate] = useState([]);

	const handleInputChangeMembers = (event) => {
		if(event.target.name === 'company_id' && event.target.value) {
			!!event.target.value && dispatch(getPolicyByCompany(event.target.value));
		}

		if(event.target.name === 'custom_report_template') {
			switch (event.target.value) {
				case 'clear-selection':
						setDefaultReportTemplate([]);
					break;

				case 'personal-information':
					setDefaultReportTemplate([
						'first_name',
						'last_name',
						'middle_name',
						'suffix',
						'address',
						'city',
						'state',
						'zip_code',
						'email',
						'birth_date',
						'social_security_number',
						'gender',
						'ethnicity'
					]);
					break;

				case 'job-information':
					setDefaultReportTemplate([
						'first_name',
						'last_name',
						'middle_name',
						'suffix',
						'position',
						'hourly_rate',
						'employee_number',
						'employ_date'
					]);

					break;

				case 'iaff-information':
					setDefaultReportTemplate([
						'first_name',
						'last_name',
						'middle_name',
						'suffix',
						'iaff_member_number',
						'status',
						'join_date',
					]);

					break;
			
				case 'payroll-deductions':
					setDefaultReportTemplate([
						'first_name',
						'last_name',
						'middle_name',
						'suffix',
						'employee_number',
						'uniaff',
						'iaff',
					]);

					break;

				case 'all-information':
					setDefaultReportTemplate([
						'first_name',
						'last_name',
						'middle_name',
						'suffix',
						'employee_number',
						'uniaff',
						'iaff',
						'status',
						'join_date',
						'position',
						'hourly_rate',
						'employ_date',
						'address',
						'city',
						'state',
						'zip_code',
						'email',
						'birth_date',
						'social_security_number',
						'iaff_member_number',
						'gender',
						'ethnicity',
						'sick_plan',
						'retire_date'
					]);

					break;

				default:
					break;
			}
		}

		const newFormData = {...dataForm};
		
		if(!event.target.value) {
			delete newFormData[event.target.name]
		} else {
			newFormData[event.target.name]=event.target.value;
		}

		setDataForm(newFormData);
	}

	const handleInputChecks = (event) => {
		if(event.target.value === 'true') {
			setDefaultReportTemplate([...defaultReportTemplate, event.target.name])
		} else {
			setDefaultReportTemplate(defaultReportTemplate.filter((reportField)=> reportField !== event.target.name))
		}
	}

	const sendAllData = () => {
		dataForm.fields = defaultReportTemplate;
		dispatch(getCustomReport(dataForm))
	}

	const dataInputDates = (name, value) => {
		const newDataForm = JSON.parse(JSON.stringify(dataForm));
		
		if(value) {
			var date = moment(value).format('L')
			var dateFormat = date.split('/')
			var dateFormatEnd = `${dateFormat[2]}-${dateFormat[0]}-${dateFormat[1]}`;
			newDataForm[name] = dateFormatEnd
		}else{
			delete newDataForm[name];
		}
		
		setDataForm(newDataForm)    	
	};

	const handleReset = () => {
		setDefaultReportTemplate([]);
		insuranceCompanySelectControl.current?.clearValue();
		insurancePolicySelectControl.current?.clearValue();
		genderSelectControl.current?.clearValue();
		statusSelectControl.current?.clearValue();
		ethnicitySelectControl.current?.clearValue();
		positionSelectControl.current?.clearValue();
		sickPlanSelectControl.current?.clearValue();
		setDataForm({fields:[]})

	}

	const getSelectControl = (fieldName) => {

		switch(fieldName) {
			case 'insuranceCompany':
					return { refVar: insuranceCompanySelectControl };
			case 'policy_id':
				return { refVar: insurancePolicySelectControl };
			case 'gender_id': 
				return { refVar: genderSelectControl };

			case 'union_membership_status_id': 
				return { refVar: statusSelectControl };

			case 'ethnicity_id': 
				return { refVar: ethnicitySelectControl };

			case 'position_id': 
				return { refVar: positionSelectControl };

			case 'sick_plan_id': 
				return { refVar: sickPlanSelectControl };


			default:
				return {};
		}
	}

	return (
		<>
			<LayoutHeaderPage title="Custom Report"/>
			<div className="tab-content mt-3 pt-4" id="pills-tabContent">
			<form>
			<div className="row">
				<div className="col-sm-12 col-12">
					<div className="row">
						{
							CustomReportFormsPartOne.map((section, i) =>
								<Fragment key={i}>
								<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
								{
									section.forms.map((fieldData)=>({
										...fieldData,
										defaultValue: fieldData.name === 'fields' ? defaultReportTemplate : dataForm[fieldData.name]
									})).map((form, i) => 
										<GenerateForm
											key={i}
											{...form}
											setNameInputDate={setNameInputDate}											
											dataInputDates={dataInputDates}
											handleInputChecks={handleInputChecks}
											onChange={handleInputChangeMembers}/>)
								}
								</Fragment>					
							)
						}
					</div>
				</div>
				<div className="col-sm-12 col-12">
					<div className="row">
						{
							CustomReportFormsPartTwo.map((form, i) =>
								<Fragment key={i}>
								<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-5': 'mt-3'}`}>{form.label}</label>
								{
									form.forms.map((fieldData)=>({
										...fieldData,
										defaultValue: dataForm[fieldData.name]
									})).map((form, i) => 
										<GenerateForm
											key={i}
											{...form}
											setNameInputDate={setNameInputDate}											
											dataInputDates={dataInputDates}
											onChange={handleInputChangeMembers}/> )
								}
								</Fragment>					
							)
						}
						<div className="col-8 col-md-2 col-lg-1">
							<button onClick={handleReset} type='button' className="btn-blue-refresh-table btn btn-primary">
								<img className="icon-buttom-refresh-layout-header-table" src={Reload} alt='Reload'/>
								Reset
							</button>						
						</div>
						{
							CustomReportFormsPartThree.map((form, i) =>
								<Fragment key={i}>
								<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{form.label}</label>
								{
									form.forms.map((fieldData)=>({
										...fieldData,
										defaultValue: dataForm[fieldData.name],
										...(getSelectControl(fieldData.name))
									})).map((form, i) => 
										<GenerateForm
											key={i}
											{...form}
											nameInputDate={nameInputDate} 
											setNameInputDate={setNameInputDate}
											dataInputDates={dataInputDates}
											onChange={handleInputChangeMembers}/> )
								}
								</Fragment>					
							)
						}						
					</div>
				</div>
			</div>
			</form>			
				<div className="row pb-5 pt-5">
					<div className="col-12 col-md-3"></div>
					<div className="col-12 col-md-3"></div>
					<div className="col-2 show-on-mobile"></div>
					<div className="col-8 col-md-3">
						<button type="submit" disabled={!defaultReportTemplate.length || customReportRequest} onClick={sendAllData} className="btn-blue btn btn-default btn-primary"><img className="icon-info-user pa-2 me-2" alt="login icon" src={Report} />Export to Excel</button>
					</div>
					<div className="col-12 col-md-3">
					</div>
				</div>
			</div>
		</>
	)
}

export default MembersManagements