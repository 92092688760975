import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setDataFiltered,
	clearDataFiltered,
	reducerName as stationReducerName
} from './../../../../Store/Reducers/station';
import InputSearch from './../../../../Components/Forms/input_search';

const TableHeader = () => {
	const dispatch = useDispatch();
	const stationData = useSelector((state)=> state[stationReducerName].data || []);
	const stationDataFiltered = useSelector((state)=> state[stationReducerName].dataFiltered);

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();

		return data.name?.toLowerCase().includes(searchTerm) || data.cost?.toString()?.toLowerCase().includes(searchTerm);
	};

	return <>
		<div className="col-12 col-md-8">
		</div>
		<div className="col-3 col-md-1 text-center">
			<label htmlFor='searchTable' className='form-label mt-2'>Search: </label>
		</div>
		<div className="col-8 col-md-3">
			<InputSearch
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						stationDataFiltered !== null && dispatch(clearDataFiltered(
							null
						));
						return;
					}

					dispatch(setDataFiltered(
						stationData.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</>
}

export default TableHeader;
