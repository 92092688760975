import React from 'react';
import TableComponent from '../../../../Components/table_v2'
import {
	reducerName as memberInsuranceReducerName,
	deleteMemberInsurance	
} from '../../../../Store/Reducers/memberInsurance';
import { useDispatch, useSelector } from 'react-redux';
import { reducerName as memberReducerName } from '../../../../Store/Reducers/member';

import DeleteIcon from './../../../../Assets/Img/Icons/delete.jpg';
import CancelIcon from './../../../../Assets/Img/Icons/cancel.jpg';
import ModalComponent from './../../../../Components/modal';
import CancellationForm from './cancellationForm';
import ButtonsActions from './../../../../Components/Forms/buttons_actions'
import moment from 'moment';
import { ROLES } from '../../../../Router/Roles';
import { reducerName as userReducerName } from '../../../../Store/Reducers/user';

const Insurance = ({showNewPoliciForm,showCancellations}) => {
	const dispatch = useDispatch();
	const memberInsuranceGetData = useSelector((state)=> state[memberInsuranceReducerName].getData || []);
  const managementData = useSelector((state)=> state[memberReducerName].managementData);
	const roles = useSelector((state)=>state[userReducerName].data?.user?.roles || []).map((roleData)=> roleData.name);
	const hasMember = !roles?.includes(ROLES.developer) && !roles?.includes(ROLES.administrator);


	const tableColumns = [
		{
			title: 'Insurance Company',
			sort: true,
      value: 'insurance_company_id',
			getLabelFromStore: (storeData, index) => {
				return storeData.memberInsurance?.getData?.[index]?.policy?.insurance_company?.name;
			}
		},
		{
			title: 'Insurance Policy',
      value: 'policy_id',
			getLabelFromStore: (storeData, index) => {
				return storeData.memberInsurance?.getData?.[index]?.policy?.name;
			}
		},
		{
			title: 'Reason',
      value: 'reason_id',
			getLabelFromStore: (storeData, index) => {
				return storeData.reason?.data?.find((reasonData) => reasonData.id === storeData.memberInsurance?.getData?.[index]?.reason_id)?.name;
			}
		},
		{
			title: 'Cost',
      value: 'cost',
			parseValue: (val)=> {
				if(!val && val !==0){
					return ''
				}

				return `$${val}`;
			}
		},
		{
			title: 'Date Added',
      value: 'join_date',
			getLabelFromStore: (storeData, index) => {
				const value = storeData.memberInsurance?.getData?.[index]?.join_date;
				var date = moment(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		},
		...( !hasMember ? [{
			title: 'Actions',
      value: 'actions',
			className: 'td-space-table-two-buttons',
			getLabelFromStore: (storeData, index) => {
				return <>
					<button 
						className='btn-icon'
						onClick={()=> {
							setIndex(index);
							setModalDescription('');
							setModalTitle('Complete form to cancel');
							setModalBody(<div className='row modal-footer' style={{width: '500px', maxWidth: '100%'}}>
								<div className='col col-12'><CancellationForm index={index} onCancel={()=> closeModal()} onSubmit={()=>{
										closeModal();
									}} /></div>
							</div>)
							openModal();
						}
					} disabled={false}>
						<img src={CancelIcon} alt='Cancel'/>Cancel
					</button>
					<button 
						className='btn-icon'
						onClick={()=> {
							setIndex(index);
							setModalTitle(null);
							setModalBody('');
							setModalDescription(`Are you sure that you want to permanently delete the Member Insurance: ${storeData[memberInsuranceReducerName]?.getData?.[index]?.policy?.insurance_company?.name}`)
							openModal();
						}
					} disabled={false}>
						<img src={DeleteIcon} alt='Delete'/>Delete
					</button>
				</>
			}
		}] : [])
	];

	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [index, setIndex] = React.useState(null);
	const [modalTitle, setModalTitle] = React.useState('');
	const [modalBody, setModalBody] = React.useState('');
	const [modalDescription, setModalDescription] = React.useState('');

	const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

	return (<>
		<ModalComponent
			modalIsOpen={modalIsOpen}
			description={modalDescription}
			modalBody={modalBody}
			title={modalTitle}
			closeModal={closeModal}
			confirmModal={()=>{
				dispatch(deleteMemberInsurance({
					memberInsuranceId: memberInsuranceGetData?.[index]?.id,
					memberId: managementData.member.id
				}));
				closeModal();
			}}
		/>
		<TableComponent
			columns={tableColumns}
			data={memberInsuranceGetData}
    />
		<div className='col col-6 col-lg-3 mb-3' hidden={!showNewPoliciForm}>
			<button type='button' disabled={false} onClick={showNewPoliciForm} className='btn-blue btn btn-default btn-primary'>Add</button>
		</div>
		<div className='col col-6 col-lg-3 mb-3' hidden={!showCancellations}>
			<button type='button' disabled={false} onClick={showCancellations} className='btn-orange btn btn-default btn-primary'>Cancellations</button>
		</div>
		<ButtonsActions hidden={!showCancellations || !showNewPoliciForm} disabled={!managementData || !Object.keys(managementData).length} UpdateAll={()=>{}} UpdateSection={()=>{}}/>
	</>)
}

export default Insurance