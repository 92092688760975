import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUnionInformationService,
  editUnionInformationService,
  destroyUnionInformationService,
  createUnionInformationService
} from './../../Services/unionInformationService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'unionInformation';

const initialState = {
  request: false,
  error: null,
  data: null
};

export const getUnionInformation = createAsyncThunk(
  'unionInformation/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getUnionInformationService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const editUnionInformation = createAsyncThunk(
  'unionInformation/editUnionInformation',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await editUnionInformationService(parameters);

      dispatch(getUnionInformation());
      dispatch(showNotification({
        message: 'Union Information updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };

    } catch (error) {

      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const destroyUnionInformation = createAsyncThunk(
  'unionInformation/destroyUnionInformation',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await destroyUnionInformationService(id);

      dispatch(getUnionInformation());
      dispatch(showNotification({
        message: 'Union Information deleted successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const createUnionInformation = createAsyncThunk(
  'unionInformation/createUnionInformation',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await createUnionInformationService(parameters);

      dispatch(getUnionInformation());
      dispatch(showNotification({
        message: 'Union Information created successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setDataFilteredReducer = (state, action) => {
  return {
    ...state,
    dataFiltered: action.payload.data
  }
};

const setDataFilteredAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearDataFilteredReducer = (state) => {
  return {
    ...state,
    dataFiltered: null
  }
};

const setEditDataHandler = (state, data) => {
  return {
    ...state,
    editData: data.payload
  }
};

export const unionInformationReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setDataFiltered: {
      reducer: setDataFilteredReducer,
      prepare: setDataFilteredAction
    },
    clearDataFiltered: clearDataFilteredReducer,
    setEditData: setEditDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getUnionInformation.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getUnionInformation.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getUnionInformation.pending, (state) => {
      state.request = true;
      state.error = null;
    })
  }
});

export const { setDataFiltered, clearDataFiltered, setEditData } = unionInformationReducer.actions

export default unionInformationReducer.reducer;
