import React from 'react';

import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

const MultiValue = (props) => {
  return null
}

const Input = (props) => {
  const values = props.getValue();
  let placeholder = props.selectProps.placeholder;

  if(values.some((val)=> val.label)) {
      placeholder = values?.reduce((accumulator, object) => {
        return `${accumulator}${object.label},`;
      }, '').slice(0, -1);
  } else {
     placeholder = `${values.length} item${values.length > 1 ? 's' : ''} selected`;
  }
    
  return (<components.Input {...props} className='select-multiple-input-container' placeholder={placeholder} />);
};

const Placeholder = () => {
  return null;
}

const IndicatorSeparator = () => {
  return null;
}

const Option = (props) => {
  const values = props.getValue();
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return <components.Option  {...newProps} >
    <div>
      <div className="row">
        <div className="col-2"><input key={values.length} onChange={()=>{}} checked={values.find((val)=>val.value === props.data.value || val === props.data.value)} type="checkbox" /></div>
        <div className="col-10">
          {props.data.label}
        </div>
      </div>
    </div>
  </components.Option> 
}

const customStyles = {
  control: (provided) => ({
    ...provided,
    background: '#ededed',
    border: 'none',
    padding: '0 8px',
    borderRadius: '8px',
    boxShadow: 'none'
  }),
  input: (provided) => ({
    ...provided,
    gridTemplateColumns: 'max-content',
    boxSizing: 'border-box'
  })
};


const AsyncMultiselect = ({defaultValue, refVar, handleSearch, defaultOptions, placeholder = 'Type more than three letters to find', isLoading, onChange}) => {
  
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      
      if (inputValue.length < 3) {
        resolve([]);
      }

      resolve(handleSearch(inputValue).slice(0, 200));
    });

  return (
    <AsyncSelect
      ref={refVar}
      onChange={onChange}
      isMulti
      isLoading={isLoading}
      components={{ MultiValue, Option, Input, Placeholder, IndicatorSeparator }}
      placeholder={placeholder}
      styles={customStyles}
      cacheOptions
      defaultValue={defaultValue}
      defaultOptions={defaultOptions}
      loadOptions={promiseOptions}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
    />
  );
};

export default AsyncMultiselect;
