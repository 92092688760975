import React from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import "react-datepicker/dist/react-datepicker.css";

const Datepicker = ({ onChange, date, dateFormat = 'MM/yyyy' }) => {
	return <>
		<FontAwesomeIcon className='date-dropdown' color='#cccccc' icon={faChevronDown} />
    <DatePicker
      selected={date}
      className='form-control'
      onChange={onChange}
      dateFormat={dateFormat}
      showMonthYearPicker
      showFullMonthYearPicker
    />
	</>
};

export default Datepicker;
