import { getAxiosInstance } from "../Config/axios";

export const addMemberNoteService = async (noteData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            url: `member/note/${noteData.memberId}`,
            data: noteData.data
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const editMemberNoteService = async (noteData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'PUT',
            url: `note/${noteData.id}`,
            data: noteData.data
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const getMemberNoteByIdService = async (id) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: `note/${id}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const deleteMemberNoteService = async (id) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'DELETE',
            url: `note/${id}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.status;
        
    } catch (error) {
        throw error
    }
};

export const getMemberNoteService = async (memberId) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: `note/get/all/${memberId}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};
