import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showNotification } from './../Reducers/notification';
import { addMemberNoteService, getMemberNoteService, deleteMemberNoteService, editMemberNoteService, getMemberNoteByIdService } from './../../Services/memberNoteService'

export const reducerName = 'memberNote';

const initialState = {
  request: false,
  error: null,
  data: null,

  getData: [],

  editMemberNoteRequest: null,
  editMemberNoteError: null,
  editMemberNoteData: null,

  getMemberNoteData: null,
  getMemberNoteRequest: null,
  getMemberNoteError: null
};

export const addMemberNote = createAsyncThunk(
  'memberNote/add',
  async (noteData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await addMemberNoteService(noteData);

      dispatch(showNotification({
        message: 'Note created successfully',
        type: 'SUCCESS'
      }));

      dispatch(getMemberNote(response.member_id));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
          message: error.message[Object.keys(error.message)[0]][0],
          type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const editMemberNote = createAsyncThunk(
  'memberNote/edit',
  async (noteData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await editMemberNoteService(noteData);
      
      dispatch(showNotification({
          message: 'Note updated successfully',
          type: 'SUCCESS'
      }));

      dispatch(getMemberNote(response.member_id));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getMemberNoteToEdit = createAsyncThunk(
  'memberNote/get/edit',
  async (noteId, { rejectWithValue }) => {
    try {
      
      const response = await getMemberNoteByIdService(noteId);

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getMemberNote = createAsyncThunk(
  'memberNote/get',
  async (memberId, { rejectWithValue }) => {
    try {
      
      const response = await getMemberNoteService(memberId);

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const deleteMemberNote = createAsyncThunk(
  'memberNote/delete',
  async ({noteId, memberId}, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await deleteMemberNoteService(noteId);
      dispatch(getMemberNote(memberId));
      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const closeEditFormFN = (state) => {
  return {
    ...state,
    getMemberNoteData: null,
    editMemberNoteData: null,
    data: null
  }
};

export const memberNoteReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    closeEditForm: closeEditFormFN
  },
  extraReducers: (builder) => {

    builder.addCase(addMemberNote.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(addMemberNote.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(addMemberNote.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    })

    builder.addCase(getMemberNote.fulfilled, (state, action) => {
      state.getData = action.payload?.data;
      state.getRequest = false;
    })

    builder.addCase(getMemberNote.rejected, (state, action) => {
      state.getError = action.payload.error;
      state.getRequest = false;
      state.getData = null;
    })

    builder.addCase(getMemberNote.pending, (state) => {
      state.getRequest = true;
      state.getError = null;
      state.getData = null;
    })

    builder.addCase(deleteMemberNote.fulfilled, (state, action) => {
      state.deleteMemberNoteData = action.payload?.data;
      state.deleteMemberNoteRequest = false;
    })

    builder.addCase(deleteMemberNote.rejected, (state, action) => {
      state.deleteMemberNoteError = action.payload.error;
      state.deleteMemberNoteRequest = false;
      state.deleteMemberNoteData = null;
    })

    builder.addCase(deleteMemberNote.pending, (state) => {
      state.deleteMemberNoteRequest = true;
      state.deleteMemberNoteError = null;
      state.deleteMemberNoteData = null;
    })

    builder.addCase(editMemberNote.fulfilled, (state, action) => {
      state.editMemberNoteData = action.payload?.data;
      state.editMemberNoteRequest = false;
    })

    builder.addCase(editMemberNote.rejected, (state, action) => {
      state.editMemberNoteError = action.payload.error;
      state.editMemberNoteRequest = false;
      state.editMemberNoteData = null;
    })

    builder.addCase(editMemberNote.pending, (state) => {
      state.editMemberNoteRequest = true;
      state.editMemberNoteError = null;
      state.editMemberNoteData = null;
    })

    builder.addCase(getMemberNoteToEdit.fulfilled, (state, action) => {
      state.getMemberNoteData = action.payload?.data;
      state.getMemberNoteRequest = false;
    })

    builder.addCase(getMemberNoteToEdit.rejected, (state, action) => {
      state.getMemberNoteError = action.payload.error;
      state.getMemberNoteRequest = false;
      state.getMemberNoteData = null;
    })

    builder.addCase(getMemberNoteToEdit.pending, (state) => {
      state.getMemberNoteRequest = true;
      state.getMemberNoteError = null;
      state.getMemberNoteData = null;
    })
  }
});

export const { closeEditForm } = memberNoteReducer.actions


export default memberNoteReducer.reducer;
