import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setDataFilter,
	clearFilteredData,
	reducerName as addressReducerName,
	getAddress,
	setDateFilter
} from './../../../../Store/Reducers/address';
import DatePicker from '../../../../Components/Forms/datepicker'
import InputSearch from '../../../../Components/Forms/input_search'

const TableHeader = () => {
	const dispatch = useDispatch();

	useEffect(()=> {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = (`0${currentDate.getMonth() + 1}`).slice(-2);
		dispatch(setDateFilter(currentDate.toISOString()));
		dispatch(getAddress({
			month: month,
			year: year
		}))
	}, [dispatch]);

	const addressData = useSelector((state)=> state[addressReducerName].data || []);
	const addressDataFiltered = useSelector((state)=> state[addressReducerName].dataFiltered);

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();

		return data?.member?.first_name?.toLowerCase().includes(searchTerm) ||
					data?.member?.last_name?.toLowerCase().includes(searchTerm) ||
					data?.member?.suffix?.toLowerCase().includes(searchTerm) ||
					data?.member?.middle_name?.toLowerCase().includes(searchTerm) ||
					data?.address_line_1?.toLowerCase().includes(searchTerm) ||
					data?.address_line_2?.toLowerCase().includes(searchTerm);
	}

	const [startDate, setStartDate] = useState(new Date());

	return <>
		<div className="col-1 col-md-3 show-on-mobile"></div>
		<div className='col-10 col-lg-1'>
			<label htmlFor='year' className='form-label mt-2'>Date: </label>
		</div>
		<div className="col-1 col-md-3 show-on-mobile"></div>
		<div className="col-1 col-md-3 show-on-mobile"></div>
		<div className='col-10 mb-3 col-lg-3 mb-4'>
			<DatePicker
				date={startDate}
				onChange={(date) => {
					setStartDate(date);
					dispatch(setDateFilter(date.toISOString()));
					const currentDate = date;
					const year = currentDate.getFullYear();
					const month = (`0${currentDate.getMonth() + 1}`).slice(-2);

					dispatch(getAddress({
						month: month,
						year: year
					}))
				}}
			/>
		</div>
		<div className="col-3 col-md-2 text-center mb-5" style={{padding:0,margin:0}}>
			<label htmlFor='searchTable' className='form-label' style={{
				top: '6px',
				position: 'relative'
			}}>Search </label>
		</div>
		<div className="col-8 col-md-4 mb-5" style={{padding:0,margin:0}}>
			<InputSearch
				defaultValue={''}
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						addressDataFiltered !== null && dispatch(clearFilteredData(
							null
						));
						return;
					}

					dispatch(setDataFilter(
						addressData.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</>
}



export default TableHeader