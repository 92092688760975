import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getInsuranceCompanyService,
  getAllInsuranceCompanyService,
  editInsuranceCompanyService,
  destroyInsuranceCompanyService,
  createInsuranceCompanyService,
  getInsuranceCompanyByFilterService,
  getInsuranceCompanyExcelReportService,
  getInsuranceCompanyPDFReportService
} from './../../Services/insuranceCompanyService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'insuranceCompany';

const initialState = {
  request: false,
  error: null,
  data: null,

  allRequest: false,
  allError: null,
  allData: [],

  allDataFiltered: null,

  editData: null,
  editRequest: false,
  editError: null,
  editResult: null,
  
  destroyRequest: false,
  destroyError: null,
  destroyResult: null,

  byFilterData: [],
  byFilterRequest: null,
  byFilterError: null,
  byFilterFilters: {
    company_id: 'all',
		policy_id: 'all'
  }
};

export const getInsuranceCompany = createAsyncThunk(
  'insuranceCompany/getInsuranceCompany',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getInsuranceCompanyService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getAllInsuranceCompany = createAsyncThunk(
  'insuranceCompany/getAllInsuranceCompany',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getAllInsuranceCompanyService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const editInsuranceCompany = createAsyncThunk(
  'insuranceCompany/editInsuranceCompany',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await editInsuranceCompanyService(parameters);

      dispatch(getAllInsuranceCompany());
      dispatch(showNotification({
        message: 'Insurance company updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };

    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const destroyInsuranceCompany = createAsyncThunk(
  'insuranceCompany/destroyInsuranceCompany',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await destroyInsuranceCompanyService(id);

      dispatch(getAllInsuranceCompany());
      dispatch(showNotification({
        message: 'Insurance company deleted successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const createInsuranceCompany = createAsyncThunk(
  'insuranceCompany/createInsuranceCompany',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await createInsuranceCompanyService(parameters);

      dispatch(getAllInsuranceCompany());
      dispatch(showNotification({
        message: 'Insurance company created successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getInsuranceCompanyByFilter = createAsyncThunk(
  'insuranceCompany/getInsuranceCompanyByFilter',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getInsuranceCompanyByFilterService(data);

      const memberPolicies = [];
      response.response.forEach((insuranceCompanyData)=> {
        insuranceCompanyData.member_policies.forEach((memberPolicy)=>{
          memberPolicies.push({
            ...insuranceCompanyData,
            ...memberPolicy
          });
        });
      });

      return {
        data: memberPolicies
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getInsuranceCompanyPDFReport = createAsyncThunk(
  'memberDisability/getInsuranceCompanyPDFReport',
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getInsuranceCompanyPDFReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getInsuranceCompanyExcelReport = createAsyncThunk(
  'memberDisability/getInsuranceCompanyExcelReport',
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getInsuranceCompanyExcelReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setAllDataFilteredReducer = (state, action) => {
  return {
    ...state,
    allDataFiltered: action.payload.data
  }
};

const setAllDataFilteredAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearAllDataFilteredReducer = (state) => {
  return {
    ...state,
    allDataFiltered: null
  }
};

const setEditDataHandler = (state, data) => {
  return {
    ...state,
    editData: data.payload
  }
};

const setInsuranceCompanyByFilterFilteredReducer = (state, action) => {
  return {
    ...state,
    byFilterDataFiltered: action.payload.data
  }
};

const setInsuranceCompanyByFilterFilteredAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearInsuranceCompanyByFilterFilteredReducer = (state) => {
  return {
    ...state,
    byFilterDataFiltered: null
  }
};

const setByFilterFiltersFN = (state, data) => {
  const actualfilters = JSON.parse(JSON.stringify(state.byFilterFilters));
  Object.keys(data.payload).forEach((key)=>{
    if(!data.payload[key]) {
      
      delete actualfilters[key]
      
      if(key === 'company_id' || key === 'policy_id') {
        data.payload[key] = 'all';
      } else {
        delete data.payload[key]
      }
    }
  })
  return {
    ...state,
    byFilterFilters: {
      ...actualfilters,
      ...data.payload
    }
  }
};

const clearByFilterFiltersFN = (state) => {
  return {
    ...state,
    byFilterFilters: {
      company_id: 'all',
      policy_id: 'all'
    }
  }
};

export const insuranceCompanyReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setAllDataFiltered: {
      reducer: setAllDataFilteredReducer,
      prepare: setAllDataFilteredAction
    },
    clearAllDataFiltered: clearAllDataFilteredReducer,
    setInsuranceCompanyByFilterFiltered: {
      reducer: setInsuranceCompanyByFilterFilteredReducer,
      prepare: setInsuranceCompanyByFilterFilteredAction
    },
    clearInsuranceCompanyByFilterFiltered: clearInsuranceCompanyByFilterFilteredReducer,
    setEditData: setEditDataHandler,
    setByFilterFilters: setByFilterFiltersFN,
    clearByFilterFilters: clearByFilterFiltersFN
  },
  extraReducers: (builder) => {

    builder.addCase(getInsuranceCompany.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getInsuranceCompany.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getInsuranceCompany.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    })

    builder.addCase(getAllInsuranceCompany.fulfilled, (state, action) => {
      state.allData = action.payload?.data;
      state.allRequest = false;
    })

    builder.addCase(getAllInsuranceCompany.rejected, (state, action) => {
      state.allError = action.payload.error;
      state.allRequest = false;
      state.allData = null;
    })

    builder.addCase(getAllInsuranceCompany.pending, (state) => {
      state.allRequest = true;
      state.allError = null;
    })

    builder.addCase(editInsuranceCompany.fulfilled, (state, action) => {
      state.editResult = action.payload?.data;
      state.editData = null;
      state.editRequest = false;
    })

    builder.addCase(editInsuranceCompany.rejected, (state, action) => {
      state.editError = action.payload.error;
      state.editRequest = false;
    })

    builder.addCase(editInsuranceCompany.pending, (state) => {
      state.editRequest = true;
      state.editError = null;
      state.editResult = null;
    })

    builder.addCase(destroyInsuranceCompany.fulfilled, (state, action) => {
      state.destroyResult = action.payload?.data;
      state.destroyRequest = false;
    })

    builder.addCase(destroyInsuranceCompany.rejected, (state, action) => {
      state.destroyError = action.payload.error;
      state.destroyRequest = false;
    })

    builder.addCase(destroyInsuranceCompany.pending, (state) => {
      state.destroyRequest = true;
      state.destroyError = null;
      state.destroyResult = null;
    })

    builder.addCase(getInsuranceCompanyByFilter.fulfilled, (state, action) => {
      state.byFilterData = action.payload?.data;
      state.byFilterRequest = false;
    })

    builder.addCase(getInsuranceCompanyByFilter.rejected, (state, action) => {
      state.byFilterError = action.payload.error;
      state.byFilterRequest = false;
    })

    builder.addCase(getInsuranceCompanyByFilter.pending, (state) => {
      state.byFilterRequest = true;
      state.byFilterError = null;
      state.byFilterData = null;
    })
  }
});

export const {
  setAllDataFiltered,
  clearAllDataFiltered,
  setEditData,
  setInsuranceCompanyByFilterFiltered,
  clearInsuranceCompanyByFilterFiltered,
  setByFilterFilters,
  clearByFilterFilters
} = insuranceCompanyReducer.actions

export default insuranceCompanyReducer.reducer;
