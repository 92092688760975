import React from 'react';
import { useSelector } from 'react-redux';
import { reducerName as memberInsuranceReducerName } from './../../../Store/Reducers/memberInsurance';

const BenefitResume = () => {
	const memberInsuranceGetData = useSelector((state)=> state[memberInsuranceReducerName].getData || []);
	
	return (<>
		<div className='col-12 col-md-5 mb-5' style={{borderTop: '2px solid gray',paddingTop: '10px'}}>
			<div className='row mb-3'>
				<div className="col-8 col-md-10">
					<legend>Union Benefits</legend>
				</div>
				<div className="col-4 col-md-2 text-right">	
						<center>Monthly Deduction</center>
				</div>
				<div className='col-5'>
					<center>Total</center>
				</div>
				<div className='col-7 text-right'>
					<legend>${parseFloat(memberInsuranceGetData.reduce((ac, val) => (ac + val.cost), 0)).toLocaleString('en-US', {minimumFractionDigits: 2})}</legend>
				</div>
			</div>
		</div>
	</>)
}

export default BenefitResume