import React, { useEffect } from 'react';
import LayoutHeaderPage from './../../../../Layouts/header_page';
import { useSelector, useDispatch } from 'react-redux';
import {
	getInsuranceCompanyByFilter,
	reducerName as insuranceCompanyReducerName,
	getInsuranceCompanyExcelReport,
	getInsuranceCompanyPDFReport
} from './../../../../Store/Reducers/insuranceCompany';
import TableComponent from './../../../../Components/table_v2';
import Notification from './../../../../Components/notification';
import TableHeader from './table_header';
import moment from 'moment';

const InsuranceCompanies = () => {

	const dispatch = useDispatch();
	const byFilterData = useSelector((state)=> state[insuranceCompanyReducerName].byFilterData || []);
	const byFilterRequest = useSelector((state)=> state[insuranceCompanyReducerName].byFilterRequest);
	const byFilterDataFiltered = useSelector((state)=> state[insuranceCompanyReducerName].byFilterDataFiltered);
	const byFilterFilters = useSelector((state)=> state[insuranceCompanyReducerName].byFilterFilters);


	const tableColumns = [
		{
			title: 'First Name',
			sort: true,
			value: 'first_name',
		},
		{
			title: 'Middle Name',
			value: 'middle_name',
		},
		{
			title: 'Last Name',
			value: 'last_name',
		},
		{
			title: 'Suffix',
			value: 'suffix'
		},
		{
			title: 'Cost',
			value: 'cost',
			parseValue: (val)=> {
				if(!val && val !==0){
					return ''
				}

				return `$${val}`;
			}
		},
		{
			title: 'Company',
			value: 'policy',
			parseValue: (value) => {
				if(!value?.insurance_company?.name) { return; }
				return value.insurance_company.name
			}
		},
		{
			title: 'Insurance Policy',
			value: 'policy',
			parseValue: (value) => {
				if(!value) { return; }
				return value.name
			}
		},
		{
			title: 'Enrolled',
			value: 'join_date',
			parseValue: (value) => {
				if(!value) { return; }
				var date = moment(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		}
	];

	useEffect(() => {
		dispatch(getInsuranceCompanyByFilter(byFilterFilters));
	}, [dispatch, byFilterFilters]);


	const handlePDFReport = () => {
		dispatch(getInsuranceCompanyPDFReport({
			parameters: byFilterFilters
		}))
	}

	const handleExcelReport = () => {
	  dispatch(getInsuranceCompanyExcelReport(byFilterFilters))
	}

	return (
		<>
			<LayoutHeaderPage title="Insurance Report"/>
			<div className="tab-content mt-3 pt-4" id="pills-tabContent">
				<TableComponent
					paginationSize={10}
					columns={tableColumns}
					data={byFilterDataFiltered || byFilterData}
					header={TableHeader}
				/>
				<Notification />
				<div className='row mb-4'>
					<div className='col-12 col-md-6'></div>
					<div className="col-2 show-on-mobile"></div>
					<div className='col-8 col-md-3 mb-3'>
						<button type='button' onClick={handlePDFReport} disabled={byFilterRequest} className='btn-orange btn-default btn-primary'>View all PDF</button>
					</div>
					<div className="col-2 show-on-mobile"></div>
					<div className="col-2 show-on-mobile"></div>
					<div className='col-8 col-md-3 mb-3'>
						<button type='button' onClick={handleExcelReport} disabled={byFilterRequest} className='btn-blue btn-default btn-primary'>Export to Excel</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default InsuranceCompanies;
