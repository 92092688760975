import { NUMBER, SPECIALALPHANUMERIC } from './../../../../Components/Forms/constant_form';

export const newNoteFormFields = [
	{
		forms: [
      {
				label: 'Note',
				placeholder: 'Type note',
				type: 'textarea',
				col: 'col-sm-12 col-12',
				required: true,
				validate: {
					type: SPECIALALPHANUMERIC
				},
				name: 'note',
				options: []
			},
			{
				label: 'Note Type',
				placeholder: 'Select a type',
				type: 'select',
				col: 'col-sm-6 col-12',
				required: true,
				validate: {
					type: NUMBER
				},
				name: 'note_type_id',
				options: [],
				reduxInfo: {
					reducer: 'noteType',
					key: 'data'
				}
			},
			{
				label: 'Date',
				placeholder: 'Select date',
				type: 'date',
				col: 'col-sm-6 col-12',
				required: true,
				name: 'updated_at'
			}
		]

	}

];
