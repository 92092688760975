import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setDataFilter,
	clearFilteredData,
	reducerName as newMemberReportReducerName,
	getNewMemberReport,
	setDateFilter
} from '../../../../Store/Reducers/newMemberReport';
import InputSearch from './../../../../Components/Forms/input_search';
import DatePicker from './../../../../Components/Forms/datepicker';

const TableHeader = () => {
	const dispatch = useDispatch();

	useEffect(()=> {
		const currentDate = new Date();
		dispatch(setDateFilter(currentDate.toISOString()));
		dispatch(getNewMemberReport({
			date: `${currentDate.toISOString().slice(0,8)}01`
		}))
	}, [dispatch]);

	const newMemberReportData = useSelector((state)=> state[newMemberReportReducerName].data || []);
	const newMemberReportDataFiltered = useSelector((state)=> state[newMemberReportReducerName].dataFiltered);

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();

		return data.first_name?.toLowerCase().includes(searchTerm) ||
					data.last_name?.toLowerCase().includes(searchTerm) ||
					data.suffix?.toLowerCase().includes(searchTerm) ||
					data.middle_name?.toLowerCase().includes(searchTerm) ||
					data.addresses?.some((addresInfo) => addresInfo.address_line_1?.toLowerCase().includes(searchTerm)) ||
					data.addresses?.some((addresInfo) => addresInfo.address_line_1?.toLowerCase().includes(searchTerm)) ||
					data.addresses?.some((addresInfo) => addresInfo.city?.toLowerCase().includes(searchTerm)) ||
					data.addresses?.some((addresInfo) => addresInfo.state?.toLowerCase().includes(searchTerm)) ||
					data.addresses?.some((addresInfo) => addresInfo.zip_code?.toLowerCase().includes(searchTerm));
	}

	const [startDate, setStartDate] = useState(new Date());

	return <>
		<div className="col-1 col-md-3 show-on-mobile"></div>
		<div className='col-10 col-lg-1' style={{paddingRight: 0}}>
			<label htmlFor='year' className='form-label mt-2'>From Date: </label>
		</div>
		<div className="col-1 col-md-3 show-on-mobile"></div>
		<div className="col-1 col-md-3 show-on-mobile"></div>
		<div className='col-10 mb-3 col-lg-3 mb-4'>
			<DatePicker
				date={startDate}
				onChange={(date) => {
					setStartDate(date);
					dispatch(setDateFilter(date.toISOString()));
					dispatch(getNewMemberReport({
						date: date.toISOString().slice(0,10)
					}))
				}}
			/>
		</div>
		<div className='col-3 col-md-2 text-center mb-5'>
			<label htmlFor='searchTable' className='form-label' style={{
				top: '6px',
				position: 'relative'
			}}>Search: </label>
		</div>
		<div className='col-8 col-md-4 mb-5'>
			<InputSearch
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						newMemberReportDataFiltered !== null && dispatch(clearFilteredData(
							null
						));
						return;
					}

					dispatch(setDataFilter(
						newMemberReportData.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</>
}



export default TableHeader