import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getLogService,
  getLogPDFReportService,
  getLogExcelReportService,
  deleteLogService
} from './../../Services/logService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'log';

const date = new Date();
const currentFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
const currentLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

const initialState = {
  logData: [],
  logError: null,
  logRequest: false,

  startDateFilter: currentFirstDay.toISOString(),
  endDateFilter: currentLastDay.toISOString()
};

export const getLog = createAsyncThunk(
  `${reducerName}/getLog`,
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getLogService(parameters);

      return {
        data: response.logs
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const deleteLog = createAsyncThunk(
  `${reducerName}/deleteLog`,
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await deleteLogService(parameters);

      dispatch(getLog());
      
      dispatch(showNotification({
        message: 'Log deleted successfully',
        type: 'SUCCESS'
      }));


      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setStartDateFilterReducer = (state, action) => {
  return {
    ...state,
    startDateFilter: action.payload.data
  }
};

const setStartDateFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const setEndDateFilterReducer = (state, action) => {
  return {
    ...state,
    endDateFilter: action.payload.data
  }
};

const setEndDateFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const setDataFilterReducer = (state, action) => {
  return {
    ...state,
    logFilteredData: action.payload.data
  }
};

const setDataFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearFilteredDataHandler = (state) => {
  return {
    ...state,
    logFilteredData: null
  }
};

export const getLogPDFReport = createAsyncThunk(
  `${reducerName}/getLogPDFReport`,
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getLogPDFReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getLogExcelReport = createAsyncThunk(
  `${reducerName}/getLogExcelReport`,
  async (parameters, { rejectWithValue }) => {
    try {
      
      const response = await getLogExcelReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const statusReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setStartDateFilter: {
      reducer: setStartDateFilterReducer,
      prepare: setStartDateFilterAction
    },
    setEndDateFilter: {
      reducer: setEndDateFilterReducer,
      prepare: setEndDateFilterAction
    },
    setDataFilter: {
      reducer: setDataFilterReducer,
      prepare: setDataFilterAction
    },
    clearFilteredData: clearFilteredDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getLog.fulfilled, (state, action) => {
      state.logData = action.payload?.data;
      state.logRequest = false;
    })

    builder.addCase(getLog.rejected, (state, action) => {
      state.logError = action.payload.error;
      state.logRequest = false;
    })

    builder.addCase(getLog.pending, (state) => {
      state.logRequest = true;
      state.logError = null;
    })



    builder.addCase(getLogPDFReport.fulfilled, (state, action) => {
      state.pdfReportData = action.payload?.data;
      state.pdfReportRequest = false;
    })

    builder.addCase(getLogPDFReport.rejected, (state, action) => {
      state.pdfReportError = action.payload.error;
      state.pdfReportRequest = false;
    })

    builder.addCase(getLogPDFReport.pending, (state) => {
      state.pdfReportRequest = true;
      state.pdfReportError = null;
    })

    builder.addCase(getLogExcelReport.fulfilled, (state, action) => {
      state.excelReportData = action.payload?.data;
      state.excelReportRequest = false;
    })

    builder.addCase(getLogExcelReport.rejected, (state, action) => {
      state.excelReportError = action.payload.error;
      state.excelReportRequest = false;
    })

    builder.addCase(getLogExcelReport.pending, (state) => {
      state.excelReportRequest = true;
      state.excelReportError = null;
    })
  }
});

export const { setDataFilter, clearFilteredData, setStartDateFilter, setEndDateFilter } = statusReducer.actions
export default statusReducer.reducer;
