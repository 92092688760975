import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import{ reducerName as sessionReducerName, clearSessionEnd } from './../Store/Reducers/session';
import ModalComponent from './modal';
import { setLocalStorage  } from '../Helpers';

const SessionEnd = () => {

  useEffect(()=>{
    const modals=document.getElementsByClassName('ReactModalPortal');
    const sessionModal = modals[0];
    
    if(sessionModal){
      sessionModal.style.display = 'none';
    }
  },[])
  
  const dispatch = useDispatch();
  const showModal = useSelector((state)=> state[sessionReducerName].end);

  const handleSessionEnd = () => {
    setLocalStorage({});
    window.location.replace('/');
    dispatch(clearSessionEnd());
  }

	return <ModalComponent
    modalBody={<div className='col-12 text-center'>
    <button type='submit' disabled={false} onClick={handleSessionEnd} className='btn-orange btn btn-default btn-primary mt-3' style={{maxWidth: '75%'}}>Accept</button>
  </div>}
    deleteButton={false}
    acceptButton={true}
    modalIsOpen={showModal}
    title={'The session has expired, you will be redirected to the login'}
    closeModal={handleSessionEnd}
    confirmModal={handleSessionEnd}
  />;
}

export default SessionEnd;
