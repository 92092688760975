import { getAxiosInstance } from "../Config/axios";
import { downloadExcelBase64File, downloadPDFBase64File } from "../Helpers";


export const getComparisonReportService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: data.parameters,
            url: 'comparison-report'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        if(data.parameters.get('action') === 'Excel'){
            downloadExcelBase64File(result.data.file.replace('data:application/vnd.ms-excel;base64,', ''), `Comparison Report.xlsx`);
        }

        if(data.parameters.get('action') === 'PDF'){
            const newDate = new Date().toISOString().slice(0,10).split('-');
            downloadPDFBase64File(result.data.file, `Comparison report ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);
        }
        

        return result.file;
        
    } catch (error) {
        throw error
    }
};
