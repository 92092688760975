import React, { useState, useContext } from 'react';
import LayoutHeaderPage from '../../../Layouts/header_page';
import UploadTemplate from '../../../Components/upload_template';
import Notification from '../../../Components/notification'
import { downloadExcelBase64File } from '../../../Helpers'
import { memberBOCC, memberAssignment, reducerName as bulkMemberReducerName } from '../../../Store/Reducers/bulkMember'
import moment from 'moment'
import { ReactReduxContext, useSelector } from 'react-redux';
const initData = {
	title: 'Upload Member_BOCC_Report.xlsx',
	textOne: 'Download the template file. Populate with the member changes you specify. Save and upload the file using the dialog below. Click the "Process" button to execute the data update.',
	textTwo: 'This process updates the following member fields. If a record in the data file does not exist, the data will not be updated for that member.' ,
	labels: [
		{
			section: [
				{
					name: 'Last Name'
				},
				{
					name: 'First Name'
				},
				{
					name: 'Middle'
				},
				{
					name: 'Job Title'
				},
				{
					name: 'Base Hours'
				},
				{
					name: 'Pay Rate'
				},
				{
					name: 'Date of Birth'
				},
				{
					name: 'Original Hire'
				},
				{
					name: 'Benefit Date'
				},
				{
					name: 'Work Status'
				},
				{
					name: 'Nex Review Date'
				},
				{
					name: 'Sick Plan'
				},
				{
					name: 'Gender'
				},
				{
					name: 'Grade'
				}

			]

		}
	]
};

const initDataTwo = {
	title: 'Upload Member_Assignment_Report.xlsx',
	textOne: 'Download the template file. Populate with the member changes you specify. Save and upload the file using the dialog below. Click the "Process" button to execute the data update.',
	textTwo: 'This process updates the following member fields. If a record in the data file does not exist, the data will not be updated for that member.' ,
	labels: [
		{
			section: [
				{
					name: 'Last Name'
				},
				{
					name: 'First Name'
				},
				{
					name: 'Middle'
				},
				{
					name: 'Suffix'
				},
				{
					name: 'Shift'
				},
				{
					name: 'R Day'
				},
				{
					name: 'Department'
				},
				{
					name: 'Battalion'
				},
				{
					name: 'Station'
				},
				{
					name: 'Unit'
				},
				{
					name: ''
				},
				{
					name: ''
				},
				{
					name: ''
				},
				{
					name: ''
				}

			]

		}
	]
};

const BulkMemberUpdate = () => {
	const { store } = useContext(ReactReduxContext);
	const bulkMemberBOCCData = useSelector((state)=> state[bulkMemberReducerName].bulkMemberBOCCData);
	const bulkMemberAssignmentData = useSelector((state)=> state[bulkMemberReducerName].bulkMemberAssignmentData);

	const [file, setFile] = useState();
	const [fileTwo, setFileTwo] = useState();

	const [showBOCCNotifications, setShowBOCCNotifications] = useState(true);


	const handleMemberBOCC = () => {
		setShowBOCCNotifications(true);
		
		const formData = new FormData();
		formData.append('file', file);

		store.dispatch(memberBOCC(formData));
	}

	const handleMemberAssignment = () => {
		setShowBOCCNotifications(false);

		const formData = new FormData();
		formData.append('file', fileTwo);

		store.dispatch(memberAssignment(formData));
	}


	const handleDownloadBOCCErrors = () => {
		const date = moment().format('L')
		downloadExcelBase64File(bulkMemberBOCCData.file.replace('data:application/vnd.ms-excel;base64,', ''), `MembersErrors-${date}.xlsx`);
	}

	const handleDownloadAssignmentErrors = () => {
		const date = moment().format('L')
		downloadExcelBase64File(bulkMemberAssignmentData.file.replace('data:application/vnd.ms-excel;base64,', ''), `MembersErrors-${date}.xlsx`);
	}

	return (
		<>
			<LayoutHeaderPage title="Bulk Member Update"/>
			<div className="tab-content mt-3" id="pills-tabContent">
				<UploadTemplate fileData={setFile} initialData={initData} showInfo={true}/>
				{ showBOCCNotifications ? <Notification scope='bocc' /> : null }
				<div className="row pb-3 pt-5">
					<div className="col-12 mb-3 col-md-3 hide-on-mobile">
					</div>
					<div className="col-12 mb-3 col-md-3 hide-on-mobile">
					</div>
					<div className="col-2 col-md-3 mb-3 show-on-mobile"></div>
					<div className="col-8 mb-3 col-md-3">
						<button type="button" disabled={!bulkMemberBOCCData?.file} onClick={handleDownloadBOCCErrors} className="btn-orange btn btn-default btn-primary">Download Errors</button>
					</div>
					<div className="col-2 col-md-3 mb-3 show-on-mobile"></div>
					<div className="col-2 col-md-3 mb-3 show-on-mobile"></div>
					<div className="col-8 mb-3 col-md-3">
						<button type="submit" onClick={handleMemberBOCC} className="btn-orange btn btn-default btn-primary">Process</button>
					</div>
				</div>
				<UploadTemplate fileData={setFileTwo} initialData={initDataTwo} showInfo={true}/>
				{ showBOCCNotifications ? null : <Notification scope='assignment' /> }
				<div className="row pb-3 pt-5">
					<div className="col-12 col-md-3 mb-3 hide-on-mobile">
					</div>
					<div className="col-12 col-md-3 mb-3 hide-on-mobile"></div>
					<div className="col-2 col-md-3 mb-3 show-on-mobile"></div>
					<div className="col-8 col-md-3 mb-3">
						<button type="button" disabled={!bulkMemberAssignmentData?.file} onClick={handleDownloadAssignmentErrors} className="btn-orange btn btn-default btn-primary">Download Errors</button>
					</div>
					<div className="col-2 col-md-3 mb-3 show-on-mobile"></div>
					<div className="col-2 col-md-3 mb-3 show-on-mobile"></div>
					<div className="col-8 col-md-3 mb-3">
						<button type="submit" onClick={handleMemberAssignment} className="btn-orange btn btn-default btn-primary">Process</button>
					</div>
				</div>	
			</div>
		</>
	)
};

export default BulkMemberUpdate;
