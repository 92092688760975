import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getComparisonReportService } from './../../Services/comparisonReportService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'comparisonReport';

const initialState = {

  reportError: null,
  reportRequest: false,
  reportData: null
};


export const getComparisonReport = createAsyncThunk(
  'comparisonReport/getComparisonReport',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getComparisonReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const comparisonReportReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {


    builder.addCase(getComparisonReport.fulfilled, (state, action) => {
      state.reportData = action.payload?.data;
      state.reportRequest = false;
    })

    builder.addCase(getComparisonReport.rejected, (state, action) => {
      state.reportError = action.payload.error;
      state.reportRequest = false;
      state.reportData = null;
    })

    builder.addCase(getComparisonReport.pending, (state) => {
      state.reportRequest = true;
      state.reportError = null;
      state.reportData = null;
    })
  }
});

export default comparisonReportReducer.reducer;
