export const CountyAssessmentFormPartOne = [
	{
		label: '',
		forms: [
			{
				label: 'Report Type',
				placeholder: 'Comparison Report',
				type: 'select',
				col: 'col-sm-12 col-12',
				required: true,
				name: 'report_type',
				options: [
					{
						id: 'IAFF',
						label: 'IAFF Benefits Comparison Report',
						value: 'IAFF'
					},
					{
						id: 'UNIAFF',
						label: 'UNIAFF Dues Comparison Report',
						value: 'UNIAFF'
					}
				]
			}
		]

	}

]