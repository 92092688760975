import React from 'react';
import { Link } from 'react-router-dom';
import ArrowLeft from '../Assets/Img/Icons/arrow-left.svg';
import HeaderPageSearch from './header_page_search';

const HeaderPage = (props) => {
	const { titleClassName = 'col-12' } = props;
	return (
		<>
			<div className="row ms-1 content-layout-back-to-dashboard mt-4 mb-3"> 
				<div className='col-12'>
					<Link to="/dashboard/" className="link-layout-back-to-dashboard"><img className="icon-layout-back-to-dashboard" src={ArrowLeft} alt='Arrow Left'/><span className="title-layout-back-to-dashboard">Back to dashboard</span></Link>
				</div>
			</div>
			<div className="row mb-3">
				<div className={titleClassName}>
					<h1 className="title-layout-header-page">{props.title}</h1>
				</div>
				<HeaderPageSearch search={props.search} className='hide-on-mobile' customWidth='35%' />
				<HeaderPageSearch search={props.search} className='show-on-mobile' customWidth='59%' />
			</div>
		</>
	)
};

export default HeaderPage;
