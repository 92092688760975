import { storageKey } from './../Constants/storage';

export const isJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
};

export const setLocalStorage = (data) => {
  return localStorage.setItem(storageKey, JSON.stringify(data));
};

export const getLocalStorage = (data) => {
  
  const storageData = localStorage.getItem(storageKey);
  
  if(!isJsonString(storageData)) {
    return false;
  }

  return JSON.parse(localStorage.getItem(storageKey));
};

export const removeLocalStorage = () => {
  return localStorage.removeItem(storageKey);
};

export const parseJwt = (token) => {
  
  if(!token) {
    return false;
  }

  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const downloadExcelBase64File = (base64str, fileName) => {
  var downloadLink      = document.createElement('a');
  downloadLink.target   = '_blank';
  downloadLink.download = fileName || 'DefaultName.xls';

  var binary = atob(base64str.replace(/\s/g, ''));
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
  }

  var blob = new Blob([view], { type: 'application/xls' });

  var URL = window.URL || window.webkitURL;
  var downloadUrl = URL.createObjectURL(blob);

  downloadLink.href = downloadUrl;

  document.body.appendChild(downloadLink);

  downloadLink.click();

  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(downloadUrl);
};

export const downloadPDFBase64FileDefault = (linkSource, defaultFileName) => {
  
  const downloadLink = document.createElement("a");
  const newDate = new Date().toISOString().slice(0,10).split('-');
  const fileName = defaultFileName || `Report ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`;

  downloadLink.href = `${linkSource.replace('data:', 'data://')}`;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadPDFBase64FileSafari = (linkSource, defaultFileName) => {
  
  const base64 = linkSource.replace('data:application/pdf;base64,', '');

  // Decode base64 string and remove space for IE compatibility
	const binary = atob(base64.replace(/\s/g, ''));
	const len = binary.length;
	const buffer = new ArrayBuffer(len);
	const view = new Uint8Array(buffer);
	for (var i = 0; i < len; i++) {
		view[i] = binary.charCodeAt(i);
	}

	// Create the blob object with content-type "application/pdf"
	const blob = new Blob( [view], { type: "application/pdf" });
	const url = URL.createObjectURL(blob);
	//window.location.href = url;
  const downloadLink = document.createElement("a");
  const newDate = new Date().toISOString().slice(0,10).split('-');
  const fileName = defaultFileName || `Report ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`;

  downloadLink.href = url;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadPDFBase64File = (link, name) => {

  // Detect Safari
  let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
  // Detect Chrome
  let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;

  // Discard Safari since it also matches Chrome
  if ((chromeAgent) && (safariAgent)) safariAgent = false;

  if(safariAgent){
    downloadPDFBase64FileSafari(link, name);
  } else {
    downloadPDFBase64FileDefault(link, name);
  }
}
