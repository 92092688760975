import { NUMBER, STRING, ALPHANUMERIC, DECIMAL } from './../../../Components/Forms/constant_form';
import Dollar from './../../../Assets/Img/Icons/dollar.svg'
export const JobInfoFormsPartOne = [
	{
		label: 'Job Info',
		forms: [
			{
				label: 'Job Title',
				placeholder: 'Select a Job Title',
				type: 'select',
				col: 'col-sm-12 col-12',
				required: false,
				validate: {
					type: NUMBER
				},
				component: 'job',
				name: 'position_id',
				reduxInfo: {
					reducer: 'position',
					key: 'data'
				}
			},
			{
				label: 'Badge #',
				placeholder: 'Badge #',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'badge_number',
				component: 'job'
			},
			{
				label: 'Work Status',
				placeholder: 'Select a Work Status',
				type: 'select',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: NUMBER
				},
				reduxInfo: {
					reducer: 'status',
					key: 'data'
				},
				name: 'status_id',
				component: 'job'
			}		

		]

	},
	{
		label: 'Dates',
		forms: [
			{
				label: 'Original Hire',
				subtitle: '',
				placeholder: 'Original Hire',
				type: 'date',
				col: 'col-sm-6 col-12',
				component: 'job',
				required: false,
				name: 'employ_date'
			},
			{
				label: 'Promotion',
				subtitle: '',
				placeholder: 'Promotion',
				type: 'date',
				col: 'col-sm-6 col-12',
				component: 'job',
				required: false,
				name: 'promo_date'
			},
			{
				label: 'Next Review Date',
				placeholder: 'Next Review Date',
				type: 'date',
				col: 'col-sm-6 col-12',
				required: false,
				component: 'job',
				name: 'salary_review_date'
			},
			{
				label: 'Benefit Date',
				placeholder: 'Benefit Date',
				type: 'date',
				col: 'col-sm-6 col-12',
				required: false,
				name: 'benefit_date',
				component: 'job'
			},
			{
				label: 'Termination Date',
				placeholder: 'Termination Date',
				type: 'date',
				col: 'col-sm-6 col-12',
				required: false,
				component: 'job',
				name: 'termination_date'
			}
		]
	},
	{
		label: 'Assignments',
		forms: [
			{
				label: 'Station',
				subtitle: '',
				placeholder: 'Station',
				type: 'select',
				col: 'col-sm-12 col-12',
				required: false,
				validate: {
					type: NUMBER
				},
				reduxInfo: {
					reducer: 'station',
					key: 'data'
				},
				name: 'station_id',
				component: 'job',
				options: []
			},
			{
				label: 'Battalion',
				subtitle: '',
				placeholder: 'Battalion',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'batallion_number',
				component: 'job'
			},
			{
				label: 'Shift',
				placeholder: 'Shift',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'shift',
				component: 'job'
			},
			{
				label: 'Unit',
				placeholder: 'Unit',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				component: 'member',
				validate: {
					type: STRING
				},
				name: 'unit'
			}
		]
	}

];

export const JobInfoFormsPartTwo = [
	{
		label: null,
		forms: [
			{
				label: 'Certification / Education',
				placeholder: 'Select a certification',
				type: 'multiselect',
				defaultValue: [],
				col: 'col-12',
				required: false,
				name: 'certificates',
				reduxInfo: {
					reducer: 'certificate',
					key: 'data'
				},
				options: [],
				component: 'certificates',
				getDefaultValue: (managementData) => {
					
					if(!managementData['certificates'] || !Array.isArray(managementData['certificates'])){
						return;
					}

					return managementData['certificates'].map((val)=> ({
						label: val.name,
						value: val.id
					}));
				}
			}
		]

	},
	{
		label: 'Payroll',
		forms: [
			{
				label: 'R Day',
				subtitle: '',
				placeholder: 'R Day',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				component: 'member',
				validate: {
					type: ALPHANUMERIC
				},
				name: 'rdray',
				options: []
			},
			{
				label: 'Department',
				subtitle: '',
				placeholder: 'Department',
				type: 'text',
				col: 'col-sm-6 col-12',
				component: 'member',
				required: false,
				validate: {
					type: ALPHANUMERIC
				},
				name: 'department',
				options: []
			},
			{
				label: 'Grade',
				subtitle: '',
				placeholder: 'Grade',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: ALPHANUMERIC
				},
				component: 'job',
				name: 'grade',
				options: []
			}
		]
	},
	{
		label: 'Annual Pay',
		forms: [
			{
				label: 'Base Hours',
				subtitle: '',
				placeholder: 'Select a Base Hours',
				type: 'select',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: NUMBER
				},
				reduxInfo: {
					reducer: 'baseHour',
					key: 'data'
				},
				component: 'job',
				name: 'base_hour_id',
				options: []
			},
			{
				label: 'Pay Rate',
				subtitle: '',
				placeholder: 'Pay Rate',
				type: 'text',
				icon: <img className="icon pa-2" alt="login icon" src={Dollar} />,
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: DECIMAL
				},
				component: 'job',
				name: 'hourly_rate',
				options: []
			},
			{
				label: 'Hourly Income',
				subtitle: '',
				placeholder: 'Hourly Income',
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				disabled: true,
				validate: {
					type: STRING
				},
				name: 'hourly_income',
				options: [],
				getDefaultValue: (managementData, aditionalData) => {
					return aditionalData?.hourly_income
				}
			},
			{
				label: 'Holiday Income',
				subtitle: '',
				placeholder: 'Holiday Income',
				disabled: true,
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'holiday_income',
				options: [],
				getDefaultValue: (managementData, aditionalData) => {
					return aditionalData?.holiday_income
				}
			},
			{
				label: 'Total Income',
				subtitle: '',
				placeholder: 'Total Income',
				disabled: true,
				type: 'text',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},
				name: 'total_income',
				options: [],
				getDefaultValue: (managementData, aditionalData) => {
					return aditionalData?.total_income
				}
			}
		]
	}
];

export const disabledFieldsByMemberRole = [
	'position_id',
	'badge_number',
	'status_id',
	'employ_date',
	'promo_date',
	'salary_review_date',
	'benefit_date',
	'termination_date',
	'station_id',
	'batallion_number',
	'shift',
	'unit',
	'rdray',
	'department',
	'grade',
	'base_hour_id',
	'hourly_rate',
	'hourly_income',
	'holiday_income',
	'total_income',
];
