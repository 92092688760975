import React, { useState, useEffect, Fragment } from 'react'
import { getUnionMembershipStatus, reducerName as unionMembershipStatusducerName } from '../../../Store/Reducers/unionMembershipStatus';
import LayoutHeaderPage from '../../../Layouts/header_page'
import GenerateForm from '../../../Components/Forms/generate_form'
import { PrintFormsPartOne } from '../../../Config/Forms/Print'
import Print from '../../../Assets/Img/Icons/print-menu.svg'
import {useDispatch,useSelector} from 'react-redux';
import {getPrintLabel, getPrintEnvelopes} from '../../../Store/Reducers/print'

const PrintLabelsAndEnvelopes = (props) => {
	const dispatch = useDispatch();
	const [fieldsData, setFieldsData] = useState([]);
	const unionMembershipStatusData = useSelector((state)=> state[unionMembershipStatusducerName].data);
	
	useEffect(() => {
		!unionMembershipStatusData?.length && dispatch(getUnionMembershipStatus());
		if(Array.isArray(unionMembershipStatusData)){
			setFieldsData(unionMembershipStatusData.map((fieldData)=>({
				id: fieldData.id,
				name: fieldData.id,
				label: fieldData.name,
				type: 'checkbox',
				col: 'col-sm-5 col-6',
				value: fieldData.id
			})));
		}
	}, [unionMembershipStatusData, dispatch]);

	const getParameters = () => {
		const checkboxes = document.querySelectorAll('div.formPrintLabelsAndEnvelopes input[type=checkbox]');
		const selectedValues = [];

		for (var i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].checked) {
				selectedValues.push(Number(checkboxes[i].name))
			}
		}

		return {
			parameters: { union_membership_status_ids: selectedValues }
		}
	}


    const sendData = () => {
			dispatch(getPrintLabel(getParameters()));
    }

    const envelopesData = () => {
			dispatch(getPrintEnvelopes(getParameters()));
    }

	return (
		<>
			<LayoutHeaderPage title="Print Labels and Envelopes"/>
			<div className="tab-content" id="pills-tabContent">
				<div className="row mb-3" style={{marginTop: '-10px'}}>
					<div className="col-12 col-md-9 formPrintLabelsAndEnvelopes">
					{
						PrintFormsPartOne.map((section, i) =>
							<Fragment key={i}>
							{
								section.forms.map((fieldData, i) =>
									<GenerateForm
										key={i}
										{...fieldData}
										options={fieldsData}
										handleInputChecks={()=>{}}
										onChange={()=>{}}/> )
							}
							</Fragment>					
						)
					}
					</div>
					<div className="col-12 col-md-3 desktop-mt-5">
						<div className="row pb-3 pt-5">
							<div className="col-2 col-md-3 show-on-mobile"></div>
							<div className='col-8 col-md-12 mb-3'>
								<button type="button" className="btn-orange btn btn-default btn-primary text-left" style={{paddingLeft:'20%'}} onClick={sendData}><img className="icon-info-user pa-2 me-2" alt="login icon" src={Print} />Print labels</button>
							</div>
							<div className="col-2 col-md-3 show-on-mobile"></div>
							<div className="col-2 col-md-3 show-on-mobile"></div>
							<div className='col-8 col-md-12 mb-3'>
								<button type="button" className="btn-blue btn btn-default btn-primary text-left" style={{paddingLeft:'20%'}} onClick={envelopesData}><img className="icon-info-user pa-2 me-2" alt="login icon" src={Print} />Print Envelopes</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PrintLabelsAndEnvelopes;
