import Members from './members'
import Personal from './personal'
import Deductions from './deductions'
import JobInfo from './job_info'
import Insurance from './insurance/index'
import Notes from './notes/index'
import Web from './web/index'
import {ROLES} from './../../../Router/Roles'

export const adminTabList = [
	{
		label: 'Member Info',
		slug: 'member-info',
		getComponent: () => <Members slug='member-info' />
	},
	{
		label: 'Beneficiaries',
		slug: 'beneficiaries',
		getComponent: () => <Personal slug='beneficiaries' />
	},
	{
		label: 'Job Info',
		slug: 'job-info',
		getComponent: () => <JobInfo slug='job-info'/>
	},
	{
		label: 'Web',
		slug: 'web',
		getComponent: () => <Web />
	},
	{
		label: 'Insurance',
		slug: 'insurance',
		getComponent: () => <Insurance />
	},
	{
		label: 'Notes',
		slug: 'notes',
		getComponent: () => <Notes />
	},
	{
		label: 'Delete',
		slug: 'delete',
		labelColor: 'red',
		onClick: (cb) => cb(),
		getComponent: () => <></>
	}
];

export const memberTabList = [
	{
		label: 'Member Info',
		slug: 'member-info',
		title: 'Member',
		getComponent: () => <Members slug='member-info' />
	},
	{
		label: 'Deductions',
		slug: 'deductions',
		title: 'Benefits',
		getComponent: () => <Deductions slug='deductions' />
	},
	{
		label: 'Beneficiaries',
		title: 'Beneficiaries',
		slug: 'beneficiaries',
		getComponent: () => <Personal slug='beneficiaries' />
	},
	{
		label: 'Job Info',
		title: 'Job Info',
		slug: 'job-info',
		getComponent: () => <JobInfo slug='job-info'/>
	}
];

export const getTabListByRole = (roles) => {
	if(roles.includes(ROLES.administrator) || roles.includes(ROLES.developer)) {
		return adminTabList
	}

	if(roles.includes(ROLES.member)) {
		return memberTabList
	}
}
