import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  getPrintLabelService,
  getPrintEnvelopesService,
  getPrintLabelByMemberService,
  getPrintEnvelopesByMemberService
} from './../../Services/printService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'print';

const initialState = {

  labelsError: null,
  labelsRequest: false,
  labelsData: null,

  printEnvelopesByMemberRequest: false,
  printEnvelopesByMemberError: null,
  printEnvelopesByMemberData: null,

  printLabelByMemberRequest: false,
  printLabelByMemberError: null,
  printLabelByMemberData: null,
};


export const getPrintLabel = createAsyncThunk(
  'print/getPrintLabel',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getPrintLabelService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getPrintEnvelopes = createAsyncThunk(
  'print/getPrintEnvelopes',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getPrintEnvelopesService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getPrintLabelByMember = createAsyncThunk(
  'print/getPrintLabelByMember',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getPrintLabelByMemberService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getPrintEnvelopesByMember = createAsyncThunk(
  'print/getPrintEnvelopesByMember',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getPrintEnvelopesByMemberService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const printReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {


    builder.addCase(getPrintLabel.fulfilled, (state, action) => {
      state.labelsData = action.payload?.data;
      state.labelsRequest = false;
    })

    builder.addCase(getPrintLabel.rejected, (state, action) => {
      state.labelsError = action.payload.error;
      state.labelsRequest = false;
      state.labelsData = null;
    })

    builder.addCase(getPrintLabel.pending, (state) => {
      state.labelsRequest = true;
      state.labelsError = null;
      state.labelsData = null;
    })

    builder.addCase(getPrintEnvelopesByMember.fulfilled, (state, action) => {
      state.printEnvelopesByMemberData = action.payload?.data;
      state.printEnvelopesByMemberRequest = false;
    })

    builder.addCase(getPrintEnvelopesByMember.rejected, (state, action) => {
      state.printEnvelopesByMemberError = action.payload.error;
      state.printEnvelopesByMemberRequest = false;
      state.printEnvelopesByMemberData = null;
    })

    builder.addCase(getPrintEnvelopesByMember.pending, (state) => {
      state.printEnvelopesByMemberRequest = true;
      state.printEnvelopesByMemberError = null;
      state.printEnvelopesByMemberData = null;
    })

    builder.addCase(getPrintLabelByMember.fulfilled, (state, action) => {
      state.printLabelByMemberData = action.payload?.data;
      state.printLabelByMemberRequest = false;
    })

    builder.addCase(getPrintLabelByMember.rejected, (state, action) => {
      state.printLabelByMemberError = action.payload.error;
      state.printLabelByMemberRequest = false;
      state.printLabelByMemberData = null;
    })

    builder.addCase(getPrintLabelByMember.pending, (state) => {
      state.printLabelByMemberRequest = true;
      state.printLabelByMemberError = null;
      state.printLabelByMemberData = null;
    })
  }
});

export default printReducer.reducer;
