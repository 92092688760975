import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginService, changePasswordService } from './../../Services/userService'
import { removeLocalStorage, setLocalStorage } from './../../Helpers'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'user';

const initialState = {
  request: true,
  error: null,
  data: null,

  changePasswordError: null,
  changePasswordRequest: false,
  changePasswordData: null
};

export const fetchLogin = createAsyncThunk(
  'users/fetchLogin',
  async (userData, { rejectWithValue }) => {
    try {
      
      const response = await loginService(userData);

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setLoginDataReducer = (state, action) => {
  return {
    ...state,
    data: action.payload.userData,
    request: false,
    error: null
  }
};

const setLoginDataAction = (userData) => {
  return {
    payload: {
      userData
    }
  }
};

const logoutReducer = (state) => {
  removeLocalStorage(null);
  return {
    ...state,
    data: null,
    request: false,
    error: null
  }
};

export const changePassword = createAsyncThunk(
  'member/change-password',
  async (updateData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await changePasswordService(updateData);

      dispatch(showNotification({
        message: 'Password updated successfully',
        type: 'SUCCESS'
      }));

      document.getElementById('formChangePassword').reset();

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: typeof error.message === 'string' ? error.message : error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const userReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setLoginData: {
      reducer: setLoginDataReducer,
      prepare: setLoginDataAction
    },
    logout: logoutReducer
  },
  extraReducers: (builder) => {

    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
      setLocalStorage(action.payload?.data);
    })

    builder.addCase(fetchLogin.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(fetchLogin.pending, (state) => {
      state.request = true;
      state.error = null;
    })

    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.changePasswordData = action.payload?.data;
      state.changePasswordRequest = false;
    })

    builder.addCase(changePassword.rejected, (state, action) => {
      state.changePasswordError = action.payload.error;
      state.changePasswordRequest = false;
      state.changePasswordData = null;
    })

    builder.addCase(changePassword.pending, (state) => {
      state.changePasswordRequest = true;
      state.changePasswordError = null;
    })
  }
});

export const { setLoginData, logout } = userReducer.actions

export default userReducer.reducer