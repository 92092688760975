export const PrintFormsPartOne = [
	{
		label: '',
		forms: [
			{
				label: 'Fields to Display',
				placeholder: 'Last Name',
				type: 'checkbox',
				col: 'col-sm-12 col-12',
				required: true,
				validate: {
					type: 'String'
				},
				name: 'union_membership_status_ids',
				options: []
			}					
		]
	}
];

export const PrintFormsPartTwo = [
	{
		label: "",
		forms: [
			{
				label: 'Selected Member(s):',
				placeholder: 'Click to search by name or "Ctrl-Click" to select multiple',
				type: 'multiselect',
				col: 'col-sm-12 col-12',
				required: true,
				validate: {},				
				name: 'selected_members',
				reduxInfo: {
					reducer: 'member',
					key: 'getMemberData'
				},
				options: []
			}
		]

	}

];
