import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setInsuranceCompanyByFilterFiltered,
	clearInsuranceCompanyByFilterFiltered,
	reducerName as insuranceCompanyReducerName,
	getInsuranceCompany,
	setByFilterFilters,
	clearByFilterFilters,
	getInsuranceCompanyByFilter
} from './../../../../Store/Reducers/insuranceCompany';
import { downloadPayrollAuthorization } from './../../../../Store/Reducers/payrollDeduction'
import { filterFields } from './constants';
import GenerateForm from './../../../../Components/Forms/generate_form'
import {
	getPolicyByCompany
} from './../../../../Store/Reducers/policy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import InputSearch from './../../../../Components/Forms/input_search'

const TableHeader = () => {
	const dispatch = useDispatch();
	const insuranceCompanyData = useSelector((state)=> state[insuranceCompanyReducerName].byFilterData || []);
	const insuranceCompanyDataFiltered = useSelector((state)=> state[insuranceCompanyReducerName].byFilterDataFiltered);
	const byFilterFilters = useSelector((state)=> state[insuranceCompanyReducerName].byFilterFilters);

	useEffect(() => {
		dispatch(getInsuranceCompany());
	}, [dispatch]);

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();

		return data.first_name?.toLowerCase().includes(searchTerm) ||
					data.last_name?.toLowerCase().includes(searchTerm) ||
					data.suffix?.toLowerCase().includes(searchTerm) ||
					data.middle_name?.toLowerCase().includes(searchTerm) ||
					data.policy?.name?.toLowerCase().includes(searchTerm) ||
					data.policy?.insurance_company?.name?.toLowerCase().includes(searchTerm);
	}

	const handleInputChange = (event) => {
		if(event.target.name === 'company_id') {
			!!event.target.value && dispatch(getPolicyByCompany(event.target.value));
		}

		dispatch(setByFilterFilters({
			[event.target.name]: event.target.value
		}))
	};

	const handlePayrollAuthorizationPDF = () => {
		dispatch(downloadPayrollAuthorization({parameters: byFilterFilters}));
	}

	return <>
		{
			filterFields.map((section, i) =>
				<Fragment key={i}>
				<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
				{
					section.forms.map((fieldData)=>({
						...fieldData,
						defaultValue: byFilterFilters[fieldData.name] !== 'all'  ? byFilterFilters[fieldData.name] : null
					})).map((form, i) => <GenerateForm
						key={i}
						{...form}
						onChange={handleInputChange}
						dataInputDates={(name, value)=> {
							dispatch(setByFilterFilters({
								[name]: value
							}))
						}}
					/> )
				}
				</Fragment>					
			)
		}
		<div className='col-12 col-lg-3 mt-1 mb-3'>
			<button type='button' disabled={false} onClick={handlePayrollAuthorizationPDF} className='btn-blue btn btn-default btn-primary'>
			<FontAwesomeIcon style={{marginRight:'5px'}} icon={faArrowsRotate} />
				View Authorizations PDF
			</button>
		</div>
		<div className='col-1 show-on-mobile'></div>
		<div className='col-1 show-on-mobile'></div>
		<div className='col-4 col-lg-2 mt-1 mb-3'>
			<button type='button' disabled={false} onClick={()=>dispatch(clearByFilterFilters())} className='btn-blue btn btn-default btn-primary'>
			<FontAwesomeIcon style={{marginRight:'5px'}} icon={faArrowsRotate} />
				Reset
			</button>
		</div>
		<div className='col col-4 col-lg-2 mt-1'>
			<button type='button' disabled={false} onClick={()=>dispatch(getInsuranceCompanyByFilter(byFilterFilters))} className='btn-orange btn btn-default btn-primary'>Show</button>
		</div>
		<div className='col-1 hide-on-mobile'></div>
		<div className="col-3 col-md-1 text-right  mt-2" style={{paddingRight:'10px',margin:0}}>
			<label htmlFor='searchTable' className='form-label mt-2'>Search: </label>
		</div>
		<div className="col-8 col-md-3 mb-3 mt-2" style={{paddingRight:'10px',margin:0}}>
			<InputSearch
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						insuranceCompanyDataFiltered !== null && dispatch(clearInsuranceCompanyByFilterFiltered(
							null
						));
						return;
					}

					dispatch(setInsuranceCompanyByFilterFiltered(
						insuranceCompanyData.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</>
};

export default TableHeader;
