import { getAxiosInstance } from "../Config/axios";
import { downloadExcelBase64File, downloadPDFBase64File } from "../Helpers";

export const getMemberDisabilityService = async (parameters) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: parameters,
            url: 'member-disability'
        };
    
        const result = await axios(options);
        
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const getMemberDisabilityPDFReportService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: data.parameters,
            url: 'download/member-disability-pdf'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Member disability ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return result.file;
        
    } catch (error) {
        data.reportPage.close();
        throw error
    }
};

export const getMemberDisabilityExcelReportService = async (parameters) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: parameters,
            url: `download/member-disability-excel`
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        downloadExcelBase64File(result.data.file.replace('data:application/vnd.ms-excel;base64,', ''), `Member_disability.xlsx`);

        return result.file;
        
    } catch (error) {
        throw error
    }
};
