import React from 'react';
import { logout } from '../Store/Reducers/user';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import Logo from './../Assets/Img/UF_UT_Logo_small.png';
import {NavLink} from 'react-router-dom';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Header = (props) => {
	const dispatch = useDispatch();

	const userData = useSelector((state)=> state?.user?.data?.user || {});
	const memberData = useSelector((state)=> state.member?.getDashboard?.member || {});

	const headerLabel = memberData.first_name ? <>{memberData.title.name}<br /> {memberData.first_name} {memberData.last_name}</> : capitalizeFirstLetter(userData.name);

	return (
		<>
			<nav className="navbar navbar-expand-lg navbar-light text-white header-bg-color">
			  <div className="container-fluid pb-2">
					<div className="col-2 show-on-mobile">
						<NavLink to={'/dashboard'}>
							<img style={{width:'50px', margin:'auto', display:'block'}} src={Logo} alt='Logo'></img>
						</NavLink>
					</div>
			    <div className="collapse navbar-collapse">
			      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
			      </ul>
						<div className="dropdown">
							<div className="dropbtn">
								<div className='row'>
									<div className='col-10 text-right' style={{padding: 0, margin: 0, overflow: 'hidden'}}>
											{headerLabel}
									</div>
									<div className='col-2 text-left' style={{padding: 0, margin: 0, paddingLeft: '5px'}}>
										<FontAwesomeIcon size='2x' style={{top: '-5px', position: 'relative'}} icon={faUser} />
									</div>
								</div>
							</div>
							<div className="dropdown-content">
								<NavLink to={'/dashboard/settings_change_password'}>
									<FontAwesomeIcon style={{marginRight: '5px'}} icon={faKey} />
									Change Password
								</NavLink>
								<button style={{cursor:'pointer'}} onClick={() => dispatch(logout())}>
									<FontAwesomeIcon style={{marginRight: '5px'}} icon={faPowerOff} />
									Logout
								</button>
							</div>
						</div>
						{ props.openMenu ? <button className="navbar-toggler" type="button" onClick={props.openMenu} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<FontAwesomeIcon style={{color:'white'}} size='lg' icon={faBars} />
						</button> : null }
			    </div>
			  </div>			  
			</nav>
		</>
	)
}

export default Header