import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reducerName as memberReducerName } from './../../../Store/Reducers/member';
import { beneficiaryDistributionTypes } from './../../../Components/Forms/beneficiary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { setActiveTabSlug } from './../../../Store/Reducers/memberForm';
import {useNavigate} from 'react-router-dom';


const Beneficiaries = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const dashboardData = useSelector((state)=> state[memberReducerName]?.getDashboard || {});
	const memberData = dashboardData.memberData || {};
	const beneficiariesData = memberData.beneficiaries || [];

	const handleClickToMemberForm = (slug) =>{
		dispatch(setActiveTabSlug({slug}));
		navigate('/dashboard/members_member_management')
	}
	
	return (<>
	<div className='col-12 col-md-4 mb-5'>
		<div className='row'>
			<div className='col-6 text-left'>
						<legend>Beneficiaries</legend>
			</div>
			<div className='col-6 text-right'>
				<button onClick={() => handleClickToMemberForm('beneficiaries')} className='gray-button'>
					<FontAwesomeIcon style={{marginRight: '3px', fontSize: '1.4em'}} icon={faEdit} /> Edit
				</button>
			</div>
			<div className='col-12'>
				{beneficiariesData.map((beneficiary, index)=><div key={index} className='row'>
					<div className='col-2'><div style={{backgroundColor: beneficiaryDistributionTypes[beneficiary.distribution].color }} className='colorBox' /></div>
					<div className='col-10'>
						<div className='row'>
							<div className='col-12'>
									<span className='ut-subtitle ut-box-subtitle mt-3 capitalize'>{beneficiaryDistributionTypes[beneficiary.distribution].label}</span>
							</div>
							<div className='col-12'>
								<span>{beneficiary.first_name} {beneficiary.last_name}</span>
							</div>
						</div>
					</div>
				</div>)}
			</div>
		</div>
	</div>
	</>)
};

export default Beneficiaries;
