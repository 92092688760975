import React, { useState } from 'react'
import TabWeb from './tabWeb';
import ChangePasswordForm from './changePasswordForm';
import ChangeMailForm from './changeMailForm';

const Web = () => {
  const [showTabWeb, setShowTabSweb] = useState(true);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [showChangeMailForm, setShowChangeMailForm] = useState(false);

	const handleShowTabWeb = () => {
		setShowTabSweb(true);
		setShowChangePasswordForm(false);
		setShowChangeMailForm(false);
	}

	const handleShowChangePasswordForm = () => {
		setShowChangePasswordForm(true);
		setShowTabSweb(false);
		setShowChangeMailForm(false);
	}

	const handleShowChangeMailForm = () => {
		setShowChangeMailForm(true);
		setShowTabSweb(false);
		setShowChangePasswordForm(false);
	}

	return (
		<>
			{
        showChangeMailForm ?
        <ChangeMailForm showTabWeb={handleShowTabWeb} /> :
        null
      }
			{
        showChangePasswordForm ?
        <ChangePasswordForm showTabWeb={handleShowTabWeb} /> :
        null
      }
			{
        showTabWeb ?
        <TabWeb showChangePasswordForm={handleShowChangePasswordForm} showChangeMailForm={handleShowChangeMailForm} /> :
        null
      }
		</>
	)
}

export default Web