import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOfficerService,
  editOfficerService,
  destroyOfficerService,
  createOfficerService,
  changeLetterheadService,
  downloadOfficerLetterheadPDFService
} from './../../Services/officerService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'officer';

const initialState = {
  request: false,
  error: null,
  data: null
};

export const getOfficer = createAsyncThunk(
  'officer/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getOfficerService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const editOfficer = createAsyncThunk(
  'officer/editOfficer',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await editOfficerService(parameters);

      dispatch(getOfficer());
      dispatch(showNotification({
        message: 'Officer updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };

    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const destroyOfficer = createAsyncThunk(
  'officer/destroyOfficer',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await destroyOfficerService(id);

      dispatch(getOfficer());
      dispatch(showNotification({
        message: 'Officer deleted successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const createOfficer = createAsyncThunk(
  'officer/createOfficer',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await createOfficerService(parameters);

      dispatch(getOfficer());
      dispatch(showNotification({
        message: 'Officer created successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const changeLetterhead = createAsyncThunk(
  'officer/changeLetterheadOfficer',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await changeLetterheadService(parameters);

      dispatch(showNotification({
        message: 'Letterhead updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const downloadOfficerLetterheadPDF = createAsyncThunk(
  'officer/changeLetterheadOfficer',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await downloadOfficerLetterheadPDFService(parameters);


      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setDataFilteredReducer = (state, action) => {
  return {
    ...state,
    dataFiltered: action.payload.data
  }
};

const setDataFilteredAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearDataFilteredReducer = (state) => {
  return {
    ...state,
    dataFiltered: null
  }
};

const setEditDataHandler = (state, data) => {
  return {
    ...state,
    editData: data.payload
  }
};

export const officerReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setDataFiltered: {
      reducer: setDataFilteredReducer,
      prepare: setDataFilteredAction
    },
    clearDataFiltered: clearDataFilteredReducer,
    setEditData: setEditDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getOfficer.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getOfficer.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getOfficer.pending, (state) => {
      state.request = true;
      state.error = null;
    })
  }
});

export const { setDataFiltered, clearDataFiltered, setEditData } = officerReducer.actions

export default officerReducer.reducer;
