import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setPoliciesByCompanyDataFiltered,
	clearPoliciesByCompanyDataFiltered,
	reducerName as policyReducerName,
} from './../../../../Store/Reducers/policy';
import { getInsuranceCompany } from './../../../../Store/Reducers/insuranceCompany';
import GenerateForm from './../../../../Components/Forms/generate_form'
import { getPolicyByCompany } from './../../../../Store/Reducers/policy';
import { insuranceCompanyFieldData } from './constants';

const TableHeader = () => {
	const dispatch = useDispatch();
	const policyData = useSelector((state)=> state[policyReducerName].dataPolicyByCompany || []);
	const policyDataFiltered = useSelector((state)=> state[policyReducerName].policiesByCompanyDataFiltered);

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();
		return data.name?.toLowerCase().includes(searchTerm) || data.amount?.toString()?.toLowerCase().includes(searchTerm) ;
	}

	const handleInputChange = (event) => {
    if(event.target.name === 'insurance_company_id') {
			dispatch(getPolicyByCompany(event.target.value));
    }
	};

	useEffect(()=>{
    dispatch(getInsuranceCompany());

		return () => {
			dispatch(getPolicyByCompany(null));
		}
  }, [dispatch]);

	return <div className='row'>
		<GenerateForm
			{...insuranceCompanyFieldData}
			onChange={handleInputChange}
		/>
		<div className="col-3 col-md-2 text-center desktop-mt-5">
			<label htmlFor='searchTable' className='form-label mt-2'>Search: </label>
		</div>
		<div className="col-8 col-md-4 desktop-mt-5">
			<input
				disabled={false}
				type='text'
				defaultValue={''}
				placeholder={'Search'}
				className='form-control'
				name={'searchTable'}
				id={`searchTable`}
				aria-describedby="Search in table"
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						policyDataFiltered !== null && dispatch(clearPoliciesByCompanyDataFiltered(
							null
						));
						return;
					}

					dispatch(setPoliciesByCompanyDataFiltered(
						policyData.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</div>
}

export default TableHeader;
