import React from 'react';
import Header from './header';

const MemberLayout = (props) => {
	return (
		<>
			<div className="background-main member-dashboard">
				<Header/>
				<div className="row">
					<div className='col-12 col-md-1'></div>
					<div className='col-12 col-md-10'>
						<props.component />
					</div>
				</div>
			</div>
		</>
	)
}

export default MemberLayout