import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEnvelopeService } from './../../Services/envelopeService'

export const reducerName = 'envelope';

const initialState = {
  request: false,
  error: null,
  data: null
};

export const getEnvelope = createAsyncThunk(
  'envelope/get',
  async (data, { rejectWithValue }) => {
    try {
      
      const response = await getEnvelopeService(data);

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);


export const envelopeReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {

    builder.addCase(getEnvelope.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getEnvelope.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getEnvelope.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    })
  }
});

export default envelopeReducer.reducer;
