import React, {Fragment} from 'react'
import GenerateForm from '../../../../Components/Forms/generate_form'
import { WebFormsPartOne, WebFormsPartTwo } from '../../../../Config/Forms/MembersManagements/web'
import ButtonsActions from '../../../../Components/Forms/buttons_actions'
import { reducerName as memberReducerName } from '../../../../Store/Reducers/member';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Web = ({ showChangePasswordForm, showChangeMailForm }) => {

	const managementData = useSelector((state)=> state[memberReducerName].managementData);
	
	return (
		<>
			<form>
			<div className="row pt-3">
				<div className="col-12">
					<div className="row">
						{
							WebFormsPartOne.map((form, i) =>
								<Fragment key={i}>
								<label key={i} className={`title-label-section-forms ${i>0 ? '': ''}`}>{form.label}</label>
								{
									form.forms.map((field)=>({
										...field,
									  defaultValue: typeof field?.getDefaultValue === 'function' ?
												field.getDefaultValue(managementData) :
												typeof field.component === 'string' ?
													Array.isArray(managementData?.[field.component]) ?
														managementData[field.component][managementData[field.component].length - 1]?.[field.componentKey || field.name] :
														managementData?.[field.component]?.[field.componentKey || field.name] :
														managementData?.[field.name]
									})).map((form, i) => <GenerateForm
										key={i}
										{...form}/> )
								}
								</Fragment>
							)
						}
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="row">
						{
							WebFormsPartTwo.map((form, i) =>
								<Fragment key={i}>
								<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{form.label}</label>
								{
									form.forms.map((form, i) => <GenerateForm key={i} disabled={form.disabled} placeholder={form.placeholder} validate={form.validate} options={form.options} required={form.required} type={form.type} col={form.col} name={form.name} label={form.label}/> )
								}
								</Fragment>
							)
						}
					</div>
				</div>
			</div>
			</form>
			<div className="row pt-5">
				<div className="col-2 col-md-3 show-on-mobile"></div>
				<div className="col-8 col-md-3 mb-3">
					<button type="button" onClick={showChangePasswordForm} className="btn-medium-grey icon-members-web btn btn-default btn-primary">
						<FontAwesomeIcon style={{marginRight:'5px'}} icon={faKey} />
						Change Password
					</button>
				</div>
				<div className="col-2 col-md-3 show-on-mobile"></div>
				<div className="col-2 col-md-3 show-on-mobile"></div>
				<div className="col-6 col-md-3 hide-on-mobile">
				</div>
				<div className="col-6 col-md-3 hide-on-mobile">
				</div>
				<div className="col-8 col-md-3">
					<button type="button" onClick={showChangeMailForm} className="btn-medium-grey icon-members-web btn btn-default btn-primary">
						<FontAwesomeIcon style={{marginRight:'5px'}} icon={faEnvelope} />
						Change Username(Email)
					</button>
				</div>
			</div>			
			<ButtonsActions disabled={!managementData || !Object.keys(managementData).length} UpdateAll={()=>{}} UpdateSection={()=>{}}/>
		</>
	)
}

export default Web