import React, {useMemo} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { reducerName as memberReducerName } from './../../../Store/Reducers/member';
import {useNavigate} from 'react-router-dom';
import { setActiveTabSlug } from './../../../Store/Reducers/memberForm';

const JobInfo = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const dashboardData = useSelector((state)=> state[memberReducerName]?.getDashboard || {});
	const memberData = useMemo(() => dashboardData.memberData || {}, [dashboardData]);
	const jobData = useMemo(() => memberData.job || {}, [memberData]);

	const handleClickToMemberForm = (slug) =>{
		dispatch(setActiveTabSlug({slug}));
		navigate('/dashboard/members_member_management')
	}

	const calculations = useMemo(()=>{

		const selectedBaseHour = jobData.base_hour;
		
		if(!selectedBaseHour) {
			return ;
		}

		const hourlyRate = jobData.hourly_rate;
		let hourly = (Number(selectedBaseHour.name) * 52 ) * hourlyRate;
		let holiday = 115.2 * hourlyRate;
		let total = hourly + holiday;

		hourly = "$" + hourly.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
		holiday = "$" + holiday.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
		total = "$" + total.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
		
		return {
			hourly_income: hourly,
			holiday_income: holiday,
			total_income: total
		}

	}, [jobData]);

return (<>
		<div className='col-12 col-md-8 mb-5'>
			<div className='row member-dashboard'>
				<div className="col-6 col-md-8 pb-4">
					<legend>Job Info</legend>
				</div>
				<div className="col-6 col-md-4" style={{textAlign:'right'}}>
					<button onClick={() => handleClickToMemberForm('job-info')} className='gray-button'>
						<FontAwesomeIcon style={{marginRight: '3px', fontSize: '1.4em'}} icon={faEdit} /> Details
					</button>
				</div>
						<div className="col-6 col-md-3 table-cell">
								<label>Hourly Rate</label>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<center className='table-value text-center'>${jobData.hourly_rate || '0.00'}</center>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<label>Original Hire</label>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<center className='table-value text-center'>{jobData.employ_date ? new Date(jobData.employ_date).toLocaleDateString().replaceAll('/', '-') : ''}</center>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<label>Holiday Income</label>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<center className='table-value text-center'>{calculations?.holiday_income || '0.00'}</center>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<label>Station</label>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<center className='table-value text-center'>{jobData.station?.name ? jobData.station.name : ''}</center>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<label>Hourly Income</label>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<center className='table-value text-center'>{calculations?.hourly_income ? calculations.hourly_income : '0.00'}</center>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<label>Battalion</label>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<center className='table-value text-center'>{jobData.batallion_number ? jobData.batallion_number : ''}</center>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<label>Total Income</label>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<center className='table-value text-center'>{calculations?.total_income ? calculations.total_income : '0.00'}</center>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<label>Shift</label>
						</div>
						<div className="col-6 col-md-3 table-cell">
								<center className='table-value text-center'>{jobData.shift ? jobData.shift : ''}</center>
						</div>
			</div>
		</div>
	</>)
}

export default JobInfo;
