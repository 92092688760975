import React from 'react'
import GenerateForm from './../../../../Components/Forms/generate_form'
import { useSelector, useDispatch } from 'react-redux';
import {
  reducerName as policyReducerName,
  editPolicy,
  createPolicy,
  editPolicyCosts
} from './../../../../Store/Reducers/policy';
import{ reducerName as insuranceCompanyReducerName } from './../../../../Store/Reducers/insuranceCompany';
import { formFields } from './constants'
import ModalComponent from './../../../../Components/modal';

const formName = 'policyForm';

const PolicyForm = ({onSubmit, onCancel}) => {
  
  const dispatch = useDispatch();
  const policyData = useSelector((state)=> state[policyReducerName].editData);
  const idPolicyByCompany = useSelector((state)=> state[policyReducerName].idPolicyByCompany);
  const insuranceCompanies = useSelector((state)=> state[insuranceCompanyReducerName].data);
  const editData = useSelector((state)=> state[policyReducerName].editData);
  const editRequest = useSelector((state)=> state[policyReducerName].editRequest);
  const company = insuranceCompanies?.find((el)=> el.id === idPolicyByCompany)

  const handleInputChange = (event) => {
	};

  const handleFormSubmit = (e) => {
			
		e.preventDefault();

		if(document.querySelectorAll(`#${formName} input.is-invalid`).length) {
			document.querySelector(`#${formName} input.is-invalid`).focus();
			return;
		}
		
		const elements = e.target.elements;
		const formData ={};
		const fields = [];

		formFields.forEach((formMember)=>formMember.forms?.forEach((field)=>fields.push(field)))
		
		for (let i = 0; i < elements.length; i++) {

				const { name, value } = elements.item(i);

        if(!name.length) {
          continue;
        }

				const fieldConfiguration = fields.find((field)=> field?.name === name);

        if(fieldConfiguration?.required && !value) {
          elements.item(i).parentElement?.childNodes?.[2]?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]?.focus();
          return;
        }
        
        formData[name] = value;
				
		}
    
    
    if(editData.id) {
      formData.insurance_company_id = editData.insurance_company_id;
      dispatch(editPolicy({
        id: editData.id,
        data: formData
      }));
    } else {
      formData.insurance_company_id = idPolicyByCompany;
      dispatch(createPolicy({
        data: formData
      }))
    }

		onSubmit();
	}

  const handleUpdateAllCosts = () => {

    if(document.querySelectorAll(`#${formName} input.is-invalid`).length) {
			document.querySelector(`#${formName} input.is-invalid`).focus();
			return;
		}

    const elements = document.getElementById('policyForm').elements;
    const formData ={};
		const fields = [];

		formFields.forEach((formMember)=>formMember.forms?.forEach((field)=>fields.push(field)))
		
		for (let i = 0; i < elements.length; i++) {

				const { name, value } = elements.item(i);

        if(!name.length) {
          continue;
        }

				const fieldConfiguration = fields.find((field)=> field?.name === name);

        if(fieldConfiguration?.required && !value) {
          elements.item(i).parentElement?.childNodes?.[2]?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]?.focus();
          return;
        }
        
        formData[name] = value;
				
		}


    formData.insurance_company_id = editData.insurance_company_id;
    dispatch(editPolicy({
      id: editData.id,
      data: formData
    }));

    formData.new_amount = formData.amount;
    dispatch(editPolicyCosts({
      id: editData.id,
      data: formData
    }));

    closeModal();
    onSubmit();
  }

  const [modalIsOpen, setIsOpen] = React.useState(false);

	const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

	return (<>
    <ModalComponent
      deleteButton={false}
      acceptButton={true}
			modalIsOpen={modalIsOpen}
			title={`Are you sure you want to update the cost of ${company?.name} - ${editData?.name} from $${editData?.amount} to $${Number(document.querySelector('#policyForm input[name=amount]')?.value || 0)}? All previous costs for this insurance policy will be updated for ALL members who hold this insurance. This action cannot be reversed.`}
			closeModal={closeModal}
			confirmModal={()=>{
				handleUpdateAllCosts();
			}}
		/>
    <form id={formName} onSubmit={handleFormSubmit}>
      <div className="row">
        <div className="col col-12">
          <div className="row">
            {
              formFields.map((section, i) =>
                <>
                <label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
                {
                  section.forms.map((field)=>({
                    ...field,
                    disabled: !policyData,
                    defaultValue: policyData[field.name]
                  })).map((form, i) => <GenerateForm
                    key={i}
                    {...form}
                    onChange={handleInputChange}
                    dataInputDates={()=>{}}
                  /> )
                }
                </>					
              )
            }
          </div>
        </div>
      </div>
      <div className='row mt-5 mb-3'>
        <div className='col col-6'>
          <button type='button' disabled={editRequest} onClick={onCancel} className='btn-light-grey btn btn-default btn-primary'>Back</button>
        </div>
        <div className='col col-6'>
          <button type='submit' disabled={editRequest} className='btn btn-default btn-primary'>{editData?.id ? 'Update' : 'Accept'}</button>
        </div>
        <div hidden={!editData?.id} className='col col-12 mt-3'>
          <button type='button' onClick={openModal} disabled={editRequest} className='btn btn-default btn-primary'>Update All Costs</button>
        </div>
      </div>
    </form>
  </>)
}

export default PolicyForm