import React from 'react'
import { useSelector } from 'react-redux';
import InsuranceTable from './insurance/table';
import CancellationsTable from './insurance/cancellations';
import { reducerName as memberInsuranceReducerName } from './../../../Store/Reducers/memberInsurance';

const Deductions = () => {
	
	const memberInsuranceGetData = useSelector((state)=> state[memberInsuranceReducerName].getData || []);

	return (
		<>
			<div className='row deductions'>
				<div className='col col-12'>
						<h1 className='title-layout-header-page'>${parseFloat(memberInsuranceGetData.reduce((ac, val) => (ac + val.cost), 0)).toLocaleString('en-US', {minimumFractionDigits: 2})}</h1>
						<span className='gray-label'>Total Benefits</span><br /><br />
				</div>
				<div className='col col-12'>
					<label className='ut-subtitle mt-3 mb-3'>Insurance</label>
				</div>
				<div className='col col-12 mb-5'>
					<InsuranceTable />
				</div>
				<div className='col col-12'>
					<label className='ut-subtitle mt-3 mb-3'>Cancellations</label>
				</div>
				<div className='col col-12 mb-5'>
					<CancellationsTable />
				</div>
			</div>
		</>
	)
}

export default Deductions