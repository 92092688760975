export const CustomReportFormsPartOne = [
	{
		label: '',
		forms: [
			{
				label: 'Custom Report Templates',
				placeholder: 'Select a Template',
				type: 'select',
				col: 'col-sm-12 col-12',
				required: true,
				name: 'custom_report_template',
				options: [
					{
						id: 'clear-selection',
						label: 'Clear Selection',
						value: 'clear-selection'
					},
					{
						id: 'personal-information',
						label: 'Personal Information',
						value: 'personal-information'
					},
					{
						id: 'job-information',
						label: 'Job Information',
						value: 'job-information'
					},
					{
						id: 'iaff-information',
						label: 'IAFF Information',
						value: 'iaff-information'
					},
					{
						id: 'payroll-deductions',
						label: 'Payroll Deductions',
						value: 'payroll-deductions',
					},
					{
						id: 'all-information',
						label: 'All Information',
						value: 'all-information'
					}
				]
			},
			{
				label: 'Fields to Display',
				type: 'checkbox',
				col: 'col-sm-12 col-12',
				required: true,
				name: 'fields',
				options: [
					{
						id: 'first_name',
						label: 'First Name',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'first_name',
						name: 'first_name'
					},
					{
						id: 'email',
						label: 'E-mail',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'email',
						name: 'email'
					},
					{
						id: 'iaff_member_number',
						label: 'IAFF #',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'iaff_member_number',
						name: 'iaff_member_number'
					},
					{
						id: 'middle_name',
						label: 'Middle Name',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'middle_name',
						name: 'middle_name'
					},
					{
						id: 'birth_date',
						label: 'Birthdate',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'birth_date',
						name: 'birth_date'
					},
					{
						id: 'status',
						label: 'Union Status',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'status',
						name: 'status',
					},
					{
						id: 'last_name',
						label: 'Last Name',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'last_name',
						name: 'last_name'
					},
					{
						id: 'position',
						label: 'Job Title',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'position',
						name: 'position'
					},
					{
						id: 'join_date',
						label: 'Join Date',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						value: 'join_date',
						name: 'join_date'
					},
					{
						id: 'suffix',
						label: 'Suffix',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'suffix',
						name: 'suffix'
					},
					{
						id: 'hourly_rate',
						label: 'Wages',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'hourly_rate',
						name: 'hourly_rate'
					},
					{
						id: 'retire_date',
						label: 'Retire Date',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'retire_date',
						name: 'retire_date'
					},
					{
						id: 'address',
						label: 'Address',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'address',
						name: 'address'
					},
					{
						id: 'employ_date',
						label: 'Hire Date',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'employ_date',
						name: 'employ_date'
					},
					{
						id: 'gender',
						label: 'Gender',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'gender',
						name: 'gender'
					},
					{
						id: 'city',
						label: 'City',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'city',
						name: 'city'
					},
					{
						id: 'employee_number',
						label: 'HRIS #',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'employee_number',
						name: 'employee_number'
					},
					{
						id: 'ethnicity',
						label: 'Race',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'ethnicity',
						name: 'ethnicity'
					},
					{
						id: 'state',
						label: 'State',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'state',
						name: 'state'
					},
					{
						id: 'social_security_number',
						label: 'SS#',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'social_security_number',
						name: 'social_security_number'
					},
					{
						id: 'sick_plan',
						label: 'Sick Plan',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'sick_plan',
						name: 'sick_plan'
					},
					{
						id: 'zip_code',
						label: 'Zip',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'zip_code',
						name: 'zip_code',
					},
					{
						id: 'uniaff',
						label: 'UNIAFF (Union Dues)',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'uniaff',
						name: 'uniaff'
					},
					{
						id: 'iaff',
						label: 'IAFF Benefits (Union Benefits)',
						type: 'checkbox',
						col: 'col-sm-4 col-12',
						required: true,
						value: 'iaff',
						name: 'iaff'
					}
				]
			}					
		]
	}
]

export const CustomReportFormsPartTwo = [
	{
		label: 'Filters to Apply (None selected returns ALL members)',
		forms: [
			{
				label: 'Date Joined',
				placeholder: 'Date Joined',
				type: 'date',
				col: 'col-sm-4 col-12',
				value: '',
				isClearable: true,
				name: 'join_date'
			},
			{
				label: 'Before Date',
				placeholder: 'Before Date',
				type: 'radio',
				col: 'col-sm-2 col-12',
				value: 'before',
				required: false,
				name: 'date_selector',
				options: []
			},
			{
				label: 'After Date',
				placeholder: 'After Date',
				type: 'radio',
				col: 'col-sm-2 col-12',
				value: 'after',
				required: false,
				name: 'date_selector',
				options: []
			}
		]
	}
]

export const CustomReportFormsPartThree = [
	{
		label: '',
		forms: [
			{
				label: 'Insurance Company',
				placeholder: 'Insurance Company',
				type: 'select',
				col: 'col-sm-4 col-12',
				value: '',
				name: 'company_id',
				reduxInfo: {
					reducer: 'insuranceCompany',
					key: 'data'
				},
				options: []
			},
			{
				label: 'Insurance Policies',
				placeholder: 'Insurance Policies',
				type: 'select',
				col: 'col-sm-4 col-12',
				value: '',
				name: 'policy_id',
				reduxInfo: {
					reducer: 'policy',
					key: 'dataPolicyByCompany'
				},
				options: []
			},
			{
				label: 'Status',
				placeholder: 'Status',
				type: 'select',
				col: 'col-sm-4 col-12',
				value: 'join_date',
				name: 'union_membership_status_id',
				reduxInfo: {
					reducer: 'unionMembershipStatus',
					key: 'data'
				},
				options: [],
				groupBy: (options) => {

					const groups = [];

					groups.push({
						label: 'Primary Statuses',
						options: options.filter((opt)=> [1,2,6,7].includes(opt.id))
					});

					groups.push({
						label: 'Temporary Statuses',
						options: options.filter((opt)=> [3, 4, 5, 18, 15, 16, 8, 9, 10, 14, 17].includes(opt.id))
					});

					groups.push({
						label: 'New Member Statuses',
						options: options.filter((opt)=> [12, 11, 13].includes(opt.id))
					});

					return groups;
				}
			},
			{
				label: 'Gender',
				placeholder: 'Gender',
				type: 'select',
				col: 'col-sm-4 col-12',
				value: '',
				name: 'gender_id',
				reduxInfo: {
					reducer: 'gender',
					key: 'data'
				},
				options: []
			},
			{
				label: 'Race',
				placeholder: 'Race',
				type: 'select',
				col: 'col-sm-4 col-12',
				value: 'join_date',
				name: 'ethnicity_id',
				reduxInfo: {
					reducer: 'ethnicity',
					key: 'data'
				},
				options: []
			},
			{
				label: 'Position',
				placeholder: 'Position',
				type: 'select',
				col: 'col-sm-4 col-12',
				value: 'join_date',
				name: 'position_id',
				reduxInfo: {
					reducer: 'position',
					key: 'data'
				},
				options: []
			},
			{
				label: 'Sick Plan',
				placeholder: 'Sick Plan',
				type: 'select',
				col: 'col-sm-4 col-12',
				name: 'sick_plan_id',
				reduxInfo: {
					reducer: 'sickPlan',
					key: 'data'
				},
				options: []
			}
		]
	}
]