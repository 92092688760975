import React from 'react';
import LayoutHeaderPage from '../../../../Layouts/header_page';
import { useSelector, useDispatch } from 'react-redux';
import {
	reducerName as newMemberReportReducerName,
	getNewMemberReportExcelReport,
	getNewMemberReportPDFReport
} from '../../../../Store/Reducers/newMemberReport';
import TableComponent from '../../../../Components/table_v2';
import Notification from '../../../../Components/notification';
import TableHeader from './table_header';
import moment from 'moment';

const NewMemberReportReport = () => {

	const dispatch = useDispatch();
	const data = useSelector((state)=> state[newMemberReportReducerName].data || []);
	const byFilterRequest = useSelector((state)=> state[newMemberReportReducerName].byFilterRequest);
	const dataFiltered = useSelector((state)=> state[newMemberReportReducerName].dataFiltered);
	const dateFilter = useSelector((state)=> state[newMemberReportReducerName].dateFilter);

	const tableColumns = [
		{
			title: 'First Name',
			sort: true,
			value: 'first_name'
		},
		{
			title: 'Middle Name',
			value: 'middle_name'
		},
		{
			title: 'Last Name',
			value: 'last_name'
		},
		{
			title: 'Suffix',
			value: 'suffix'
		},
		{
			title: 'Home Address',
			value: 'addresses',
			parseValue: (value) => {
				if(!Array.isArray(value) || !value.length) { return; }
				const address = value[value.length -1];
				
				if(!address?.address_line_1?.length && !address?.address_line_2?.length) {
					return '';
				}
				
				return `${address?.address_line_1}, ${address?.address_line_2}`
			}
		},
		{
			title: 'City',
			value: 'addresses',
			parseValue: (value) => {
				if(!Array.isArray(value) || !value.length) { return; }
				
				const address = value[value.length -1];
				return address.city
			}
		},
		{
			title: 'State',
			value: 'addresses',
			parseValue: (value) => {
				if(!Array.isArray(value) || !value.length) { return; }
				
				const address = value[value.length -1];
				return address.state
			}
		},
		{
			title: 'Zip Code',
			value: 'addresses',
			parseValue: (value) => {
				if(!Array.isArray(value) || !value.length) { return; }
				
				const address = value[value.length -1];
				return address.zip_code
			}
		},
		{
			title: 'Join Date',
			value: 'join_date',
			parseValue: (value) => {
				if(!value) { return; }
				var date = moment(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		}
	];

	const handlePDFReport = () => {
		const currentDate = new Date(dateFilter);
		dispatch(getNewMemberReportPDFReport({
			parameters: {
				date: currentDate.toISOString().slice(0,10)
			}
		}))
	}

	const handleExcelReport = () => {
		const currentDate = new Date(dateFilter);
	  dispatch(getNewMemberReportExcelReport({
			date: currentDate.toISOString().slice(0,10)
		}))
	}

	return (
		<>
			<LayoutHeaderPage title="New Members"/>
			<div className="tab-content mt-3 pt-4" id="pills-tabContent">
				<TableComponent
					paginationSize={10}
					columns={tableColumns}
					data={dataFiltered || data}
					header={TableHeader}
				/>
				<Notification />
				<div className='row mt-3 mb-5'>
					<div className='col-12 col-md-6'></div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className='col-8 col-md-3 mb-3'>
						<button type='button' onClick={handlePDFReport} disabled={byFilterRequest} className='btn-orange btn-default btn-primary'>View all PDF</button>
					</div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className='col-8 col-md-3'>
						<button type='button' onClick={handleExcelReport} disabled={byFilterRequest} className='btn-blue btn-default btn-primary'>Export to Excel</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default NewMemberReportReport;
