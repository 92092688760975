import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBeneficiaryByMemberIdService,
  addBeneficiaryByMemberIdService
} from './../../Services/beneficiaryService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'beneficiary';

const initialState = {

  getByMemberId: null,
  getByMemberIdError: null,
  getByMemberIdRequest: null,

  addByMemberId: null,
  addByMemberIdError: null,
  addByMemberIdRequest: null
};

export const getBeneficiaryByMemberId = createAsyncThunk(
  'beneficiary/get',
  async (memberId, { rejectWithValue }) => {
    try {
      
      const response = !!memberId ? await getBeneficiaryByMemberIdService(memberId) : null;

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const addBeneficiaryByMemberId = createAsyncThunk(
  'beneficiary/add',
  async (memberData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await addBeneficiaryByMemberIdService(memberData);

      dispatch(showNotification({
        message: 'Beneficiaries updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);



export const beneficiaryReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {

    builder.addCase(getBeneficiaryByMemberId.fulfilled, (state, action) => {
      state.getByMemberId = action.payload?.data;
      state.getByMemberIdRequest = false;
    })

    builder.addCase(getBeneficiaryByMemberId.rejected, (state, action) => {
      state.getByMemberIdError = action.payload.error;
      state.getByMemberIdRequest = false;
    })

    builder.addCase(getBeneficiaryByMemberId.pending, (state) => {
      state.getByMemberIdRequest = true;
      state.getByMemberId = null;
      state.getByMemberIdError = null;
    })

    builder.addCase(addBeneficiaryByMemberId.fulfilled, (state, action) => {
      state.addByMemberId = action.payload?.data;
      state.addByMemberIdRequest = false;
    })

    builder.addCase(addBeneficiaryByMemberId.rejected, (state, action) => {
      state.addByMemberIdError = action.payload.error;
      state.addByMemberIdRequest = false;
    })

    builder.addCase(addBeneficiaryByMemberId.pending, (state) => {
      state.addByMemberIdRequest = true;
      state.addByMemberId = null;
      state.addByMemberIdError = null;
    })

  }
});

export default beneficiaryReducer.reducer;
