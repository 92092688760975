import { getAxiosInstance } from "../Config/axios";
import { downloadPDFBase64File } from "../Helpers"
 
export const getEnvelopeService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: `envelope/${data.memberId}`
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Envelope ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return result.file;
        
    } catch (error) {
        data.reportPage.close();
        throw error
    }
}