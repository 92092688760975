import { getAxiosInstance } from "../Config/axios";

const defaultCountryCode = 'us';
const apiHost = 'https://api.zippopotam.us'
const baseUrl = `${apiHost}/${defaultCountryCode}/`

export const searchPlaceByPostalCodeService = async (postalCode) => {
    try {
        const axios = getAxiosInstance(baseUrl, {});
        const options = {
            method: 'GET',
            url: postalCode
        };
    
        const result = await axios(options);
        
        if(result?.status === 400) {
            throw result.data;
        }

        return result?.data?.places[0];
        
    } catch (error) {
        throw error
    }
};
