import React, { useState, useEffect, useRef } from 'react';
import LayoutHeaderPage from './../../../../Layouts/header_page';
import { useSelector, useDispatch } from 'react-redux';
import {
	getOfficer,
	reducerName as officerReducerName,
	destroyOfficer,
	setEditData,
	changeLetterhead,
	downloadOfficerLetterheadPDF
} from './../../../../Store/Reducers/officer';
import TableComponent from './../../../../Components/table_v2';
import ModalComponent from './../../../../Components/modal';
import DeleteIcon from './../../../../Assets/Img/Icons/delete.jpg';
import EditIcon from  './../../../../Assets/Img/Icons/edit.jpg';
import Notification from './../../../../Components/notification';
import TableHeader from './table_header';
import Form from './form';

const Officers = () => {

	const dispatch = useDispatch();
	const officerData = useSelector((state)=> state[officerReducerName].data || []);
	const officerDataFiltered = useSelector((state)=> state[officerReducerName].dataFiltered);
	const gridData = officerDataFiltered || officerData;
	
	const tableColumns = [
		{
			title: 'Name',
			sort: true,
      value: 'name',
		},
		{
			title: 'Position',
      value: 'type',
		},
		{
			title: 'Actions',
      value: 'actions',
			className: 'td-space-table-three-buttons',
			getLabelFromStore: (storeData, index) => {
				return <>
					<button 
						className='btn-icon'
						onClick={()=> {
							setModalDescription('');
							setModalTitle('Edit Officer');
							setModalBody(<div className='row modal-footer' style={{width: '500px', maxWidth: '100%'}}>
								<div className='col col-12'><Form index={index} onCancel={()=> closeModal()} onSubmit={()=>{
										closeModal();
									}} /></div>
							</div>);
							dispatch(setEditData(gridData[index]));
							openModal();
						}
					} disabled={false}>
						<img src={EditIcon} alt='Edit'/> Edit
					</button>
					<button 
						className='btn-icon'
						onClick={()=> {
							setModalTitle(null);
							setModalBody('');
							setModalDescription(`Are you sure that you want to delete permanently Officer: ${gridData?.[index]?.name}`)
							setDeleteButton(true);
							setIndex(index);
							openModal();
						}
					} disabled={false}>
						<img src={DeleteIcon} alt='Delete'/>Delete
					</button>
				</>
			}
		}
	];

	useEffect(() => {
		dispatch(getOfficer())
	}, [dispatch]);


	const [modalIsOpen, setIsOpen] = useState(false);
	const [modalDescription, setModalDescription] = useState(null);
	const [modalTitle, setModalTitle] = useState(null);
	const [deleteButton, setDeleteButton] = useState(true);
	const [index, setIndex] = useState(null);
	const [modalBody, setModalBody] = React.useState('');

	const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

	const createHandle = () => {
		setModalDescription('');
		setModalTitle('Create Officer');
		setModalBody(<div className='row modal-footer' style={{width: '500px', maxWidth: '100%'}}>
			<div className='col col-12'><Form index={index} onCancel={()=> closeModal()} onSubmit={()=>{
					closeModal();
				}} /></div>
		</div>);
		dispatch(setEditData({}));
		openModal();
	};

	const [file, setFile] = useState();

	const uploadLetterhead = () => {
		const formData = new FormData();
		formData.append('image', file);
		dispatch(changeLetterhead(formData));
		setFile(null);
	};

	const fileInput = useRef(null);
	const openSelectFiles = (event) => {
		fileInput.current.click()
	}

	const handleOfficerLetterheadPDF = () => {
		dispatch(downloadOfficerLetterheadPDF({}))
	}

	return (
		<>
			<LayoutHeaderPage title="Officers"/>
			<div className="tab-content mt-3" id="pills-tabContent">
				<div className='row mt-3 mb-3'>
					<TableComponent
						columns={tableColumns}
						data={officerDataFiltered || officerData}
						header={TableHeader}
					/>
					<Notification />
					<div className='col-12 col-lg-9'></div>
					<div className='col-2 show-on-mobile'></div>
					<div className='col-8 col-lg-3'>
						<button type="button" className="btn-orange btn btn-default btn-primary" onClick={createHandle}>Add New Officer</button>
					</div>
				</div>
				<div className='row mt-5 mb-3'>
					<div className='col-2 show-on-mobile'></div>
					<div className='col-8 col-md-12'>
						<label className="title-component-upload-template">Update Letterhead:</label>
						<div className="row mt-4">
							<div className="col-sm-3">
								<button type="submit" data-bs-target="#inputGroupFile01" className="btn-component-select-file btn btn-default btn-primary mb-3" onClick={openSelectFiles}>
									Select Files
								</button>
								<span className="text-component-upload-template">* Allowable format: .png</span><br />
								<span className="text-component-upload-template">* Recommended measures in pixels:</span><br />
								<span className="text-component-upload-template" style={{marginLeft: '12px'}}>  width 1228 - height 260</span>
								<input type="file" hidden accept="image/png" className="form-control" onChange={(event) => setFile(event.target.files[0])} ref={fileInput} id="inputGroupFile01"/>
							</div>	
							<div className="col-sm-3">
								{ file ?
									<span className="text-component-select-file">{ file.name }</span>
									:
									<span className="text-component-select-file">No file was chosen </span>
								}
							</div>
						</div>
					</div>
					<div className='col-2 show-on-mobile'></div>
					<div className='col-2 show-on-mobile'></div>
					<div className="col-8 col-lg-3 mt-3">
						<button type="button" disabled={!file} onClick={uploadLetterhead} className="btn-orange btn btn-default btn-primary">Update</button>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 col-md-9'></div>
					<div className='col-2 show-on-mobile'></div>
					<div className='col-8 col-md-3'>
						<button type='button' disabled={false} onClick={handleOfficerLetterheadPDF} className='btn-blue btn btn-default btn-primary'>View PDF</button>
					</div>
				</div>
			</div>
			<ModalComponent
				modalIsOpen={modalIsOpen}
				description={modalDescription}
				modalBody={modalBody}
				title={modalTitle}
				closeModal={closeModal}
				deleteButton={deleteButton}
				confirmModal={()=>{
					dispatch(destroyOfficer({
						id: gridData?.[index]?.id
					}))
					closeModal();
				}}
			/>
		</>
	)
}

export default Officers;
