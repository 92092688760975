export const ALPHANUMERIC = 'Alphanumeric';
export const SPECIALALPHANUMERIC = 'Specialalphanumeric';
export const REQUIRED = 'Required';
export const STRING = 'String';
export const NUMBER = 'Number';
export const EMAIL = 'Email';
export const PASSWORD = 'Password';
export const DECIMAL = 'Decimal';

export const VALIDATION_DATA = {
  [ALPHANUMERIC]: {
    type: ALPHANUMERIC,
    message: 'Value must be alphanumeric',
    regexp: /[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
  },
  [SPECIALALPHANUMERIC]: {
    type: SPECIALALPHANUMERIC,
    message: 'Value must be alphanumeric',
    regexp: /[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
  },
  [STRING]: {
    type: STRING,
    message: 'Value must be string',
    regexp: /[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
  },
  [NUMBER]: {
    type: NUMBER,
    message: 'Value must be numeric',
    regexp: /^([0-9\s])*$/
  },
  [DECIMAL]: {
    type: DECIMAL,
    message: 'Value must be numeric',
    regexp: /[+-]?([0-9]*[.])?[0-9]+/
  },
  [EMAIL]: {
    type: EMAIL,
    message: 'Value must be a valid email address',
    regexp: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  },
  [REQUIRED]: {
    type: REQUIRED,
    message: 'Value is required'
  },
  [PASSWORD]: {
    type: PASSWORD,
    message: 'Password must be equals',
    passwordIsEqual: (value, name) => {
      const repeatField = document.getElementsByName(name);
      if(!repeatField?.[0]){
        return true;
      }
      
      return value === repeatField[0].value;
    }
  }
}
