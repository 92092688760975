import { SPECIALALPHANUMERIC, PASSWORD } from './../../../Components/Forms/constant_form';

export const ChangePasswordFormsPartOne = [
	{
		label: "",
		forms: [
			{
				label: 'Current Password:',
				subtitle: '',
				placeholder: 'Current Password',
				type: 'password',
				col: 'col-sm-12 col-12',
				required: true,
				validate: {
					type: SPECIALALPHANUMERIC
				},				
				name: 'current_password',
				options: []
			},
			{
				label: 'New Password:',
				subtitle: '',
				placeholder: 'New Password',
				type: 'password',
				col: 'col-sm-12 col-12',
				required: true,
				validate: {
					type: PASSWORD
				},				
				name: 'new_password'
			},
			{
				label: 'Repeat New Password:',
				subtitle: '',
				placeholder: 'Repeat New Password',
				type: 'password',
				col: 'col-sm-12 col-12',
				required: true,
				validate: {
					type: PASSWORD
				},				
				name: 'confirm_password',
				validateFieldName: 'new_password'
			}						
		]
	}
];
