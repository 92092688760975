import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setDataFilter,
	clearFilteredData,
	reducerName as jobUnionReducerName,
	setStartDateFilter,
	setEndDateFilter
} from '../../../Store/Reducers/jobUnionStatus';
import InputSearch from './../../../Components/Forms/input_search';
import DatePicker from './../../../Components/Forms/datepicker';

const TableHeader = () => {
	const dispatch = useDispatch();

	const statusChangesFilteredData = useSelector((state)=> state[jobUnionReducerName].statusChangesFilteredData);
	const statusChangesData = useSelector((state)=> state[jobUnionReducerName].statusChangesData || []);
	const startDateFilter = useSelector((state)=> state[jobUnionReducerName].startDateFilter);
	const endDateFilter = useSelector((state)=> state[jobUnionReducerName].endDateFilter);

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();

		return data?.first_name?.toLowerCase().includes(searchTerm) ||
			data?.last_name?.toLowerCase().includes(searchTerm) ||
			data?.middle_name?.toLowerCase().includes(searchTerm) ||
			data?.unionStatus?.toLowerCase().includes(searchTerm) ||
			data?.suffix?.toLowerCase().includes(searchTerm)
	}

	return <>
		<div className='col-12 col-md-4' style={{padding: 0, margin: 0}}>
			<div className='row' style={{padding: 0, margin: 0}}>
				<div className='col-12 col-md-4 col-lg-4'>
					<label htmlFor='year' className='form-label mt-2'>Start Date:</label>
				</div>
				<div className='col-12 col-md-8 col-lg-8'>
					<DatePicker
						date={startDateFilter}
						onChange={(date) => {
							dispatch(setStartDateFilter(date));
						}}
						dateFormat="MM/dd/yyyy"
					/>
				</div>
			</div>
		</div>
		<div className='col-12 col-md-2 col-lg-1'>
			<label htmlFor='year' className='form-label mt-2'>End Date: </label>
		</div>
		<div className='col-12 col-md-2 col-lg-3 mb-3'>
			<DatePicker
				date={endDateFilter}
				onChange={(date) => {
					const lastDayOfMonthDate = new Date(date.getFullYear(), date.getMonth()+1, 0);
					dispatch(setEndDateFilter(lastDayOfMonthDate));
				}}

				dateFormat="MM/dd/yyyy"
			/>
		</div>
		<div className='col-3 col-md-4 col-lg-1'>
			<label htmlFor='searchTable' className='form-label mt-2'>Search: </label>
		</div>
		<div className='col-9 col-md-4 col-lg-3 mb-4'>
			<InputSearch
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						statusChangesFilteredData !== null && dispatch(clearFilteredData(
							null
						));
						return;
					}

					dispatch(setDataFilter(
						statusChangesData.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</>
};

export default TableHeader;
