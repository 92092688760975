import React, { useState, useMemo, Fragment} from 'react'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { reducerName as baseHourReducerName } from '../../../Store/Reducers/baseHour';
import GenerateForm from '../../../Components/Forms/generate_form'
import { JobInfoFormsPartOne, JobInfoFormsPartTwo, disabledFieldsByMemberRole } from '../../../Config/Forms/MembersManagements/job_info'
import { reducerName as memberReducerName, updateMemberJobData } from '../../../Store/Reducers/member';
import ButtonsActions from '../../../Components/Forms/buttons_actions'
import Notification from '../../../Components/notification'
import { editForm } from '../../../Store/Reducers/memberForm';
import {reducerName as userReducerName} from './../../../Store/Reducers/user';
import {ROLES} from './../../../Router/Roles';

const JobInfo = ({ slug: formName }) => {
	const dispatch = useDispatch();
	const managementData = useSelector((state)=> state[memberReducerName].managementData);
	const updateJobDataRequest = useSelector((state)=> state[memberReducerName].updateJobDataRequest);
	const baseHourData = useSelector((state)=> state[baseHourReducerName].data);
	const roles = useSelector((state)=>state[userReducerName].data?.user?.roles || []).map((roleData)=> roleData.name);
	const [DataFormMembers, setFormMembers] = useState({})

	
	const dataInputDates = (name, value) => {
		var date = moment(value).format('L')
		var dateFormat = date.split('/')
		var dateFormatEnd = `${dateFormat[2]}-${dateFormat[0]}-${dateFormat[1]}`
		setFormMembers({
				...DataFormMembers,
				[name] : dateFormatEnd
		});
		dispatch(editForm({
			formName: formName,
			fieldValue: dateFormatEnd,
			fieldName: name
		}));
	};

	const handleOnChangeForm = (event) => {
		setFormMembers({
				...DataFormMembers,
				[event.target.name] : event.target.value
		})

		dispatch(editForm({
			formName: formName,
			fieldValue: event.target.value,
			fieldName: event.target.name
		}));

		dispatch(editForm({
			formName: formName,
			fieldName: 'error',
			fieldValue: !!document.querySelectorAll(`#${formName} input.is-invalid`).length
		}));
	};

	const handleJobInfoDataMemberManagementSubmit = (e) => {
			
		e.preventDefault();

		if(document.querySelectorAll(`#${formName} input.is-invalid`).length) {
			document.querySelector(`#${formName} input.is-invalid`).focus();
			return;
		}
		
		const elements = e.target.elements;
		const memberData ={};
		const fields = [];

		JobInfoFormsPartOne.forEach((formMember)=> formMember.forms?.forEach((field)=> fields.push(field)))
		JobInfoFormsPartTwo.forEach((formMember)=> formMember.forms?.forEach((field)=> fields.push(field)))
		
		for (let i = 0; i < elements.length; i++) {

				const { name, value } = elements.item(i);

				if(!name.length) {
          continue;
        }
				
				const fieldConfiguration = fields.find((field)=> field?.name === name);

				if(fieldConfiguration?.type === "date") {
					const dateValue = value.split("/");
					if(dateValue.length >= 2) {
						memberData[name] = `${dateValue[2]}-${dateValue[0]}-${dateValue[1]}`;
					}
				} else {

					if(fieldConfiguration?.required && !value) {
						elements.item(i).parentElement?.childNodes?.[2]?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]?.focus();
						return;
					}
					
					memberData[name] = value;
				}
		}

		memberData.certificates = DataFormMembers.certificates;

		dispatch(updateMemberJobData({
			id: managementData.member.id, data: memberData
		}));

	}

	const calculations = useMemo(()=>{

		const baseHourId = DataFormMembers.base_hour_id || managementData.job?.base_hour_id;
		const selectedBaseHour = baseHourData?.find((opt)=> opt.id === baseHourId);
		
		if(!selectedBaseHour) {
			return ;
		}

		const hourlyRate = DataFormMembers.hourly_rate || managementData.job.hourly_rate;
		let hourly = (selectedBaseHour.name * 52 ) * hourlyRate;
		let holiday = 115.2 * hourlyRate;
		let total = hourly + holiday;


		hourly = "$" + hourly.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
		holiday = "$" + holiday.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
		total = "$" + total.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
		
		return {
			hourly_income: hourly,
			holiday_income: holiday,
			total_income: total
		}

	}, [DataFormMembers, baseHourData, managementData]);

	return (
		<>
			<form id={formName} onSubmit={handleJobInfoDataMemberManagementSubmit}>
			<div className="row">
				<div className="col-sm-6 col-12">
					<div className="row">
						{
							JobInfoFormsPartOne.map((section, i) =>
								<Fragment  key={i}>
								<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
								<span className="subtitleLabelSectionForms mb-2">{section.subtitle}</span>
								{
									section.forms.map((field)=>({
										...field,
										disabled: (!managementData || !Object.keys(managementData).length) || field.disabled  || (!roles.includes(ROLES.developer) && !roles.includes(ROLES.administrator) && roles.includes(ROLES.member) && disabledFieldsByMemberRole.includes(field.name)),
										defaultValue: typeof field?.getDefaultValue === 'function' ?
											field.getDefaultValue(managementData, calculations) :
											typeof field.component === 'string' ?
												Array.isArray(managementData?.[field.component]) ?
													managementData[field.component][managementData[field.component].length - 1]?.[field.componentKey || field.name] :
													managementData?.[field.component]?.[field.componentKey || field.name] :
													managementData?.[field.name]
									})).map((form, i) => <GenerateForm
									key={i}
									{...form}
									dataInputDates={dataInputDates}
									onChange={handleOnChangeForm}
								/> )
								}
								</Fragment>					
							)
						}
					</div>
				</div>
				<div className="col-sm-6 col-12">
					<div className="row" style={{paddingTop: "32px"}}>
						{
							JobInfoFormsPartTwo.map((form, i) =>
								<Fragment key={i}>
								<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{form.label}</label>
								{
									form.forms.map((field)=>({
										...field,
										disabled: (!managementData || !Object.keys(managementData).length) || field.disabled  || (!roles.includes(ROLES.developer) && !roles.includes(ROLES.administrator) && roles.includes(ROLES.member) && disabledFieldsByMemberRole.includes(field.name)),
										defaultValue: typeof field?.getDefaultValue === 'function' ?
											field.getDefaultValue(managementData, calculations) :
											typeof field.component === 'string' ?
												Array.isArray(managementData?.[field.component]) ?
													managementData[field.component][managementData[field.component].length - 1]?.[field.componentKey || field.name] :
													managementData?.[field.component]?.[field.componentKey || field.name] :
													managementData?.[field.name]
									})).map((form, i) => <GenerateForm
									key={i}
									{...form}
									dataInputDates={dataInputDates}
									onChange={handleOnChangeForm}
								/> )
								}
								</Fragment>					
							)
						}
					</div>
				</div>
			</div>
			<Notification />
			<ButtonsActions disabled={updateJobDataRequest || !managementData || !Object.keys(managementData).length || document.querySelectorAll(`#${formName} input.is-invalid`).length} UpdateAll={()=>{}} UpdateSection={()=>{}}/>
			</form>
		</>
	)
}

export default JobInfo