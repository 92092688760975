import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setDataFilter,
	clearFilteredData,
	reducerName as memberDisabilityReducerName,
	setStartDateFilter,
	setEndDateFilter
} from '../../../Store/Reducers/memberDisability';
import InputSearch from '../../../Components/Forms/input_search';
import DatePicker from '../../../Components/Forms/datepicker';

const TableHeader = () => {
	const dispatch = useDispatch();

	const memberDisabilityfilteredData = useSelector((state)=> state[memberDisabilityReducerName].filteredData);
	const memberDisabilityData = useSelector((state)=> state[memberDisabilityReducerName].data || []);
	const startDateFilter = useSelector((state)=> state[memberDisabilityReducerName].startDateFilter);
	const endDateFilter = useSelector((state)=> state[memberDisabilityReducerName].endDateFilter);

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();

		return data?.member?.first_name.toLowerCase().includes(searchTerm) ||
			data?.member?.last_name.toLowerCase().includes(searchTerm) ||
			data?.disability?.name?.toLowerCase().includes(searchTerm) ||
			data?.member?.suffix.toLowerCase().includes(searchTerm)
	}


	return <>
		<div className='col-12 col-md-4' style={{padding: 0, margin: 0}}>
			<div className='row' style={{padding: 0, margin: 0}}>
				<div className='col-12 col-md-4 col-lg-4'>
					<label htmlFor='year' className='form-label mt-2'>Start Date:</label>
				</div>
				<div className='col-12 col-md-8 col-lg-8'>
					<DatePicker
						date={new Date(startDateFilter)}
						onChange={(date) => {
							dispatch(setStartDateFilter(date.toISOString()));
						}}
						dateFormat="MM/dd/yyyy"
					/>
				</div>
			</div>
		</div>
		<div className='col-12 col-md-4 col-lg-1'>
			<label htmlFor='end' className='form-label mt-2'>End Date: </label>
		</div>
		<div className='col-12 col-md-4 col-lg-3 mb-3'>
			<DatePicker
				date={new Date(endDateFilter)}
				onChange={(date) => {
					dispatch(setEndDateFilter(date.toISOString()));
				}}
				dateFormat="MM/dd/yyyy"
			/>
		</div>
		<div className='col-3 col-md-4 col-lg-1'>
			<label htmlFor='searchTable' className='form-label mt-2'>Search: </label>
		</div>
		<div className='col-9 col-md-4 col-lg-3 mb-5'>
			<InputSearch
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						memberDisabilityfilteredData !== null && dispatch(clearFilteredData(
							null
						));
						return;
					}

					dispatch(setDataFilter(
						memberDisabilityData.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</>
}



export default TableHeader