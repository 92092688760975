import { NUMBER, DECIMAL } from './../../../Components/Forms/constant_form';
import Dollar from './../../../Assets/Img/Icons/dollar.svg'

export const InsuranceFormPartOne = [
	{
		label: "",
		forms: [
			{
				label: 'Member',
				placeholder: 'Select a Member',
				type: 'memberSearch',
				col: 'col-12 col-md-6',
				required: true,
				name: 'insurance_member'
			},
			{
				label: 'Insurance Company',
				placeholder: 'Select a Insurance Company',
				type: 'select',
				col: 'col-12 col-md-6',
				required: true,
				validate: {
					type: NUMBER
				},				
				name: 'insurance_company_id',
				options: [],
				reduxInfo: {
					reducer: 'insuranceCompany',
					key: 'data'
				}
			},
			{
				label: 'Insurance Policies',
				placeholder: 'Select a Insurance Policies',
				type: 'select',
				col: 'col-12 col-md-6',
				required: true,
				validate: {
					type: NUMBER
				},
				name: 'policy_id',
				options: [],
				reduxInfo: {
					reducer: 'policy',
					key: 'dataPolicyByCompany'
				}
			},
			{
				label: 'Date Added',
				placeholder: 'Date Added',
				type: 'date',
				col: 'col-12 col-md-6',
				required: true,
				name: 'join_date'
			},
			{
				label: 'Cost',
				icon: <img className="icon pa-2" alt="login icon" src={Dollar} />,
				type: 'number',
				col: 'col-12 col-md-6',
				required: true,
				validate: {
					type: DECIMAL
				},				
				name: 'cost',
				options: []
			},
			{
				label: 'Reason',
				placeholder: 'Select a Reason',
				type: 'select',
				col: 'col-12 col-md-6',
				required: true,
				validate: {
					type: NUMBER
				},
				name: 'reason_id',
				options: [],
				reduxInfo: {
					reducer: 'reason',
					key: 'data'
				}
			}
		]
	}
];
