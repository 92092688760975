import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ArrowLeft from '../Assets/Img/Icons/arrow-paginate-left.svg';
import ArrowRight from '../Assets/Img/Icons/arrow-paginate-right.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const TableComponent = ({ columns, data  = [], header: Header, pagination = true, paginationSize = 5 }) => {
	
	const defaultPaginationSize = pagination === false ? 999 : paginationSize;
	const [sortColumn, setSortColumn] = useState();
	const [sortDirection, setSortDirection] = useState(1);
	const state = useSelector((state) => state);
	const [paginatedData, setPaginatedData] = useState(data?.slice(0, defaultPaginationSize ));
	const [page, setPage] = useState(0);

	useEffect(() => {
		let pageNumber = page;
		if(data?.length < defaultPaginationSize * page) {
			pageNumber = 0;
			setPage(0)
		}
		setPaginatedData(data?.slice()?.sort((a,b)=>{

			if(!sortColumn) {
				return 0
			}

			const colData = columns.find((colData)=> colData.value === sortColumn);

			const colAValue = colData.parseValue ? colData.parseValue(a[sortColumn]) : a[sortColumn];
			const colBValue = colData.parseValue ? colData.parseValue(b[sortColumn]) : b[sortColumn];

			const textA = colAValue?.toString()?.toUpperCase();
			const textB = colBValue?.toString()?.toUpperCase();
			
			if(sortDirection) {
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
			}else{
				return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
			}

		}).slice(defaultPaginationSize * pageNumber, (defaultPaginationSize * pageNumber) + defaultPaginationSize) || []);
	}, [data, page, defaultPaginationSize, sortColumn, sortDirection, columns]);

	// Validate config properties
	if(!Array.isArray(columns)) {
		return <>Empty data</>;
	}

	const handlePreviousPage = () => page > 0 && setPage(page - 1);
	const handleNextPage = () => page * defaultPaginationSize < (data?.length - defaultPaginationSize) && setPage(page + 1);

	const gridData = paginatedData;

	const handlePaginate = (colData) => {
		setSortDirection(colData.value===sortColumn && !sortDirection);
		setSortColumn(colData.value);
	};

	return (
		<div className="row mb-3">
			{ Header && <Header /> }
			<div className="col-12 table-responsive">
			<table className="table">
				<thead>
					<tr>
						{columns.map((colData, index) => {
								return <th key={index} scope='col'>{colData.title} <button hidden={!colData.sort} onClick={() => handlePaginate(colData)} className='font-button'>
									{ sortDirection || colData.value !== sortColumn ?
									 <FontAwesomeIcon color={colData.value === sortColumn ? 'red' : '#cccccc'} icon={faSortUp} />:
									 <FontAwesomeIcon color={colData.value === sortColumn ? 'red' : '#cccccc'} icon={faSortDown} /> 
									}
								</button></th>
							})}
					</tr>
				</thead>
				<tbody>
					{gridData.map((paginateData, index) => {
						return <tr key={index} className="contentDataListTable">
							{columns.map((colData, index) => {
							return <td key={index} className={`td-space-table ${colData.className}`}>
									{
										typeof colData.getLabelFromStore === 'function' ?
										colData.getLabelFromStore(state, data.indexOf(paginateData)) :
										typeof colData.parseValue === 'function' ? colData.parseValue(paginateData[colData.value]) :
										paginateData[colData.value]
									}
							</td>
						})}
						</tr>
					})}
				</tbody>
			</table>
			</div>
			{data?.length === 0 ? <div className='col col-12'><center className='text-number-elements-table'>No data available in table</center></div>: null}
			{ pagination ? <div className="col-6 col-md-6 text-center mt-3">
				<span className="text-number-elements-table" style={{top: '9px', position: 'relative'}}>{`Showing ${gridData.length} of ${data?.length} entries`}</span>
			</div> : null }
			{ pagination ? <div className="col-6 text-center col-md-6 mt-3">
				<nav aria-label="Page navigation">
					<ul className="pagination">
						<li onClick={handlePreviousPage} className="page-item text-pagination"><button className={`page-link btn-pagination ${page <= 0 ? 'disabled' : ''}`} ><img className="icon-arrow-pagination pa-2 me-2 left" alt="Arrow Left" src={ArrowLeft} />Previous</button></li>
						<li onClick={handleNextPage} className="page-item text-pagination"><button className={`page-link btn-pagination ${page * defaultPaginationSize >= (data?.length - defaultPaginationSize) ? 'disabled' : ''}`} >Next<img className="icon-arrow-pagination next pa-2 ms-2" alt="Arrow Right" src={ArrowRight} /></button></li>
					</ul>
				</nav>
			</div> : null }
		</div>
	)
}

export default TableComponent