export const loginFormConfig = [
	{
		forms: [
			{
				label: 'Username',
				placeholder: 'Type your username',
				type: 'email',
				col: 'col-12',
				required: true,
				validate: {
					type: 'Email',
					message: 'Must be a valid email'
				},
				name: 'email',
				options: [],
				addon: <button className='login-help-link'>I Forgot my username</button>
			},
			{
				label: 'Password',
				validate: {},
				placeholder: 'Type your password',
				type: 'password',
				col: 'col-12',
				required: true,	
				name: 'password',
				options: [],
				addon: <button className='login-help-link'>I Forgot my password</button>
			}		

		]

	}

];

export const getRecaptchaConfig = () => ({
	key: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY
});
