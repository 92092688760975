export const filterFields = [
	{
		label: '',
		forms: [
			{
				label: '',
				placeholder: 'All Companies',
				type: 'select',
				col: 'col-sm-3 col-10 mobile-col-offset-1',
				required: false,
				validate: {},				
				name: 'company_id',
				options: [],
				reduxInfo: {
					reducer: 'insuranceCompany',
					key: 'data'
				}
			},
			{
				label: '',
				placeholder: 'All Policies',
				type: 'select',
				col: 'col-sm-3 col-6',
				required: false,
				validate: {},
				name: 'policy_id',
				options: [],
				reduxInfo: {
					reducer: 'policy',
					key: 'dataPolicyByCompany'
				}
			},
			{
				label: '',
				placeholder: 'Choose Date',
				type: 'date',
				isClearable: true,
				col: 'col-sm-2 col-6 ut-dropdown',
				required: false,
				validate: {},
				name: 'start_date'
			},
			{
				label: '',
				placeholder: 'Choose Date',
				type: 'date',
				isClearable: true,
				col: 'col-sm-2 col-6',
				required: false,
				validate: {},
				name: 'end_date',
			},
			{
				label: '',
				placeholder: 'All Months',
				isClearable: true,
				type: 'select',
				col: 'col-sm-2 col-6',
				required: false,
				validate: {},
				name: 'month',
				options: [
					{
						value: '01',
						id: '01',
						label: 'January'
					},
					{
						value: '02',
						id: '02',
						label: 'February'
					},
					{
						value: '03',
						id: '03',
						label: 'March'
					},
					{
						value: '04',
						id: '04',
						label: 'April'
					},
					{
						value: '05',
						id: '05',
						label: 'May'
					},
					{
						value: '06',
						id: '06',
						label: 'June'
					},
					{
						value: '07',
						id: '07',
						label: 'July'
					},
					{
						value: '08',
						id: '08',
						label: 'August'
					},
					{
						value: '09',
						id: '09',
						label: 'September'
					},
					{
						value: '10',
						id: '10',
						label: 'October'
					},
					{
						value: '11',
						id: '11',
						label: 'November'
					},
					{
						value: '12',
						id: '12',
						label: 'December'
					},
				]
			}
		]

	}
];
