
export const WebFormsPartOne = [
	{
		label: "",
		forms: [
			{
				label: 'Associated Username (Email):',
				type: 'email',
				placeholder: 'Email',
				col: 'col-sm-12 col-12',
				required: true,
				disabled: true,
				component: 'user',
				validate: {
					type: 'Email'
				},				
				name: 'email'
			}
		]

	}
]


export const WebFormsPartTwo = [
	{
		label: "",
		forms: [
			{
				label: 'Last Login:',
				type: 'text',
				placeholder: 'Last Login',
				col: 'col-sm-12 col-12',
				disabled: true,
				component: 'user',
				validate: {
					type: 'String'
				},				
				name: 'last_login',
				options: []
			}

		]

	}
]

