import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomReportService } from './../../Services/customReportService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'customReport';

const initialState = {

  ExcelReportError: null,
  ExcelReportRequest: false,
  ExcelReportData: null
};


export const getCustomReport = createAsyncThunk(
  'customReport/getCustomReport',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getCustomReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const customReportReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {


    builder.addCase(getCustomReport.fulfilled, (state, action) => {
      state.ExcelReportData = action.payload?.data;
      state.ExcelReportRequest = false;
    })

    builder.addCase(getCustomReport.rejected, (state, action) => {
      state.ExcelReportError = action.payload.error;
      state.ExcelReportRequest = false;
      state.ExcelReportData = null;
    })

    builder.addCase(getCustomReport.pending, (state) => {
      state.ExcelReportRequest = true;
      state.ExcelReportError = null;
      state.ExcelReportData = null;
    })
  }
});

export default customReportReducer.reducer;
