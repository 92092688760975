import React, { useState, useEffect } from 'react';
import LayoutHeaderPage from './../../../../Layouts/header_page';
import { useSelector, useDispatch } from 'react-redux';
import {
	getGeneralSetting,
	reducerName as generalSettingReducerName,
	setEditData
} from './../../../../Store/Reducers/generalSetting';
import TableComponent from './../../../../Components/table_v2';
import ModalComponent from './../../../../Components/modal';
import EditIcon from  './../../../../Assets/Img/Icons/edit.jpg';
import Notification from './../../../../Components/notification';
import TableHeader from './table_header';
import Form from './form';

const GeneralSettings = () => {

	const dispatch = useDispatch();
	const generalSettingData = useSelector((state)=> state[generalSettingReducerName].data || []);
	const generalSettingDataFiltered = useSelector((state)=> state[generalSettingReducerName].dataFiltered);
	const gridData = generalSettingDataFiltered || generalSettingData;
	
	const tableColumns = [
		{
			title: 'Name',
			sort: true,
      value: 'name',
		},
		{
			title: 'Cost',
      value: 'amount',
			parseValue: (val)=> {
				if(!val && val !==0){
					return ''
				}

				return `$${val}`;
			}
		},
		{
			title: 'Actions',
      value: 'actions',
			className: 'td-space-table-three-buttons',
			getLabelFromStore: (storeData, index) => {
				return <>
					<button 
						className='btn-icon'
						onClick={()=> {
							setModalDescription('');
							setModalTitle('Edit General Setting');
							setModalBody(<div className='row modal-footer' style={{width: '500px', maxWidth: '100%'}}>
								<div className='col col-12'><Form index={index} onCancel={()=> closeModal()} onSubmit={()=>{
										closeModal();
									}} /></div>
							</div>);
							dispatch(setEditData(gridData[index]));
							openModal();
						}
					} disabled={false}>
						<img src={EditIcon} alt='Edit'/> Edit
					</button>
				</>
			}
		}
	];

	useEffect(() => {
		dispatch(getGeneralSetting())
	}, [dispatch]);


	const [modalIsOpen, setIsOpen] = useState(false);
	const [modalDescription, setModalDescription] = useState(null);
	const [modalTitle, setModalTitle] = useState(null);
	const [modalBody, setModalBody] = React.useState('');

	const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };


	return (
		<>
			<LayoutHeaderPage title="General Settings"/>
			<div className="tab-content container mt-4" id="pills-tabContent">
				<TableComponent
					columns={tableColumns}
					data={generalSettingDataFiltered || generalSettingData}
					header={TableHeader}
				/>
				<Notification />
				<div className='row mt-5 mb-3'>
					<div className='col-12 col-lg-9'></div>
					<div className='col-12 col-lg-3'>
					</div>
				</div>
			</div>
			<ModalComponent
				modalIsOpen={modalIsOpen}
				description={modalDescription}
				modalBody={modalBody}
				title={modalTitle}
				closeModal={closeModal}
				confirmModal={()=>{
					closeModal();
				}}
			/>
		</>
	)
}

export default GeneralSettings;
