import React, { useState, useEffect } from 'react'

const Checkbox = (props) => {
	const isCheckedProp = props.option.isChecked;
	const [isChecked, setIsChecked] = useState(!!isCheckedProp)

	useEffect(()=> {
		setIsChecked(!!isCheckedProp);
	}, [isCheckedProp]);

	return (
		<>
			<input
				key={isChecked}
				checked={isChecked}
				value={!isChecked}
				type={props.option.type}
				placeholder={props.option.placeholder}
				onBlur={props.ValidField}
				className={`form-check-input-checkbox ${props.classError} ${props.option.class} ${props.option.lineBreak ? 'mt-3' : ''}`}
				required={props.option.required}
				name={props.option.name}
				id={`Input${props.name}`}
				aria-describedby="emailHelp"
				onChange={props.onChange} onClick={() => setIsChecked(!isChecked)}/>
		</>
	)
}

export default Checkbox