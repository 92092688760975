import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { reducerName as memberReducerName } from '../../../Store/Reducers/member';
import { reducerName as beneficiaryReducerName, addBeneficiaryByMemberId } from '../../../Store/Reducers/beneficiary';
import ButtonsActions from '../../../Components/Forms/buttons_actions'
import BeneficiaryForm from '../../../Components/Forms/beneficiary'
import Notification from '../../../Components/notification'
import { editForm, reducerName as memberReducerFormName } from '../../../Store/Reducers/memberForm';

const distributionValues = [
	'primary',
	'secundary',
	'tertiary'
];

const Personal = ({ slug: formName }) => {
	
	const dispatch = useDispatch();
	const managementData = useSelector((state)=> state[memberReducerName].managementData);
	const beneficiaryDataRequest = useSelector((state)=> state[beneficiaryReducerName].addByMemberIdRequest);
	const formData = useSelector((state)=> state[memberReducerFormName].form);

	const [showPercentError, setShowPercentError] = useState(false);

	const percentageUsed = useMemo(()=> {
		let porcentUsed = 0;
		document.querySelectorAll('div.percentage input').forEach((el) =>{
			porcentUsed += Number(el.value) || 0
		});

		return porcentUsed;
	}, [formData]);

	const validateForm = (onlyGetData = false) => {
		const beneficiaryLastNames = document.querySelectorAll(`#${formName} input[name=last_name]`);
		const beneficiaryFirstNames = document.querySelectorAll(`#${formName} input[name=first_name]`);
		const beneficiaryPercents = document.querySelectorAll(`#${formName} input[name=percent]`);
		const params = [];
		let error = false;

		for (let index = 0; index < 3; index++) {
			const lastName = beneficiaryLastNames[index].value.trim();
			const firstName = beneficiaryFirstNames[index].value.trim();
			const percent = beneficiaryPercents[index].value.trim();
			const distribution = distributionValues[index];

			if(lastName || firstName || percent) {

				if(!lastName) {
					error = true;
					!onlyGetData && (beneficiaryLastNames[index].classList += ' is-invalid');
					!onlyGetData && beneficiaryLastNames[index].focus();
					break;
				} else {
					!onlyGetData && (beneficiaryLastNames[index].classList.remove('is-invalid'));
				}

				if(!firstName) {
					error = true;
					!onlyGetData && (beneficiaryFirstNames[index].classList += ' is-invalid');
					!onlyGetData && beneficiaryFirstNames[index].focus();
					break;
				} else {
					!onlyGetData && (beneficiaryFirstNames[index].classList.remove('is-invalid'));
				}

				if(!percent) {
					error = true;
					!onlyGetData && (beneficiaryPercents[index].classList += ' is-invalid');
					!onlyGetData && beneficiaryPercents[index].focus();
					break;
				} else {
					!onlyGetData && (beneficiaryPercents[index].classList.remove('is-invalid'));
				}

				!error && params.push({
					first_name: firstName,
					last_name: lastName,
					percent,
					distribution
				})
			}
		}

		const finalPercent = params.reduce((accumulator, object) => {
			return accumulator + Number(object.percent);
		}, 0);

		const percentIsValid = finalPercent === 100;

		setShowPercentError(!percentIsValid);
		error = error || !percentIsValid;

		return {
			error,
			params
		}
	}

	const handleFormPersonalMemberManagementSubmit = (e) => {
			
		e.preventDefault();

		if(percentageUsed !== 100) {
			setShowPercentError(true);
			return;
		}

		const formData = validateForm();

		if(formData.error) {

			return;
		}

		dispatch(addBeneficiaryByMemberId({
			id: managementData.member.id, 
			data: formData.params
		}));
	}

	return (
		<>
			<form id={formName} onSubmit={handleFormPersonalMemberManagementSubmit}>
			<BeneficiaryForm onChange={(event)=> {
				setShowPercentError(false);
				document.querySelectorAll(`#${formName} input.is-invalid`).forEach((input) => {
						input.classList.remove('is-invalid');
				});
				const formData = validateForm(true);

				dispatch(editForm({
					formName: formName,
					fieldName: 'params',
					fieldValue: formData.params
				}));

				dispatch(editForm({
					formName: formName,
					fieldName: 'error',
					fieldValue: formData.error
				}));
			}} />
			{ (showPercentError && percentageUsed !== 100) ? <div className="col-12 center pt-1">
							{ <span className="login-danger text-danger">Beneficiary percent value must be 100 finally</span> }
					</div> : null}
			<Notification />
			<ButtonsActions disabled={beneficiaryDataRequest || !managementData || !Object.keys(managementData).length || document.querySelectorAll(`#${formName} input.is-invalid`).length || ( showPercentError && (percentageUsed > 100 || percentageUsed < 0))} UpdateAll={()=>{}} UpdateSection={()=>{}}/>
			</form>
		</>
	)
}

export default Personal