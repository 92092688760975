import React, { useState, useEffect, useRef, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getInsuranceCompany } from './../../../Store/Reducers/insuranceCompany'
import { getMember, addMemberPolicy, reducerName as memberReducerName } from './../../../Store/Reducers/member'
import { getPolicyByCompany } from './../../../Store/Reducers/policy'
import { getReason } from './../../../Store/Reducers/reason'
import LayoutHeaderPage from '../../../Layouts/header_page'
import GenerateForm from '../../../Components/Forms/generate_form'
import Notification from '../../../Components/notification'
import { InsuranceFormPartOne } from '../../../Config/Forms/Insurance'
import { Link } from 'react-router-dom';
const formName = 'addMemberPolicyForm';

const MembersManagements = () => {
	const dispatch = useDispatch();
	const searchSelectControl = useRef();
	const [dataForm, setDataForm] = useState({})
	const addMemberPolicyData = useSelector((state)=> state[memberReducerName].addMemberPolicy);
	const managementData = useSelector((state)=> state[memberReducerName].managementData);

	useEffect(()=>{
		if(!addMemberPolicyData || !Object.keys(addMemberPolicyData)?.length){
			return
		}
		setDataForm({});
		searchSelectControl.current?.clearValue();
		document.getElementById(formName).reset();
	}, [addMemberPolicyData]);

	useEffect(() => {
		dispatch(getInsuranceCompany());
		dispatch(getMember());
		dispatch(getReason());
	}, [dispatch]);

	const dataInputDates = (name, value) => {
		const date = moment(value).format('L')
		const dateFormat = date.split('/')
		const dateFormatEnd = `${dateFormat[2]}-${dateFormat[0]}-${dateFormat[1]}`
		
		setDataForm({
				...dataForm,
				[name]: dateFormatEnd
		});
	};

	const handleInputChange = (event) => {
		if(event.target.name === 'insurance_company_id') {
			!!event.target.value && dispatch(getPolicyByCompany(event.target.value));
		}
		
		setDataForm({
				...dataForm,
				[event.target.name]: event.target.value
		})
	};

	const sendDataApi = () => {
		dispatch(addMemberPolicy({
			memberId: managementData.member.id,
			data: dataForm
		}));
	}

	return (
		<>
			<LayoutHeaderPage title="Add Insurance for Member"/>
			<div className="tab-content mt-3" id="pills-tabContent">
				<form id={formName} name={formName}>
					<div className="row">
						<div className="col-12">
							<div className="row">
								{
									InsuranceFormPartOne.map((section, i) =>
										<Fragment key={i}>
										<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
										{
											section.forms.map((fieldData)=>({
												...fieldData,
												defaultValue: dataForm[fieldData.name],
												...(fieldData.name === 'insurance_member' && { refVar: searchSelectControl })
											})).map((form, i) => <GenerateForm
												key={i}
												{...form}
												dataInputDates={dataInputDates}
												onChange={handleInputChange}/> )
										}
										</Fragment>					
									)
								}
							</div>
						</div>
					</div>
				</form>
				<Notification />
				<div className="row pb-3 pt-3">
					<div className="col-12 col-md-3 hide-on-mobile"></div>
					<div className="col-12 col-md-3 hide-on-mobile"></div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className="col-8 col-md-3 mb-3">
						<button type="button" disabled={!managementData?.member?.id || Object.keys(dataForm).length !== 5} className="btn-orange btn btn-default btn-primary" onClick={sendDataApi}>Add</button>
					</div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className="col-8 col-md-3 mb-3">
						<Link to="/dashboard/" className="btn-light-grey btn btn-default btn-primary">Close</Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default MembersManagements