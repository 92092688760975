import React, { useState, useEffect } from 'react';
import LayoutHeaderPage from '../../../Layouts/header_page';
import { useSelector, useDispatch } from 'react-redux';
import {
	getMemberPoliciesByCancellationDate,
	reducerName as memberInsuranceReducerName,
	destroyPolicyInsuranceById,
	getAllMemberPDFPolicy,
	getAllMemberExcelPolicy
} from '../../../Store/Reducers/memberInsurance';
import TableComponent from '../../../Components/table_v2';
import ModalComponent from './../../../Components/modal';
import DeleteIcon from './../../../Assets/Img/Icons/delete.jpg';
import InformationIcon from  './../../../Assets/Img/Icons/information.png';
import moment from 'moment';
import Notification from './../../../Components/notification';
import TableHeader from './cancellation_data_table_header';

const CancellationData = () => {

	const dispatch = useDispatch();
	const policiesByCancellationDate = useSelector((state)=> state[memberInsuranceReducerName].memberPoliciesByCancellationDateData || []);
	const filteredCancellationDataTable = useSelector((state)=> state[memberInsuranceReducerName].filteredCancellationDataTable);
	const destroyPolicyData = useSelector((state)=> state[memberInsuranceReducerName].destroyPolicyData);
	const dateFilterCancellationTable = useSelector((state)=> state[memberInsuranceReducerName].dateFilterCancellationTable);
	
	const tableColumns = [
		{
			title: 'First Name',
      value: 'first_name',
			sort: true,
		},
		{
			title: 'Middle Name',
      value: 'middle_name',
		},
		{
			title: 'Last Name',
      value: 'last_name',
		},
		{
			title: 'Suffix',
      value: 'suffix'
		},
		{
			title: 'Company',
      value: 'insurance_company'
		},
		{
			title: 'Insurance Policy',
      value: 'policy'
		},
		{
			title: 'Cancellation',
      value: 'cancellation_date',
			parseValue: (value) => {
				var date = moment(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		},
		{
			title: 'Reason',
      value: 'reason'
		},
		{
			title: 'Actions',
      value: 'actions',
			className: 'td-space-table-three-buttons',
			getLabelFromStore: (storeData, index) => {
				
				setIndex(index);

				return <>
					<button 
						className='btn-icon'
						onClick={()=> {
							openModal();
							setModalTitle(null);
							setDeleteButton(true);
							setModalDescription(`Are you sure that you want to permanently delete the Member Insurance: ${policiesByCancellationDate?.[index]?.policy}`);
							setIndex(index);
						}
					} disabled={false}>
						<img src={DeleteIcon} alt='Delete'/>Delete
					</button>
					<button 
						className='btn-icon'
						onClick={()=> {
							openModal();
							setDeleteButton(false);
							setModalTitle('Description:');
							setIndex(index);
							setModalDescription(policiesByCancellationDate?.[index]?.description);
						}
					} disabled={false}>
						<img src={InformationIcon} alt='Description'/> Description
					</button>
				</>
			}
		}
	];

	useEffect(()=> {

		if(!destroyPolicyData) {
			return 
		}

		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = (`0${currentDate.getMonth() + 1}`).slice(-2);

		dispatch(getMemberPoliciesByCancellationDate({
			month: month,
			year: year
		}))

	}, [destroyPolicyData, dispatch]);

	useEffect(() => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = (`0${currentDate.getMonth() + 1}`).slice(-2);

		dispatch(getMemberPoliciesByCancellationDate({
			month: month,
			year: year
		}))
	}, [dispatch]);


	const [modalIsOpen, setIsOpen] = useState(false);
	const [modalDescription, setModalDescription] = useState(null);
	const [modalTitle, setModalTitle] = useState(null);
	const [deleteButton, setDeleteButton] = useState(true);
	const [index, setIndex] = useState(null);

	const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

	const handleMemberPolicyPDF = () => {
		const currentDate = dateFilterCancellationTable ? dateFilterCancellationTable : new Date();
		const year = currentDate.getFullYear();
		const month = (`0${currentDate.getMonth() + 1}`).slice(-2);

		dispatch(getAllMemberPDFPolicy({
			paramteres: {
				month: month,
				year: year
			}
		}))
	}

	const handleMemberPolicyExcel = () => {
		const currentDate = dateFilterCancellationTable ? dateFilterCancellationTable : new Date();
		const year = currentDate.getFullYear();
		const month = (`0${currentDate.getMonth() + 1}`).slice(-2);

	  dispatch(getAllMemberExcelPolicy({
			month: month,
			year: year
		}))
	}

	return (
		<>
			<LayoutHeaderPage title="Insurance Cancellation Data"/>
			<div className="tab-content container mt-2 pt-1" id="pills-tabContent">
				<TableComponent
					columns={tableColumns}
					data={filteredCancellationDataTable || policiesByCancellationDate}
					header={TableHeader}
				/>
				<div className='row mb-3 mt-3'>
					<div className='col-8 mobile-col-offset-2 col-lg-3 mb-3'>
						<button type='button' disabled={false} onClick={handleMemberPolicyPDF} className='btn-blue btn btn-default btn-primary'>View PDF</button>
					</div>
					<div className='col-8 mobile-col-offset-2 col-lg-3 mb-3'>
						<button type='button' disabled={false} onClick={handleMemberPolicyExcel} className='btn-orange btn btn-default btn-primary'>Export Excel</button>
					</div>
				</div>
			</div>
			<Notification />
			<ModalComponent
				modalIsOpen={modalIsOpen}
				description={modalDescription}
				title={modalTitle}
				closeModal={closeModal}
				deleteButton={deleteButton}
				confirmModal={()=>{
					dispatch(destroyPolicyInsuranceById({
						policyId: policiesByCancellationDate?.[index]?.id
					}))
					closeModal();
				}}
			/>
		</>
	)
}

export default CancellationData