import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setDataFilter,
	clearFilteredData,
	reducerName as logReducerName,
	setStartDateFilter,
	setEndDateFilter
} from '../../../Store/Reducers/log';
import InputSearch from './../../../Components/Forms/input_search';
import DatePicker from './../../../Components/Forms/datepicker';

const TableHeader = () => {
	const dispatch = useDispatch();

	const logFilteredData = useSelector((state)=> state[logReducerName].logFilteredData);
	const logData = useSelector((state)=> state[logReducerName].logData || []);
	const startDateFilter = useSelector((state)=> new Date( state[logReducerName].startDateFilter ));
	const endDateFilter = useSelector((state)=> new Date( state[logReducerName].endDateFilter ));

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();

		return data?.member?.first_name?.toLowerCase().includes(searchTerm) ||
			data?.member?.last_name?.toLowerCase().includes(searchTerm) ||
			data?.member?.middle_name?.toLowerCase().includes(searchTerm) ||
			data?.description?.toLowerCase().includes(searchTerm) ||
			data?.member?.suffix?.toLowerCase().includes(searchTerm)
	}

	return <>
		<div className='col-10 mobile-col-offset-1 col-md-4'>
			<div className='row' style={{padding: 0, margin: 0}}>
				<div className='col-12 col-md-4 col-lg-4'>
					<label htmlFor='year' className='form-label mt-2'>Start Date:</label>
				</div>
				<div className='col-12 col-md-8 col-lg-8'>
					<DatePicker
						date={startDateFilter}
						onChange={(date) => {
							dispatch(setStartDateFilter(date.toISOString()));
						}}
						dateFormat="MM/dd/yyyy"
					/>
				</div>
			</div>
		</div>
		<div className='col-10 mobile-col-offset-1 col-md-4'>
			<div className='row' style={{padding: 0, margin: 0}}>
				<div className='col-12 col-md-4 col-lg-4'>
					<label htmlFor='year' className='form-label mt-2'>End Date:</label>
				</div>
				<div className='col-12 col-md-8 col-lg-8'>
					<DatePicker
						date={endDateFilter}
						onChange={(date) => {
							dispatch(setEndDateFilter(date.toISOString()));
						}}
						dateFormat="MM/dd/yyyy"
					/>
				</div>
			</div>
		</div>
		<div className='col-2 mobile-col-offset-1 col-md-4 col-lg-1 mobile-mt-4'>
			<label htmlFor='searchTable' className='form-label mt-2'>Search</label>
		</div>
		<div className='col-8 col-md-4 col-lg-3 mobile-mt-4 mb-5'>
			<InputSearch
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						logFilteredData !== null && dispatch(clearFilteredData(
							null
						));
						return;
					}

					dispatch(setDataFilter(
						logData.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</>
};

export default TableHeader;
