import { configureStore } from '@reduxjs/toolkit';
import reducersGeneral from './Reducers';
import userReducer, { reducerName as userReducerName } from './Reducers/user';
import memberReducer, { reducerName as memberReducerName } from './Reducers/member';
import sickPlanReducer, { reducerName as sickPlanReducerName } from './Reducers/sickPlan';
import unionMembershipStatusReducer, { reducerName as unionMembershipStatusReducerName } from './Reducers/unionMembershipStatus';
import genderReducer, { reducerName as genderReducerName } from './Reducers/gender';
import titleReducer, { reducerName as titleReducerName } from './Reducers/title';
import ethnicityReducer, { reducerName as ethnicityReducerName } from './Reducers/ethnicity';
import positionReducer, { reducerName as positionReducerName } from './Reducers/position';
import certificateReducer, { reducerName as certificateReducerName } from './Reducers/certificate';
import statusReducer, { reducerName as statusReducerName } from './Reducers/status';
import stationReducer, { reducerName as stationReducerName } from './Reducers/station';
import baseHourReducer, { reducerName as baseHourReducerName } from './Reducers/baseHour';
import disabilityReducer, { reducerName as disabilityReducerName } from './Reducers/disability';
import noteTypeReducer, { reducerName as noteTypeReducerName } from './Reducers/noteType';
import memberNoteReducer, { reducerName as memberNoteReducerName } from './Reducers/memberNote';
import memberInsuranceReducer, { reducerName as memberInsuranceReducerName } from './Reducers/memberInsurance';
import reasonReducer, { reducerName as reasonReducerName } from './Reducers/reason';
import policyReducer, { reducerName as policyReducerName } from './Reducers/policy';
import insuranceCompanyReducer, { reducerName as insuranceCompanyReducerName } from './Reducers/insuranceCompany';
import envelopeReducer, { reducerName as envelopeReducerName } from './Reducers/envelope';
import beneficiaryReducer, { reducerName as beneficiaryReducerName } from './Reducers/beneficiary';
import notificationReducer, { reducerName as notificationReducerName } from './Reducers/notification';
import zipPopotamReducer, { reducerName as zipPopotamReducerName } from './Reducers/zipPopotam';
import memberFormReducer, { reducerName as memberFormReducerName } from './Reducers/memberForm';
import bulkMemberReducer, { reducerName as bulkMemberReducerName } from './Reducers/bulkMember';
import payrollDeductionReducer, { reducerName as payrollDeductionReducerName } from './Reducers/payrollDeduction';
import memberDisabilityReducer, { reducerName as memberDisabilityReducerName } from './Reducers/memberDisability';
import jobUnionStatusReducer, { reducerName as jobUnionStatusReducerName } from './Reducers/jobUnionStatus';
import logReducer, { reducerName as logReducerName } from './Reducers/log';
import generalSettingReducer, { reducerName as generalSettingReducerName } from './Reducers/generalSetting';
import unionInformationReducer, { reducerName as unionInformationReducerName } from './Reducers/unionInformation';
import officerReducer, { reducerName as officerReducerName } from './Reducers/officer';
import addressReducer, { reducerName as addressReducerName } from './Reducers/address';
import newMemberReportReducer, { reducerName as newMemberReportReducerName } from './Reducers/newMemberReport';
import customReportReducer, { reducerName as customReportReducerName } from './Reducers/customReport';
import comparisonReportReducer, { reducerName as comparisonReportReducerName } from './Reducers/comparisonReport';
import printReducer, { reducerName as printReducerName } from './Reducers/print';
import sessionReducer, { reducerName as sessionReducerName } from './Reducers/session';

export default configureStore({
  reducer: {
    general: reducersGeneral,
    [userReducerName]: userReducer,
    [memberReducerName]: memberReducer,
    [sickPlanReducerName]: sickPlanReducer,
    [unionMembershipStatusReducerName]: unionMembershipStatusReducer,
    [titleReducerName]: titleReducer,
    [genderReducerName]: genderReducer,
    [ethnicityReducerName]: ethnicityReducer,
    [positionReducerName]: positionReducer,
    [certificateReducerName]: certificateReducer,
    [statusReducerName]: statusReducer,
    [stationReducerName]: stationReducer,
    [baseHourReducerName]: baseHourReducer,
    [disabilityReducerName]: disabilityReducer,
    [noteTypeReducerName]: noteTypeReducer,
    [memberNoteReducerName]: memberNoteReducer,
    [memberInsuranceReducerName]: memberInsuranceReducer,
    [reasonReducerName]: reasonReducer,
    [policyReducerName]: policyReducer,
    [insuranceCompanyReducerName]: insuranceCompanyReducer,
    [envelopeReducerName]: envelopeReducer,
    [beneficiaryReducerName]: beneficiaryReducer,
    [notificationReducerName]: notificationReducer,
    [zipPopotamReducerName]: zipPopotamReducer,
    [memberFormReducerName]: memberFormReducer,
    [bulkMemberReducerName]: bulkMemberReducer,
    [payrollDeductionReducerName]: payrollDeductionReducer,
    [memberDisabilityReducerName]: memberDisabilityReducer,
    [jobUnionStatusReducerName]: jobUnionStatusReducer,
    [logReducerName]: logReducer,
    [generalSettingReducerName]: generalSettingReducer,
    [unionInformationReducerName]: unionInformationReducer,
    [officerReducerName]: officerReducer,
    [addressReducerName]: addressReducer,
    [newMemberReportReducerName]: newMemberReportReducer,
    [customReportReducerName]: customReportReducer,
    [comparisonReportReducerName]: comparisonReportReducer,
    [printReducerName]: printReducer,
    [sessionReducerName]: sessionReducer,
  },
})