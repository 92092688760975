import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getReasonService } from './../../Services/reasonService'

export const reducerName = 'reason';

const initialState = {
  request: false,
  error: null,
  data: null
};

export const getReason = createAsyncThunk(
  'reason/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getReasonService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);


export const reasonReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {

    builder.addCase(getReason.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getReason.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getReason.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    })
  }
});

export default reasonReducer.reducer;
