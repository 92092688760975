import { getAxiosInstance } from "../Config/axios";

export const getSickPlanService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'sickplan/get/all'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const editSickPlanService = async ({ data, id }) => {
    try {
        const method = 'PUT';
        const url = `sickplan/${id}`;
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const destroySickPlanService = async ({ id }) => {
    try {
        const method = 'DELETE';
        const url = `sickplan/${id}`;
        const axios = getAxiosInstance();
        const options = {
            method,
            url
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};


export const createSickPlanService = async ({ data }) => {
    try {
        const method = 'POST';
        const url = 'sickplan';
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

