import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { reducerName as notificationReducerName, hideNotification } from './../Store/Reducers/notification';

const ERROR = 'ERROR';
const SUCCESS = 'SUCCESS';

const Notification = () => {
	const dispatch = useDispatch();
  const message = useSelector((state) => state[notificationReducerName].message);
  const type = useSelector((state) => state[notificationReducerName].type);

  useEffect(() => {
		if(!message?.trim().length) {
			return;
		}

		const hideNotificationFN = setTimeout(() => {
			dispatch(hideNotification());
		}, 3000);

		return () => {
			clearTimeout(hideNotificationFN);
		}
	}, [message, dispatch]);

  
	return (<> { message && type ? <>
    { type === ERROR ? <div className="col-12 center pt-3">
          { <span className="login-danger text-danger">{message}</span> }
    </div> : null}

    { type === SUCCESS ? <div className="col-12 center pt-3">
        <span className="login-danger text-success">{message}</span>
    </div> : null}
    </> : null }
  </>)
}

export default Notification