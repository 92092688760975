import React from 'react';

const LoadingReport = () => {
	return (
		<div className="row login-page">
				<div className="col-sm-12">
					<div className="row">
						<div className="col-12 mb-3">
							Generating report...
						</div>
					</div>
				</div>
		</div>
	)
};

export default LoadingReport;
