import { getAxiosInstance } from "../Config/axios";

export const getBeneficiaryByMemberIdService = async (memberId) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: `beneficiary/by-member/${memberId}`
        };
    
        const result = await axios(options);
        
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const addBeneficiaryByMemberIdService = async (memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: memberData.data,
            url: `beneficiary/by-member/${memberData.id}`
        };
    
        const result = await axios(options);
        
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};
