import React from 'react';
import Modal from 'react-modal';
import DeleteIcon from './../Assets/Img/Icons/delete.png';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    with: '100%'
  },
  overlay: {
    backgroundColor: 'rgb(133 133 133 / 24%)'
  }
};

Modal.setAppElement('#root');

const ModalComponent = ({
  description = '',
  preventScroll = true,
  modalIsOpen = true,
  title,
  closeModal = () => {},
  confirmModal = () => {},
  afterOpenModal = () => {},
  modalBody,
  deleteButton = true,
  acceptButton = false
}) => {
  
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        preventScroll={preventScroll}
      >
        <div className='modal-header'>
          <span className='ut-subtitle'>{title || 'Confirmation is Needed'}</span>
          <button
            className='btn-icon'
            onClick={closeModal}
          >x</button>
        </div>
        <div>{description}</div>
        { modalBody || <div className='row modal-footer' style={{width: '500px', maxWidth: '100%'}}>
          <div className='col-6 col-md-4'>
            <button type='button' disabled={false} onClick={closeModal} className='btn-light-grey btn btn-default btn-primary'>Close</button>
          </div>
          <div className='col-6 col-md-4'>
            { deleteButton && <button type='submit' disabled={false} onClick={confirmModal} className='btn-orange btn btn-default btn-primary'><img src={DeleteIcon} alt='Delete'/> Delete</button> }
            { acceptButton && <button type='submit' disabled={false} onClick={confirmModal} className='btn-orange btn btn-default btn-primary'>Accept</button> }
          </div>
        </div>}
      </Modal>
    </div>
  );
}

export default ModalComponent;
