import React, { useEffect } from 'react'
import GenerateForm from './../../../../Components/Forms/generate_form'
import { useDispatch, useSelector } from 'react-redux';
import { reducerName as memberReducerName } from './../../../../Store/Reducers/member';
import { getReason } from './../../../../Store/Reducers/reason';
import { getPolicy, getPolicyByCompany } from './../../../../Store/Reducers/policy';
import { getInsuranceCompany } from './../../../../Store/Reducers/insuranceCompany';
import { reducerName as memberInsuranceReducerName, cancellationMemberInsurance } from './../../../../Store/Reducers/memberInsurance';
import { cancellationFormFields } from './constants'
import Notification from './../../../../Components/notification';

const formName = 'newPolicyForm';

const CancellationForm = ({onSubmit, onCancel, index}) => {
  const dispatch = useDispatch();
  const managementData = useSelector((state)=> state[memberReducerName].managementData);
  const memberInsuranceData = useSelector((state)=> state[memberInsuranceReducerName].data);
  const memberInsuranceGetData = useSelector((state)=> state[memberInsuranceReducerName].getData);
  const cancellationRequest = useSelector((state)=> state[memberInsuranceReducerName].cancellationMemberInsuranceRequest);


  useEffect(()=>{
    dispatch(getReason());
    dispatch(getInsuranceCompany());
    dispatch(getPolicy());
  }, [dispatch]);

  useEffect(()=>{
    if(!!memberInsuranceData){
      const form = document.getElementById(formName);
      form?.reset();
    }
  }, [memberInsuranceData]);

  const handleInputChange = (event) => {
    if(event.target.name === 'insurance_company_id') {
      !!event.target.value && dispatch(getPolicyByCompany(event.target.value));
    }
	};

  const handleFormSubmit = (e) => {
			
		e.preventDefault();

		if(document.querySelectorAll(`#${formName} input.is-invalid`).length) {
			document.querySelector(`#${formName} input.is-invalid`).focus();
			return;
		}
		
		const elements = e.target.elements;
		const memberData ={};
		const fields = [];

		cancellationFormFields.forEach((formMember)=>formMember.forms?.forEach((field)=>fields.push(field)))
		
		for (let i = 0; i < elements.length; i++) {

				const { name, value } = elements.item(i);

        if(!name.length) {
          continue;
        }

				const fieldConfiguration = fields.find((field)=> field?.name === name);

        if(fieldConfiguration?.required && !value) {
          elements.item(i).parentElement?.childNodes?.[2]?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]?.focus();
          return;
        }
        
        memberData[name] = value;
				
		}

    dispatch(cancellationMemberInsurance({
      memberPolicyId: memberInsuranceGetData?.[index]?.id,
      memberId: managementData.member.id,
      data: memberData
    }))

		onSubmit();
	}


	return (<>
    <form id={formName} onSubmit={handleFormSubmit}>
      <div className="row">
        <div className="col col-12">
          <div className="row">
            {
              cancellationFormFields.map((section, i) =>
                <>
                <label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
                {
                  section.forms.map((field)=>({
                    ...field,
                    disabled: (!managementData || !Object.keys(managementData).length) || field.disabled,
                    defaultValue: typeof field?.getDefaultValue === 'function' ?
                      field.getDefaultValue(managementData) :
                      typeof field.component === 'string' ?
                        Array.isArray(managementData?.[field.component]) ?
                          managementData[field.component][managementData[field.component].length - 1]?.[field.componentKey || field.name] :
                          managementData?.[field.component]?.[field.componentKey || field.name] :
                          managementData?.[field.name]
                  })).map((form, i) => <GenerateForm
                    key={i}
                    {...form}
                    onChange={handleInputChange}
                    dataInputDates={()=>{}}
                  /> )
                }
                </>					
              )
            }
          </div>
        </div>
      </div>
      <div className='row mt-5 mb-3'>
        <Notification />
        <div className='col col-6'>
          <button type='button' disabled={cancellationRequest} onClick={onCancel} className='btn-light-grey btn btn-default btn-primary'>Back</button>
        </div>
        <div className='col col-6'>
          <button type='submit' disabled={cancellationRequest} className='btn-orange btn btn-default btn-primary'>Submit</button>
        </div>
      </div>
    </form>
  </>)
}

export default CancellationForm