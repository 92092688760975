import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { reducerName, searchMemberById, searchMember } from '../Store/Reducers/member';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const DropdownIndicator = props => {
	return (
		<components.DropdownIndicator {...props}>
			<FontAwesomeIcon style={{marginRight:'5px'}} icon={faSearch} />
		</components.DropdownIndicator>
	);
};


const MemberSearch = (props) => {
	const dispatch = useDispatch();
	const customStyles = {
		container: (provided) =>({
			...provided,
			width: props.customWidth || '100%',
			display: 'inline-block'
		}),
		control: (provided) => ({
			...provided,
			background: props.background || '#ededed',
			borderRadius: '7px',
			border: 'none',
			width: '100%'
		}),
		indicatorsContainer: (provided) => ({
				...provided,
				padding: '0',
				margin: '-5px 0'
		}),
	};
	const [searchTerm, setSearchTerm] = useState('');
	const data = useSelector((state)=> state[reducerName].searchData);
	const searchRequest = useSelector((state)=> state[reducerName].searchRequest);


	useEffect(() => {
		if(!searchTerm?.trim().length) {
			return;
		}

		const searchMemberFN = setTimeout(() => {
			dispatch(searchMember({ term: searchTerm }));
		}, 1500);

		return () => {
			clearTimeout(searchMemberFN);
		}
	}, [dispatch, searchTerm]);


	return <Select
        loadingMessage={() => 'Searching...'}
        isLoading={searchRequest}
        {...(props.refVar && { ref: props.refVar })}
        placeholder='Search'
        styles={customStyles}
        components={{ DropdownIndicator }}
        onChange={(selectedValue)=>{
          dispatch(searchMemberById(selectedValue?.value))
        }}
        onInputChange={setSearchTerm}
        isSearchable
        isClearable
        noOptionsMessage={() => !data?.length && searchTerm.length ? 'Without results' : 'Search'}
        autoFocus
        options={Array.isArray(data) ? data.map((mem)=>({value: mem.id, label: `${mem.first_name} ${mem.last_name} ${`${mem.suffix ? `${mem.suffix}. `: ''}` || ''}`})) : []}
      />
}

export default MemberSearch