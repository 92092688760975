import Settings from '../Assets/Img/Icons/settings-menu.svg'
import Print from '../Assets/Img/Icons/print-menu.svg'
import Report from '../Assets/Img/Icons/report-menu.svg'
import Status from '../Assets/Img/Icons/status-menu.svg'
import Disability from '../Assets/Img/Icons/disability-menu.svg'
import Members from '../Assets/Img/Icons/members-menu.svg'
import Insurance from '../Assets/Img/Icons/insurance-menu.svg'
import {ROLES} from '../Router/Roles'

const Menu = [
	{
		id: 0,
		title: 'Members',
		status: true,
		icon: Members,
		name: 'members',
		roles: [ROLES.developer, ROLES.administrator]
	},
	{
		id: 1,
		title: 'Insurance',
		status: false,
		icon: Insurance,
		name: 'insurance',
		roles: [ROLES.developer, ROLES.administrator],
	},
	{
		id: 2,
		title: 'Disability',
		status: false,
		icon: Disability,
		name: 'disability',
		roles: [ROLES.developer, ROLES.administrator],
	},
	{
		id: 3,
		title: 'Status',
		status: false,
		icon: Status,
		name: 'status',
		roles: [ROLES.developer, ROLES.administrator],
	},
	{
		id: 4,
		title: 'Report',
		status: false,
		icon: Report,
		name: 'report',
		roles: [ROLES.developer, ROLES.administrator],
	},
	{
		id: 5,
		title: 'Print',
		status: false,
		icon: Print,
		name: 'print',
		roles: [ROLES.developer, ROLES.administrator],
	},
	{
		id: 6,
		title: 'Settings',
		status: false,
		icon: Settings,
		name: 'settings',
		roles: [ROLES.developer, ROLES.administrator]
	}
]

export default Menu