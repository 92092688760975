import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setDataFiltered,
	clearDataFiltered,
	reducerName as positionReducerName
} from './../../../../Store/Reducers/position';
import InputSearch from './../../../../Components/Forms/input_search';

const TableHeader = () => {
	const dispatch = useDispatch();
	const positionData = useSelector((state)=> state[positionReducerName].data || []);
	const positionDataFiltered = useSelector((state)=> state[positionReducerName].dataFiltered);

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();

		return data.name?.toLowerCase().includes(searchTerm) || data.cost?.toString()?.toLowerCase().includes(searchTerm);
	}


	return <>
		<div className="col-12 col-md-8">
		</div>
		<div className="col-3 col-md-1 text-center">
			<label htmlFor='searchTable' className='form-label mt-2'>Search: </label>
		</div>
		<div className="col-8 col-md-3">
			<InputSearch
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						positionDataFiltered !== null && dispatch(clearDataFiltered(
							null
						));
						return;
					}

					dispatch(setDataFiltered(
						positionData.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</>
}

export default TableHeader;
