import React, { useState, useEffect, useRef } from 'react'
import { VALIDATION_DATA, REQUIRED, PASSWORD } from './constant_form'
import { useSelector } from 'react-redux';
import Checkbox from './checkbox'
import MemberSearch from './../memberSearch'
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import InputMask from 'react-input-mask';
import AsyncMultiselect from './async_multiselect';
import "react-datepicker/dist/react-datepicker.css";

const GenerateForm = (props) => {
	const localRef = useRef();
	const refVar = props.refVar || localRef;
	const defaultOptionsProps = props.options;
	const state = useSelector((state)=> state);
	const [defaultValueProps, setDefaultValueProps] = useState(props.defaultValue)
	const [messageError, setMessageError] = useState('')
	const [checkboxOptions, setCheckboxOptions] = useState([]);
	const [classError, setClassError] = useState('');
	const [dateValue, setDateValue] = useState(props.type === 'date' && props.defaultValue ? moment(new Date(props.defaultValue).toISOString(), 'YYYY-MM-DD').toDate() : null)
	const [value, setValue] = useState(props.defaultValue)

	useEffect(()=>{
		setDefaultValueProps(props.defaultValue);
		setValue(props.defaultValue);
	}, [props.defaultValue])

	useEffect(()=>{
		props.type === 'date' && setDateValue(props.type === 'date' && defaultValueProps ? moment(new Date(defaultValueProps).toISOString(), 'YYYY-MM-DD').toDate() : null)
	}, [defaultValueProps, props.type])

	useEffect(()=>{
		if(props.type === 'checkbox') {
			const haveDefaultValue = Array.isArray(defaultValueProps);
			setCheckboxOptions(defaultOptionsProps.map((opt)=>{
				if(haveDefaultValue && defaultValueProps.includes(opt.id)){
					opt.isChecked = true;
				} else {
					opt.isChecked = false;
				}
				return opt
			}));
		}
	}, [defaultOptionsProps, defaultValueProps, props.type])

	const ValidField = (e) => {
		let errorMessage = '';
		let elementClass = 'is-valid'

		if(props.required && (e.target.value?.toString().trim() === '' || !e.target.value)) {
			elementClass = "is-invalid";
			errorMessage = VALIDATION_DATA[REQUIRED].message;
		}
		
		if(!errorMessage?.length && !!VALIDATION_DATA[props.validate?.type] && !!VALIDATION_DATA[props.validate?.type].regexp) {

			const isInvalid = !new RegExp(VALIDATION_DATA[props.validate.type].regexp).test(e.target.value);
			
			if(isInvalid) {

				elementClass = "is-invalid";
				errorMessage = VALIDATION_DATA[props.validate.type].message;
			}
		}

		if(!errorMessage?.length && !!VALIDATION_DATA[props.validate?.type] && props.validate?.type === PASSWORD && !!VALIDATION_DATA[props.validate?.type].passwordIsEqual) {

			const isInvalid = !VALIDATION_DATA[props.validate?.type].passwordIsEqual(e.target.value, props.validateFieldName);
			
			if(isInvalid) {

				elementClass = "is-invalid";
				errorMessage = VALIDATION_DATA[props.validate.type].message;
			}
		}

		setMessageError(errorMessage);
		setClassError(elementClass);
		
		return errorMessage === '';
	}


	if(props.type === 'text' || props.type === 'email' || props.type === 'password' || props.type === 'number'){
		return (
			<>
				<div className={`${props.col} mb-3`}>
					<label htmlFor={`input${props.name}`} className={`${props.colorLabelTwo ? 'form-label-grey' : 'form-label'} mt-2`}>{props.label}<small className="requiredField">{props.required && props.label ? '*' : ''}</small> <span className="subtitleLabels">{props.subtitle}</span></label>
					{props.icon ?
					<div class='input-icons'>
						{props.icon}
						<input
								disabled={props.disabled}
								type={props.type}
								defaultValue={props.defaultValue}
								placeholder={props.placeholder}
								onBlur={ValidField}
								className={`${props.type === 'radio' ? 'form-check-input': 'form-control'} ${classError} ${props.class} ${props.lineBreak ? 'mt-3' : ''}`}
								required={props.required}
								name={props.name}
								id={`input${props.name}`}
								aria-describedby="emailHelp"
								onChange={((event)=>{
									ValidField(event);
									props.onChange(event);
								})}
							/>
					</div>
					: 
							<input
								disabled={props.disabled}
								type={props.type}
								defaultValue={props.defaultValue}
								placeholder={props.placeholder}
								onBlur={ValidField}
								className={`${props.type === 'radio' ? 'form-check-input': 'form-control'} ${classError} ${props.class} ${props.lineBreak ? 'mt-3' : ''}`}
								required={props.required}
								name={props.name}
								id={`input${props.name}`}
								aria-describedby="emailHelp"
								onChange={((event)=>{
									ValidField(event);
									props.onChange(event);
								})}
							/>
						}
			        <small id="passwordHelp" className="text-danger">
			          {messageError}
			        </small>
							{props.addon ? props.addon : null}
				</div>
			</>
		)

	} else if (props.type === 'mask') {
		return <div className={`${props.col} mb-3`}>
		<label htmlFor={`input${props.name}`} className={`${props.colorLabelTwo ? 'form-label-grey' : 'form-label'} mt-2`}>{props.label}<small className="requiredField">{props.required && props.label ? '*' : ''}</small> <span className="subtitleLabels">{props.subtitle}</span></label>
			<InputMask
				disabled={props.disabled}
				type={props.type}
				value={value}
				placeholder={props.placeholder}
				onBlur={ValidField}
				className={`${props.type === 'radio' ? 'form-check-input': 'form-control'} ${classError} ${props.class} ${props.lineBreak ? 'mt-3' : ''}`}
				required={props.required}
				name={props.name}
				id={`input${props.name}`}
				aria-describedby="emailHelp"
				onChange={((event)=>{
					setValue(event.target.value);
					ValidField(event);
					props.onChange(event);
				})}
				mask={props.mask || "999-999-9999"}
				maskChar={props.maskChar || "-"}
			/>
					<small id="passwordHelp" className="text-danger">
						{messageError}
					</small>
					{props.addon ? props.addon : null}
		</div>
	} else if (props.type === 'textarea') {
		return (
			<>
				<div className={`${props.col}`}>
					<label htmlFor={`input${props.name}`} className={`${props.colorLabelTwo ? 'form-label-grey' : 'form-label'} mt-2`}>{props.label}<small className="requiredField">{props.required && props.label ? '*' : ''}</small> <span className="subtitleLabels">{props.subtitle}</span></label>
					<textarea
						disabled={props.disabled}
						type={props.type}
						defaultValue={defaultValueProps}
						placeholder={props.placeholder}
						onBlur={ValidField}
						className={`form-control ${classError} ${props.class} ${props.lineBreak ? 'mt-3' : ''}`}
						required={props.required}
						name={props.name}
						id={`input${props.name}`}
						aria-describedby="textarea"
						onChange={((event)=>{
							ValidField(event);
							props.onChange(event);
						})}
					/>
			        <small id="passwordHelp" className="text-danger">
			          {messageError}
			        </small>
							{props.addon ? props.addon : null}
				</div>
			</>
		)
	} else if (props.type === 'select') {

		const IndicatorSeparator = () => {
			return null;
		}


		const customStyles = {
			control: (provided) => ({
				...provided,
				background: '#ededed',
				border: 'none',
				padding: '0 8px',
				borderRadius: '8px',
				boxShadow: 'none'
			})
		};

		const reduxInfo = props.reduxInfo;
		const options = reduxInfo && Array.isArray(state[reduxInfo.reducer]?.[reduxInfo.key]) ? state[reduxInfo.reducer][reduxInfo.key].map((opt)=>({
			...opt,
			value: opt.value || opt.id,
			label: opt.label || opt.name
		})) : props.options;
		
		const defaultValue = options?.find((opt)=> opt.id === props.defaultValue);

		const haveLabel = !!props.label || !!props.subtitle;

		return (
			<>
			<div className={`${props.col} mb-3`}>
				<div style={{padding:0,margin:0}} className='row'>
					{ haveLabel ? <div className='col-12' style={{padding:0,margin:0}}>
						<label htmlFor={`Input${props.name}`} className="form-label mt-2">{props.label}{props.subtitle}<small className="requiredField">{props.required ? '*' : ''}</small></label>
					</div> : null }
					<div className={`col-12`} style={{padding:0,margin:0}}>
						<Select
							ref={refVar}
							className={classError}
							isClearable={true}
							components={{IndicatorSeparator}}
							styles={customStyles}
							key={defaultValue}
							isDisabled={props.disabled}
							isLoading={props.isLoading}
							defaultValue={defaultValue} 
							aria-label="Default select example" 
							placeholder={props.placeholder}
							name={props.name} 
							onBlur={()=>{
								ValidField({
									target: {
										name: props.name,
										value: refVar.current?.getValue()?.[0]?.id
									}
								})
							}}
							onChange={(a)=> {
								props.onChange({
									target: {
										name: props.name,
										value: a?.id
									}
								})
							}}
							options={props.groupBy ? props.groupBy(options) : options}
						/>
					</div>
					<div className='col-12'>
						<small id="passwordHelp" className="text-danger">
							{messageError}
						</small>							
					</div>
				</div>
			</div>
			</>
		)
	} else if (props.type === 'datalist') {
		return (
			<>
				<div className={`${props.col}`}>
					<label htmlFor={`Input${props.name}`} className="form-label mt-2">{props.label}{props.subtitle}<small className="requiredField">{props.required ? '*' : ''}</small></label>
					<input className={`form-control form-select ${props.class}`} list="datalistOptions" id="exampleDataList" placeholder={props.label} onBlur={ValidField} name={props.name} onChange={props.onChange} />
					<datalist id="datalistOptions">
					  <option className="optionDefaultForSelect" value="" disabled selected>{props.placeholder}</option>
					  {
					  	props.options[0] ?
					  		props.options.map((option, key) => <option className="option-select" key={key} value={option.value ? option.value : option.id}>{option.name || option.first_name}</option>)
					  	:
					  		<></>	
					  }
					</datalist>				
			        <small id="passwordHelp" className="text-danger">
			          {messageError}
			        </small>							
				</div>
			</>
		)
	} else if (props.type === 'radio') {
		const checked = props.value === defaultValueProps;

		return (
			<>
				<div className={`${props.col}`}>
					<input
						type={props.type}
						{...(!!checked && { checked:{checked}})}
						value={props.value}
						placeholder={props.placeholder}
						onBlur={ValidField}
						className={`form-check-input desktop-mt-5 ${classError} ${props.class} ${props.lineBreak ? 'mt-3' : ''}`}
						required={props.required}
						name={props.name}
						id={`input${props.name}`}
						aria-describedby="emailHelp"
						onChange={props.onChange}
					/>
					<label style={{marginLeft: '5px', top: '-3px', position: 'relative'}} htmlFor={`input${props.name}`} className={`mobile-mt-5p ${props.colorLabelTwo ? 'form-label-grey' : 'form-label'} label-input-radio desktop-mt-5`}>{props.label} <span className="subtitleLabels">{props.subtitle}</span><small className="requiredField">{props.required ? '*' : ''}</small></label>
			        <small id="passwordHelp" className="text-danger">
			          {messageError}
			        </small>
				</div>
			</>
		)
	} else if (props.type === 'date'){

		const haveLabel = !!props.label || !!props.subtitle;
		return (
		<>
			<div className={`${props.col} mb-3`}>
				<div style={{padding:0,margin:0, display:'block'}} className='row'>
					{ haveLabel ? <div className='col-12'>
						<label htmlFor={`input${props.name}`} className={`${props.colorLabelTwo ? 'form-label-grey' : 'form-label'} label-input-radio mt-2`}>{props.label} <span className="subtitleLabels">{props.subtitle}</span><small className="requiredField">{props.required ? '*' : ''}</small></label>
					</div> : null }
					<div className={`col-12`}></div>
						<FontAwesomeIcon className='date-dropdown' color='#cccccc' icon={faChevronDown} />
						<DatePicker
							autoComplete='off'
							isClearable={props.isClearable}
							selected={dateValue}
							disabled={props.disabled}
							dateFormat={props.name === 'month' ? 'MMMM' : props.name === 'year' ? 'yyyy' : 'MM/dd/yyyy' } 
							name={props.name} 
							type={props.type}
							showMonthYearPicker={props.name === 'month' ? true : false}
							showYearPicker={props.name === 'year' ? true : false}
							placeholderText={props.placeholder} 
							onBlur={(e)=>{
								ValidField(e);
							}} 
							className={`${props.type === 'radio' ? 'form-check-input': 'form-control'} ${props.validate ? classError : ''} ${props.class} ${props.lineBreak ? 'mt-3' : ''}`} 
							required={props.required} 
							onChange={(date) => {
								ValidField({
									target: {
										value: date, name: props.name
									}
								});

								setDateValue(date);
								
								props.dataInputDates(props.name, date)
							}} />
					</div>
					<div className='col-12'>
		        <small id="passwordHelp" className="text-danger">
		          {messageError}
		        </small>		        
					</div>
				</div>
        </>
        )
	} else if (props.type === 'checkbox') {
		return (
			<>
				<div className={`${props.col}`}>
					<label htmlFor={`Input${props.name}`} className={`${props.colorLabelTwo ? 'form-label-grey' : 'form-label'} label-input-radio mt-2`}>{props.label} <span className="subtitleLabels">{props.subtitle}</span></label>
					<div className="row">
					{
						checkboxOptions.map((option) => 
							<div key={`${option.id}${option.isChecked}`} className={`${option.col}`}>
								<div className='row' style={{padding:0,margin:0}}>
									<div className='col-1 col-md-1 check-option'>
										<Checkbox option={option} ValidField={ValidField} classError={classError} onChange={props.handleInputChecks}/>
									</div>
									<div className='col-1 check-option'></div>
									<div className='col-10 col-md-8 check-option'>
										<label htmlFor={`Input${props.name}`} className={`${option.colorLabelTwo ? 'form-label-grey' : ''} label-input-radio mt-2`}>{option.label} <span className="subtitleLabels">{option.subtitle}</span></label>
									</div>
									<div className='col-12'>
										<small id="passwordHelp" className="text-danger">
											{messageError}
										</small>
									</div>
								</div>
							</div>
						)
					}
					</div>
				</div>
			</>
		)
	} else if (props.type === 'multiselect') {

		
		const reduxInfo = props.reduxInfo;
		const options = reduxInfo && Array.isArray(state[reduxInfo.reducer]?.[reduxInfo.key]) ? state[reduxInfo.reducer][reduxInfo.key] : props.options;

		return (
			<>
			<div className={`${props.col}`}>
			<label htmlFor={`Input${props.name}`} className="form-label mt-2">{props.label}{props.subtitle}<small className="requiredField">{props.required ? '*' : ''}</small></label>
				<AsyncMultiselect
					refVar={refVar}
					defaultValue={defaultValueProps}
					isLoading={props.isLoading}
					onChange={(values) => {
						props.onChange({
							target: {
								name: props.name,
								value: values.map((val)=> val.id)
							}
						})
					}}
					
					defaultOptions={options.map((opt)=>({
						...opt,
						value: opt.value || opt.id,
						label: opt.label || opt.name,
						description: opt.description || opt.name
					})).slice(0, 200)}

					handleSearch={(inputValue)=>{
						return (options.map((opt)=>({
							...opt,
							value: opt.value || opt.id,
							label: opt.label || opt.name,
							description: opt.description || opt.name
						}))).filter((i) =>
							i.description.split(" ").map((el) => el.toLowerCase()).join(" ").includes(inputValue.trim().toLowerCase())
						);
					}}
				/>
				</div>
			</>
		)
	} else if (props.type === 'memberSearch') {

		return (
			<>
				<div className={`${props.col} mb-3`}>
					<label htmlFor={`Input${props.name}`} className="form-label mt-2">{props.label}{props.subtitle}<small className="requiredField">{props.required ? '*' : ''}</small></label>
					<MemberSearch refVar={refVar} />
					<small id="passwordHelp" className="text-danger">
						{messageError}
					</small>							
				</div>
			</>
		)
	}
}

export default GenerateForm