import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getSickPlanService,
  editSickPlanService,
  destroySickPlanService,
  createSickPlanService
} from './../../Services/sickPlanService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'sickPlan';

const initialState = {
  
  request: false,
  error: null,
  data: null,

  dataFiltered: null
};

export const getSickPlan = createAsyncThunk(
  'sickPlan/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getSickPlanService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const editSickPlan = createAsyncThunk(
  'sickPlan/editSickPlan',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await editSickPlanService(parameters);

      dispatch(getSickPlan());
      dispatch(showNotification({
        message: 'Sick plan updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };

    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const destroySickPlan = createAsyncThunk(
  'sickPlan/destroySickPlan',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await destroySickPlanService(id);

      dispatch(getSickPlan());
      dispatch(showNotification({
        message: 'Sick plan deleted successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const createSickPlan = createAsyncThunk(
  'sickPlan/createSickPlan',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await createSickPlanService(parameters);

      dispatch(getSickPlan());
      dispatch(showNotification({
        message: 'Sick plan created successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setDataFilteredReducer = (state, action) => {
  return {
    ...state,
    dataFiltered: action.payload.data
  }
};

const setDataFilteredAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearDataFilteredReducer = (state) => {
  return {
    ...state,
    dataFiltered: null
  }
};

const setEditDataHandler = (state, data) => {
  return {
    ...state,
    editData: data.payload
  }
};


export const sickPlanReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setDataFiltered: {
      reducer: setDataFilteredReducer,
      prepare: setDataFilteredAction
    },
    clearDataFiltered: clearDataFilteredReducer,
    setEditData: setEditDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getSickPlan.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getSickPlan.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getSickPlan.pending, (state) => {
      state.request = true;
      state.error = null;
    })
  }
});

export const { setDataFiltered, clearDataFiltered, setEditData } = sickPlanReducer.actions

export default sickPlanReducer.reducer;
