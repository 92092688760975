import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import LayoutHeaderPage from '../../../Layouts/header_page';
import GenerateForm from '../../../Components/Forms/generate_form';
import { AddMembersFormsPartOne, AddMembersFormsPartTwo } from '../../../Config/Forms/MembersManagements/add_members';
import { addMember, reducerName } from '../../../Store/Reducers/member';
import { getSickPlan, reducerName as sickPlanReducerName } from '../../../Store/Reducers/sickPlan';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTitle, reducerName as titleReducerName } from '../../../Store/Reducers/title';
import { getGender, reducerName as genderReducerName } from '../../../Store/Reducers/gender';
import { getEthnicity, reducerName as ethnicityReducerName } from '../../../Store/Reducers/ethnicity';
import { getPosition, reducerName as positionReducerName } from '../../../Store/Reducers/position';
import Notification from '../../../Components/notification';
import { searchPlaceByPostalCode, reducerName as zipPopotamReducerName } from '../../../Store/Reducers/zipPopotam';
const formAddMemberId = 'create-member-form';

const MembersManagements = () => {
	
	const dispatch = useDispatch();
	
	const request = useSelector((state)=> state[reducerName].request);
	const memberData = useSelector((state)=> state[reducerName].data);
	const titleData = useSelector((state)=> state[titleReducerName].data);
	const genderData = useSelector((state)=> state[genderReducerName].data);
	const ethnicityData = useSelector((state)=> state[ethnicityReducerName].data);
	const sickPlanData = useSelector((state)=> state[sickPlanReducerName].data);
	const positionData = useSelector((state)=> state[positionReducerName].data);
	const placeByPostalCodeData = useSelector((state)=> state[zipPopotamReducerName].placeByPostalCode);

	useEffect(()=>{
		if(!memberData || !Object.keys(memberData)?.length){
			return
		}

		document.getElementById(formAddMemberId).reset();
	}, [memberData]);
	

	useEffect(() => {
		!titleData?.length && dispatch(getTitle());
	}, [titleData, dispatch]);

	useEffect(() => {
		!positionData?.length && dispatch(getPosition());
	}, [positionData, dispatch]);

	useEffect(() => {
		!sickPlanData?.length && dispatch(getSickPlan());
	}, [sickPlanData, dispatch]);

	useEffect(() => {
		!genderData?.length && dispatch(getGender());
	}, [genderData, dispatch]);

	useEffect(() => {
		!ethnicityData?.length && dispatch(getEthnicity());
	}, [ethnicityData, dispatch]);

	const [DataFormMembers, setFormMembers] = useState({})

	useEffect(()=>{
		const actualPostalCode = document.querySelector(`#${formAddMemberId} input[name=zip_code]`)?.value;
		const placeData = placeByPostalCodeData[actualPostalCode];

		if(!actualPostalCode || !placeData) {
			return;
		}

		document.querySelector(`#${formAddMemberId} input[name=state]`).value = placeData.state;
		document.querySelector(`#${formAddMemberId} input[name=city]`).value = placeData['place name'];
		setFormMembers({
			...DataFormMembers,
			['city'] : placeData['place name'],
			['state'] : placeData.state,
		})

	}, [placeByPostalCodeData]);


	const handleInputChangeMembers = (event) => {

		if(event.target.name === 'zip_code') {
			document.querySelector(`#${formAddMemberId} input[name=state]`).value = '';
			document.querySelector(`#${formAddMemberId} input[name=city]`).value = '';

      if(!!event.target.value) {
				dispatch(searchPlaceByPostalCode(event.target.value));
			} else {
				return setFormMembers({
					...DataFormMembers,
					['city'] : '',
					['state'] : '',
					['zip_code'] : ''
				});
			}
    }

		setFormMembers({
				...DataFormMembers,
				[event.target.name] : event.target.value
		})
	}

	const dataInputDates = (name, value) => {
		var date = moment(value).format('L')
		var dateFormat = date.split('/')
		var dateFormatEnd = `${dateFormat[2]}-${dateFormat[0]}-${dateFormat[1]}`
			setFormMembers({
					...DataFormMembers,
					[name] : dateFormatEnd
			})    	
	}

	const handleAddMember = (e) => {
		e.preventDefault();
		if(document.querySelectorAll(`#${formAddMemberId} input.is-invalid`).length) {
			document.querySelector(`#${formAddMemberId} input.is-invalid`).focus();
			return;
		}
		
		dispatch(addMember(DataFormMembers))
	};
	

	return (
		<>
			<LayoutHeaderPage title="Add Member"/>
			<div className="tab-content mt-3 pt-4" id="pills-tabContent">
				<form id={formAddMemberId} onSubmit={handleAddMember}>
					<div className="row">
						<div className="col-sm-6 col-12">
							<div className="row">
								{
									AddMembersFormsPartOne.map((section, i) =>
										<Fragment key={i}>
										<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
										{
											section.forms.map((form, i) => 
												<GenerateForm 
													key={i} 
													{...form}
													onChange={handleInputChangeMembers}/> 
											)
										}
										</Fragment>					
									)
								}
								</div>
							</div>
							<div className="col-sm-6 col-12 mb-5">
								<div className="row">
								{
									AddMembersFormsPartTwo.map((form, i) =>
										<Fragment key={i}>
										<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{form.label}</label>
										{
											form.forms.map((form, i) => 
												<GenerateForm 
													key={i} 
													{...form}
													dataInputDates={dataInputDates}
													onChange={handleInputChangeMembers}/> 
											)
										}
										</Fragment>					
									)
								}
							</div>
						</div>
					</div>
					<Notification />
					<div className="row pb-5 pt-5">
						<div className="col-md-3">
						</div>
						<div className="col-md-3">
						</div>
						<div className="col-2 col-md-3 show-on-mobile"></div>
						<div className="col-8 col-md-3 mb-3">
							<button disabled={request} type="submit" className="btn-orange btn btn-default btn-primary">Create</button>
						</div>
						<div className="col-2 col-md-3 show-on-mobile"></div>
						<div className="col-2 col-md-3 show-on-mobile"></div>
						<div className="col-8 col-md-3">
							<Link disabled={request} to="/dashboard/" className="btn-light-grey btn btn-default btn-primary">Close</Link>
						</div>
						<div className="col-2 col-md-3 show-on-mobile"></div>
					</div>
				</form>			
			</div>
		</>
	)
}

export default MembersManagements