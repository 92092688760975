import React, { useEffect } from 'react'
import moment from 'moment'
import LayoutHeaderPage from '../../../Layouts/header_page'
import TableComponent from '../../../Components/table_v2'
import Notification from './../../../Components/notification';
import { useSelector, useDispatch } from 'react-redux'
import {
	getMemberDisability,
	reducerName as memberDisabilityReducerName,
	getMemberDisabilityExcelReport,
	getMemberDisabilityPDFReport
} from '../../../Store/Reducers/memberDisability';
import TableHeader from './table_header';


const MembersOnDisability = () => {
	
	const dispatch = useDispatch();
	
	const memberDisabilities = useSelector((state)=> state[memberDisabilityReducerName].data);
	const memberDisabilityfilteredData = useSelector((state)=> state[memberDisabilityReducerName].filteredData);
	const startDateFilter = useSelector((state)=> state[memberDisabilityReducerName].startDateFilter);
	const endDateFilter = useSelector((state)=> state[memberDisabilityReducerName].endDateFilter);

	const tableColumns = [
		{
			title: 'First Name',
			sort: true,
      value: 'member',
			parseValue: (value) => {
				return value.first_name
			}
		},
		{
			title: 'Middle Name',
      value: 'member',
			parseValue: (value) => {
				return value.middle_name
			}
		},
		{
			title: 'Last Name',
      value: 'member',
			parseValue: (value) => {
				return value.last_name
			}
		},
		{
			title: 'Suffix',
      value: 'member',
			parseValue: (value) => {
				return value.suffix
			}
		},
		{
			title: 'Disability',
      value: 'disability',
			parseValue: (value) => {
				return value.name
			}
		},
		{
			title: 'Last Worked',
      value: 'last_day_worked',
			parseValue: (value) => {
				if(!value) {
					return '';
				}
				var date = moment(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		},
		{
			title: 'Start Date',
      value: 'start_date',
			parseValue: (value) => {
				if(!value) {
					return '';
				}
				var date = moment(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		},
		{
			title: 'End Date',
      value: 'end_date',
			parseValue: (value) => {
				if(!value) {
					return '';
				}
				var date = moment(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		}
	];

	useEffect(() => {

		if(!endDateFilter || !startDateFilter) {
			return;
		}

		dispatch(getMemberDisability({
			start_date: startDateFilter.slice(0, 10),
			end_date: endDateFilter.slice(0, 10)
		}));

	},[startDateFilter, endDateFilter, dispatch]);

	const handlePDFReport = () => {
		dispatch(getMemberDisabilityPDFReport({
			parameters: {
				start_date: startDateFilter.slice(0, 10),
				end_date: endDateFilter.slice(0, 10)
			}
		}));
	};

	const handleExcelReport = () => {
	  dispatch(getMemberDisabilityExcelReport({
			start_date: startDateFilter.slice(0, 10),
			end_date: endDateFilter.slice(0, 10)
		}))
	}


	return (
		<>
			<LayoutHeaderPage title="Members on Disability"/>
			<div className="tab-content container mt-3 pt-3" id="pills-tabContent">
				<TableComponent
					columns={tableColumns}
					data={memberDisabilityfilteredData || memberDisabilities}
					header={TableHeader}
				/>
				<div className='row pb-5 pt-3'>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className='col-8 col-md-3 mb-3'>
						<button type='button' disabled={false} onClick={handlePDFReport} className='btn-blue btn btn-default btn-primary'>View PDF</button>
					</div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className="col-2 col-md-3 show-on-mobile"></div>
					<div className='col-8 col-md-3 mb-3'>
						<button type='button' disabled={false} onClick={handleExcelReport} className='btn-orange btn btn-default btn-primary'>Export Excel</button>
					</div>
				</div>
			</div>
			<Notification />
		</>
	)
}

export default MembersOnDisability