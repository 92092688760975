import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Helmet from './../../../Assets/Img/Icons/helmet.svg';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { reducerName as memberReducerName } from './../../../Store/Reducers/member';

const PayrollDeductions = ({hideOnMobile, showOnMobile}) => {
	const dashboardData = useSelector((state)=> state[memberReducerName]?.getDashboard || {});
	const iaffData = dashboardData.iaff || {};

	
	return (<>
		<div className={`col-12 col-md-7 mb-5 ${hideOnMobile ? 'hide-on-mobile' : ''} ${showOnMobile ? 'show-on-mobile' : ''}`}>
			<div className='row'>
				<div className="col-12">
					<legend>Payroll Deductions</legend>
				</div>
				<div className="col-2 col-md-1" style={{textAlign:'right'}}>
					<img style={{ width: '100%', top: '10px', position: 'relative' }}  alt="Helmet icon" src={Helmet} />					
				</div>
				<div className="col-4 col-md-3 col-md-3">
					UNIAFF Dues
					<label>${iaffData.uniaff_active ? parseFloat(iaffData.uniaff_active)?.toLocaleString('en-US', {minimumFractionDigits: 2})  : '0.00'}</label> <br /><br />
				</div>
				<div className="col-2 col-md-1" style={{textAlign:'right'}}>
					<FontAwesomeIcon style={{ top: '10px', position: 'relative' }} size="lg" icon={faDollarSign} />
				</div>
				<div className="col-4 col-md-3">
					IAFF Benefits
					<label>${iaffData.iaff ? parseFloat(iaffData.iaff)?.toLocaleString('en-US', {minimumFractionDigits: 2})  : '0.00'}</label> <br /><br />
				</div>
			</div>
		</div>
	</>)
}

export default PayrollDeductions