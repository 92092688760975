import { getAxiosInstance } from "../Config/axios";

export const getMemberService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'member/get/all'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
}

export const addMemberService = async (memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: memberData,
            url: 'member'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const searchMemberService = async (searchData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: searchData,
            url: 'member/search'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const searchMemberByIdService = async (memberId) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: `member/${memberId}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const updateMemberService = async (id, memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'PUT',
            data: memberData,
            url: `member/${id}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const updateMemberPersonalDataService = async (id, memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'PUT',
            data: memberData,
            url: `member/personal-info/${id}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const updateMemberJobDataService = async (id, memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'PUT',
            data: memberData,
            url: `member/job-info/${id}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const deleteMemberService = async (id) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'DELETE',
            url: `member/${id}`
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result.data?.status;
        
    } catch (error) {
        throw error
    }
};

export const changePasswordService = async (id, memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'PUT',
            data: memberData,
            url: `member/change-password/${id}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const changeMailService = async (id, memberData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'PUT',
            data: memberData,
            url: `member/change-mail/${id}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const addMemberPolicyService = async (policyData) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: policyData.data,
            url: `member/member-policy/${policyData.memberId}`
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const getDashboardService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'member/get/dashboard'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};
