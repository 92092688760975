import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { searchPlaceByPostalCodeService } from './../../Services/zipPopotamService'

export const reducerName = 'zipPopotam';

const initialState = {
  searchPlaceByPostalCodeRequest : false,
  searchPlaceByPostalCodeError   : null ,
  placeByPostalCode              : {}
};

export const searchPlaceByPostalCode = createAsyncThunk(
  'zipPopotam/searchPlaceByPostalCode',
  async (postalCode, { rejectWithValue }) => {
    try {
      
      const response = await searchPlaceByPostalCodeService(postalCode);

      return {
        postalCode,
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);


export const zipPopotamReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {

    builder.addCase(searchPlaceByPostalCode.fulfilled, (state, action) => {
      state.placeByPostalCode = {
        ...state.placeByPostalCode,
        [action.payload?.postalCode] : action.payload?.data
      };
      state.searchPlaceByPostalCodeRequest = false;
    })

    builder.addCase(searchPlaceByPostalCode.rejected, (state, action) => {
      state.searchPlaceByPostalCodeError = action.payload.error;
      state.searchPlaceByPostalCodeRequest = false;
    })

    builder.addCase(searchPlaceByPostalCode.pending, (state) => {
      state.searchPlaceByPostalCodeRequest = true;
      state.searchPlaceByPostalCodeError = null;
    })
  }
});

export default zipPopotamReducer.reducer;
