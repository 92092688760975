import { getAxiosInstance } from "../Config/axios";
import { downloadExcelBase64File, downloadPDFBase64File } from "../Helpers";

export const getInsuranceCompanyService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'insurance-company/get/all'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const getAllInsuranceCompanyService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'insurance-company'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const editInsuranceCompanyService = async ({ data, id }) => {
    try {
        const method = 'PUT';
        const url = `insurance-company/${id}`;
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const destroyInsuranceCompanyService = async ({ id }) => {
    try {
        const method = 'DELETE';
        const url = `insurance-company/${id}`;
        const axios = getAxiosInstance();
        const options = {
            method,
            url
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};


export const createInsuranceCompanyService = async ({ data }) => {
    try {
        const method = 'POST';
        const url = 'insurance-company';
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const getInsuranceCompanyByFilterService = async (data) => {
    try {
        const method = 'POST';
        const url = 'insurance-company/get/by-filter';
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const getInsuranceCompanyPDFReportService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: data.parameters,
            url: 'download/payment-pdf'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Payment ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return result.file;
        
    } catch (error) {
        throw error
    }
};

export const getInsuranceCompanyExcelReportService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: data,
            url: `download/payment-excel`
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        downloadExcelBase64File(result.data.file.replace('data:application/vnd.ms-excel;base64,', ''), `Insurance Report.xlsx`);

        return result.file;
        
    } catch (error) {
        throw error
    }
};
