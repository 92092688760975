import React, { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Route, Navigate, Routes } from 'react-router-dom'
import { getLocalStorage, parseJwt, setLocalStorage } from './../Helpers';
import { setLoginData, reducerName as userReducerName } from './../Store/Reducers/user';
import { ReactReduxContext } from 'react-redux';
import routes from './../Router';

const Index = () => {
	const { store } = useContext(ReactReduxContext)
	const roles = useSelector((state)=>state[userReducerName].data?.user?.roles || []).map((roleData)=> roleData.name);
	const routesByRoles = routes.filter((routeData)=> !routeData.roles || routeData.roles?.some((rol)=> roles.includes(rol)));
	
	useEffect(()=>{
		const localStorageData = getLocalStorage();
		
		if (parseJwt(localStorageData?.token).exp < new Date()/1000) {
			store.dispatch(setLoginData(null));
			setLocalStorage({});
			return;
		}

		store.dispatch(setLoginData(localStorageData));
	}, [store]);

	return <Routes>
		{routesByRoles.map((route, i) => {
			return <Route
				exact
				key={i}
				path={route.path}
				element={<route.layout component={route.component} />}
			/>
		})}
		<Route
			exact
			path="*"
			element={<Navigate to="/login" />}
		/>
	</Routes>
}

export default Index