import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getPayrollDeductionService,
  downloadReportAllService,
  downloadReportByMemberIdService,
  downloadPayrollAuthorizationService
} from './../../Services/payrollDeductionService'

export const reducerName = 'payrollDeduction';

const initialState = {
  
  getPayrollDeductionData: null,
  getPayrollDeductionRequest: null,
  getPayrollDeductionError: null,

  downloadReportAllData: null,
  downloadReportAllRequest: false,
  downloadReportAllError: null,

  downloadReportByIdData: null,
  downloadReportByIdRequest: false,
  downloadReportByIdError: null
};

export const getPayrollDeduction = createAsyncThunk(
  'payrollDeduction/get',
  async (memberId, { rejectWithValue }) => {
    try {
      
      const response = await getPayrollDeductionService(memberId);

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);


export const downloadReportAll = createAsyncThunk(
  'payrollDeduction/downloadReportAll',
  async (data, { rejectWithValue }) => {
    try {
      
      const response = await downloadReportAllService(data);

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const downloadPayrollAuthorization = createAsyncThunk(
  'payrollDeduction/downloadPayrollAuthorization',
  async (data, { rejectWithValue }) => {
    try {
      
      const response = await downloadPayrollAuthorizationService(data);

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);



export const downloadReportByMemberId = createAsyncThunk(
  'payrollDeduction/downloadReportByMemberId',
  async (parameters, { rejectWithValue }) => {
    try {

      const response = await downloadReportByMemberIdService(parameters);

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);


export const payrollDeductionReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {

    builder.addCase(getPayrollDeduction.fulfilled, (state, action) => {
      state.getData = action.payload?.data;
      state.getRequest = false;
    })

    builder.addCase(getPayrollDeduction.rejected, (state, action) => {
      state.getError = action.payload.error;
      state.getRequest = false;
      state.getData = null;
    })

    builder.addCase(getPayrollDeduction.pending, (state) => {
      state.getRequest = true;
      state.getError = null;
      state.getData = null;
    })

    builder.addCase(downloadReportAll.fulfilled, (state, action) => {
      state.downloadReportAllData = action.payload?.data;
      state.downloadReportAllRequest = false;
    })

    builder.addCase(downloadReportAll.rejected, (state, action) => {
      state.downloadReportAllError = action.payload.error;
      state.downloadReportAllRequest = false;
      state.downloadReportAllData = null;
    })

    builder.addCase(downloadReportAll.pending, (state) => {
      state.downloadReportAllRequest = true;
      state.downloadReportAllError = null;
      state.downloadReportAllData = null;
    })

    builder.addCase(downloadReportByMemberId.fulfilled, (state, action) => {
      state.downloadReportByIdData = action.payload?.data;
      state.downloadReportByIdRequest = false;
    })

    builder.addCase(downloadReportByMemberId.rejected, (state, action) => {
      state.downloadReportByIdError = action.payload.error;
      state.downloadReportByIdRequest = false;
      state.downloadReportByIdData = null;
    })

    builder.addCase(downloadReportByMemberId.pending, (state) => {
      state.downloadReportByIdRequest = true;
      state.downloadReportByIdError = null;
      state.downloadReportByIdData = null;
    })
  }
});



export default payrollDeductionReducer.reducer;
