import { NUMBER, DECIMAL } from './../../../../Components/Forms/constant_form';
import Dollar from './../../../../Assets/Img/Icons/dollar.svg'

export const newPolicyFormFields = [
	{
		forms: [
      {
				label: 'Insurance Company',
				placeholder: 'Type insurance company',
				type: 'select',
				col: 'col-sm-6 col-12',
				reduxInfo: {
					reducer: 'insuranceCompany',
					key: 'data'
				},
				required: true,
				validate: {
					type: NUMBER
				},
				name: 'insurance_company_id',
				options: []
			},
			{
				label: 'Insurance Policies',
				placeholder: 'Select a policy',
				type: 'select',
				col: 'col-sm-6 col-12',
				required: true,
				validate: {
					type: NUMBER
				},
				name: 'policy_id',
				options: [],
				reduxInfo: {
					reducer: 'policy',
					key: 'dataPolicyByCompany'
				}
			},
			{
				label: 'Date Added',
				placeholder: 'Select date',
				type: 'date',
				component: 'member',
				col: 'col-sm-6 col-12',
				required: true,
				name: 'join_date',
				options: []
			},
			{
				label: 'Cost',
				icon: <img className="icon pa-2" alt="login icon" src={Dollar} />,
				type: 'text',
				col: 'col-sm-6 col-12',
				required: true,
				validate: {
					type: DECIMAL
				},
				name: 'cost',
				options: []
			},
			{
				label: 'Reason',
				placeholder: 'Type reason',
				type: 'select',
				col: 'col-sm-6 col-12',
				required: true,
				name: 'reason_id',
				reduxInfo: {
					reducer: 'reason',
					key: 'data'
				},
				options: []
			}			

		]

	}

];

export const cancellationFormFields = [
	{
		forms: [
			{
				label: 'Reason',
				placeholder: 'Type reason',
				type: 'select',
				col: 'col-sm-12 col-12',
				required: true,
				name: 'reason_id',
				reduxInfo: {
					reducer: 'reason',
					key: 'data'
				},
				options: []
			},
			{
				label: 'Description',
				placeholder: 'Type description',
				type: 'textarea',
				col: 'col-sm-12 col-12',
				required: true,
				name: 'description'
			}			

		]

	}

];
