import { createSlice } from '@reduxjs/toolkit';
import { adminTabList } from './../../Pages/Dashboard/Members/constants'

export const reducerName = 'memberForm';

const initialState = {
  form: {},
  activeTabSlug: adminTabList[0].slug
};

const editFormFN = (state, action) => {

  if(!state.form[action.payload?.formName]) {
    state.form[action.payload?.formName] = {};
  }

  state.form[action.payload?.formName] = {
    ...state.form[action.payload?.formName],
    [action.payload?.fieldName]: action.payload?.fieldValue
  }
}

const setActiveTabSlugFN = (state, action) => {
  state.activeTabSlug = action.payload?.slug;
}

const setFormInitialStateFN = (state) => {
  state.form = initialState.form;
}

export const memberFormReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    editForm: editFormFN,
    setActiveTabSlug: setActiveTabSlugFN,
    setInitialStateForm: setFormInitialStateFN
  }
});

export const { editForm, setActiveTabSlug, setInitialStateForm } = memberFormReducer.actions


export default memberFormReducer.reducer;
