import { NUMBER, STRING, ALPHANUMERIC, EMAIL, DECIMAL } from './../../../Components/Forms/constant_form';

export const MembersFormsPartOne = [
	{
		label: "Name",
		forms: [
			{
				label: 'Title',
				placeholder: 'Select a Title',
				type: "select",
				col: 'col-12 col-md-6',
				component: 'member',
				required: false,
				validate: {
					type: NUMBER
				},				
				name: 'title_id',
				options: [],
				reduxInfo: {
					reducer: 'title',
					key: 'data'
				}
			},
			{
				label: 'Last Name',
				placeholder: 'Last Name',
				type: "text",
				col: 'col-sm-6 col-12',
				component: 'member',
				required: true,
				validate: {
					type: STRING
				},
				name: 'last_name',
				options: []
			},
			{
				label: 'First Name',
				placeholder: 'First Name',
				type: "text",
				component: 'member',
				col: 'col-sm-6 col-12',
				required: true,
				validate: {
					type: STRING
				},				
				name: 'first_name',
				options: []
			},
			{
				label: 'Middle',
				placeholder: 'Middle',
				type: "text",
				component: 'member',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},				
				name: 'middle_name',
				options: []
			},
			{
				label: 'Suffix',
				subtitle: '(Type in custom Suffix)',
				placeholder: 'Suffix',
				type: "text",
				component: 'member',
				col: 'col-sm-12 col-12',
				required: false,
				validate: {
					type: STRING
				},				
				name: 'suffix',
				options: []
			}			

		]

	},
	{
		label: "Address",
		forms: [
			{
				label: 'Address',
				subtitle: '(1)',
				component: 'address',
				placeholder: 'Address (1)',
				type: "text",
				col: 'col-sm-12 col-12',
				required: false,
				validate: {
					type: ALPHANUMERIC
				},				
				name: 'address_line_1',
				options: []
			},
			{
				label: 'Address',
				subtitle: '(2)',
				component: 'address',
				placeholder: 'Address (2)',
				type: "text",
				col: 'col-sm-12 col-12',
				required: false,
				validate: {
					type: ALPHANUMERIC
				},				
				name: 'address_line_2',
				options: []
			},
			{
				label: 'Zip Code',
				placeholder: 'Zip Code',
				component: 'address',
				type: "text",
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},				
				name: 'zip_code'
			},
			{
				label: 'State',
				placeholder: 'State',
				type: "text",
				component: 'address',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},				
				name: 'state'
			},
			{
				label: 'City',
				placeholder: 'City',
				type: "text",
				component: 'address',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: STRING
				},				
				name: 'city'
			}
		]
	},
	{
		label: 'Contact Details',
		forms: [
			{
				label: 'Home Phone',
				type: 'mask',
				placeholder: 'Home Phone',
				col: 'col-sm-6 col-12',
				required: false,
				component: 'member',
				name: 'home_phone'
			},
			{
				label: 'Cell Phone',
				type: 'mask',
				placeholder: 'Cell Phone',
				col: 'col-sm-6 col-12',
				required: false,
				component: 'member',
				name: 'cell_phone'
			},
			{
				label: 'Email Address',
				type: 'text',
				placeholder: 'Email Address',
				col: 'col-sm-12 col-12',
				required: true,
				disabled: true,
				validate: {
					type: EMAIL
				},
				component: 'user',
				name: 'email'
			}

		]

	}

];

export const MembersFormsPartTwo = [
	{
		label: "Personal",
		forms: [
			{
				label: 'Date of Birth',
				type: 'date',
				placeholder: 'Date of Birth',
				col: 'col-sm-6 col-12',
				required: false,
				component: 'member',
				name: 'birth_date'
			},
			{
				label: 'Gender',
				type: 'select',
				placeholder: 'Select a Gender',
				col: 'col-sm-6 col-12',
				required: false,
				reduxInfo: {
					reducer: 'gender',
					key: 'data'
				},
				validate: {
					type: NUMBER
				},
				getDefaultValue: (managementData) => {
					
					if(!managementData.gender){
						return;
					}
					
					return managementData.gender.id;
				},
				name: 'gender_id',
				options: []
			},
			{
				label: 'Race',
				type: 'select',
				placeholder: 'Select a Race',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: NUMBER
				},
				component: 'member',
				reduxInfo: {
					reducer: 'ethnicity',
					key: 'data'
				},
				name: 'ethnicity_id',
				options: []
			}
		]
	},{
		label: null,
		forms: [
			{
				label: 'Union Status',
				placeholder: 'Union Status',
				type: 'select',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: NUMBER
				},				
				name: 'union_membership_status_id',
				component: 'jobUnionStatus',
				componentKey: 'id',
				reduxInfo: {
					reducer: 'unionMembershipStatus',
					key: 'data'
				},	
				options: [],
				groupBy: (options) => {

					const groups = [];

					groups.push({
						label: 'Primary Statuses',
						options: options.filter((opt)=> [1,2,6,7].includes(opt.id))
					});

					groups.push({
						label: 'Temporary Statuses',
						options: options.filter((opt)=> [3, 4, 5, 18, 15, 16, 8, 9, 10, 14, 17].includes(opt.id))
					});

					groups.push({
						label: 'New Member Statuses',
						options: options.filter((opt)=> [12, 11, 13].includes(opt.id))
					});

					return groups;
				}
			},
			{
				label: 'Join Date',
				placeholder: 'Join Date',
				type: 'date',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
				},
				component: 'member',		
				name: 'join_date',
				options: []
			},
			{
				label: 'Sick Plan',
				placeholder: 'Select a Sick Plan',
				type: 'select',
				col: 'col-sm-6 col-12',
				required: false,
				component: 'member',
				validate: {
					type: NUMBER
				},				
				name: 'sick_plan_id',
				reduxInfo: {
					reducer: 'sickPlan',
					key: 'data'
				},
				options: []
			},
			{
				label: 'Retire Date',
				placeholder: 'Retire Date',
				type: 'date',
				col: 'col-sm-6 col-12',
				required: false,
				component: 'member',
				validate: {
				},				
				name: 'retire_date',
				options: []
			}		

		]

	},
	{
		label: "IAFF Payments",
		forms: [
			{
				label: 'IAFF Benefits',
				disabled: true,
				placeholder: 'IAFF Benefits',
				type: 'text',
				col: 'col-sm-6 col-12',
				component: 'member',
				required: false,
				validate: {
					type: DECIMAL
				},				
				name: 'iaff',
				options: []
			},
			{
				label: 'UNIAFF Dues',
				disabled: true,
				placeholder: 'UNIAFF Dues',
				type: "text",
				col: 'col-sm-6 col-12',
				component: 'member',
				required: false,
				validate: {
					type: DECIMAL
				},				
				name: 'uniaff',
				options: []
			}
		]
	},
	{
		label: "Disability",
		forms: [
			{
				label: 'Status',
				placeholder: 'Select a Status',
				type: "select",
				col: 'col-sm-6 col-12',
				required: false,
				component: 'disabilities',
				getDefaultValue: (managementData) => {
					
					if(!managementData['disabilities'] || !Array.isArray(managementData['disabilities'])){
						return;
					}

					const arrayData = JSON.parse(JSON.stringify(managementData['disabilities']));

					const value = arrayData.sort( (a, b) => {
						return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
					})[managementData['disabilities']?.length -1]?.['disability_id']
					
					return value;
				},
				validate: {
					type: NUMBER
				},
				reduxInfo: {
					reducer: 'disability',
					key: 'data'
				},
				name: 'disability_id',
				options: []
			},
			{
				label: 'Last Worked',
				placeholder: 'Last Worked',
				component: 'disabilities',
				getDefaultValue: (managementData) => {
					
					if(!managementData['disabilities'] || !Array.isArray(managementData['disabilities'])){
						return;
					}

					const arrayData = JSON.parse(JSON.stringify(managementData['disabilities']));

					return arrayData.sort( (a, b) => {
						return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
					})[managementData['disabilities']?.length -1]?.['last_day_worked']
				},
				type: "date",
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
				},				
				name: 'last_day_worked',
				options: []
			},
			{
				label: 'Start Date',
				placeholder: 'Start Date',
				getDefaultValue: (managementData) => {
					
					if(!managementData['disabilities'] || !Array.isArray(managementData['disabilities'])){
						return;
					}

					const arrayData = JSON.parse(JSON.stringify(managementData['disabilities']));

					return arrayData.sort( (a, b) => {
						return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
					})[managementData['disabilities']?.length -1]?.['start_date']
				},
				type: "date",
				component: 'disabilities',
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
				},				
				name: 'start_date',
				options: []
			},
			{
				label: 'End Date',
				placeholder: 'End Date',
				component: 'disabilities',
				getDefaultValue: (managementData) => {
					
					if(!managementData['disabilities'] || !Array.isArray(managementData['disabilities'])){
						return;
					}

					const arrayData = JSON.parse(JSON.stringify(managementData['disabilities']));

					return arrayData.sort( (a, b) => {
						return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
					})[managementData['disabilities']?.length -1]?.['end_date']
				},
				type: "date",
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
				},				
				name: 'end_date',
				options: []
			}
		]
	},
	{
		label: "ID Numbers",
		forms: [
			{
				label: 'Social Security#',
				placeholder: 'Social Security#',
				type: "mask",
				component: "member",
				col: 'col-sm-6 col-12',
				required: true,	
				name: 'social_security_number',
				mask: '999-99-9999'
			},
			{
				label: 'Employee Number',
				placeholder: 'Employee Number',
				component: "member",
				type: "text",
				col: 'col-sm-6 col-12',
				required: false,
				validate: {
					type: NUMBER
				},				
				name: 'employee_number',
				options: []
			},
			{
				label: 'IAFF Member #',
				placeholder: 'IAFF Member #',
				component: "member",
				type: "text",
				col: 'col-sm-6 col-12',
				required: true,
				validate: {
					type: NUMBER
				},				
				name: 'iaff_member_number',
				options: []
			}
		]
	}
];

export const disabledFieldsByMemberRole = [
	'last_day_worked',
	'start_date',
	'end_date',
	'iaff',
	'uniaff',
	'union_membership_status_id',
	'join_date',
	'retire_date',
	'sick_plan_id',
	'employee_number',
	'iaff_member_number'
];
