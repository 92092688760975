import React, { useEffect } from 'react'
import TableComponent from '../../../Components/table_v2'
import TableHeader from './table_header';
import moment from 'moment';
import LayoutHeaderPage from '../../../Layouts/header_page'
import { useSelector, useDispatch } from 'react-redux'
import {
	getJobUnionStatusChanges,
  reducerName as jobUnionStatusReducerName,
	getJobUnionStatusExcelReport,
	getJobUnionStatusPDFReport
} from './../../../Store/Reducers/jobUnionStatus'

const ShowStatusChanges = () => {

  const dispatch = useDispatch();
	const statusChangesData = useSelector((state)=> state[jobUnionStatusReducerName].statusChangesData || []);
	const statusChangesFilteredData = useSelector((state)=> state[jobUnionStatusReducerName].statusChangesFilteredData);
	const startDateFilter = useSelector((state)=> state[jobUnionStatusReducerName].startDateFilter);
	const endDateFilter = useSelector((state)=> state[jobUnionStatusReducerName].endDateFilter);
  const tableColumns = [
		{
			title: 'First Name',
			sort: true,
      value: 'first_name'
		},
		{
			title: 'Middle Name',
      value: 'middle_name'
		},
		{
			title: 'Last Name',
      value: 'last_name'
		},
		{
			title: 'Suffix',
      value: 'suffix'
		},
		{
			title: 'Union Status',
      value: 'unionStatus'
		},
		{
			title: 'Date Of Change',
      value: 'updated_at',
			parseValue: (value) => {
				var date = moment(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		}
	];


	useEffect(() => {

		if(!endDateFilter || !startDateFilter) {
			return;
		}

		dispatch(getJobUnionStatusChanges({
			start_date: startDateFilter.toISOString().slice(0, 10),
			end_date: endDateFilter.toISOString().slice(0, 10)
		}));

	},[startDateFilter, endDateFilter, dispatch]);
	
	const getParameters = () => ({
		start_date: startDateFilter.toISOString().slice(0, 10),
		end_date: endDateFilter.toISOString().slice(0, 10)
	});

	const handlePDFReport = () => {
		const data =  {
			parameters: getParameters(),
		};
		dispatch(getJobUnionStatusPDFReport(data))
	}

	const handleExcelReport = () => {
	  dispatch(getJobUnionStatusExcelReport(getParameters()))
	}

  return (
    <>
      <LayoutHeaderPage title="Show Status Changes" />
      <div className="tab-content container mt-3 pt-3" id="pills-tabContent">
        <TableComponent
          columns={tableColumns}
          data={statusChangesFilteredData || statusChangesData}
          header={TableHeader}
        />
				<div className='row pb-3 pt-3'>
					<div className='col-2 show-on-mobile'></div>
					<div className='col-8 col-lg-3 mb-3'>
						<button type='button' disabled={!startDateFilter || !endDateFilter} onClick={handlePDFReport} className='btn-blue btn btn-default btn-primary'>View PDF</button>
					</div>
					<div className='col-2 show-on-mobile'></div>
					<div className='col-2 show-on-mobile'></div>
					<div className='col-8 col-lg-3 mb-3'>
						<button type='button' disabled={!startDateFilter || !endDateFilter} onClick={handleExcelReport} className='btn-orange btn btn-default btn-primary'>Export Excel</button>
					</div>
				</div>
      </div>
    </>
  )
};

export default ShowStatusChanges;
