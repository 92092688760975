import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAddressService, getAddressPDFReportService, getAddressExcelReportService, destroyAddressService } from './../../Services/addressService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'address';

const initialState = {
  request: false,
  error: null,
  data: [],

  PDFReportError: null,
  PDFReportRequest: false,
  PDFReportData: null,
  
  ExcelReportError: null,
  ExcelReportRequest: false,
  ExcelReportData: null
};

export const destroyAddress = createAsyncThunk(
  'address/destroyAddress',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await destroyAddressService(parameters);
      const currentDate = new Date(parameters.dateFilter);
      const year = currentDate.getFullYear();
      const month = (`0${currentDate.getMonth() + 1}`).slice(-2);
      dispatch(getAddress({
        month: month,
        year: year
      }))
      dispatch(showNotification({
        message: 'Address deleted successfully',
        type: 'SUCCESS'
      }));
      return {
        data: response
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getAddress = createAsyncThunk(
  'address/getAddress',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getAddressService(parameters);

      return {
        data: response.addresses
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getAddressPDFReport = createAsyncThunk(
  'address/getAddressPDFReport',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getAddressPDFReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getAddressExcelReport = createAsyncThunk(
  'address/getAddressExcelReport',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await getAddressExcelReportService(parameters);

      return {
        data: response?.file
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setDataFilterReducer = (state, action) => {
  return {
    ...state,
    dataFiltered: action.payload.data
  }
};

const setDataFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearFilteredDataHandler = (state) => {
  return {
    ...state,
    dataFiltered: null
  }
};

const setDateFilterReducer = (state, action) => {
  return {
    ...state,
    dateFilter: action.payload.data
  }
};

const setDateFilterAction = (data) => {
  return {
    payload: {
      data
    }
  }
};


export const addressReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setDateFilter: {
      reducer: setDateFilterReducer,
      prepare: setDateFilterAction
    },
    setDataFilter: {
      reducer: setDataFilterReducer,
      prepare: setDataFilterAction
    },
    clearFilteredData: clearFilteredDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getAddress.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    });

    builder.addCase(getAddress.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    });

    builder.addCase(getAddress.pending, (state) => {
      state.request = true;
      state.error = null;
    });

    builder.addCase(getAddressPDFReport.fulfilled, (state, action) => {
      state.PDFReportData = action.payload?.data;
      state.PDFReportRequest = false;
    })

    builder.addCase(getAddressPDFReport.rejected, (state, action) => {
      state.PDFReportError = action.payload.error;
      state.PDFReportRequest = false;
      state.PDFReportData = null;
    })

    builder.addCase(getAddressPDFReport.pending, (state) => {
      state.PDFReportRequest = true;
      state.PDFReportError = null;
      state.PDFReportData = null;
    })

    builder.addCase(getAddressExcelReport.fulfilled, (state, action) => {
      state.ExcelReportData = action.payload?.data;
      state.ExcelReportRequest = false;
    })

    builder.addCase(getAddressExcelReport.rejected, (state, action) => {
      state.ExcelReportError = action.payload.error;
      state.ExcelReportRequest = false;
      state.ExcelReportData = null;
    })

    builder.addCase(getAddressExcelReport.pending, (state) => {
      state.ExcelReportRequest = true;
      state.ExcelReportError = null;
      state.ExcelReportData = null;
    })
  }
});
export const { setDataFilter, clearFilteredData, setDateFilter, setEndDateFilter } = addressReducer.actions

export default addressReducer.reducer;
