import { ALPHANUMERIC, DECIMAL } from './../../../../Components/Forms/constant_form';
import Dollar from './../../../../Assets/Img/Icons/dollar.svg'

export const formFields = [
	{
		forms: [
      {
				label: 'Name',
				placeholder: 'Type sick plan name',
				type: 'text',
				col: 'col-12',
				required: true,
				validate: {
					type: ALPHANUMERIC
				},
				name: 'name'
			},
			{
				label: 'Cost',
				icon: <img className="icon pa-2" alt="login icon" src={Dollar} />,
				type: 'text',
				col: 'col-12',
				required: true,
				validate: {
					type: DECIMAL
				},
				name: 'cost'
			}
		]

	}

];
