import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addMemberService,
  searchMemberService,
  searchMemberByIdService,
  updateMemberService,
  updateMemberPersonalDataService,
  updateMemberJobDataService,
  deleteMemberService,
  changePasswordService,
  changeMailService,
  getMemberService,
  addMemberPolicyService,
  getDashboardService
} from './../../Services/memberService'
import { showNotification } from './../Reducers/notification';
import { getBeneficiaryByMemberId } from './../Reducers/beneficiary';
import { getMemberInsurance } from './../Reducers/memberInsurance';
import { getMemberNote } from './../Reducers/memberNote';
import { setInitialStateForm } from './memberForm';

export const reducerName = 'member';

const initialState = {
  request: false,
  error: null,
  data: null,

  searchRequest: false,
  searchError: null,
  searchData: [],

  managementRequest: false,
  managementError: null,
  managementData: null,

  updateRequest: false,
  updateError: null,
  updateData: null,

  updatePersonalDataRequest: false,
  updatePersonalDataError: null,
  updatePersonalData: null,

  updateJobDataRequest: false,
  updateJobDataError: null,
  updateJobData: null,

  deleteMemberRequest: false,
  deleteMemberError: null,
  deleteMember: null,

  changePasswordRequest: false,
  changePasswordError: null,
  changePassword: null,

  changeEmailRequest: false,
  changeEmailError: null,
  changeEmail: null,

  getMemberData: [],
  getMemberError: null,
  getMemberRequest: false,

  addMemberPolicyError: null,
  addMemberPolicyRequest: false,
  addMemberPolicy: null
};

export const addMember = createAsyncThunk(
  'member/add',
  async (memberData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await addMemberService(memberData);

      dispatch(showNotification({
        message: `Success! The member '${response.first_name} ${response.last_name}' has been created correctly. The web username is ${response.email}`,
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const deleteMember = createAsyncThunk(
  'member/delete',
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState();
      const memberData = state[reducerName]?.['managementData']?.['member'];
      const response = await deleteMemberService(id);

      dispatch(showNotification({
        message: `Member ${memberData.first_name} ${memberData.last_name} deleted successfully`,
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {      
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const searchMember = createAsyncThunk(
  'member/search',
  async (searchData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = !!searchData?.term ? await searchMemberService(searchData) : [];

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const searchMemberById = createAsyncThunk(
  'member/search/id',
  async (memberId, { rejectWithValue, dispatch }) => {
    try {
      
      const response = !!memberId ? await searchMemberByIdService(memberId) : null;

      if(!!memberId) {
        dispatch(getBeneficiaryByMemberId(memberId));
        dispatch(getMemberInsurance(memberId));
        dispatch(getMemberNote(memberId));
      }

      dispatch(setInitialStateForm());
      
      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const updateMember = createAsyncThunk(
  'member/update',
  async (updateData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await updateMemberService(updateData.id, updateData.data);

      dispatch(showNotification({
        message: 'Member updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const updateMemberPersonalData = createAsyncThunk(
  'member/personal-info/update',
  async (updateData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await updateMemberPersonalDataService(updateData.id, updateData.data);
     
      dispatch(showNotification({
        message: 'Member updated successfully',
        type: 'SUCCESS'
      }));

      const memberData = await searchMemberByIdService(response.id);

      return {
        data: response,
        memberData
      };
    } catch (error) {
      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const updateMemberJobData = createAsyncThunk(
  'member/job-info/update',
  async (updateData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await updateMemberJobDataService(updateData.id, updateData.data);

      dispatch(showNotification({
        message: 'Member updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const changePassword = createAsyncThunk(
  'member/change-password',
  async (updateData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await changePasswordService(updateData.id, updateData.data);

      dispatch(showNotification({
        message: 'Password updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const changeMail = createAsyncThunk(
  'member/change-mail',
  async (updateData, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await changeMailService(updateData.id, updateData.data);

      dispatch(showNotification({
        message: 'Mail updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message[Object.keys(error.message)[0]][0],
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getMember = createAsyncThunk(
  'member/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getMemberService();

      return {
        data: response.map((member)=>({
          ...member,
          name: `${member.first_name} ${member.last_name}`
        }))
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const addMemberPolicy = createAsyncThunk(
  'member/addMemberPolicy',
  async (policyData, { rejectWithValue, dispatch}) => {
    try {
      
      const response = await addMemberPolicyService(policyData);

      dispatch(showNotification({
        message: `Success! The insurance was added`,
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getDashboard = createAsyncThunk(
  'member/getDashboard',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getDashboardService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const memberReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {

    builder.addCase(getMember.fulfilled, (state, action) => {
      state.getMemberData = action.payload?.data;
      state.getMemberRequest = false;
    })

    builder.addCase(getMember.rejected, (state, action) => {
      state.getMemberError = action.payload.error;
      state.getMemberRequest = false;
      state.getMemberData = null;
    })

    builder.addCase(getMember.pending, (state) => {
      state.getMemberRequest = true;
      state.getMemberError = null;
      state.getMemberData = null;
    })

    builder.addCase(addMember.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(addMember.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(addMember.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    })

    builder.addCase(searchMember.fulfilled, (state, action) => {
      state.searchData = action.payload?.data;
      state.searchRequest = false;
    })

    builder.addCase(searchMember.rejected, (state, action) => {
      state.searchError = action.payload.error;
      state.searchRequest = false;
      state.searchData = null;
    })

    builder.addCase(searchMember.pending, (state) => {
      state.searchRequest = true;
      state.searchError = null;
      state.searchData = null;
    })
    
    builder.addCase(searchMemberById.fulfilled, (state, action) => {
      state.managementData = action.payload?.data;
      if(action.payload?.data){
        state.deleteMember = null;
      }
      state.managementRequest = false;
    })

    builder.addCase(searchMemberById.rejected, (state, action) => {
      state.managementError = action.payload.error;
      state.managementRequest = false;
      state.managementData = null;
    })
    
    builder.addCase(searchMemberById.pending, (state) => {
      state.updateData = null;
      state.managementRequest = true;
      state.managementError = null;
      state.managementData = null;
    })

    builder.addCase(updateMember.fulfilled, (state, action) => {
      state.updateData = action.payload?.data;
      state.updateRequest = false;
    })

    builder.addCase(updateMember.rejected, (state, action) => {
      state.updateError = action.payload.error;
      state.updateRequest = false;
      state.updateData = null;
    })

    builder.addCase(updateMember.pending, (state) => {
      state.updateRequest = true;
      state.updateError = null;
      state.updateData = null;
    })

    builder.addCase(updateMemberPersonalData.fulfilled, (state, action) => {
      state.managementData = action.payload?.memberData;
      state.updatePersonalData = action.payload?.data;
      state.updatePersonalDataRequest = false;
    })

    builder.addCase(updateMemberPersonalData.rejected, (state, action) => {
      state.updatePersonalDataError = action.payload.error;
      state.updatePersonalDataRequest = false;
      state.updatePersonalData = null;
    })

    builder.addCase(updateMemberPersonalData.pending, (state) => {
      state.updatePersonalDataRequest = true;
      state.updatePersonalDataError = null;
      state.updatePersonalData = null;
    })

    builder.addCase(updateMemberJobData.fulfilled, (state, action) => {
      state.updateJobData = action.payload?.data;
      state.updateJobDataRequest = false;
    })

    builder.addCase(updateMemberJobData.rejected, (state, action) => {
      state.updateJobDataError = action.payload.error;
      state.updateJobDataRequest = false;
      state.updateJobData = null;
    })

    builder.addCase(updateMemberJobData.pending, (state) => {
      state.updateJobDataRequest = true;
      state.updateJobDataError = null;
      state.updateJobData = null;
    })

    builder.addCase(deleteMember.fulfilled, (state, action) => {
      state.deleteMember = action.payload?.data;
      state.searchData = [];
      state.managementData = null;
      state.deleteMemberRequest = false;
    })

    builder.addCase(deleteMember.rejected, (state, action) => {
      state.deleteMemberError = action.payload.error;
      state.deleteMemberRequest = false;
      state.deleteMember = null;
    })

    builder.addCase(deleteMember.pending, (state) => {
      state.deleteMemberRequest = true;
      state.deleteMemberError = null;
      state.deleteMember = null;
    })

    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.changePassword = action.payload?.data;
      state.changePasswordRequest = false;
    })

    builder.addCase(changePassword.rejected, (state, action) => {
      state.changePasswordError = action.payload.error;
      state.changePasswordRequest = false;
      state.changePassword = null;
    })

    builder.addCase(changePassword.pending, (state) => {
      state.changePasswordRequest = true;
      state.changePasswordError = null;
      state.changePassword = null;
    })

    builder.addCase(changeMail.fulfilled, (state, action) => {
      state.changeMail = action.payload?.data;
      state.changeMailRequest = false;
    })

    builder.addCase(changeMail.rejected, (state, action) => {
      state.changeMailError = action.payload.error;
      state.changeMailRequest = false;
      state.changeMail = null;
    })

    builder.addCase(changeMail.pending, (state) => {
      state.changeMailRequest = true;
      state.changeMailError = null;
      state.changeMail = null;
    })

    builder.addCase(addMemberPolicy.fulfilled, (state, action) => {
      state.addMemberPolicy = action.payload?.data;
      state.addMemberPolicyRequest = false;
    })

    builder.addCase(addMemberPolicy.rejected, (state, action) => {
      state.addMemberPolicyError = action.payload.error;
      state.addMemberPolicyRequest = false;
      state.addMemberPolicy = null;
    })

    builder.addCase(addMemberPolicy.pending, (state) => {
      state.addMemberPolicyRequest = true;
      state.addMemberPolicyError = null;
      state.addMemberPolicy = null;
    })

    builder.addCase(getDashboard.fulfilled, (state, action) => {
      state.getDashboard = action.payload?.data;
      state.getDashboardRequest = false;
    })

    builder.addCase(getDashboard.rejected, (state, action) => {
      state.getDashboardError = action.payload.error;
      state.getDashboardRequest = false;
      state.getDashboard = null;
    })

    builder.addCase(getDashboard.pending, (state) => {
      state.getDashboardRequest = true;
      state.getDashboardError = null;
      state.getDashboard = null;
    })
  }
});

//export const { setLoginData, logout } = memberReducer.actions

export default memberReducer.reducer