import { getAxiosInstance } from "../Config/axios";
import { downloadExcelBase64File, downloadPDFBase64File } from "../Helpers"

export const getLogService = async (parameters) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: parameters,
            url: 'log'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const deleteLogService = async (id) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'DELETE',
            url: `log/${id}`
        };
    
        const result = await axios(options);
        
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const getLogPDFReportService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: data.parameters,
            url: 'download/logs-pdf'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `General Log ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return result.file;
        
    } catch (error) {
        data.reportPage.close();
        throw error
    }
};

export const getLogExcelReportService = async (parameters) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: parameters,
            url: `download/logs-excel`
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        downloadExcelBase64File(result.data.file.replace('data:application/vnd.ms-excel;base64,', ''), `Log ${parameters.start_date.slice(0, 10)} to ${parameters.end_date.slice(0, 10)}.xlsx`);

        return result.file;
        
    } catch (error) {
        throw error
    }
};
