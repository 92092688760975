import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTitleService } from './../../Services/titleService'

export const reducerName = 'title';

const initialState = {
  request: false,
  error: null,
  data: null
};

export const getTitle = createAsyncThunk(
  'title/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getTitleService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);


export const titleReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {

    builder.addCase(getTitle.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getTitle.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getTitle.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    })
  }
});

export default titleReducer.reducer;
