import React, { useState, useContext } from 'react';
import { loginFormConfig, getRecaptchaConfig } from './../../Config/Forms/Login/index'
import GenerateForm from './../../Components/Forms/generate_form'
import ReCAPTCHA from 'react-google-recaptcha';
import LoginScreen from '../../Assets/Img/login-screen.png';
import Logo from '../../Assets/Img/HR_UT_Logo.png';
import { ReactReduxContext, useSelector } from 'react-redux';
import { fetchLogin, reducerName as userReducerName } from './../../Store/Reducers/user';
import { Navigate } from 'react-router-dom'

const recaptchaConfig = getRecaptchaConfig();
const recaptchaKey = recaptchaConfig.key;

const Login = () => {
	const { store } = useContext(ReactReduxContext);
	const [form, setForm] = useState({});
	const [showCaptchaError, setShowCaptchaError] = useState(false);
	const error = useSelector((state)=> state.user.error);
	const request = useSelector((state)=> state.user.request);
	const userData = useSelector((state)=> state[userReducerName].data);

	if(userData?.token && !userData?.request){
		const user = userData?.user;
		if(user.temporary_password === 1) {
			return <Navigate to="/dashboard/settings_change_password" />
		}

		return <Navigate to="/dashboard" />
	}

	const handleInputChange = (event) => {
		setForm({
				...form,
				[event.target.name] : event.target.value
		})
	};

	const handleLogin = (e) => {
		e.preventDefault();

		if(!form.reCaptchaToken) {
			return setShowCaptchaError(true);
		}

		store.dispatch(fetchLogin(form));
	};

	const setRecaptchaToken = (reCaptchaToken) => {
		setForm({ ...form, reCaptchaToken });
		reCaptchaToken && showCaptchaError && setShowCaptchaError(false);
	}

	return (
		<>
			<div className="row login-page">
					<div className="hide-on-mobile col-sm-12 col-lg-6 col-md-12" style={{
							backgroundSize: 'cover',
							backgroundImage: `url(${LoginScreen})`
						}}>
					</div>
					<div className="col-sm-12 col-lg-6 col-md-12">
						<div className="row">
							<div className="col-12 mb-3">
								<img className="login-logo" src={Logo} alt='Union Tracking Logo'></img>
							</div>
							<div className="col-12 center hide-on-mobile">
								<span className="login-text title">IAFF Local 2294</span>
								<span className="login-text subtitle">Member Access</span>
							</div>
							{ error && <div className="col-12 center pt-1">
								<span className="login-danger text-danger">{error}</span>
							</div>}
							<div className="col-12">
								<form onSubmit={handleLogin} className="login-form">
								{
									loginFormConfig.map((section, i) =>
										<div key={i}>
										<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
										{
											section.forms.map((form, i) => 
												<GenerateForm 
													key={i} 
													placeholder={form.placeholder} 
													validate={form.validate} 
													options={form.options} 
													required={form.required} 
													subtitle={form.subtitle} 
													type={form.type} 
													col={form.col} 
													name={form.name} 
													label={form.label}
													addon={form.addon}
													value={form.value ? form.value : ''}
													onChange={handleInputChange}/> 
											)
										}
										</div>
									)
								}
								<div className="pt-3">
									<div className="col-12 login-recaptcha-container">
										<ReCAPTCHA
											sitekey={recaptchaKey}
											onChange={setRecaptchaToken}
										/>
										{ showCaptchaError && <small id="passwordHelp" className="text-danger">Complete verification please</small> }
									</div>
									<div className="col-12">
									</div>
								</div>
								<div className="row pb-3 pt-3">
									<div className="col-1" />
									<div className="col-10">
										<button disabled={request} type="submit" className="btn-orange btn btn-default btn-primary">Login</button>
									</div>
								</div>
								</form>
							</div>
						</div>
					</div>
			</div>
		</>
	)
}

export default Login