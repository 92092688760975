import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUser,
	faEdit,
	faEnvelope,
	faCalendarMinus,
	faFlag,
	faLifeRing
} from '@fortawesome/free-regular-svg-icons';
import {
	faHashtag,
	faHouse,
	faPhone
} from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { reducerName as userReducerName } from '../../../Store/Reducers/user';
import { reducerName as memberReducerName } from '../../../Store/Reducers/member';
import { setActiveTabSlug } from './../../../Store/Reducers/memberForm';

const PersonalInfo = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userData = useSelector((state)=> state[userReducerName]?.data?.user || {});
	const dashboardData = useSelector((state)=> state[memberReducerName]?.getDashboard || {});
	const memberData = dashboardData.memberData || {};
	const member = memberData.member || {};
	const jobUnionStatus = memberData.jobUnionStatus || {};
	const activeAddressData = (member.addresses || []).filter((add) => add.status);
	const addressData = activeAddressData[activeAddressData.length -1] || {};
	const activeDisabilitiesData = (memberData.disabilities || []);
	const disabilityData = activeDisabilitiesData[activeDisabilitiesData.length -1] || {};

	const handleClickToMemberForm = (slug) =>{
		dispatch(setActiveTabSlug({slug}));
		navigate('/dashboard/members_member_management')
	}
	
	return (<>
		<div className="col-7 col-md-3  mb-3">
			<legend>Personal Info</legend>
		</div>
		<div className="col-5 col-md-9" style={{textAlign:'right'}}>
			<button onClick={() => handleClickToMemberForm('member-info')} className='gray-button'>
				<FontAwesomeIcon style={{marginRight: '3px', fontSize: '1.4em'}} icon={faEdit} /> Edit
			</button>
		</div>
		<div className="col-12 col-md-3">
			<FontAwesomeIcon style={{ top: '10px', position: 'relative', marginRight: '10px'}} size="lg" icon={faUser} />
			Name <br /> <label style={{marginLeft: '27.5px'}}>{member.first_name} {member.last_name}</label> <br /><br />
		</div>
		<div className="col-12 col-md-3">
			<FontAwesomeIcon style={{ top: '10px', position: 'relative', marginRight: '10px'}} size="lg" icon={faEnvelope} />
			Email <br /> <label style={{marginLeft: '27.5px'}}>{userData.email}</label> <br /><br />
		</div>
		<div className="col-12 col-md-3">
			<FontAwesomeIcon style={{ top: '10px', position: 'relative', marginRight: '10px'}} size="lg" icon={faCalendarMinus} />
			Union Status <br /> <label style={{marginLeft: '27.5px'}}>{jobUnionStatus?.name}</label> <br /><br />
		</div>
		<div className="col-12 col-md-3">
			<FontAwesomeIcon style={{ top: '10px', position: 'relative', marginRight: '10px'}} size="lg" icon={faHashtag} />
			Employee Number <br /> <label style={{marginLeft: '27.5px'}}>{member.employee_number}</label> <br /><br />
		</div>
		<div className="col-12 col-md-3">
			<FontAwesomeIcon style={{ top: '10px', position: 'relative', marginRight: '10px'}} size="lg" icon={faPhone} />
			Phone <br /> <label style={{marginLeft: '27.5px'}}>{member.home_phone}</label> <br /><br />
		</div>
		<div className="col-12 col-md-3">
			<FontAwesomeIcon style={{ top: '10px', position: 'relative', marginRight: '10px'}} size="lg" icon={faHouse} />
			Address <br /> <label style={{marginLeft: '27.5px'}}>{addressData.address_line_1} {addressData.address_line_2} {addressData.city} {addressData.state} {addressData.zip_code}</label> <br /><br />
		</div>
		<div className="col-12 col-md-3">
			<FontAwesomeIcon style={{ top: '10px', position: 'relative', marginRight: '10px'}} size="lg" icon={faFlag} />
			Disability Status <br /> <label style={{marginLeft: '27.5px'}}>{disabilityData.disability?.name}</label> <br /><br />
		</div>
		<div className="col-12 col-md-3 mb-5">
			<FontAwesomeIcon style={{ top: '10px', position: 'relative', marginRight: '10px'}} size="lg" icon={faLifeRing} />
			IAFF Number <br /> <label style={{marginLeft: '27.5px'}}>{member.iaff_member_number}</label> <br /><br />
		</div>
	</>)
}

export default PersonalInfo;
