import React from 'react'
import GenerateForm from './../../../../Components/Forms/generate_form'
import { useSelector, useDispatch } from 'react-redux';
import {
  reducerName as insuranceCompanyReducerName,
  editInsuranceCompany,
  createInsuranceCompany
} from './../../../../Store/Reducers/insuranceCompany';
import { formFields } from './constants'

const formName = 'insuranceCompanyForm';

const InsuranceCompanyForm = ({onSubmit, onCancel, index}) => {
  
  const dispatch = useDispatch();
  const insuranceCompanyData = useSelector((state)=> state[insuranceCompanyReducerName].editData);
  const editData = useSelector((state)=> state[insuranceCompanyReducerName].editData);
  const editRequest = useSelector((state)=> state[insuranceCompanyReducerName].editRequest);

  const handleInputChange = (event) => {
	};

  const handleFormSubmit = (e) => {
			
		e.preventDefault();

		if(document.querySelectorAll(`#${formName} input.is-invalid`).length) {
			document.querySelector(`#${formName} input.is-invalid`).focus();
			return;
		}
		
		const elements = e.target.elements;
		const formData ={};
		const fields = [];

		formFields.forEach((formMember)=>formMember.forms?.forEach((field)=>fields.push(field)))
		
		for (let i = 0; i < elements.length; i++) {

				const { name, value } = elements.item(i);

        if(!name.length) {
          continue;
        }

				const fieldConfiguration = fields.find((field)=> field?.name === name);

        if(fieldConfiguration?.required && !value) {
          elements.item(i).parentElement?.childNodes?.[2]?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]?.focus();
          return;
        }
        
        formData[name] = value;
				
		}

    if(editData.id) {
      dispatch(editInsuranceCompany({
        id: editData.id,
        data: formData
      }));
    } else {
      dispatch(createInsuranceCompany({
        data: formData
      }))
    }

		onSubmit();
	}


	return (<>
    <form id={formName} onSubmit={handleFormSubmit}>
      <div className="row">
          {
            formFields.map((section, i) =>
              <>
              <label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
              {
                section.forms.map((field)=>({
                  ...field,
                  disabled: !insuranceCompanyData,
                  defaultValue: insuranceCompanyData[field.name]
                })).map((form, i) => <GenerateForm
                  key={i}
                  {...form}
                  onChange={handleInputChange}
                  dataInputDates={()=>{}}
                /> )
              }
              </>					
            )
          }
      </div>
      <div className='row mt-5'>
        <div className='col-12 col-md-6 mb-3'>
          <button type='button' disabled={editRequest} onClick={onCancel} className='btn-light-grey btn btn-default btn-primary'>Back</button>
        </div>
        <div className='col-12 col-md-6 mb-3'>
          <button type='submit' disabled={editRequest} className='btn btn-default btn-primary'>Accept</button>
        </div>
      </div>
    </form>
  </>)
}

export default InsuranceCompanyForm;
