import React, { useState } from 'react';
import Header from './header';
import Menu from './menu_dashboard';


const AdminLayout = (props) => {
	const [showMenu, setShowMenu] = useState(false)

	const openMenu = () => {
		showMenu ? setShowMenu(false) : setShowMenu(true)
	}

	return (
		<>
			<div className="background-main">
				<Header openMenu={openMenu}/>
				<div className="row" style={{margin: 0, padding: 0}}>
					<div className={`col-12 ${window.innerWidth >= 1536 ? 'col-md-2' : 'col-md-3'}`} style={{margin: 0, padding: 0}}>
						<div className="content-pages">
							{
								showMenu ?
									<Menu toggleMenu={openMenu} show="content-menu-dashboard-show"/> 
								:
									<Menu toggleMenu={openMenu} show="content-menu-dashboard"/>
							}
						</div>
					</div>
					<div className={`col-12 ${window.innerWidth >= 1536 ? 'col-md-10' : 'col-md-9'}`} style={{margin: 0, padding: 0}}>
						<div className='row'>
							<div className='col-12 ut-content'>
								<props.component />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AdminLayout