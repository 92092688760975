import React, { useContext, useEffect, Fragment } from 'react'
import GenerateForm from './../../../../Components/Forms/generate_form'
import Notification from './../../../../Components/notification'
import { ReactReduxContext, useSelector } from 'react-redux';
import { reducerName as memberReducerName, changePassword } from './../../../../Store/Reducers/member';
import { changePasswordFormFields } from './constants'

const formName = 'changePasswordForm';

const ChangePasswordForm = ({ showTabWeb }) => {
  
  const { store } = useContext(ReactReduxContext);
  const managementData = useSelector((state)=> state[memberReducerName].managementData);
  const changePasswordData = useSelector((state)=> state[memberReducerName].changePassword);
  const changePasswordRequest = useSelector((state)=> state[memberReducerName].changePasswordRequest);


  const handleFormSubmit = (e) => {
			
		e.preventDefault();

		if(document.querySelectorAll(`#${formName} input.is-invalid`).length) {
			document.querySelector(`#${formName} input.is-invalid`).focus();
			return;
		}
		
		const elements = e.target.elements;
		const formData ={};
		const fields = [];

		changePasswordFormFields.forEach((formMember)=>formMember.forms?.forEach((field)=>fields.push(field)))
		
		for (let i = 0; i < elements.length; i++) {

      const { name, value } = elements.item(i);

      if(!name.length) {
        continue;
      }

      const fieldConfiguration = fields.find((field)=> field?.name === name);

      if(fieldConfiguration?.required && !value) {
        elements.item(i).parentElement?.childNodes?.[2]?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]?.focus();
        return;
      }
      
      formData[name] = value;
				
		}

    store.dispatch(changePassword({
      id: managementData?.member?.id,
      data: formData
    }))

	}

  useEffect(()=>{
    if(!!changePasswordData){
      const form = document.getElementById(formName);
      form?.reset();
    }
  }, [changePasswordData]);


	return (<>
    <form id={formName} onSubmit={handleFormSubmit}>
      <div className="row">
        <div className="col col-12">
          <div className="row">
            {
              changePasswordFormFields.map((section, i) =>
                <Fragment key={i}>
                <label className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
                {
                  section.forms.map((field)=>({
                    ...field,
                    disabled: (!managementData || !Object.keys(managementData).length) || field.disabled
                  })).map((form, i) => <GenerateForm
                    key={i}
                    {...form}
                    onChange={()=>{}}
                  /> )
                }
                </Fragment>					
              )
            }
          </div>
        </div>
      </div>
      <div className='row mt-5 mb-3'>      
        <Notification />
        <div className='col col-3'>
          <button type='button' disabled={changePasswordRequest} onClick={showTabWeb} className='btn-light-grey btn btn-default btn-primary'>Back</button>
        </div>
        <div className='col col-3'>
          <button type='submit' disabled={changePasswordRequest} className='btn-blue btn btn-default btn-primary'>Change password</button>
        </div>
      </div>
    </form>
  </>)
}

export default ChangePasswordForm