import React, {useEffect} from 'react';
import LayoutHeaderPage from '../../../Layouts/header_page'
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from './../../../Components/modal';
import Notification from './../../../Components/notification';
import { reducerName as memberReducerName, deleteMember, searchMemberById } from '../../../Store/Reducers/member';
import { reducerName as memberFormReducerName } from '../../../Store/Reducers/memberForm';
import { getTabListByRole } from './constants';
import { setActiveTabSlug } from '../../../Store/Reducers/memberForm';
import { reducerName as userReducerName } from './../../../Store/Reducers/user';
import { ROLES } from './../../../Router/Roles';
import { getNoteType } from './../../../Store/Reducers/noteType';
import { getSickPlan } from './../../../Store/Reducers/sickPlan';
import { getTitle } from './../../../Store/Reducers/title';
import { getGender } from './../../../Store/Reducers/gender';
import { getDisability } from './../../../Store/Reducers/disability';
import { getUnionMembershipStatus } from './../../../Store/Reducers/unionMembershipStatus';
import { getEthnicity } from './../../../Store/Reducers/ethnicity';
import { getPosition } from './../../../Store/Reducers/position';
import { getCertificate } from './../../../Store/Reducers/certificate';
import { getStatus } from './../../../Store/Reducers/status';
import { getStation } from './../../../Store/Reducers/station';
import { getBaseHour } from './../../../Store/Reducers/baseHour';
import { getReason } from './../../../Store/Reducers/reason';
import { getPolicy } from './../../../Store/Reducers/policy';
import { getInsuranceCompany } from './../../../Store/Reducers/insuranceCompany';

const MembersManagement = () => {
	const dispatch = useDispatch();

	useEffect(()=>{
    dispatch(getNoteType());
		dispatch(getGender());
		dispatch(getDisability());
		dispatch(getTitle());
		dispatch(getUnionMembershipStatus());
		dispatch(getSickPlan());
		dispatch(getPosition());
		dispatch(getEthnicity());
		dispatch(getCertificate());
		dispatch(getStatus());
		dispatch(getStation());
		dispatch(getBaseHour());
		dispatch(getReason());
    dispatch(getInsuranceCompany());
    dispatch(getPolicy());

		return () => {
			dispatch(searchMemberById(null));
		}
  }, [dispatch]);


	const searchError = useSelector((state)=> state[memberReducerName].searchError);
	const memberData = useSelector((state)=> state[memberReducerName].managementData);
	const roles = useSelector((state)=>state[userReducerName].data?.user?.roles || []).map((roleData)=> roleData.name);
	const tabsByRoles = getTabListByRole(roles);
	const deleteMemberData = useSelector((state)=> state[memberReducerName].deleteMember);
	const activeTabSlug = useSelector((state)=> state[memberFormReducerName].activeTabSlug);
	const selectedTab = tabsByRoles.find((tabData) => tabData.slug === activeTabSlug);
	const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }
	
	return (
		<>
			<LayoutHeaderPage titleClassName='col-12 col-md-5' title={ selectedTab?.title || `Member Management`} search={roles.includes(ROLES.developer) || roles.includes(ROLES.administrator)}/>
			<ModalComponent
				modalIsOpen={modalIsOpen}
				closeModal={closeModal}
				confirmModal={()=>{
					dispatch(deleteMember(memberData.member?.id));
					closeModal();
				}}
			/>

			{ deleteMemberData ? <Notification /> : null }
					
			{ !!searchError ? <div className="col-12 center pt-1">
							{ <span className="login-danger text-danger">{searchError}</span> }
					</div> : null}
			
			{ Object.keys(memberData || {})?.length ? <> 
				<ul className="nav nav-pills mb-3" role="tablist">
					{ tabsByRoles.map((tabData) => {
						return <li key={tabData.slug} className="nav-item" role="presentation">
							{
								typeof tabData.onClick === 'function' ?
								<button
									style={{
										color: tabData.labelColor
									}}
									className={`nav-link`}
									id={tabData.slug}
									onClick={ () => tabData.onClick(openModal)}
									type="button"
								>{tabData.label}</button>
								:
								<button
									style={{
										color: tabData.labelColor
									}}
									className={`nav-link ${tabData.slug === activeTabSlug ? 'active': ''}`}
									id={tabData.slug}
									data-bs-toggle="pill"
									data-bs-target={`#${tabData.slug}-content`}
									type="button"
									role="tab"
									aria-controls={`${tabData.slug}-content`}
									onClick={()=>{
										dispatch(setActiveTabSlug({slug: tabData.slug}))
									}}
									aria-selected={tabData.slug === activeTabSlug}
								>{tabData.label}</button>
							}
						</li>	
					})}
				</ul>
				<div className="tab-content">
					{ tabsByRoles.map((tabData) => {
						return <div key={tabData.slug} className={`tab-pane fade ${tabData.slug === activeTabSlug ? 'show active': ''}`} id={`${tabData.slug}-content`} role="tabpanel" aria-labelledby={tabData.slug}>
							{tabData.getComponent()}
						</div>
					})}
				</div> 
			</> : null }
		</>
	)
}

export default MembersManagement