export const ReportFormPartOne = [
	{
		label: "",
		forms: [
			{
				label: 'SELECTED MEMBER(S):',
				placeholder: 'Click to search by name or "Ctrl-Click" to select multiple',
				type: 'multiselect',
				col: 'col-sm-12 col-12',
				required: false,
				validate: {},
				name: 'selected_members',
				options: [],
				reduxInfo: {
					reducer: 'member',
					key: 'getMemberData'
				}
			}
		]

	}

]

export const ReportFormPartTwo = [
	{
		label: '',
		forms: [
			{
				label: 'Monthly Payroll Deductions Authorization',
				type: 'radio',
				col: 'col-sm-12 col-12',
				value: 'monthly_payroll',
				required: false,
				validate: {},
				name: 'monthly_payroll',
				options: []
			},
			{
				label: 'Payroll Deductions Breakdown',
				type: 'radio',
				col: 'col-sm-12 col-12',
				value: 'payroll_deduction_breakdown',
				required: false,
				validate: {},
				name: 'monthly_payroll',
				options: []
			}
		]

	}

]
