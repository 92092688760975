import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import {reducerName as userReducerName} from './../Store/Reducers/user';
import Logo from '../Assets/Img/Icons/logo.svg'
import listMenu from '../Config/menu.js'
import { useDispatch, useSelector} from 'react-redux';
import routes from './../Router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { logout } from '../Store/Reducers/user';

const MenuDashboard = (props) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const arrayUrl = location.pathname.split('/');
	const arrayTo = arrayUrl?.[2]?.split('_');
	const roles = useSelector((state)=>state[userReducerName].data?.user?.roles || []).map((roleData)=> roleData.name);
	const routesByRoles = routes.filter((routeData)=> routeData.roles?.some((rol)=> roles.includes(rol)));
	const menu = listMenu.filter((routeData)=> routeData.roles?.some((rol)=> roles.includes(rol)));

	return (
		<>
			<div className={`accordion-menu-overlay ${props.show}`}>
				<button className='overlay-close' onClick={props.toggleMenu}>X</button>
			</div>
			<nav className={`nav flex-column w-20 ${props.show} text-white mt-3 card--open`}>
					<div className="accordion" id="accordionExample">
						<div>
							<center>
								<img className="logo-on-dashboard mt-3 mb-3" alt="login icon" src={Logo} />
							</center>
						</div>
						<div className='accordion-tree'>
							<div className='accordion-item show-on-mobile mt-3' style={{paddingLeft: '8px', marginBottom:'5px'}}>
								<button className='list-group-item list-options logout' onClick={() => dispatch(logout())}>
									<h2 className='accordion-header'>
										<FontAwesomeIcon style={{marginRight: '5px'}} icon={faPowerOff} />
										Logout
									</h2>
								</button>
							</div>
							{
								menu.map((element, i) => 
										<div className="accordion-item" key={element.id}>
											<h2 className="accordion-header" id={`headingOne${element.id}`}>
												<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${element.id}`} aria-expanded={element.status} aria-controls={`collapseOne${element.id}`}>
													<img className="icon-info-user pa-2" alt="login icon" src={element.icon} />
													<center>{element.title}</center>
												</button>
											</h2>
											<div id={`collapseOne${element.id}`} className={ element.name === arrayTo?.[0] ? `accordion-collapse collapse text-white show` : `accordion-collapse collapse text-white`} aria-labelledby={`headingOne${element.id}`} data-bs-parent="#accordionExample">
												<div className={element.status ? `accordion-body content-list-options text-white list-options show` : `accordion-body content-list-options text-white list-options`}>
											<ul className="list-group ms-2">
											{
												routesByRoles.filter((routeData)=> {
													return routeData.module === element.name
												}).map(option =>
													arrayUrl[2] === option.to ?
													<Link to={option.path} key={option.path} className="list-group-item list-options current-route">{option.name}</Link>
													: 
													<Link to={option.path} key={option.path} className="list-group-item list-options">{option.name}</Link>
												)
											}
											</ul>				        
												</div>
											</div>
										</div>
								)
							}
						</div>
				</div>
			</nav>
		</>
	)
}

export default MenuDashboard