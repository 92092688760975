import { PASSWORD, EMAIL } from './../../../../Components/Forms/constant_form';

export const changePasswordFormFields = [
	{
		forms: [
      {
				label: 'Password',
				placeholder: 'Type new member account password',
				type: 'password',
				col: 'col-sm-4 col-12',
				required: true,
				validate: {
					type: PASSWORD
				},
				name: 'password'
			}
		]
	},
	{
		forms: [
			{
				label: 'Repeat Password',
				placeholder: 'Repeat Password',
				type: 'password',
				col: 'col-sm-4 col-12',
				required: true,
				validate: {
					type: PASSWORD
				},
				name: 'confirm_password',
				validateFieldName: 'password'
			}
		]
	}
];

export const changeMailFormFields = [
	{
		forms: [
      {
				label: 'Email',
				placeholder: 'Type new member username',
				type: 'text',
				col: 'col-sm-4 col-12',
				required: true,
				validate: {
					type: EMAIL
				},
				name: 'email'
			}
		]
	}
];
