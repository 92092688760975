import { getAxiosInstance } from "../Config/axios";
import { downloadPDFBase64File } from "../Helpers";

export const getPayrollDeductionService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'base-hour/get/all'
        };
    
        const result = await axios(options);
        
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const downloadReportAllService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            params: data.parameters,
            url: 'download/all-payroll-pdf'
        };
    
        const result = await axios(options);
        
        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Payroll ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const downloadReportByMemberIdService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: data.parameters,
            url: 'download/multi-payroll-pdf'
        };
    
        const result = await axios(options);
        
        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Multi payroll ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const downloadPayrollAuthorizationService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'POST',
            data: data.parameters,
            url: 'download/payroll-authorization-pdf'
        };
    
        const result = await axios(options);
        
        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Payroll authorization ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);
        
        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};
