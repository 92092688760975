import React, { useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeaderPage from '../../../Layouts/header_page'
import GenerateForm from '../../../Components/Forms/generate_form'
import Notification from '../../../Components/notification'
import { ChangePasswordFormsPartOne } from '../../../Config/Forms/Settings'
import { changePassword, reducerName } from './../../../Store/Reducers/user'


const ChangePassword = () => {

	const dispatch = useDispatch();
	const [dataForm, setDataForm] = useState({})
	
	const changePasswordRequest = useSelector((state)=> state[reducerName].changePasswordRequest);


	const handleInputChange = (event) => {
			setDataForm({
					...dataForm,
					[event.target.name] : event.target.value
			})
	}

	const sendData = async (e) => {
		e.preventDefault();

		if(document.querySelectorAll(`#formChangePassword input.is-invalid`).length) {
			document.querySelector(`#formChangePassword input.is-invalid`).focus();
			return;
		}

		dispatch(changePassword(dataForm));
	}

	return (
		<>
			<LayoutHeaderPage title="Change Password"/>
			<div className="tab-content container mt-4" id="pills-tabContent">
			<form id='formChangePassword' name='formChangePassword' onSubmit={sendData}>
			<div className="row p-5">
				<div className="col-sm-12 col-12">
					<div className="row">
						{
							ChangePasswordFormsPartOne.map((section, i) =>
								<Fragment key={i}>
								<label key={i} className={`title-label-section-forms ${i>0 ? 'mt-3': ''}`}>{section.label}</label>
								{
									section.forms.map((form, i) => 
										<GenerateForm 
											key={i} 
											{...form}
											onChange={handleInputChange}
										/> 
									)
								}
								</Fragment>					
							)
						}
					</div>
				</div>
			</div>
			<Notification />
			<div className="row pb-5 pt-3">
				<div className="col-12 col-md-3">
				</div>
				<div className="col-12 col-md-3">
				</div>
				<div className="col-2 col-md-3">
				</div>
				<div className="col-8 col-md-3">
					<button type="submit" disabled={changePasswordRequest} className="btn-blue btn btn-default btn-primary">Change Password</button>
				</div>
			</div>
			</form>			
			</div>
		</>
	)
}

export default ChangePassword