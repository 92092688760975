import React, { useEffect } from 'react';
import TableComponent from '../../../../Components/table_v2'
import {
	reducerName as memberInsuranceReducerName,
	getCancellationInsurance,
	destroyPolicyInsuranceById,
	getMemberPDFPolicy,
	getMemberExcelPolicy
} from '../../../../Store/Reducers/memberInsurance';
import { useDispatch, useSelector } from 'react-redux';
import { reducerName as memberReducerName } from '../../../../Store/Reducers/member';
import { reducerName as userReducerName } from '../../../../Store/Reducers/user';
import DeleteIcon from './../../../../Assets/Img/Icons/delete.jpg';
import ModalComponent from './../../../../Components/modal';
import Notification from './../../../../Components/notification';
import moment from 'moment';
import { ROLES } from '../../../../Router/Roles';

const CancellationsForm = ({ showInsuranceTable }) => {
	
	const dispatch = useDispatch();
	const memberCancellationInsuranceData = useSelector((state)=> state[memberInsuranceReducerName].getCancellationInsuranceData || []);
  const managementData = useSelector((state)=> state[memberReducerName].managementData);
	const roles = useSelector((state)=>state[userReducerName].data?.user?.roles || []).map((roleData)=> roleData.name);
	const hasMember = !roles?.includes(ROLES.developer) && !roles?.includes(ROLES.administrator);

	useEffect(()=>{
    !!managementData?.member?.id && dispatch(getCancellationInsurance(managementData.member.id));
  }, [managementData, dispatch])


	const tableColumns = [
		{
			title: 'Insurance Company',
			sort: true,
      value: 'insurance_company_id',
			getLabelFromStore: (storeData, index) => {
				return storeData[memberInsuranceReducerName]?.getCancellationInsuranceData?.[index]?.policy?.insurance_company?.name;
			}
		},
		{
			title: 'Insurance Policy',
      value: 'policy_id',
			getLabelFromStore: (storeData, index) => {
				return storeData[memberInsuranceReducerName]?.getCancellationInsuranceData?.[index]?.policy?.name;
			}
		},
		{
			title: 'Description',
      value: 'cancellation_description',
			getLabelFromStore: (storeData, index) => {
				return storeData[memberInsuranceReducerName]?.getCancellationInsuranceData?.[index]?.cancellation_description;
			}
		},
		{
			title: 'Reason',
      value: 'reason_id',
			getLabelFromStore: (storeData, index) => {
				return storeData[memberInsuranceReducerName]?.getCancellationInsuranceData?.[index]?.cancellation_reason?.name;
			}
		},
		{
			title: 'Date Cancelled',
      value: 'deleted_at',
			getLabelFromStore: (storeData, index) => {
				const value = storeData[memberInsuranceReducerName]?.getCancellationInsuranceData?.[index]?.deleted_at;
				var date = moment(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		},
		...( !hasMember ? [{
			title: 'Actions',
      value: 'actions',
			getLabelFromStore: (storeData, index) => {
				return <>
					<button 
						className='btn-icon'
						onClick={()=> {
							openModal();
							setIndex(index);
						}
					} disabled={false}>
						<img src={DeleteIcon} alt='Delete'/>Delete
					</button>
				</>
			}
		}]:[])
	];

	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [index, setIndex] = React.useState(null);

	const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

	const handleMemberPolicyPDF = () => {
		dispatch(getMemberPDFPolicy({
			memberId: managementData?.member?.id,
		}))
	}

	const handleMemberPolicyExcel = () => {
		dispatch(getMemberExcelPolicy(managementData?.member?.id))
	}


	return (<>
		<ModalComponent
			modalIsOpen={modalIsOpen}
			description={`Are you sure that you want to permanently delete the Member Insurance: ${memberCancellationInsuranceData?.[index]?.policy?.insurance_company?.name}`}
			closeModal={closeModal}
			confirmModal={()=>{
				dispatch(destroyPolicyInsuranceById({
					policyId: memberCancellationInsuranceData?.[index]?.id,
					memberId: managementData?.member?.id
				}))
				closeModal();
			}}
		/>
		<TableComponent
			columns={tableColumns}
			data={memberCancellationInsuranceData}
    />
		<Notification />
		<div className='row' hidden={!showInsuranceTable}>
			<div className='col col-6 col-lg-2  mb-3'>
				<button type='button' disabled={false} onClick={showInsuranceTable} className='btn-light-grey btn btn-default btn-primary'>Back</button>
			</div>
			<div className='col col-6 col-lg-3  mb-3'>
				<button type='button' disabled={false} onClick={handleMemberPolicyPDF} className='btn-blue btn btn-default btn-primary'>View PDF</button>
			</div>
			<div className='col col-6 col-lg-3  mb-3'>
				<button type='button' disabled={false} onClick={handleMemberPolicyExcel} className='btn-orange btn btn-default btn-primary'>Export Excel</button>
			</div>
		</div>
		<div className='row mb-3' hidden={!!showInsuranceTable}>
			<div className='col col-6 col-lg-9'>
			</div>
			<div className='col col-6 col-lg-3'>
				<button type='button' disabled={false} onClick={handleMemberPolicyPDF} className='btn-orange btn btn-default btn-primary'>View PDF</button>
			</div>
		</div>
	</>)
}

export default CancellationsForm