import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getMemberPoliciesByCancellationDate,
	setFilteredCancellationDataTable,
	clearFilteredCancellationDataTable,
	reducerName as memberInsuranceReducerName,
	setDateFilterInsuranceCancellationTable
} from '../../../Store/Reducers/memberInsurance';
import DatePicker from './../../../Components/Forms/datepicker'
import InputSearch from './../../../Components/Forms/input_search'

const TableHeader = () => {
	const dispatch = useDispatch();
	const policiesByCancellationDate = useSelector((state)=> state[memberInsuranceReducerName].memberPoliciesByCancellationDateData || []);
	const filteredCancellationDataTable = useSelector((state)=> state[memberInsuranceReducerName].filteredCancellationDataTable);
	const [startDate, setStartDate] = useState(new Date());

	const handleSearchFilter = (searchTerm) => (data) => {
		searchTerm = searchTerm.toLowerCase();

		return data?.first_name?.toLowerCase().includes(searchTerm) ||
			data?.last_name?.toLowerCase().includes(searchTerm) ||
			data?.description?.toLowerCase().includes(searchTerm) ||
			data?.suffix?.toLowerCase().includes(searchTerm) ||
			data?.insurance_company?.toLowerCase().includes(searchTerm) ||
			data?.policy?.toLowerCase().includes(searchTerm) ||
			data?.reason?.toLowerCase().includes(searchTerm);
	}


	return <>
		<div className='col-12 col-lg-1 mt-2'>
			<label htmlFor='year' className='form-label mt-2'>Date: </label>
		</div>
		<div className='col-12 mb-3 col-lg-3 mt-2'>
			<DatePicker
				date={startDate}
				onChange={(date) => {
					setStartDate(date);
					dispatch(setDateFilterInsuranceCancellationTable(date));
					const currentDate = date;
					const year = currentDate.getFullYear();
					const month = (`0${currentDate.getMonth() + 1}`).slice(-2);

					dispatch(getMemberPoliciesByCancellationDate({
						month: month,
						year: year
					}))
				}}
			/>
		</div>
		<div className='col-3 col-md-4 col-lg-1 mt-2 mb-3'>
			<label htmlFor='searchTable' className='form-label mt-2'>Search: </label>
		</div>
		<div className='col-9 col-md-4 col-lg-3 mt-2 mb-3'>
			<InputSearch
				onKeyUp={((event)=>{

					if(!event.target.value?.length) {
						filteredCancellationDataTable !== null && dispatch(clearFilteredCancellationDataTable(
							null
						));
						return;
					}

					dispatch(setFilteredCancellationDataTable(
						policiesByCancellationDate.filter(handleSearchFilter(event.target.value))
					));
				})}
			/>
		</div>
	</>
}



export default TableHeader