import React, { useState, useEffect } from 'react';
import InsuranceTable from './table';
import NewPolicyForm from './newPolicyForm';
import Cancellations from './cancellations';

const Insurance = () => {

  const [showNewPolicyForm, setShowNewPolicyForm] = useState(false);
  const [showInsuranceTable, setShowInsuranceTable] = useState(true);
  const [showCancellations, setShowCancellations] = useState(false);

  const handleShowNewPoliciForm = () => {
    setShowNewPolicyForm(true);
    setShowInsuranceTable(false);
    setShowCancellations(false);
  }
  
  const handleShowCancellations = () => {
    setShowNewPolicyForm(false);
    setShowInsuranceTable(false);
    setShowCancellations(true);
  }

  const handleShowInsuranceTable = () => {
    setShowNewPolicyForm(false);
    setShowInsuranceTable(true);
    setShowCancellations(false);
  }

  return (
    <div className='row'>

      {
        showNewPolicyForm ?
        <NewPolicyForm showInsuranceTable={handleShowInsuranceTable} /> :
        null
      }
      {
        showCancellations ?
        <Cancellations showInsuranceTable={handleShowInsuranceTable} /> :
        null
      }
      {
        showInsuranceTable ?
        <InsuranceTable showNewPoliciForm={handleShowNewPoliciForm} showCancellations={handleShowCancellations} /> :
        null
      }
    </div>
  )
}

export default Insurance;
