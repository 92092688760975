import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBaseHourService } from './../../Services/baseHourService'

export const reducerName = 'baseHour';

const initialState = {
  request: false,
  error: null,
  data: null
};

export const getBaseHour = createAsyncThunk(
  'baseHour/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getBaseHourService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);


export const baseHourReducer = createSlice({
  name: reducerName,
  initialState,
  extraReducers: (builder) => {

    builder.addCase(getBaseHour.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getBaseHour.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getBaseHour.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    })
  }
});

export default baseHourReducer.reducer;
