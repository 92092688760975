import { getLocalStorage  } from '../Helpers';
import axios from "axios";

const API_URL = process.env.REACT_APP_UNION_TRACKING_API_HOST;

export const getAxiosInstance = (baseUrl = API_URL, customHeaders = false) => {
  const storageData = getLocalStorage();
  const headers = customHeaders === false ? {
    Authorization: `Bearer ${storageData.token}`,
    'Access-Control-Allow-Origin': '*'
  } : customHeaders;
  
  const validateStatus = (status) => {
    const defaultt = status >= 200 && status < 300;
    const extra = status === 400
    return defaultt || extra;
  };

  const instance = axios.create({
    validateStatus,
    baseURL: baseUrl,
    headers: {
      common: headers
    }
  });

  instance.interceptors.response.use(response => {
    return response;
  }, error => {
   if (error.response.status === 401) {
    const modals=document.getElementsByClassName('ReactModalPortal');
    const sessionModal = modals[0];
    if(sessionModal){
      sessionModal.style.display = 'block';
    }
   }
   return error;
  });

  return instance;
}

