import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getPositionService,
  editPositionService,
  destroyPositionService,
  createPositionService
} from './../../Services/positionService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'position';

const initialState = {
  request: false,
  error: null,
  data: null
};

export const getPosition = createAsyncThunk(
  'position/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getPositionService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const editPosition = createAsyncThunk(
  'position/editPosition',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await editPositionService(parameters);

      dispatch(getPosition());
      dispatch(showNotification({
        message: 'Position updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };

    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const destroyPosition = createAsyncThunk(
  'position/destroyPosition',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await destroyPositionService(id);

      dispatch(getPosition());
      dispatch(showNotification({
        message: 'Position deleted successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const createPosition = createAsyncThunk(
  'position/createPosition',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await createPositionService(parameters);

      dispatch(getPosition());
      dispatch(showNotification({
        message: 'Position created successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setDataFilteredReducer = (state, action) => {
  return {
    ...state,
    dataFiltered: action.payload.data
  }
};

const setDataFilteredAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearDataFilteredReducer = (state) => {
  return {
    ...state,
    dataFiltered: null
  }
};

const setEditDataHandler = (state, data) => {
  return {
    ...state,
    editData: data.payload
  }
};

export const positionReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setDataFiltered: {
      reducer: setDataFilteredReducer,
      prepare: setDataFilteredAction
    },
    clearDataFiltered: clearDataFilteredReducer,
    setEditData: setEditDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getPosition.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getPosition.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getPosition.pending, (state) => {
      state.request = true;
      state.error = null;
    })
  }
});

export const { setDataFiltered, clearDataFiltered, setEditData } = positionReducer.actions

export default positionReducer.reducer;
