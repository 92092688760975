import { getAxiosInstance } from "../Config/axios";
import { downloadPDFBase64File } from "../Helpers";

export const getOfficerService = async () => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'officer/get/all'
        };
    
        const result = await axios(options);
        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const editOfficerService = async ({ data, id }) => {
    try {
        const method = 'PUT';
        const url = `officer/${id}`;
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const destroyOfficerService = async ({ id }) => {
    try {
        const method = 'DELETE';
        const url = `officer/${id}`;
        const axios = getAxiosInstance();
        const options = {
            method,
            url
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const createOfficerService = async ({ data }) => {
    try {
        const method = 'POST';
        const url = 'officer';
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const changeLetterheadService = async (data) => {
    try {
        const method = 'POST';
        const url = 'officer/change/letter-head';
        const axios = getAxiosInstance();
        const options = {
            method,
            url,
            data
        };
    
        const result = await axios(options);

        if(result?.data?.status === 400) {
            throw result.data;
        }

        document.getElementById("inputGroupFile01").value = "";

        return result?.data?.data;
        
    } catch (error) {
        throw error
    }
};

export const downloadOfficerLetterheadPDFService = async (data) => {
    try {
        const axios = getAxiosInstance();
        const options = {
            method: 'GET',
            url: 'download/officer-letterhead'
        };
    
        const result = await axios(options);

        if(!result?.data?.file) {
            throw new Error('No file');
        }

        const newDate = new Date().toISOString().slice(0,10).split('-');
        downloadPDFBase64File(result.data.file, `Officer letterhead ${newDate[1]}/${newDate[2]}/${newDate[0]}.pdf`);

        return result.file;
        
    } catch (error) {
        throw error
    }
};
