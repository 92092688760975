import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getStationService,
  editStationService,
  destroyStationService,
  createStationService
} from './../../Services/stationService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'station';

const initialState = {
  request: false,
  error: null,
  data: null
};

export const getStation = createAsyncThunk(
  'station/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getStationService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const editStation = createAsyncThunk(
  'station/editStation',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await editStationService(parameters);

      dispatch(getStation());
      dispatch(showNotification({
        message: 'Station updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };

    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const destroyStation = createAsyncThunk(
  'station/destroyStation',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await destroyStationService(id);

      dispatch(getStation());
      dispatch(showNotification({
        message: 'Station deleted successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const createStation = createAsyncThunk(
  'station/createStation',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await createStationService(parameters);

      dispatch(getStation());
      dispatch(showNotification({
        message: 'Station created successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setDataFilteredReducer = (state, action) => {
  return {
    ...state,
    dataFiltered: action.payload.data
  }
};

const setDataFilteredAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearDataFilteredReducer = (state) => {
  return {
    ...state,
    dataFiltered: null
  }
};

const setEditDataHandler = (state, data) => {
  return {
    ...state,
    editData: data.payload
  }
};

export const stationReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setDataFiltered: {
      reducer: setDataFilteredReducer,
      prepare: setDataFilteredAction
    },
    clearDataFiltered: clearDataFilteredReducer,
    setEditData: setEditDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getStation.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getStation.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getStation.pending, (state) => {
      state.request = true;
      state.error = null;
    })
  }
});

export const { setDataFiltered, clearDataFiltered, setEditData } = stationReducer.actions

export default stationReducer.reducer;
