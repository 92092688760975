import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getPolicyService,
  getPolicyByCompanyService,
  destroyPolicyService,
  createPolicyService,
  editPolicyService,
  editPolicyCostsService
} from './../../Services/policyService'
import { showNotification } from './../Reducers/notification';

export const reducerName = 'policy';

const initialState = {
  
  request: false,
  error: null,
  data: null,

  requestPolicyByCompany: false,
  errorPolicyByCompany: null,
  dataPolicyByCompany: null
};

export const getPolicy = createAsyncThunk(
  'policy/get',
  async (a, { rejectWithValue }) => {
    try {
      
      const response = await getPolicyService();

      return {
        data: response
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const getPolicyByCompany = createAsyncThunk(
  'policy/by-company',
  async (companyId, { rejectWithValue }) => {
    try {
      
      const response = !!companyId ? await getPolicyByCompanyService(companyId) : [];

      return {
        data: response,
        companyId: companyId
      };
    } catch (error) {
      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const editPolicy = createAsyncThunk(
  'insuranceCompany/editPolicy',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await editPolicyService(parameters);

      dispatch(getPolicyByCompany(parameters.data.insurance_company_id));

      dispatch(showNotification({
        message: 'Insurance policy updated successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };

    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const editPolicyCosts = createAsyncThunk(
  'insuranceCompany/editPolicyCosts',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await editPolicyCostsService(parameters);

      dispatch(getPolicyByCompany(parameters.data.insurance_company_id));

      return {
        data: response
      };

    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

export const destroyPolicy = createAsyncThunk(
  'insuranceCompany/destroyPolicy',
  async ({id, insuranceCompanyId}, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await destroyPolicyService(id);

      dispatch(getPolicyByCompany(insuranceCompanyId));

      dispatch(showNotification({
        message: 'Insurance policy deleted successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {
      
      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);

const setPoliciesByCompanyDataFilteredReducer = (state, action) => {
  return {
    ...state,
    policiesByCompanyDataFiltered: action.payload.data
  }
};

const setPoliciesByCompanyDataFilteredAction = (data) => {
  return {
    payload: {
      data
    }
  }
};

const clearPoliciesByCompanyDataFilteredReducer = (state) => {
  return {
    ...state,
    policiesByCompanyDataFiltered: null
  }
};

const setEditDataHandler = (state, data) => {
  return {
    ...state,
    editData: data.payload
  }
};

export const createPolicy = createAsyncThunk(
  'insuranceCompany/createPolicy',
  async (parameters, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await createPolicyService(parameters);

      dispatch(getPolicyByCompany(parameters.data.insurance_company_id));

      dispatch(showNotification({
        message: 'Insurance policy created successfully',
        type: 'SUCCESS'
      }));

      return {
        data: response
      };
    } catch (error) {

      dispatch(showNotification({
        message: error.message,
        type: 'ERROR'
      }));

      return rejectWithValue({
        error: error.message
      })
    }
  }
);


export const policyReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setPoliciesByCompanyDataFiltered: {
      reducer: setPoliciesByCompanyDataFilteredReducer,
      prepare: setPoliciesByCompanyDataFilteredAction
    },
    clearPoliciesByCompanyDataFiltered: clearPoliciesByCompanyDataFilteredReducer,
    setEditData: setEditDataHandler
  },
  extraReducers: (builder) => {

    builder.addCase(getPolicy.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.request = false;
    })

    builder.addCase(getPolicy.rejected, (state, action) => {
      state.error = action.payload.error;
      state.request = false;
      state.data = null;
    })

    builder.addCase(getPolicy.pending, (state) => {
      state.request = true;
      state.error = null;
      state.data = null;
    })

    builder.addCase(getPolicyByCompany.fulfilled, (state, action) => {
      state.dataPolicyByCompany = action.payload?.data;
      state.idPolicyByCompany = action.payload?.companyId;
      state.requestPolicyByCompany = false;
    })

    builder.addCase(getPolicyByCompany.rejected, (state, action) => {
      state.errorPolicyByCompany = action.payload.error;
      state.requestPolicyByCompany = false;
      state.dataPolicyByCompany = null;
    })

    builder.addCase(getPolicyByCompany.pending, (state) => {
      state.requestPolicyByCompany = true;
      state.errorPolicyByCompany = null;
    })
  }
});

export const { setPoliciesByCompanyDataFiltered, clearPoliciesByCompanyDataFiltered, setEditData } = policyReducer.actions

export default policyReducer.reducer;
