import Dashboard from '../Pages/Dashboard'; 
import MemberDashboard from '../Pages/Dashboard/MemberDashboard'; 
import Login from '../Pages/Login'; 
import LoadingReport from '../Pages/LoadingReport'; 
import MembersManagement from '../Pages/Dashboard/Members/'; 
import BulkAddressUpdate from '../Pages/Dashboard/Members/bulk_address_update'; 
import AddMembers from '../Pages/Dashboard/Members/add_members'; 
import BulkMemberAdd from '../Pages/Dashboard/Members/bulk_member_add'; 
import BulkMemberUpdate from '../Pages/Dashboard/Members/bulk_member_update'; 
import AddInsurance from '../Pages/Dashboard/Insurance/'; 
import CancellationData from '../Pages/Dashboard/Insurance/cancellation_data'; 
import GeneralLog from '../Pages/Dashboard/Status/general_log'; 
import ShowStatusChanges from '../Pages/Dashboard/Status/show_status_changes'; 
import MembersOnDisability from '../Pages/Dashboard/Disability'; 
import PayrollDeduction from '../Pages/Dashboard/Report'; 
import InsuranceReport from '../Pages/Dashboard/Report/insurance_report'; 
import AddressChangesReport from '../Pages/Dashboard/Report/address_changes_report'; 
import NewMembers from '../Pages/Dashboard/Report/new_members'; 
import CountyAssessment from '../Pages/Dashboard/Report/county_assessment'; 
import CustomReport from '../Pages/Dashboard/Report/custom_report'; 
import PrintLabelsandEnvelopes from '../Pages/Dashboard/Print'; 
import PrintLabelsandEnvelopesByUsers from '../Pages/Dashboard/Print/print_labels_and_envelopes_by_users'; 
import InsuranceCompanies from '../Pages/Dashboard/Settings/insurance_companies'; 
import InsurancePolicies from '../Pages/Dashboard/Settings/insurance_policies'; 
import SickPlans from '../Pages/Dashboard/Settings/sick_plans'; 
import Stations from '../Pages/Dashboard/Settings/stations'; 
import Positions from '../Pages/Dashboard/Settings/positions'; 
import GeneralSettings from '../Pages/Dashboard/Settings/general_settings'; 
import UnionInformation from '../Pages/Dashboard/Settings/union_information'; 
import Officers from '../Pages/Dashboard/Settings/officers'; 
import ChangePassword from '../Pages/Dashboard/Settings/change_password'; 
import { ROLES } from './Roles'; 
import AdminLayout from './../Layouts/admin_layout';
import MemberLayout from './../Layouts/member_layout';

const EmptyComponent = () => {
  return <div />;
};

const routes = [
  {
    path: '/report',
    component: EmptyComponent,
    layout: LoadingReport
  },
  {
    path: '/login',
    component: EmptyComponent,
    layout: Login
  },
  {
    path: '/dashboard',
    roles: [ROLES.developer, ROLES.administrator],
    component: Dashboard,
    layout: AdminLayout
  },
  {
    path: '/dashboard',
    layout: MemberLayout,
    roles: [ROLES.member],
    component: MemberDashboard
  },
  {
    path: '/dashboard/members_member_management',
    module: 'members',
    name: 'Member Management',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: MembersManagement
  },
  {
    path: '/dashboard/members_member_management',
    module: 'members',
    name: 'Member Management',
    roles: [ROLES.member],
    layout: MemberLayout,
    component: MembersManagement
  },
  {
    path: '/dashboard/members_add_member',
    module: 'members',
    name: 'Add Member',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: AddMembers
  },
  {
    path: '/dashboard/members_bulk_address_update',
    module: 'members',
    name: 'Bulk Address Update',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: BulkAddressUpdate
  },
  {
    path: '/dashboard/members_bulk_member_add',
    module: 'members',
    name: 'Bulk Member Add',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: BulkMemberAdd
  },
  {
    path: '/dashboard/members_bulk_member_update',
    module: 'members',
    name: 'Bulk Member Update',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: BulkMemberUpdate
  },
  {
    path: '/dashboard/insurance_add_insurance_for_member',
    name: 'Add insurance for member',
    module: 'insurance',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: AddInsurance
  },
  {
    path: '/dashboard/insurance_cancellation_data',
    name: 'Insurance Cancellation Data',
    module: 'insurance',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: CancellationData
  },
  {
    path: '/dashboard/disability_members_on_disability',
    module: 'disability',
    name: 'Disability',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: MembersOnDisability
  },
  {
    path: '/dashboard/status_show_status_changes',
    name: 'Show Status Changes',
    module: 'status',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: ShowStatusChanges
  },
  {
    path: '/dashboard/status_general_log',
    name: 'General Log',
    module: 'status',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: GeneralLog
  },
  {
    path: '/dashboard/report_payroll_deduction',
    name: 'Payroll Deductions',
    module: 'report',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: PayrollDeduction
  },
  {
    path: '/dashboard/report_insurance_report',
    name: 'Insurance Report',
    module: 'report',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: InsuranceReport
  },
  {
    path: '/dashboard/report_address_changes_report',
    name: 'Address Changes Report',
    module: 'report',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: AddressChangesReport
  },
  {
    path: '/dashboard/report_new_members',
    name: 'New Members',
    module: 'report',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: NewMembers
  },
  {
    path: '/dashboard/report_county_assessment',
    module: 'report',
    name: 'County Assessment / Comparison Report',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: CountyAssessment
  },
  {
    path: '/dashboard/report_custom_report',
    name: 'Custom Report',
    module: 'report',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: CustomReport
  },
  {
    path: '/dashboard/print_labels_and_envelopes',
    module: 'print',
    name: 'Print Labels and Envelopes',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: PrintLabelsandEnvelopes
  },
  {
    path: '/dashboard/print_labels_and_envelopes_by_users',
    name: 'Print Labels and Envelopes By Users',
    module: 'print',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: PrintLabelsandEnvelopesByUsers
  },
  {
    path: '/dashboard/settings_insurance_companies',
    name: 'Insurance Companies',
    module: 'settings',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: InsuranceCompanies
  },
  {
    path: '/dashboard/settings_insurance_policies',
    name: 'Insurance Policies',
    module: 'settings',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: InsurancePolicies
  },
  {
    path: '/dashboard/settings_sick_plans',
    module: 'settings',
    name: 'Sick Plans',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: SickPlans
  },
  {
    path: '/dashboard/settings_stations',
    name: 'Stations',
    module: 'settings',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: Stations
  },
  {
    path: '/dashboard/settings_positions',
    name: 'Positions',
    module: 'settings',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: Positions
  },
  {
    path: '/dashboard/settings_general_settings',
    module: 'settings',
    name: 'General Settings',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: GeneralSettings
  },
  {
    path: '/dashboard/settings_union_information',
    name: 'Union Information',
    module: 'settings',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: UnionInformation
  },
  {
    path: '/dashboard/settings_officers',
    name: 'Officers',
    module: 'settings',
    roles: [ROLES.developer, ROLES.administrator],
    layout: AdminLayout,
    component: Officers
  },
  {
    path: '/dashboard/settings_change_password',
    name: 'Change Password',
    module: 'settings',
    roles: [ROLES.developer, ROLES.administrator],
    component: ChangePassword,
    layout: AdminLayout
  },
  {
    path: '/dashboard/settings_change_password',
    name: 'Change Password',
    module: 'settings',
    roles: [ROLES.member],
    component: ChangePassword,
    layout: MemberLayout
  }
];

export default routes