import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reducerName as memberReducerName, getDashboard, searchMemberById } from './../../../Store/Reducers/member';
import { getMemberInsurance } from './../../../Store/Reducers/memberInsurance';
import PersonalInfo from './PersonalInfo';
import Deductions from './Deductions';
import Benefits from './Benefits';
import PayrollDeductions from './PayrollDeductions';
import BenefitResume from './BenefitResume';
import JobInfo from './JobInfo';
import Beneficiaries from './Beneficiaries';

const MemberDashboard = () => {
	const dispatch = useDispatch();
	const dashboardData = useSelector((state)=> state[memberReducerName]?.getDashboard || {});
	const memberData = useMemo(() => dashboardData.memberData || {}, [dashboardData]);

	useEffect(()=>{
		dispatch(getDashboard());
	}, [dispatch]);

	useEffect(()=>{
		if(!memberData?.member?.id) {
			return ;
		}

		dispatch(getMemberInsurance(memberData?.member?.id));
	}, [memberData, dispatch]);

	useEffect(()=>{
		if(!memberData?.member?.id) {
			return 
		}

		dispatch(searchMemberById(memberData.member.id));
	}, [dispatch, memberData]);
	
	return (<>
	<div className='row mt-5 '>
		<div className="col-12 mb-2">
			<h1 className="title-layout-header-page">Dashboard</h1>
		</div>
	</div>
	<div className='row dashboard'>
			<PersonalInfo />
			<Deductions />
			<PayrollDeductions showOnMobile />
			<Benefits />
			<PayrollDeductions hideOnMobile />
			<BenefitResume />
			<JobInfo />
			<Beneficiaries />
	</div>
	</>)
}

export default MemberDashboard