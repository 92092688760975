import React, { useEffect, useState } from 'react'
import TableComponent from '../../../Components/table_v2'
import TableHeader from './general_log_table_header';
import moment from 'moment';
import LayoutHeaderPage from '../../../Layouts/header_page'
import { useSelector, useDispatch } from 'react-redux'
import {
  reducerName as logReducerName,
	getLog,
	getLogExcelReport,
	getLogPDFReport,
	deleteLog
} from './../../../Store/Reducers/log'
import Notification from '../../../Components/notification'
import ModalComponent from './../../../Components/modal';
import DeleteIcon from './../../../Assets/Img/Icons/delete.jpg';

const GeneralLog = () => {

	const dispatch = useDispatch();
	const logData = useSelector((state)=> state[logReducerName].logData || []);
	const logFilteredData = useSelector((state)=> state[logReducerName].logFilteredData);
	const startDateFilter = useSelector((state)=> state[logReducerName].startDateFilter);
	const endDateFilter = useSelector((state)=> state[logReducerName].endDateFilter);
  const tableColumns = [
		{
			title: 'Type',
			sort: true,
      value: 'log_type',
			parseValue: (value) => {
				return value?.name
			}
		},
		{
			title: 'First Name',
      value: 'member',
			parseValue: (value) => {
				return value?.first_name
			}
		},
		{
			title: 'Middle Name',
      value: 'member',
			parseValue: (value) => {
				return value?.middle_name
			}
		},
		{
			title: 'Last Name',
      value: 'member',
			parseValue: (value) => {
				return value?.last_name
			}
		},
		{
			title: 'Suffix',
      value: 'member',
			parseValue: (value) => {
				return value?.suffix
			}
		},
		{
			title: 'Description',
      value: 'description'
		},
		{
			title: 'Date',
      value: 'created_at',
			parseValue: (value) => {
				var date = moment(value).format('L')
				var dateFormat = date.split('/')
				return `${dateFormat[0]}/${dateFormat[1]}/${dateFormat[2]}`
			}
		},
		{
			title: 'Actions',
      value: 'created_at',
			getLabelFromStore: (storeData, index) => {
				return <button 
						className='btn-icon'
						onClick={()=> {
							openModal();
							setModalTitle(null);
							setDeleteButton(true);
							setIndex(index);
							setModalDescription(`Are you sure that you want to permanently delete the log ID : ${(logFilteredData || logData)[index].id}`);
						}}
						disabled={false}>
						<img src={DeleteIcon} alt='Delete'/>Delete
					</button>;
			}
		}
	];

	const [modalIsOpen, setIsOpen] = useState(false);
	const [modalDescription, setModalDescription] = useState(null);
	const [modalTitle, setModalTitle] = useState(null);
	const [deleteButton, setDeleteButton] = useState(true);
	const [index, setIndex] = useState(null);

	const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

	useEffect(() => {

		if(!endDateFilter || !startDateFilter) {
			return;
		}

		dispatch(getLog({
			start_date: startDateFilter.slice(0, 10),
			end_date: endDateFilter.slice(0, 10)
		}));

	},[startDateFilter, endDateFilter, dispatch]);

	const handlePDFReport = () => {

		dispatch(getLogPDFReport({
			parameters: {
				start_date: startDateFilter.slice(0, 10),
				end_date: endDateFilter.slice(0, 10)
			}
		}));
	};

	const handleExcelReport = () => {
	  dispatch(getLogExcelReport({
			start_date: startDateFilter.slice(0, 10),
			end_date: endDateFilter.slice(0, 10)
		}))
	}

	return (
		<>
			<LayoutHeaderPage title="General Log"/>
			<div className="tab-content" id="pills-tabContent">
        <TableComponent
          columns={tableColumns}
          data={logFilteredData || logData}
          header={TableHeader}
        />
				<Notification />
				<div className='row mb-4 mt-4'>
					<div className='col-8 mobile-col-offset-2 col-lg-3 mb-3'>
						<button type='button' disabled={!startDateFilter || !endDateFilter} onClick={handlePDFReport} className='btn-blue btn btn-default btn-primary'>View PDF</button>
					</div>
					<div className='col-8 mobile-col-offset-2 col-lg-3 mb-3'>
						<button type='button' disabled={!startDateFilter || !endDateFilter} onClick={handleExcelReport} className='btn-orange btn btn-default btn-primary'>Export Excel</button>
					</div>
				</div>
      </div>
			<ModalComponent
				modalIsOpen={modalIsOpen}
				description={modalDescription}
				title={modalTitle}
				closeModal={closeModal}
				deleteButton={deleteButton}
				confirmModal={()=>{
					dispatch(deleteLog((logFilteredData || logData)[index].id))
					closeModal();
				}}
			/>
		</>
	)
};

export default GeneralLog;
