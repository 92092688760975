import React, { useState, useEffect } from 'react';
import LayoutHeaderPage from './../../../../Layouts/header_page';
import { useSelector, useDispatch } from 'react-redux';
import {
	getSickPlan,
	reducerName as sickPlanReducerName,
	destroySickPlan,
	setEditData
} from './../../../../Store/Reducers/sickPlan';
import TableComponent from './../../../../Components/table_v2';
import ModalComponent from './../../../../Components/modal';
import DeleteIcon from './../../../../Assets/Img/Icons/delete.jpg';
import EditIcon from  './../../../../Assets/Img/Icons/edit.jpg';
import Notification from './../../../../Components/notification';
import TableHeader from './table_header';
import Form from './form';

const SickPlans = () => {

	const dispatch = useDispatch();
	const sickPlanData = useSelector((state)=> state[sickPlanReducerName].data || []);
	const sickPlanDataFiltered = useSelector((state)=> state[sickPlanReducerName].dataFiltered);
	const gridData = sickPlanDataFiltered || sickPlanData;
	
	const tableColumns = [
		{
			title: 'Name',
			sort: true,
      value: 'name',
		},
		{
			title: 'Cost',
      value: 'cost',
			parseValue: (val)=> {
				if(!val && val !==0){
					return ''
				}

				return `$${val}`;
			}
		},
		{
			title: 'Actions',
      value: 'actions',
			className: 'td-space-table-three-buttons',
			getLabelFromStore: (storeData, index) => {
				return <>
					<button 
						className='btn-icon'
						onClick={()=> {
							setModalDescription('');
							setModalTitle('Edit Sick Plan');
							setModalBody(<div className='row modal-footer' style={{width: '500px', maxWidth: '100%'}}>
								<div className='col col-12'><Form index={index} onCancel={()=> closeModal()} onSubmit={()=>{
										closeModal();
									}} /></div>
							</div>);
							dispatch(setEditData(gridData[index]));
							openModal();
						}
					} disabled={false}>
						<img src={EditIcon} alt='Edit'/> Edit
					</button>
					<button 
						className='btn-icon'
						onClick={()=> {
							setModalTitle(null);
							setModalBody('');
							setModalDescription(`Are you sure that you want to delete permanently Sick Plan: ${gridData?.[index]?.name}`)
							setDeleteButton(true);
							setIndex(index);
							openModal();
						}
					} disabled={false}>
						<img src={DeleteIcon} alt='Delete'/>Delete
					</button>
				</>
			}
		}
	];

	useEffect(() => {
		dispatch(getSickPlan())
	}, [dispatch]);


	const [modalIsOpen, setIsOpen] = useState(false);
	const [modalDescription, setModalDescription] = useState(null);
	const [modalTitle, setModalTitle] = useState(null);
	const [deleteButton, setDeleteButton] = useState(true);
	const [index, setIndex] = useState(null);
	const [modalBody, setModalBody] = React.useState('');

	const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

	const createHandle = () => {
		setModalDescription('');
		setModalTitle('Create Sick Plan');
		setModalBody(<div className='row modal-footer' style={{width: '500px', maxWidth: '100%'}}>
			<div className='col col-12'><Form index={index} onCancel={()=> closeModal()} onSubmit={()=>{
					closeModal();
				}} /></div>
		</div>);
		dispatch(setEditData({}));
		openModal();
	};

	return (
		<>
			<LayoutHeaderPage title="Sick Plans"/>
			<div className="tab-content mt-3 pt-4" id="pills-tabContent">
				<TableComponent
					columns={tableColumns}
					data={sickPlanDataFiltered || sickPlanData}
					header={TableHeader}
				/>
				<Notification />
				<div className='row mt-5 mb-3'>
					<div className='col-12 col-lg-9'></div>
					<div className='col-2 show-on-mobile'></div>
					<div className='col-8 col-lg-3'>
						<button type='button' onClick={createHandle} disabled={false} className='btn-orange btn-default btn-primary'>Create</button>
					</div>
				</div>
			</div>
			<ModalComponent
				modalIsOpen={modalIsOpen}
				description={modalDescription}
				modalBody={modalBody}
				title={modalTitle}
				closeModal={closeModal}
				deleteButton={deleteButton}
				confirmModal={()=>{
					dispatch(destroySickPlan({
						id: gridData?.[index]?.id
					}))
					closeModal();
				}}
			/>
		</>
	)
}

export default SickPlans;
